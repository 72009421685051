import { api } from "../api";
import axios, { CancelTokenSource } from "axios";
import {
  CANCEL_REGISTRATION_ROUTE,
  COMPLETE_REGISTRATION_ROUTE,
  CONFIRM_REGISTRATION_LOGIN_ROUTE,
  CONFIRM_REGISTRATION_ROUTE,
  FETCH_ME_ROUTE,
  FORGOT_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  REGISTER_ROUTE,
  RESET_ROUTE,
  STATUS_CHECK_ROUTE,
  getConfirmRegistrationEmailRoute,
} from "../routes/auth";
import { Login } from "../types/auth";
import { TOKEN_KEY } from "../../auth/constants";

// Ação de login
export const login = async (authData: Login) => {
  const resp = await api.post(LOGIN_ROUTE, authData);
  return resp;
};

// Ação de logout
export const logout = async () => {
  const resp = await api.post(LOGOUT_ROUTE);
  return resp;
};

let cancelTokenSourceFetchMe: CancelTokenSource;

// Checa os dados do usuario logado
export const fetchMe = async () => {
  // Cancele a solicitação anterior antes de fazer uma nova solicitação
  if (cancelTokenSourceFetchMe) {
    cancelTokenSourceFetchMe.cancel();
  }

  // Cria um novo token de cancelamento
  cancelTokenSourceFetchMe = axios.CancelToken.source();
  try {
    const response = await api.get(FETCH_ME_ROUTE, {
      cancelToken: cancelTokenSourceFetchMe.token,
    });
    return response;
  } catch (error) {
    const err = (error as any).response.status;
    if (err === 401) {
      localStorage.setItem(TOKEN_KEY, "");
      window.location.replace("login");
    }
    if (axios.isCancel(error)) {
    } else {
      // Lida com erros usuais
    }
  }
};

// Criar conta
export const register = async (data: any) =>
  api.post<any>(REGISTER_ROUTE, data);

// Recuperar senha
export const forgot = async (email: any) => api.post<any>(FORGOT_ROUTE, email);

// Resetar senha na página de 'Recuperar acesso'
export const reset = async (data: any) => api.post<any>(RESET_ROUTE, data);

// Cancelar cadastro na página de 'Ativar conta'
export const cancelRegistration = async (data: any) =>
  api.post<any>(CANCEL_REGISTRATION_ROUTE, data);

// Confirmar cadastro na página de 'Ativar conta'
export const confirmRegistration = async (data: any) =>
  api.post<any>(CONFIRM_REGISTRATION_ROUTE, data);

// Resgatar o email do usuario via token
export const getConfirmRegistrationEmail = (token: string) =>
  api.get<any>(getConfirmRegistrationEmailRoute(token));

// Completar registro da conta
export const completeRegistration = async (data: any) =>
  api.post<any>(COMPLETE_REGISTRATION_ROUTE, data);

// Confirmar cadastro na página do Login
export const confirmRegistrationLogin = async (data: any) =>
  api.post<any>(CONFIRM_REGISTRATION_LOGIN_ROUTE, data);

// Rota para checar o login
export const getStatusCheck = () => api.get<any>(STATUS_CHECK_ROUTE);
