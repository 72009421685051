import { Box, FormLabel } from "@mui/material";
import { TextField } from "../../../Form";

import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import React from "react";
import { firstLetterUpperCase } from "../../parse";

interface StepZeroProps {
  formik: FormikProps<any>;
  setConditiosLength?: (e: number) => void;
  setConditionAction?: (e: string) => void;
  row?: any;
  setActive?: (e: string) => void;
  setSingleShot?: (e: string) => void;
  setSelected?: (e: string[]) => void;
  setSensorDivider?: (e: number[]) => void;
  filteredSensor?: (index: number) => any;
  setChecked?: (e: boolean) => void;
  setConditionWork?: (e: string) => void;
  setTimeUnit?: (e: string) => void;
  maxConditiosLength: number;
  planPermissions?: any;
  initialSensorConditions?: {
    sensor: string;
    operator: string;
    operatorIcon: string;
    condValue: string;
    timeCondition: string;
    timeConditionFinal: string;
  }[];
  setSensorCondition?: (
    e: {
      sensor: string;
      operator: string;
      operatorIcon: string;
      condValue: string;
      timeCondition: string;
      timeConditionFinal: string;
    }[]
  ) => void;
  initialSelectedIcon?: number[];
  setCheckedIcon?: (e: number[]) => void;
}

const StepZero = ({
  formik,
  setConditiosLength,
  setConditionAction,
  row,
  setActive,
  setSingleShot,
  setSelected,
  setSensorDivider,
  filteredSensor,
  setChecked,
  setConditionWork,
  maxConditiosLength,
  planPermissions,
  initialSensorConditions,
  initialSelectedIcon,
  setCheckedIcon,
  setSensorCondition,
  setTimeUnit,
}: StepZeroProps) => {
  const { t } = useTranslation();

  let initialSelectedState = Array.from(
    { length: maxConditiosLength },
    () => "Sensor"
  );
  let initialSensorDividerState = Array.from(
    { length: maxConditiosLength },
    () => 0
  );

  React.useEffect(() => {
    for (let i = 0; i < row?.conditions?.length; i++) {
      initialSelectedState[i] =
        row?.conditions[i]?.posSensor === -2
          ? firstLetterUpperCase(t("TEXT.TIME"))
          : row?.conditions[i]?.posSensor === -1
          ? firstLetterUpperCase(t("TEXT.STATE"))
          : "Sensor";

      initialSensorDividerState[i] =
        filteredSensor && (filteredSensor(i) as any)?.max[1];
    }
    if (setSelected) {
      setSelected(initialSelectedState);
    }
    if (setSensorDivider) {
      setSensorDivider(initialSensorDividerState);
    }
    if (setChecked) {
      setChecked(row?.disable_on_error);
    }
    if (setConditionWork) {
      setConditionWork(row.operator);
    }

    if (setTimeUnit) {
      setTimeUnit("minutes");
    }

    if (
      initialSelectedIcon &&
      initialSensorConditions &&
      setSensorCondition &&
      setCheckedIcon
    ) {
      for (let i = 0; i < planPermissions?.NUM_CONDITIONS_SCENARIOS; i++) {
        initialSelectedIcon[i] = 0;
      }
      let index = 0;
      row?.conditions.forEach(
        (condition: {
          posSensor: string;
          operador: string;
          valorReferencia: any;
          valorReferencia_2: any;
        }) => {
          if (index < planPermissions?.NUM_CONDITIONS_SCENARIOS) {
            initialSensorConditions[index].sensor =
              +condition?.posSensor >= 0 ? condition?.posSensor : "";
            initialSensorConditions[index].operator =
              +condition?.posSensor !== -1 ? condition?.operador : "";
            initialSensorConditions[index].operatorIcon =
              +condition?.posSensor === -1 ? condition?.operador : "";
            initialSensorConditions[index].condValue =
              +condition?.posSensor === -2
                ? ""
                : +condition?.posSensor >= 0
                ? initialSensorDividerState[index] > 0
                  ? condition?.valorReferencia /
                    initialSensorDividerState[index]
                  : condition?.valorReferencia
                : condition?.valorReferencia;

            initialSensorConditions[index].timeCondition =
              +condition?.posSensor === -2 ? condition?.valorReferencia : "";
            initialSensorConditions[index].timeConditionFinal =
              +condition?.posSensor === -2 &&
              initialSensorConditions[index].operator === "entre"
                ? condition?.valorReferencia_2
                : "";
            if (+condition?.posSensor === -1) {
              initialSelectedIcon[index] =
                +initialSensorConditions[index].condValue;
            }
            index += 1;
          }
        }
      );
      setCheckedIcon(initialSelectedIcon);
      setSensorCondition(initialSensorConditions);
    }

    // Definição das ações
    const valueSetAction =
      row?.action_obj.commands[0].resource.split("/")[0] === "acoes"
        ? "0"
        : row?.action_obj.commands[0].resource.split("/")[0] === "parametros"
        ? "1"
        : row?.action_obj.commands[0].resource.split("/")[0] === "setpoints"
        ? "2"
        : "3";

    // Setar valore iniciais
    if (setConditionAction) {
      setConditionAction(valueSetAction);
    }
    if (setConditiosLength) {
      setConditiosLength(row?.conditions?.length);
    }
    if (setSingleShot) {
      setSingleShot(
        row.trigger_type === "single"
          ? "0"
          : row.trigger_type === "cyclic" && row.histerese !== "1440"
          ? "1"
          : "2"
      );
    }
    if (setActive) {
      setActive(row.enabled === true ? "true" : "false");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <FormLabel sx={{ textAlign: "left" }}>{`${t("TEXT.NAME")}:`}</FormLabel>
      <Box m={1} mb={2}>
        <TextField
          formik={formik}
          disabled={false}
          label=""
          name="name"
          maxLength={100}
          fullWidth
        />
      </Box>
      <FormLabel sx={{ textAlign: "left" }}>{`${t(
        "TEXT.DESCRIPTION"
      )}:`}</FormLabel>
      <Box display="grid" m={1}>
        <TextField
          formik={formik}
          disabled={false}
          label=""
          name="description"
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default StepZero;
