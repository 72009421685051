import React from "react";

import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  DoneOutline as DoneOutlineIcon,
  ErrorOutline,
  CloudQueue,
  CloudOff,
} from "@mui/icons-material";
import Gauge from "../Gauge.";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import { useTranslation } from "react-i18next";
import { green, red } from "@mui/material/colors";
import ModalApply from "../Modal/Apply";
import Spinner from "../../../components/Spinner";
import {
  checkIfIsComIntegar,
  checkIfIsComNotIntegar,
  checkIfIsComUnit,
} from "../parse";
import {
  MeasuresData,
  RowData,
} from "../../../components/Controller/parser/types";

let intervalId: any;
let intervalIdInc: any;

interface BodyProps {
  checked: boolean;
  data: any;
  submittingApply: boolean;
  startIntervalApply: () => void;
  devicesError: number;
  value: number;
  setValue: (e: any) => void;
  changingSetpoint: boolean;
  bgColor: string;
  mainMeasure: MeasuresData;
  secondaryMeasures: MeasuresData[];
  filteredSetpoint: RowData;
  editSetpointIsValid: boolean;
  devicesOffline: number;
}

const Body = ({
  checked,
  data,
  submittingApply,
  startIntervalApply,
  devicesError,
  value,
  setValue,
  changingSetpoint,
  mainMeasure,
  secondaryMeasures,
  bgColor,
  filteredSetpoint,
  editSetpointIsValid,
  devicesOffline,
}: BodyProps) => {
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeModeContext);
  const [submitValue, setSubmitValue] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingSector, setLoadingSector] = React.useState(true);
  // Ação de aumentar o setpoint
  const [isPressingAdd, setIsPressingAdd] = React.useState(false);
  const [incrementAdd, setIncrementAdd] = React.useState(0.1);
  // Ação de diminuir o setpoint
  const [isPressingRemove, setIsPressingRemove] = React.useState(false);
  const [incrementRemove, setIncrementRemove] = React.useState(0.1);

  // Ação de adicionar Setpoint no setor
  React.useEffect(() => {
    if (isPressingAdd) {
      intervalId = setInterval(() => {
        setValue((prevValue: number) =>
          prevValue >= +data.resource_limit[`${data.default_resource}`].max
            ? +data.resource_limit[`${data.default_resource}`].max
            : filteredSetpoint.type === "INT"
            ? prevValue + 1
            : prevValue + incrementAdd
        );
      }, 70);
      intervalIdInc = setInterval(() => {
        /* setValue((prevValue: number) => Math.round(prevValue)); */
        setIncrementAdd(1);
      }, 2000);
    }
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalIdInc);
      if (!isPressingAdd) {
        setIncrementAdd(0.1);
      }
    };
  }, [isPressingAdd, incrementAdd, data, setValue, filteredSetpoint]);
  const handleMouseDownAdd = () => {
    setIsPressingAdd(true);
  };
  const handleMouseUpAdd = () => {
    setIsPressingAdd(false);
  };

  // Ação de decrementar Setpoint no setor
  React.useEffect(() => {
    if (isPressingRemove) {
      intervalId = setInterval(() => {
        setValue((prevValue: number) =>
          prevValue <= +data.resource_limit[`${data.default_resource}`].min
            ? +data.resource_limit[`${data.default_resource}`].min
            : filteredSetpoint.type === "INT"
            ? prevValue - 1
            : prevValue - incrementRemove
        );
      }, 70);
      intervalIdInc = setInterval(() => {
        /* setValue((prevValue: number) => Math.round(prevValue)); */
        setIncrementRemove(1);
      }, 2000);
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(intervalIdInc);
      if (!isPressingRemove) {
        setIncrementRemove(0.1);
      }
    };
  }, [data, incrementRemove, isPressingRemove, setValue, filteredSetpoint]);
  const handleMouseDownRemove = () => {
    setIsPressingRemove(true);
  };
  const handleMouseUpRemove = () => {
    setIsPressingRemove(false);
  };

  // Ações de abrir o modal de aplicar setpoint
  const [openModalApply, setOpenModalApply] = React.useState<boolean>(false);
  const handleOpenModalApply = () => setOpenModalApply(true);
  const handleCloseModalApply = () => {
    setOpenModalApply(false);
  };

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (data.sectorCtrl && data.sectorCtrl?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [loading, data.sectorCtrl]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  // Filtrar controladores online
  const ctrls = data.ctrls ?? [];
  const filteredCtrls = ctrls.filter((f: any) => f.ctrl.includes("CTRL"));
  const devicesOnline = filteredCtrls?.filter(
    (f: any) => f.online === true
  ).length;

  // Loading do Spinner dos controladores do Setor
  React.useEffect(() => {
    const fetchStatus = () => {
      if (devicesOnline && devicesOnline?.length !== 0 && loadingSector) {
        setLoadingSector(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [loadingSector, devicesOnline]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoadingSector(false);
    }, 12000);
  }, []);

  // Validação final do botão de incrementar, decrementar e aplicar Setpoint
  const actionsSetpointIsValid =
    checked || editSetpointIsValid || changingSetpoint;

  return (
    <Box
      mt={-0.5}
      pt={0.5}
      sx={{
        backgroundColor: bgColor,
        color: "white",
        display: "grid",
        textAlign: "right",
      }}
    >
      <Box>
        <Box display="flex" ml={2.5} pt={2} position="relative">
          <Box
            display="flex"
            width={83}
            flex="33%"
            sx={{ placeContent: "center", position: "absolute" }}
          >
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={70}
                height={30}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <Typography fontSize={26} fontWeight={500} pt={1}>
                <Tooltip
                  title={
                    mainMeasure?.description ?? t("TEXT.REFERENCE_OFFLINE")
                  }
                >
                  <Box component="span">
                    {mainMeasure
                      ? !Number.isInteger(mainMeasure.value)
                        ? checkIfIsComNotIntegar(mainMeasure.value, "--")
                        : checkIfIsComIntegar(mainMeasure.value, "--")
                      : "--"}
                  </Box>
                </Tooltip>
              </Typography>
            )}
            <Typography fontSize={13} fontWeight={500} pt={1.8} pl={0.2}>
              {mainMeasure
                ? checkIfIsComUnit(
                    mainMeasure.value,
                    mainMeasure?.unitOfMeasurement
                  )
                : ""}
            </Typography>
          </Box>
          {changingSetpoint === true || loadingSector ? (
            <Box mt={4.6} mb={3} ml={11.5} mr={1}>
              <Spinner size={100} disableMargin />
            </Box>
          ) : (
            <Box mt={-1.5}>
              {editSetpointIsValid ? (
                <Box mt="47.6px" mb={2} ml={8}>
                  <CircularProgress
                    variant="determinate"
                    value={63}
                    size={160}
                    style={{ transform: "rotate(157deg)" }}
                    sx={{
                      mb: -15.6,
                      height: 50,
                      color:
                        theme === "light"
                          ? "rgba(0,0,0,0.25)"
                          : "rgba(255,255,255,0.3)",
                    }}
                  />
                  <Typography textAlign="center" fontSize={45} fontWeight={500}>
                    OFF
                  </Typography>
                </Box>
              ) : (
                <>
                  <Gauge
                    value={value}
                    minValue={
                      data.resource_limit[`${data.default_resource}`].min
                    }
                    maxValue={
                      data.resource_limit[`${data.default_resource}`].max
                    }
                    unit={filteredSetpoint?.unitOfMeasurement}
                  />
                  <Tooltip title={filteredSetpoint.description}>
                    <Typography
                      textAlign="center"
                      mt={-13}
                      mb={7}
                      ml={11.8}
                      fontSize={14}
                      fontWeight={500}
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      width={100}
                    >
                      {filteredSetpoint.description}
                    </Typography>
                  </Tooltip>
                </>
              )}
            </Box>
          )}
          <Box display="flex" flex="33%" sx={{ placeContent: "center" }} mr={2}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={70}
                height={30}
                sx={{ borderRadius: "5px", ml: -2 }}
              />
            ) : (
              <Typography fontSize={26} fontWeight={500} pt={1}>
                <Tooltip
                  title={
                    secondaryMeasures ? secondaryMeasures[0]?.description : ""
                  }
                >
                  <Box component="span">
                    {secondaryMeasures && secondaryMeasures[0]
                      ? !Number.isInteger(secondaryMeasures[0].value)
                        ? checkIfIsComNotIntegar(secondaryMeasures[0].value, "")
                        : checkIfIsComIntegar(secondaryMeasures[0].value, "")
                      : ""}
                  </Box>
                </Tooltip>
              </Typography>
            )}
            <Typography fontSize={13} fontWeight={500} pt={1.8} pl={0.2}>
              {secondaryMeasures && secondaryMeasures[0]
                ? checkIfIsComUnit(
                    secondaryMeasures[0].value,
                    secondaryMeasures[0].unitOfMeasurement
                  )
                : ""}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt={-2.5}>
          <Box>
            <IconButton
              color="inherit"
              onMouseDown={handleMouseDownRemove}
              onMouseUp={handleMouseUpRemove}
              onMouseLeave={handleMouseUpRemove}
              onTouchStart={handleMouseDownRemove}
              onTouchEnd={handleMouseUpRemove}
              disabled={actionsSetpointIsValid || submittingApply === true}
            >
              <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Box>
          <Box textAlign="center" ml={0.8} mr={0.8} mt={2.5}>
            <Avatar
              sx={{
                backgroundColor: actionsSetpointIsValid
                  ? "transparent"
                  : "white",
                boxShadow: actionsSetpointIsValid
                  ? theme === "light"
                    ? "0px 0px 0px 3.4px rgba(0,0,0,0.25)"
                    : "0px 0px 0px 3.4px rgba(255,255,255,0.3)"
                  : "",
                color: "black",
                width: 45,
                height: 45,
              }}
            >
              {submittingApply === true ? (
                <Spinner size={25} />
              ) : (
                <IconButton
                  onClick={handleOpenModalApply}
                  color="primary"
                  disabled={actionsSetpointIsValid}
                >
                  <Tooltip title={`${t("BUTTON.APPLY")} Setpoint`}>
                    <DoneOutlineIcon
                      sx={{
                        fontSize: 35,
                      }}
                    />
                  </Tooltip>
                </IconButton>
              )}
            </Avatar>
          </Box>
          <Box>
            <IconButton
              color="inherit"
              onMouseDown={handleMouseDownAdd}
              onMouseUp={handleMouseUpAdd}
              onMouseLeave={handleMouseUpAdd}
              onTouchStart={handleMouseDownAdd}
              onTouchEnd={handleMouseUpAdd}
              disabled={actionsSetpointIsValid || submittingApply === true}
            >
              <AddCircleOutlineIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          ml={2}
          mt={2}
          height={50}
          width={300}
        >
          <Box
            display="flex"
            mt={-2.5}
            flex="33%"
            sx={{ placeContent: "center" }}
          >
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={70}
                height={30}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <Typography fontSize={26} fontWeight={500}>
                <Tooltip
                  title={
                    secondaryMeasures ? secondaryMeasures[1]?.description : ""
                  }
                >
                  <Box component="span">
                    {secondaryMeasures && secondaryMeasures[1]
                      ? !Number.isInteger(secondaryMeasures[1].value)
                        ? checkIfIsComNotIntegar(secondaryMeasures[1].value, "")
                        : checkIfIsComIntegar(secondaryMeasures[1].value, "")
                      : ""}
                  </Box>
                </Tooltip>
              </Typography>
            )}
            <Typography fontSize={13} fontWeight={500} pt={1} pl={0.2} pb={6}>
              {secondaryMeasures && secondaryMeasures[1]
                ? checkIfIsComUnit(
                    secondaryMeasures[1].value,
                    secondaryMeasures[1].unitOfMeasurement
                  )
                : ""}
            </Typography>
          </Box>
          <Box display="flex" flex="33%" sx={{ placeContent: "center" }}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={70}
                height={30}
                sx={{ borderRadius: "5px", mb: 2 }}
              />
            ) : (
              <Typography fontSize={26} fontWeight={500}>
                <Tooltip
                  title={
                    secondaryMeasures ? secondaryMeasures[2]?.description : ""
                  }
                >
                  <Box component="span">
                    {secondaryMeasures && secondaryMeasures[2]
                      ? !Number.isInteger(secondaryMeasures[2].value)
                        ? checkIfIsComNotIntegar(secondaryMeasures[2].value, "")
                        : checkIfIsComIntegar(secondaryMeasures[2].value, "")
                      : ""}
                  </Box>
                </Tooltip>
              </Typography>
            )}
            <Typography fontSize={13} fontWeight={500} pt={1} pb={3}>
              {secondaryMeasures && secondaryMeasures[2]
                ? checkIfIsComUnit(
                    secondaryMeasures[2].value,
                    secondaryMeasures[2].unitOfMeasurement
                  )
                : ""}
            </Typography>
          </Box>
          <Box
            display="flex"
            flex="33%"
            mt={-2.5}
            sx={{ placeContent: "center" }}
          >
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={70}
                height={30}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <Typography fontSize={26} fontWeight={500}>
                <Tooltip
                  title={
                    secondaryMeasures ? secondaryMeasures[3]?.description : ""
                  }
                >
                  <Box component="span">
                    {secondaryMeasures && secondaryMeasures[3]
                      ? !Number.isInteger(secondaryMeasures[3].value)
                        ? checkIfIsComNotIntegar(secondaryMeasures[3].value, "")
                        : checkIfIsComIntegar(secondaryMeasures[3].value, "")
                      : ""}
                  </Box>
                </Tooltip>
              </Typography>
            )}
            <Typography fontSize={13} fontWeight={500} pt={1} pl={0.2} pb={6}>
              {secondaryMeasures && secondaryMeasures[3]
                ? checkIfIsComUnit(
                    secondaryMeasures[3].value,
                    secondaryMeasures[3].unitOfMeasurement
                  )
                : ""}
            </Typography>
          </Box>
        </Box>
        <Paper
          sx={{
            ml: 4,
            mr: 4,
            mb: 4,
            pt: 1,
            pb: 1,
            borderRadius: "20px",
            backgroundColor: "white",
            height: 40,
          }}
          elevation={0}
        >
          <Box display="flex" justifyContent="space-around">
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={35}
                height={25}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <Tooltip title={t("TEXT.DEVICES_ONLINE")}>
                <Box display="flex">
                  <Typography fontWeight={700} color={green[600]}>
                    {devicesOnline}
                  </Typography>
                  <CloudQueue sx={{ color: green[600], ml: 0.5 }} />
                </Box>
              </Tooltip>
            )}
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={35}
                height={25}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <Tooltip title={t("TEXT.DEVICES_OFFLINE")}>
                <Box display="flex">
                  <Typography fontWeight={700} color="black">
                    {devicesOffline}
                  </Typography>
                  <CloudOff sx={{ color: "black", ml: 0.5 }} />
                </Box>
              </Tooltip>
            )}
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={35}
                height={25}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <Tooltip title={t("TEXT.DEVICES_ERROR")}>
                <Box display="flex">
                  <Typography fontWeight={700} color={red[600]}>
                    {devicesError}
                  </Typography>
                  <ErrorOutline sx={{ color: red[600], ml: 0.5 }} />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Paper>
      </Box>
      <ModalApply
        data={data}
        handleClose={handleCloseModalApply}
        handleOpen={openModalApply}
        setSubmitValue={setSubmitValue}
        value={value}
        submitValue={submitValue}
        setValue={setValue}
        startIntervalApply={startIntervalApply}
      />
    </Box>
  );
};

export default Body;
