import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface AccordionMacListProviderProps {
  children: ReactNode;
}

interface AccordionMacListData {
  accordionMacList: string[];
  setAccordionMacList: Dispatch<SetStateAction<string[]>>;
}

export const AccordionMacListContext = createContext(
  {} as AccordionMacListData
);

export const AccordionMacListProvider = ({
  children,
}: AccordionMacListProviderProps) => {
  const [accordionMacList, setAccordionMacList] = useState<string[]>([]);

  const AccordionMacListProviderValue = useMemo(
    () => ({
      accordionMacList,
      setAccordionMacList,
    }),
    [accordionMacList]
  );

  return (
    <AccordionMacListContext.Provider value={AccordionMacListProviderValue}>
      {children}
    </AccordionMacListContext.Provider>
  );
};
