// Payload de um card offline com os dados do Dashboard
export const cardOffline = (model: string, ctrl: string, rotulo: string) => {
  const cardOffline = {
    lastMsg: 1722881229990,
    end: +ctrl.split("L")[1],
    modelo: model,
    serial: "",
    rotulo: rotulo,
    alarme: 65536,
    estado: 0,
    medidas: {
      m1: 0,
      m2: 0,
      m3: 0,
      m4: 0,
      m5: 0,
    },
    setpoints: {
      s1: 0,
      s2: 0,
      s3: 0,
      s4: 0,
      s5: 0,
    },
  };
  return cardOffline;
};
