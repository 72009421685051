import React from "react";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
  Alert,
  AlertTitle,
  Divider,
  FormControlLabel,
} from "@mui/material";
import {
  HighlightOffSharp as HighlightOffIcon,
  ViewList as ViewListIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  FeedOutlined as FeedOutlinedIcon,
  PersonOutline as PersonOutlineIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  HelpOutline as HelpOutlineIcon,
  PasswordOutlined as PasswordOutlinedIcon,
  AttachMoneyOutlined as AttachMoneyOutlinedIcon,
  SmsFailedOutlined as SmsFailedOutlinedIcon,
  DevicesOutlined as DevicesOutlinedIcon,
  ContentCopy as ContentCopyIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  LocalOffer as LocalOfferIcon,
  DesktopAccessDisabled as DesktopAccessDisabledIcon,
} from "@mui/icons-material";

import {
  editDevice,
  getControllersOfDevice,
  getDevice,
  getDeviceAccessCode,
} from "../../../services/data/arcsys";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { TOKEN_KEY } from "../../../auth/constants";
import { api } from "../../../services/api";
import Spinner from "../../../components/Spinner";
import { convertToDateInRow, uniqueArray } from "./parse";
import { getUserById } from "../../../services/data/user";
import AvatarIcon from "../../../components/AvatarIcon";
import { green, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import ModalEditAlertCom from "./Modal/ModalEditAlertCom";
import ModalUnshare from "../Modal/ModalUnshare";
import ModalEditName from "./Modal/ModalEditName";
import ModalRemoveController from "./Modal/ModalRemoveController";
import useIsMobile from "../../../hooks/useIsMobile";
import { getLanguage } from "../../../translations/languages";
import { PLANS_AND_SUBSCRIPTIONS } from "../../../Routes/constants";
import {
  convertToDateTime,
  selectNameByTranslations,
} from "../../PlansAndSubscriptions/parse";
import ModalComFailureQuestion from "./Modal/ModalComFailureQuestion";
import { ToastContext } from "../../../contexts/ToastContext";
import { TagsContext } from "../../../contexts/TagsContext";
import { getDashboardTags } from "../../../services/data/TAGs";
import ModalDeleteTag from "../../../components/Controller/SettingsTable/Modal/Tag/ModalDelete";
import ModalAddTag from "../../../components/Controller/SettingsTable/Modal/Tag/ModalAdd";
import ModalSelectTag from "../../../components/Controller/SettingsTable/Modal/Tag/ModalSelect";
import Switch from "../../../components/Switch";
import { DashboardFilterContext } from "../../../contexts/DashboardFilterContext";

let userList: any = [];

const DevicePage = () => {
  const params = useParams();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1370;
  const navigate = useNavigate();
  const language = getLanguage().code;
  const { t } = useTranslation();
  const { token, authenticated, userArcsys, userId } =
    React.useContext(AuthContext);
  const { toastSuccess, toastError } = React.useContext(ToastContext);
  const { tagsList, setTagsList, globalTagEdited, setGlobalTagEdited } =
    React.useContext(TagsContext);
  const { setDashboardDeviceEdited } = React.useContext(DashboardFilterContext);
  const [device, setDevice] = React.useState<any>();
  const [controllers, setControllers] = React.useState<any>();
  const [accessCode, setAccessCode] = React.useState<any>();
  const [devicePermission, setDevicePermission] = React.useState<string>();
  const [showAccessCode, setShowAccessCode] = React.useState<Boolean>(false);
  const [openModalEditName, setOpenModalEditName] = React.useState(false);
  const [modalEditNameValues, setModalEditNameValues] = React.useState<any>();
  const [openModalUnshare, setOpenModalUnshare] = React.useState(false);
  const [modalUnshareValues, setModalUnshareValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const [openModalEditAlertCom, setOpenModalEditAlertCom] =
    React.useState(false);
  const [modalEditAlertComValues, setModalEditAlertComValues] =
    React.useState<any>();
  const [deviceEdited, setDeviceEdited] = React.useState<boolean>(false);
  const [openModalComFailureQuestion, setOpenModalComFailureQuestion] =
    React.useState(false);
  const [tagEdited, setTagEdited] = React.useState<boolean>(false);
  const id = params?.addr && +params?.addr;

  // Ação de alterar a apresentação dos cards offline no Dashboard
  const handleChangeHidden = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (device) {
      // Novos dados do dispositivo
      const newDevice = {
        ...device,
        showOffline: !device.showOffline,
      };

      // Editar os dados do Dispositivo
      editDevice(device.id, newDevice)
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.DEVICE_EDITED"));
          setDeviceEdited(true);
          setDashboardDeviceEdited(true);
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.DEVICE_EDITED"));
        });
    } else {
      toastError(t("TOAST.ERROR.DEVICE_EDITED"));
    }
  };

  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  // Resgatar as TAGs dos controladores
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      if ((tagsList.length === 0 || globalTagEdited === true) && id) {
        getDashboardTags(+id).then((res) => {
          const { data } = res;
          setGlobalTagEdited(false);
          setTagsList(data);
          setLoading(false);
        });
      }
    }
  }, [
    authenticated,
    globalTagEdited,
    id,
    setGlobalTagEdited,
    setTagsList,
    tagsList.length,
    token,
  ]);

  // Atualizar os dados da TAG após selecionar alguma TAG
  React.useEffect(() => {
    if (tagEdited && id) {
      // Resgatar os dados do usuário
      getDashboardTags(+id).then((res) => {
        const { data } = res;
        setGlobalTagEdited(false);
        setTagsList(data);
        setLoading(false);
      });
      setTagEdited(false);
    }
  }, [id, setGlobalTagEdited, setTagsList, tagEdited]);

  // Checar se a TAG existe no controlador
  const checkIsTagExist = (id: string) =>
    tagsList.length > 0 &&
    tagsList
      .map((m: any) => {
        return m.ctrl_ids.includes(id) ? true : false;
      })
      .includes(true);

  // Ações de abrir o modal de adicionar TAG
  const [openModalAdd, setOpenModalAdd] = React.useState<boolean>(false);
  const [modalAddValuesTag, setModalAddValuesTag] = React.useState<any>();
  const handleOpenModalAdd = (e: any, ctrlId: string) => {
    e.stopPropagation();
    setModalAddValuesTag({ ctrlId });
    setOpenModalAdd(true);
  };
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Ações de abrir o modal de selecionar TAG
  const [openModalSelect, setOpenModalSelect] = React.useState<boolean>(false);
  const [modalSelectValuesTag, setModalSelectValuesTag] = React.useState<any>();
  const handleOpenModalSelect = (e: any, ctrlId: string) => {
    e.stopPropagation();
    setModalSelectValuesTag({ ctrlId });
    setOpenModalSelect(true);
  };
  const handleCloseModalSelect = () => {
    setOpenModalSelect(false);
  };

  // Ações de abrir o modal de deletar TAG
  const [openModalDeleteTag, setOpenModalDeleteTag] =
    React.useState<boolean>(false);
  const [modalDeleteValuesTag, setModalDeleteValuesTag] = React.useState<any>();
  const handleOpenModalDeleteTag = (e: any, tag: any, ctrlId: string) => {
    e.stopPropagation();
    setModalDeleteValuesTag({ tag, ctrlId });
    setOpenModalDeleteTag(true);
  };
  const handleCloseModalDeleteTag = () => {
    setOpenModalDeleteTag(false);
  };

  // Ações para visualizar o Código de acesso
  const handleClickShowAccessCode = () => setShowAccessCode((show) => !show);
  const handleMouseDownAccessCode = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // Ações do modal de dúvidas sobre o Alerta de Falha de Comunicação
  const handleOpenModalComFailureQuestion = () => {
    setOpenModalComFailureQuestion(true);
  };
  const handleCloseModalComFailureQuestion = () => {
    setOpenModalComFailureQuestion(false);
  };

  // Ações do modal de editar nome
  const handleOpenModalEditName = () => {
    setModalEditNameValues({
      name: device.name,
      id: device.id,
      allDevice: device,
    });
    setOpenModalEditName(true);
  };
  const handleCloseModalEditName = () => {
    setOpenModalEditName(false);
  };

  // Ações do modal de editar alerta de falha de comunicação
  const handleOpenModalEditAlertCom = () => {
    setModalEditAlertComValues({
      id: device.id,
      allDevice: device,
    });
    setOpenModalEditAlertCom(true);
  };
  const handleCloseModalEditAlertCom = () => {
    setOpenModalEditAlertCom(false);
  };

  // Ações do modal de descompartilhar
  const handleOpenModalUnshare = (e: any, device: string, id: string) => {
    e.stopPropagation();
    setModalUnshareValues({ device, id });
    setOpenModalUnshare(true);
  };
  const handleCloseModalUnshare = () => {
    setOpenModalUnshare(false);
  };

  // Ações do modal de deletar o controlador
  const handleOpenModalDelete = (e: any, row: any) => {
    e.stopPropagation();
    setModalDeleteValues({ row });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  React.useEffect(() => {
    userList = [];
    const mac = params.mac ?? "";

    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      // Resgatar dados gerais do Dispositivo
      getDevice(mac).then((res) => {
        setDevice(res.data);

        // Lista de usuários do dispositivo
        res.data?.users.map((u: any) => {
          // Resgatar os dados do usuário
          if (+userId !== u.id) {
            getUserById(u.id).then((res) => {
              if (res?.data) {
                const userData = {
                  name: res.data.name,
                  permission: u.role,
                  status: !u.pending,
                  id: +res.data.id,
                };
                userList.push(userData);
              }
            });
          } else {
            const userData = {
              name: userArcsys?.name,
              permission: u.role,
              status: !u.pending,
              id: +userArcsys?.id,
            };
            userList.push(userData);
          }
          return u;
        });

        // Checar a permissão do usuário
        const filterPermissions = res.data?.users.filter(
          (f: any) => f.id === +userId
        )[0];
        setDevicePermission(filterPermissions?.role);

        // Resgatar e armazenar os Controladores do dispositivo em um useState
        getControllersOfDevice(res.data?.id).then((res) => {
          setControllers(res.data.items);
        });

        // Resgatar e armazenar o código de acesso em um useState
        getDeviceAccessCode(mac).then((res) => {
          setAccessCode(res.data);
        });
      });
    }
  }, [authenticated, params, token, userArcsys, userId]);

  // Atualizar os dados do Dispositivo após a edição de qualquer dado
  React.useEffect(() => {
    if (deviceEdited) {
      userList = [];
      const mac = params.mac ?? "";
      getDevice(mac).then((res) => {
        setDevice(res.data);

        // Lista de usuários do disositivo
        res.data?.users.map((u: any) => {
          // Resgatar os dados do usuário
          getUserById(u.id).then((res) => {
            if (res?.data) {
              const userData = {
                name: res.data.name,
                permission: u.role,
                status: !u.pending,
                id: +res.data.id,
              };
              userList.push(userData);
            }
          });
          return u;
        });

        // Chegar a permissão do usuário
        const filterPermissions = res.data?.users.filter(
          (f: any) => f.id === +userId
        )[0];
        setDevicePermission(filterPermissions?.role);

        // Resgatar e armazenar os Controladores do dispositivo em um useState
        getControllersOfDevice(res.data?.id).then((res) => {
          setControllers(res.data.items);
        });

        // Resgatar e armazenar o código de acesso em um useState
        getDeviceAccessCode(mac).then((res) => {
          setAccessCode(res.data);
        });
      });
      setDeviceEdited(false);
      // if (+userId) {
      //   getUserById(+userId).then((res) => {
      //     if (res?.data) {
      //       const { data } = res as any;
      //       // Resgatar os arcsys do usuário logado
      //       setUserArcsys(data);
      //       localStorage.setItem("userId", JSON.stringify(data._id));
      //     }
      //   });
      // }
    }
  }, [deviceEdited, params.mac, setDeviceEdited, userId]);

  // Data do cadastro do Dispositivo
  const deviceCreation = convertToDateInRow(
    device?.creationTs,
    localStorage.lang
  );

  // Data do início do Plano do Dispositivo
  const planCreation =
    device &&
    convertToDateInRow(
      new Date(
        device?.subscriptions
          .filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
          )
          .reverse()[0]?.created_at
      ).getTime(),
      localStorage.lang
    );

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (uniqueArray(userList).length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  // Nome do Plano do Dispositivo
  const planName =
    language === "es"
      ? device &&
        device?.subscriptions
          .filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
          )
          .reverse()[0]?.plan.data[2].name
      : language === "en"
      ? device?.subscriptions
          .filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
          )
          .reverse()[0]?.plan.data[1].name
      : device?.subscriptions
          .filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
          )
          .reverse()[0]?.plan.data[0].name;

  selectNameByTranslations(
    device?.subscriptions
      .filter(
        (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
      )
      .reverse()[0],
    language
  );

  // Ação de copiar o Código de Acesso
  async function copyAccessCode() {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(accessCode);
      toastSuccess(t("TOAST.SUCCESS.ACCESS_CODE_COPIED"));
    }
  }

  return device ? (
    <>
      <Grid container sx={{ placeContent: "center" }}>
        <Box m={3.5} mt={0}>
          <Box position="sticky" top="90px">
            <Card sx={{ mt: 0 }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pt: 3.5,
                    pb: 3.5,
                    pl: 5,
                    pr: 5,
                    borderRadius: 4,
                    mb: 2.4,
                    backgroundColor: red[100],
                  }}
                >
                  <Avatar
                    sx={{ width: 120, height: 120, boxShadow: 3, ml: 4, mr: 4 }}
                  >
                    <AvatarIcon
                      name={device.name}
                      width={120}
                      height={120}
                      fontSize={70}
                      bgcolor={red[400]}
                    />
                  </Avatar>
                </Box>
                <Typography
                  fontSize={25}
                  fontWeight={500}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textAlign="center"
                  width={260}
                >
                  {device.name}
                </Typography>
                <Box display="flex" justifyContent="center" mt={0.5} mb={0.5}>
                  <Typography fontWeight={500} fontSize={14}>
                    MAC:
                  </Typography>
                  <Typography ml={0.6} fontSize={14}>
                    {device.mac}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" mt={0.5} mb={0.5}>
                  <Typography
                    ml={0.6}
                    fontSize={14}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textAlign="center"
                    width={260}
                  >
                    {device?.owner?.email}
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardContent>
                <Box display="flex" justifyContent="center" mt={0.5} mb={0.5}>
                  <Typography fontWeight={500}>
                    {t("TEXT.REGISTERED_AT")}:
                  </Typography>
                  <Typography ml={0.6}>{deviceCreation}</Typography>
                </Box>
                <Box display="flex" justifyContent="center" mt={0.5}>
                  <Typography fontWeight={500}>
                    {t("TEXT.BEGINNING_PLAN")}:
                  </Typography>
                  <Typography ml={0.6}>{planCreation}</Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardContent>
                <Box mt={1} display="flex" justifyContent="center" width={260}>
                  {(devicePermission === "OWNER" || userIsAdmin) && (
                    <Box>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        textAlign="center"
                      >
                        {device?.users?.length}
                      </Typography>
                      <Typography>{t("TEXT.USERS")}</Typography>
                    </Box>
                  )}
                  <Box ml={devicePermission === "OWNER" || userIsAdmin ? 4 : 0}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      textAlign="center"
                    >
                      {controllers?.length}
                    </Typography>
                    <Typography>{t("TEXT.CONTROLLERS")}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          {(devicePermission === "OWNER" || userIsAdmin) && (
            <Paper sx={{ p: mobile ? 0 : 3.5, borderRadius: 3 }} elevation={3}>
              <Box
                display="flex"
                alignItems="center"
                pt={mobile ? 2 : 0}
                pl={mobile ? 2 : 0}
                pb={1.5}
              >
                <Box sx={{ mr: 1 }}>
                  <FeedOutlinedIcon fontSize="large" />
                </Box>
                <Typography fontSize={mobile ? 25 : 28} gutterBottom>
                  {t("TEXT.BASIC_INFO")}
                </Typography>
              </Box>
              <Table size="small">
                <TableBody>
                  <TableRow hover>
                    <TableCell align="left">
                      <Box display="flex">
                        <DevicesOutlinedIcon sx={{ alignSelf: "center" }} />
                        <Typography sx={{ ml: 2 }}>{t("TEXT.NAME")}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Typography textAlign="center">{device.name}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      {mobile || smallWindow ? (
                        <IconButton
                          color="primary"
                          onClick={handleOpenModalEditName}
                          sx={{ mt: 1, mb: 1 }}
                        >
                          <EditIcon color="warning" />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          onClick={handleOpenModalEditName}
                          color="warning"
                          startIcon={<EditIcon />}
                          sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                        >
                          {t("BUTTON.EDIT")}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  {device && (
                    <TableRow hover>
                      <TableCell align="left">
                        <Box display="flex">
                          <DesktopAccessDisabledIcon
                            sx={{ alignSelf: "center" }}
                          />
                          <Typography sx={{ ml: 2 }}>
                            {t("TEXT.SHOW_OFFLINE_DEVICES")}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Typography textAlign="center">
                          {device.showOffline === true
                            ? t("TEXT.YES")
                            : t("TEXT.NO")}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                          <FormControlLabel
                            sx={{ mr: 0 }}
                            control={
                              <Switch
                                sx={{ mt: 1, mb: 1 }}
                                checked={device.showOffline}
                                onChange={handleChangeHidden}
                              />
                            }
                            labelPlacement="end"
                            label=""
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow hover>
                    <TableCell align="left">
                      <Box display="flex">
                        <SmsFailedOutlinedIcon sx={{ alignSelf: "center" }} />
                        <Typography sx={{ ml: 2, alignSelf: "center" }}>
                          {t("TEXT.COMMUNICATION_FAILURE")}
                        </Typography>
                        <IconButton
                          color="primary"
                          onClick={handleOpenModalComFailureQuestion}
                          sx={{ pl: mobile ? 0 : 1 }}
                        >
                          <HelpOutlineIcon color="info" />
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ pl: 0, pr: 0 }}>
                      <Typography textAlign="center">
                        {device.alertComHours === 0
                          ? t("TEXT.DISABLED")
                          : device.alertComHours === 1
                          ? `${t("TEXT.AFTER")} ${device.alertComHours} ${t(
                              "TEXT.HOUR"
                            )}`
                          : `${t("TEXT.AFTER")} ${device.alertComHours} ${t(
                              "TEXT.HOUR"
                            )}s`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {mobile || smallWindow ? (
                        <IconButton
                          color="primary"
                          onClick={handleOpenModalEditAlertCom}
                          sx={{ mt: 1, mb: 1 }}
                        >
                          <EditIcon color="warning" />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          onClick={handleOpenModalEditAlertCom}
                          color="warning"
                          startIcon={<EditIcon />}
                          sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                        >
                          {t("BUTTON.EDIT")}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left" sx={{ pr: 0 }}>
                      <Box display="flex">
                        <AttachMoneyOutlinedIcon sx={{ alignSelf: "center" }} />
                        <Typography sx={{ ml: 2 }}>{t("TEXT.PLAN")}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ pl: mobile ? 0 : 1.5, pr: mobile ? 0 : 1.5 }}
                    >
                      <Typography textAlign="center">{planName}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      {mobile || smallWindow ? (
                        <IconButton
                          color="primary"
                          onClick={() =>
                            navigate(
                              `${PLANS_AND_SUBSCRIPTIONS}/${device.mac}/${device.id}`
                            )
                          }
                          sx={{ mt: 1, mb: 1 }}
                        >
                          <EditIcon color="warning" />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          onClick={() =>
                            navigate(
                              `${PLANS_AND_SUBSCRIPTIONS}/${device.mac}/${device.id}`
                            )
                          }
                          color="warning"
                          startIcon={<EditIcon />}
                          sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                        >
                          {t("TEXT.EDIT")}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  {accessCode && (
                    <TableRow hover>
                      <TableCell align="left">
                        <Box display="flex">
                          <PasswordOutlinedIcon sx={{ alignSelf: "center" }} />
                          <Typography sx={{ ml: 2 }}>
                            {t("TEXT.ACCESS_CODE")}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left" sx={{ pl: 0, pr: 0 }}>
                        <Box
                          display={mobile ? "grid" : "flex"}
                          sx={{ placeContent: "center" }}
                        >
                          <Typography pt={1} id="myInput" fontSize={15}>
                            {showAccessCode ? accessCode : "••••••••••••"}
                          </Typography>
                          {showAccessCode && (
                            <Tooltip title={t("TEXT.COPY")}>
                              <IconButton onClick={copyAccessCode}>
                                <ContentCopyIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        {mobile || smallWindow ? (
                          <IconButton
                            color="primary"
                            onClick={handleClickShowAccessCode}
                            onMouseDown={handleMouseDownAccessCode}
                            sx={{ mt: 1, mb: 1 }}
                          >
                            {showAccessCode === true ? (
                              <VisibilityOffIcon color="warning" />
                            ) : (
                              <VisibilityIcon color="warning" />
                            )}
                          </IconButton>
                        ) : (
                          <Button
                            size="small"
                            onClick={handleClickShowAccessCode}
                            onMouseDown={handleMouseDownAccessCode}
                            color="warning"
                            startIcon={
                              showAccessCode === true ? (
                                <VisibilityOffIcon color="warning" />
                              ) : (
                                <VisibilityIcon color="warning" />
                              )
                            }
                            sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                          >
                            {showAccessCode === true
                              ? t("TEXT.HIDE")
                              : t("TEXT.SHOW")}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          )}
          {(devicePermission === "OWNER" || userIsAdmin) && (
            <Paper
              sx={{ p: mobile ? 0 : 3.5, borderRadius: 3, mt: 3.5, mb: 3.5 }}
              elevation={3}
            >
              <Box
                display="flex"
                alignItems="center"
                pt={mobile ? 2 : 0}
                pl={mobile ? 2 : 0}
                pb={1.5}
              >
                <Box sx={{ mr: 1 }}>
                  <PersonOutlineIcon fontSize="large" />
                </Box>
                <Typography fontSize={mobile ? 25 : 28} gutterBottom>
                  {t("TEXT.DEVICE_USERS")}
                </Typography>
              </Box>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {device && device.users.length !== 0 && (
                  <Box overflow="auto" whiteSpace="nowrap">
                    {!loading ? (
                      !mobile ? (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                {t("TEXT.NAME")}
                              </TableCell>
                              <TableCell align="center">
                                {t("TEXT.PERMISSION")}
                              </TableCell>
                              <TableCell align="center">
                                {t("TEXT.ACTIONS")}
                              </TableCell>
                              <TableCell align="center">Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {uniqueArray(userList).map(
                              (row: any, i: number) => (
                                <TableRow
                                  hover
                                  key={i}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.permission === "OWNER" || userIsAdmin
                                      ? t("TEXT.MANAGE")
                                      : row.permission === "EDIT"
                                      ? t("TEXT.EDIT")
                                      : t("TEXT.VIEW")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.permission !== "OWNER" && (
                                      <Tooltip
                                        title={t("TEXT.UNSHARE_DEVICE_TITLE")}
                                      >
                                        <IconButton
                                          sx={{ p: 0 }}
                                          color="error"
                                          onClick={(e) =>
                                            handleOpenModalUnshare(
                                              e,
                                              device.name,
                                              row.id
                                            )
                                          }
                                        >
                                          <HighlightOffIcon
                                            sx={{ fontSize: 26 }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Chip
                                      label={
                                        row.status
                                          ? t("TEXT.ACTIVE")
                                          : t("TEXT.INACTIVE")
                                      }
                                      color={row.status ? "success" : "error"}
                                      sx={{
                                        mr: 1,
                                        fontWeight: 700,
                                        backgroundColor: row.status
                                          ? green[100]
                                          : red[100],
                                        color: row.status
                                          ? green[800]
                                          : red[800],
                                        textTransform: "uppercase",
                                        fontSize: 12,
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      ) : (
                        <Table>
                          <TableBody>
                            {uniqueArray(userList).map(
                              (row: any, i: number) => (
                                <TableRow
                                  hover
                                  key={i}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center" sx={{ pl: 3.5 }}>
                                    <Box
                                      display="flex"
                                      justifyContent="left"
                                      mb={1}
                                    >
                                      <Typography fontWeight={500} mr={1}>
                                        {t("TEXT.NAME")}:
                                      </Typography>
                                      <Typography>{row.name}</Typography>
                                    </Box>
                                    <Box
                                      display="flex"
                                      justifyContent="left"
                                      mb={1}
                                    >
                                      <Typography fontWeight={500} mr={1}>
                                        {t("TEXT.PERMISSION")}:
                                      </Typography>
                                      <Typography>
                                        {row.permission === "OWNER" ||
                                        userIsAdmin
                                          ? t("TEXT.MANAGE")
                                          : row.permission === "EDIT"
                                          ? t("TEXT.EDIT")
                                          : t("TEXT.VIEW")}
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="flex"
                                      justifyContent="left"
                                      mb={1}
                                    >
                                      <Typography fontWeight={500} mr={1}>
                                        {t("TEXT.ACTIONS")}:
                                      </Typography>
                                      {row.permission !== "OWNER" && (
                                        <Tooltip
                                          title={t("TEXT.UNSHARE_DEVICE_TITLE")}
                                        >
                                          <IconButton
                                            sx={{ p: 0 }}
                                            color="error"
                                            onClick={(e) =>
                                              handleOpenModalUnshare(
                                                e,
                                                device.name,
                                                row.id
                                              )
                                            }
                                          >
                                            <HighlightOffIcon
                                              sx={{ fontSize: 26 }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </Box>
                                    <Box display="flex" justifyContent="left">
                                      <Typography
                                        fontWeight={500}
                                        mr={1}
                                        mt={0.4}
                                      >
                                        Status:
                                      </Typography>
                                      <Chip
                                        label={
                                          row.status
                                            ? t("TEXT.ACTIVE")
                                            : t("TEXT.INACTIVE")
                                        }
                                        color={row.status ? "success" : "error"}
                                        sx={{
                                          mr: 1,
                                          fontWeight: 700,
                                          backgroundColor: row.status
                                            ? green[100]
                                            : red[100],
                                          color: row.status
                                            ? green[800]
                                            : red[800],
                                          textTransform: "uppercase",
                                          fontSize: 12,
                                        }}
                                      />
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      )
                    ) : (
                      <Box>
                        <Spinner />
                      </Box>
                    )}
                  </Box>
                )}
              </Paper>
            </Paper>
          )}
          <Paper sx={{ p: mobile ? 0 : 3.5, borderRadius: 3, mb: 3 }}>
            <Box
              display="flex"
              alignItems="center"
              pt={mobile ? 2 : 0}
              pl={mobile ? 2 : 0}
              pb={1.5}
            >
              <Box sx={{ mr: 1 }}>
                <ViewListIcon fontSize="large" />
              </Box>
              <Typography fontSize={mobile ? 25 : 28} gutterBottom>
                {t("TEXT.CONTROLLERS")}
              </Typography>
            </Box>
            {loading ? (
              <Box>
                <Spinner />
              </Box>
            ) : controllers && controllers.length !== 0 ? (
              <Box
                sx={{
                  maxHeight: mobile ? 280 : 380,
                  overflow:
                    !mobile && controllers.length < 5 ? "hidden" : "scroll",
                  overflowX: "hidden",
                }}
              >
                <Paper>
                  {!mobile && !smallWindow ? (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">{t("TEXT.NAME")}</TableCell>
                          <TableCell align="center">
                            {t("TEXT.LAST_RECORDED_EVENT")}
                          </TableCell>
                          {(devicePermission === "OWNER" || userIsAdmin) && (
                            <TableCell align="center">
                              {t("TEXT.ACTIONS")}
                            </TableCell>
                          )}
                          <TableCell align="center">TAG</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {controllers.map((row: any, i: number) => (
                          <TableRow
                            hover
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              cursor: "pointer",
                            }}
                            onClick={() => navigate(`/controller/${row._id}`)}
                          >
                            <TableCell align="center">{`${
                              row.name
                            } (${row._id.slice(22, 25)})`}</TableCell>
                            <TableCell align="center">
                              {convertToDateTime(
                                row.lastMsgTs,
                                localStorage.lang
                              ).replace(",", " - ")}
                            </TableCell>
                            {(devicePermission === "OWNER" || userIsAdmin) && (
                              <TableCell align="center">
                                <Box
                                  pl={3}
                                  pr={3}
                                  pt={0.5}
                                  pb={0.5}
                                  onClick={(e) => handleOpenModalDelete(e, row)}
                                >
                                  <Box display="flex" justifyContent="center">
                                    <Tooltip
                                      title={t("TEXT.DELETE_CONTROLLER")}
                                    >
                                      <IconButton color="error" sx={{ p: 0 }}>
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </TableCell>
                            )}
                            {row._id.slice(22, 25) !== "36" ? (
                              <TableCell align="center">
                                {tagsList.length > 0 &&
                                  tagsList.map((m: any, i: number) =>
                                    m.ctrl_ids.map(
                                      (mc: string) =>
                                        mc === row._id && (
                                          <Box
                                            key={i}
                                            display="flex"
                                            justifyContent="center"
                                          >
                                            <Chip
                                              label={m.tag_name}
                                              avatar={
                                                <LocalOfferOutlinedIcon />
                                              }
                                              color="success"
                                              sx={{
                                                mr: 0.5,
                                                fontWeight: 700,
                                                backgroundColor: green[100],
                                                color: green[800],
                                                textTransform: "uppercase",
                                                fontSize: 12,
                                                "& .MuiChip-avatar": {
                                                  color: "black",
                                                },
                                              }}
                                            />
                                            <Tooltip
                                              key={i}
                                              title={`${t("TEXT.REMOVE")} TAG`}
                                            >
                                              <IconButton
                                                color="primary"
                                                sx={{ p: 0 }}
                                                onClick={(e) =>
                                                  handleOpenModalDeleteTag(
                                                    e,
                                                    m,
                                                    mc
                                                  )
                                                }
                                              >
                                                <DeleteOutlineIcon color="warning" />
                                              </IconButton>
                                            </Tooltip>
                                          </Box>
                                        )
                                    )
                                  )}
                                {checkIsTagExist(row._id) === false && (
                                  <>
                                    <Tooltip title={t("TEXT.SELECT_TAG")}>
                                      <IconButton
                                        color="primary"
                                        sx={{ p: 0.5 }}
                                        onClick={(e) =>
                                          handleOpenModalSelect(e, row._id)
                                        }
                                      >
                                        <LocalOfferIcon color="warning" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t("TEXT.CREATE_TAG")}>
                                      <IconButton
                                        color="primary"
                                        sx={{ p: 0.5 }}
                                        onClick={(e) =>
                                          handleOpenModalAdd(e, row._id)
                                        }
                                      >
                                        <AddCircleOutlineIcon color="warning" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                            <TableCell align="center">
                              <Chip
                                label={
                                  row.status === "ACTIVE" ? "Online" : "Offline"
                                }
                                color={
                                  row.status === "ACTIVE" ? "success" : "error"
                                }
                                sx={{
                                  mr: 1,
                                  fontWeight: 700,
                                  backgroundColor:
                                    row.status === "ACTIVE"
                                      ? green[100]
                                      : red[100],
                                  color:
                                    row.status === "ACTIVE"
                                      ? green[800]
                                      : red[800],
                                  textTransform: "uppercase",
                                  fontSize: 12,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Table aria-label="simple table">
                      <TableBody>
                        {controllers.map((row: any, i: number) => (
                          <TableRow
                            hover
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              cursor: "pointer",
                            }}
                            onClick={() => navigate(`/controller/${row._id}`)}
                          >
                            <TableCell align="center" sx={{ pl: 3.5 }}>
                              <Box display="flex" justifyContent="left" mb={1}>
                                <Typography fontWeight={500} mr={1}>
                                  {t("TEXT.NAME")}:
                                </Typography>
                                <Typography>{`${row.name} (${row._id.slice(
                                  22,
                                  25
                                )})`}</Typography>
                              </Box>
                              <Box display="flex" mb={1}>
                                <Typography
                                  mr={1}
                                  textAlign="left"
                                  fontSize={14.8}
                                >
                                  <strong>
                                    {t("TEXT.LAST_RECORDED_EVENT")}:{" "}
                                  </strong>
                                  {convertToDateTime(
                                    row.lastMsgTs,
                                    localStorage.lang
                                  ).replace(",", " - ")}
                                </Typography>
                              </Box>
                              {(devicePermission === "OWNER" ||
                                userIsAdmin) && (
                                <Box display="flex" justifyContent="left">
                                  <Typography fontWeight={500} mr={1}>
                                    {t("TEXT.ACTIONS")}:
                                  </Typography>
                                  <Tooltip title={t("TEXT.DELETE_CONTROLLER")}>
                                    <IconButton
                                      color="error"
                                      sx={{ p: 0 }}
                                      onClick={(e) =>
                                        handleOpenModalDelete(e, row)
                                      }
                                    >
                                      <DeleteOutlineIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                              {row._id.slice(22, 25) !== "36" && (
                                <Box
                                  display="flex"
                                  justifyContent="left"
                                  mb={1}
                                  mt={1}
                                >
                                  <Typography fontWeight={500} mr={1} mt={0.5}>
                                    TAG:
                                  </Typography>
                                  {tagsList.length > 0 &&
                                    tagsList.map((m: any, i: number) =>
                                      m.ctrl_ids.map(
                                        (mc: string) =>
                                          mc === row._id && (
                                            <Box key={i} display="flex">
                                              <Chip
                                                label={m.tag_name}
                                                avatar={
                                                  <LocalOfferOutlinedIcon />
                                                }
                                                color="success"
                                                sx={{
                                                  mr: 0.5,
                                                  fontWeight: 700,
                                                  backgroundColor: green[100],
                                                  color: green[800],
                                                  textTransform: "uppercase",
                                                  fontSize: 12,
                                                  "& .MuiChip-avatar": {
                                                    color: "black",
                                                  },
                                                }}
                                              />
                                              <Tooltip
                                                key={i}
                                                title={`${t(
                                                  "TEXT.REMOVE"
                                                )} TAG`}
                                              >
                                                <IconButton
                                                  color="primary"
                                                  sx={{ p: 0 }}
                                                  onClick={(e) =>
                                                    handleOpenModalDeleteTag(
                                                      e,
                                                      m,
                                                      mc
                                                    )
                                                  }
                                                >
                                                  <DeleteOutlineIcon color="warning" />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                          )
                                      )
                                    )}
                                  {checkIsTagExist(row._id) === false && (
                                    <>
                                      <Tooltip title={t("TEXT.SELECT_TAG")}>
                                        <IconButton
                                          color="primary"
                                          sx={{ p: 0.5 }}
                                          onClick={(e) =>
                                            handleOpenModalSelect(e, row._id)
                                          }
                                        >
                                          <LocalOfferIcon color="warning" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={t("TEXT.CREATE_TAG")}>
                                        <IconButton
                                          color="primary"
                                          sx={{ p: 0.5 }}
                                          onClick={(e) =>
                                            handleOpenModalAdd(e, row._id)
                                          }
                                        >
                                          <AddCircleOutlineIcon color="warning" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </Box>
                              )}
                              <Box display="flex" justifyContent="left" mt={1}>
                                <Typography fontWeight={500} mr={1} mt={0.4}>
                                  Status:
                                </Typography>
                                <Chip
                                  label={
                                    row.status === "ACTIVE"
                                      ? "Online"
                                      : "Offline"
                                  }
                                  color={
                                    row.status === "ACTIVE"
                                      ? "success"
                                      : "error"
                                  }
                                  sx={{
                                    mr: 1,
                                    fontWeight: 700,
                                    backgroundColor:
                                      row.status === "ACTIVE"
                                        ? green[100]
                                        : red[100],
                                    color:
                                      row.status === "ACTIVE"
                                        ? green[800]
                                        : red[800],
                                    textTransform: "uppercase",
                                    fontSize: 12,
                                  }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </Paper>
              </Box>
            ) : (
              <Grid container justifyContent="center">
                <Grid item>
                  <Alert
                    severity="info"
                    sx={{
                      alignItems: "center",
                      placeContent: "center",
                      mb: mobile ? 3 : 0,
                    }}
                  >
                    <AlertTitle sx={{ mt: 0.8 }}>
                      {t("TEXT.NO_CONTROLLER_FOUND")}
                    </AlertTitle>
                  </Alert>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
      {modalEditNameValues && (
        <ModalEditName
          setDeviceEdited={setDeviceEdited}
          id={modalEditNameValues.id}
          allDevice={modalEditNameValues}
          handleOpen={openModalEditName}
          handleClose={handleCloseModalEditName}
        />
      )}
      {modalEditAlertComValues && (
        <ModalEditAlertCom
          id={modalEditAlertComValues.id}
          setDeviceEdited={setDeviceEdited}
          allDevice={modalEditAlertComValues}
          handleOpen={openModalEditAlertCom}
          handleClose={handleCloseModalEditAlertCom}
        />
      )}
      {modalUnshareValues && (
        <ModalUnshare
          id={device.id}
          setDeviceEdited={setDeviceEdited}
          userId={modalUnshareValues.id}
          device={modalUnshareValues.device}
          handleOpen={openModalUnshare}
          handleClose={handleCloseModalUnshare}
        />
      )}
      {modalDeleteValues && (
        <ModalRemoveController
          setDeviceEdited={setDeviceEdited}
          setDashboardDeviceEdited={setDashboardDeviceEdited}
          row={modalDeleteValues}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
      {openModalComFailureQuestion && (
        <ModalComFailureQuestion
          handleOpen={openModalComFailureQuestion}
          handleClose={handleCloseModalComFailureQuestion}
        />
      )}

      {modalAddValuesTag && (
        <ModalAddTag
          ctrlId={modalAddValuesTag.ctrlId}
          handleOpen={openModalAdd}
          handleClose={handleCloseModalAdd}
          setTagEdited={setTagEdited}
        />
      )}
      {modalSelectValuesTag && (
        <ModalSelectTag
          ctrlId={modalSelectValuesTag.ctrlId}
          handleOpen={openModalSelect}
          handleClose={handleCloseModalSelect}
          setTagEdited={setTagEdited}
        />
      )}
      {modalDeleteValuesTag && (
        <ModalDeleteTag
          tagName={modalDeleteValuesTag.tag.tag_name}
          tag_id={modalDeleteValuesTag.tag._id}
          ctrlId={modalDeleteValuesTag.ctrlId}
          handleOpen={openModalDeleteTag}
          handleClose={handleCloseModalDeleteTag}
          setTagEdited={setTagEdited}
        />
      )}
    </>
  ) : (
    <Box m="126px 0px">
      <Spinner />
    </Box>
  );
};

export default DevicePage;
