import React from "react";
import { Navigate } from "react-router-dom";

import { Grid } from "@mui/material";

import LoginComponent from "../components/Login";
import { loginBackground } from "../components/Login/BackgroundImages";
import { AuthContext } from "../contexts/AuthContext";
import { DASHBOARD } from "../Routes/constants";

const Login = () => {
  const { authenticated } = React.useContext(AuthContext);

  // Se o usuário estiver autenticado ele irá para a página Dashboard
  // Se não estiver autenticado, continua na página do Login
  return authenticated ? (
    <Navigate to={DASHBOARD} replace />
  ) : (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        backgroundImage: loginBackground,
        backgroundSize: "cover",
      }}
    >
      <Grid item>
        <LoginComponent />
      </Grid>
    </Grid>
  );
};

export default Login;
