import { useTranslation } from "react-i18next";

import { Send as SendIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";

import { red } from "@mui/material/colors";
import Modal from ".";
import { useParams } from "react-router-dom";
import { PutCommandData } from "../../../../../services/data/ctrls";
import { AuthContext } from "../../../../../contexts/AuthContext";
import React from "react";
import { sendSectorCommand } from "../../../../../services/data/sector";
import { ToastContext } from "../../../../../contexts/ToastContext";

interface ModalRemoveProps {
  handleOpen: boolean;
  modalValues: any;
  handleClose: () => void;
  ctrlId?: string;
  idSector?: string;
  startIntervalAction?: () => void;
  handleCloseModalAction?: () => void;
  handleCloseModalActionCtrl?: (e: any) => void;
  ctrl?: string;
}

const ModalAction = ({
  handleOpen,
  handleClose,
  modalValues,
  ctrlId,
  idSector,
  startIntervalAction,
  handleCloseModalAction,
  handleCloseModalActionCtrl,
  ctrl,
}: ModalRemoveProps) => {
  const { t } = useTranslation();
  const { toastError } = React.useContext(ToastContext);
  const params = useParams();
  const sectorParams = {
    mac: ctrlId?.split("/")[0],
    ctrl: ctrlId?.split("/")[1],
  };
  const cardParams = { mac: ctrl?.split("/")[0], ctrl: ctrl?.split("/")[1] };

  const checkParam = ctrlId ? sectorParams : params;
  const { client } = React.useContext(AuthContext);

  const swapKeysAndValues = (obj: any) => {
    const swapped = Object.entries(obj).map(([key, value]) => [value, +key]);
    return Object.fromEntries(swapped);
  };

  // Submit das ações do tipo toggle
  const handleChange = (tipo: string, idAcao: number, aValue: number) => {
    const valueFromChange =
      tipo === "toggle"
        ? swapKeysAndValues({ idAcao })
        : swapKeysAndValues({ aValue });

    let parametross = {
      value: String(aValue),
      param: "acoes",
      subparam: String(idAcao),
      divider: 1,
    } as any;

    if (ctrlId && idSector && startIntervalAction) {
      const dataApply = {
        resource: `acoes/${idAcao}`,
        value: tipo === "toggle" ? 1 : aValue,
      };
      // Aplicar alteração do Setpoint do setor por id do usuario
      sendSectorCommand(idSector, dataApply)
        .then(() => {
          startIntervalAction();
          if (handleCloseModalAction) {
            handleCloseModalAction();
          }
        })
        .catch(() => {
          toastError(t("ERROR.OPERATION_IN_PROGRESS"));
        });
    } else {
      // Editar parâmetro das Ações do Controlador
      PutCommandData(
        checkParam?.mac ? checkParam : cardParams,
        parametross,
        { value: aValue },
        client,
        tipo
      );
    }

    Object.keys(valueFromChange).forEach((key) => {
      valueFromChange[key] = idAcao;
    });
    handleClose();
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <SendIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {idSector
          ? t("TEXT.SUBMIT_ACTION_COLLECTIVE")
          : t("TEXT.SUBMIT_ACTION")}
        ?
      </Typography>
      <Typography variant="body2">
        {idSector
          ? t("TEXT.SUBMIT_ACTION_COLLECTIVE_MESSAGE")
          : t("TEXT.SUBMIT_ACTION_MESSAGE")}
        <strong>{` ${modalValues?.label}`}</strong>?
      </Typography>
      <Button
        variant="contained"
        color="error"
        onClick={(e) => {
          if (handleCloseModalActionCtrl) {
            handleCloseModalActionCtrl(e);
          }
          handleChange(modalValues.tipo, +modalValues.id, +modalValues.value);
        }}
        fullWidth
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: red[400],
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("BUTTON.SEND")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: red[50],
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalAction;
