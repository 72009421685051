import { api } from "../api";
import {
  SECTOR_ROUTE,
  deleteCtrlFromSectorRoute,
  deleteSectorRoute,
  editSectorRoute,
  getSectorByUserIdRoute,
  getSectorsByIdSectorRoute,
  sectorLockRoute,
  sendSectorCommandRoute,
  sincronizeSectorRoute,
} from "../routes/sector";

// Rota para retornar a configuração do setor por id do usuario
export const getSectorByUserId = (id: string) =>
  api.get<any>(getSectorByUserIdRoute(id));

// Rota para aplicar alteração do Setpoint do setor por id do usuario
export const sendSectorCommand = async (id: string, data: any) =>
  api.post<any>(sendSectorCommandRoute(id), data);

// Rota para bloquear/desbloquear alterações em cada setor
export const sectorLock = async (sectorid: string, data: any) =>
  api.post<any>(sectorLockRoute(sectorid), data);

// Rota para resgatar os setores por id do setor
export const getSectorsByIdSector = (id: string) =>
  api.get<any>(getSectorsByIdSectorRoute(id));

// Rota para criar setor
export const createSector = async (data: any) =>
  api.post<any>(SECTOR_ROUTE, data);

// Rota para remover setor
export const deleteSector = (sectorid: string) =>
  api.delete<any>(deleteSectorRoute(sectorid));

// Rota para remover controlador do setor
export const deleteCtrlFromSector = (
  sectorId: string,
  ctrlId: string,
  data: any
) => api.delete<any>(deleteCtrlFromSectorRoute(sectorId, ctrlId), data);

// Rota para editar uma variavel qualquer do payload setor
export const editSector = async (sectorid: string, data: any) =>
  api.put<void>(editSectorRoute(sectorid), data);

// Rota para sincronizar o setor após a edição
export const sincronizeSector = async (sectorid: string, data: any) =>
  api.post<any>(sincronizeSectorRoute(sectorid), data);
