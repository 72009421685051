export const MAC_ROUTE = "/mac";

// Rota para verificar se o endereço MAC está sendo utilizado
export const getMacRoute = (mac: string) => `${MAC_ROUTE}/${mac}`;

// Rota que retorna a lista de endereços MACs gerais para o admin
export const getMacListAdminRoute = (
  page: number,
  offset: number,
  general: string,
  status: string
) =>
  `${MAC_ROUTE}?order=-creationTs&page=${page}&offset=${offset}&general=${general}&status=${status}`;
