import React from "react";

import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MobileStepper,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  Tune as TuneIcon,
  BrowserNotSupported as BrowserNotSupportedIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import useIsMobile from "../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import Modal from "../../../pages/Devices/Modal";
import { red } from "@mui/material/colors";
import { Acoes, Estado, RowData } from "../../Controller/parser/types";
import {
  filteredConditions,
  getSelectedDaysString,
  splitTime,
  splitValue,
} from "../parse";
import { getCtrlOption } from "../../../config";
import { applyDivider } from "../../Controller/parser/utils";
import { getLanguage } from "../../../translations/languages";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

interface ModalConditionsProps {
  values: any;
  handleOpen: boolean;
  handleClose: () => void;
  sensorSelected: (e: number) => RowData;
  acoes: Acoes[];
  setpoints: RowData[];
  parametros: RowData[];
  action_obj: any;
  estado: Estado[];
}

const ModalConditions = ({
  values,
  handleOpen,
  handleClose,
  sensorSelected,
  acoes,
  setpoints,
  parametros,
  action_obj,
  estado,
}: ModalConditionsProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { theme } = React.useContext(ThemeModeContext);
  const data = values.conditions;
  const conditions = data.conditions;
  const action = action_obj?.commands[0];
  const language = getLanguage().code;

  // Ações de avançar ou voltar stepp das condições
  const [activeStep, setActiveStep] = React.useState(0);
  const themeStepp = useTheme();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  // Icones dos Estados
  const conditionFilteredData = filteredConditions(estado, conditions, theme);

  // Filtrar o campo 'Ação'
  const filterAction = acoes?.filter(
    (f) => +f.id === +splitValue(action?.resource)[1]
  )[0];

  // Filtrar o campo 'Parametros'
  const filterParam = parametros?.filter(
    (_f, i: number) => +i === +splitValue(action?.resource)[1]
  )[0];

  // Filtrar o campo 'Setpoints'
  const filterSetpoints = setpoints?.filter(
    (_f, i: number) => +i === +splitValue(action?.resource)[1]?.split("s")[1]
  )[0];

  // Titulo da Ação
  const checkActionTitle =
    splitValue(action?.resource)[0] === "acoes"
      ? t("TEXT.TAKE_ACTION_COMMAND")
      : splitValue(action?.resource)[0] === "parametros"
      ? t("TEXT.PARAMETER_CHANGE")
      : splitValue(action?.resource)[0] === "setpoints"
      ? t("TEXT.SETPOINT_CHANGE")
      : t("TEXT.TELEGRAM_MESSAGE");

  // Nome da Ação
  const checkActionName =
    splitValue(action?.resource)[0] === "acoes" &&
    filterAction?.tipo !== "toggle"
      ? `${filterAction.label}:`
      : splitValue(action?.resource)[0] === "acoes" &&
        filterAction?.tipo === "toggle"
      ? filterAction.label
      : splitValue(action?.resource)[0] === "parametros"
      ? `${filterParam?.description}:`
      : splitValue(action?.resource)[0] === "setpoints"
      ? `${filterSetpoints?.description}:`
      : "";

  // Unidade da Ação
  const checkActionUnit =
    splitValue(action?.resource)[0] === "acoes"
      ? ""
      : splitValue(action?.resource)[0] === "parametros"
      ? filterParam?.unitOfMeasurement
      : splitValue(action?.resource)[0] === "setpoints"
      ? filterSetpoints?.unitOfMeasurement
      : "";

  // Valor da Ação
  const checkActionValue =
    splitValue(action?.resource)[0] === "acoes"
      ? action.value
      : splitValue(action?.resource)[0] === "parametros"
      ? applyDivider(action.value, filterParam?.divider ?? 0)
      : applyDivider(action.value, filterSetpoints?.divider ?? 0);

  // Checa se o parâmetro não é do tipo 'INT'
  const optionsParamNotInt =
    getCtrlOption(`d${filterParam?.type}`) ?? getCtrlOption(filterParam?.type);

  // Caso o parâmetro não é do tipo 'INT', resgata o valor
  const getValueParamNotInt =
    optionsParamNotInt &&
    Object.entries(optionsParamNotInt).filter(
      (f: any) => +f[0] === +action.value
    )[0][1];

  // Resgata o valor final com o dividor de cada Sensor no .map
  const finalValueSensorDivider = (
    posSensor: number,
    valorReferencia: number
  ) =>
    (sensorSelected(+posSensor) as any)?.max[1] > 0
      ? valorReferencia / (sensorSelected(+posSensor) as any)?.max[1]
      : valorReferencia;

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 420}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <FilterListIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={22} fontWeight={700} mt={2}>
        {t("TEXT.SCENARIO_PARAMS")}:
      </Typography>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          pt: 0,
          ml: 1,
        }}
      >
        <ListItem sx={{ pt: mobile ? 0 : 1, pb: mobile ? 0 : 1, pl: 0.5 }}>
          <ListItemAvatar sx={{ minWidth: 51 }}>
            <Avatar>
              <PlayCircleOutlineIcon />
            </Avatar>
          </ListItemAvatar>
          {splitValue(action.resource)[0] === "acoes" &&
          filterAction?.tipo === "toggle" ? (
            <ListItemText
              primary={
                <Typography fontSize={16} fontWeight={500}>
                  {checkActionTitle}
                </Typography>
              }
              secondary={checkActionName}
            />
          ) : action.value !== "" && optionsParamNotInt ? (
            <ListItemText
              primary={
                <Typography fontSize={16} fontWeight={500}>
                  {checkActionTitle}
                </Typography>
              }
              secondary={`${checkActionName} ${getValueParamNotInt}`}
            />
          ) : action.value !== "" && !optionsParamNotInt ? (
            <ListItemText
              primary={
                <Typography fontSize={16} fontWeight={500}>
                  {checkActionTitle}
                </Typography>
              }
              secondary={`${checkActionName} ${checkActionValue} ${checkActionUnit}`}
            />
          ) : (
            <Typography fontSize={15} mt={0.2}>
              {checkActionName}
            </Typography>
          )}
        </ListItem>
        <ListItem sx={{ pt: mobile ? 0 : 1, pb: mobile ? 0 : 1, pl: 0.5 }}>
          <ListItemAvatar sx={{ minWidth: 51 }}>
            <Avatar>
              <TuneIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography fontSize={16} fontWeight={500}>
                {t("TEXT.FIRING_FREQUENCY")}
              </Typography>
            }
            secondary={
              data.trigger_type === "single"
                ? t("TEXT.SINGLE_SHOT")
                : data.trigger_type === "cyclic" && data.histerese !== "1440"
                ? t("TEXT.CONTINUOUS_SHOOTING")
                : t("TEXT.DAILY_SHOT")
            }
          />
        </ListItem>
        {data.trigger_type === "cyclic" && data.histerese !== "1440" && (
          <Box component="span">
            <ListItem sx={{ pt: mobile ? 0 : 1, pb: mobile ? 0 : 1, pl: 0.5 }}>
              <ListItemAvatar sx={{ minWidth: 51 }}>
                <Avatar>
                  <TuneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography fontSize={16} fontWeight={500}>
                    {t("TEXT.MINIMUM_INTERVAL_SHOTS")}
                  </Typography>
                }
                secondary={`${data.histerese} ${t("TEXT.MINUTES")}`}
              />
            </ListItem>
          </Box>
        )}
        {data?.disable_on_error !== undefined &&
          data.trigger_type !== "single" && (
            <ListItem sx={{ pt: mobile ? 0 : 1, pb: mobile ? 0 : 1, pl: 0.5 }}>
              <ListItemAvatar sx={{ minWidth: 51 }}>
                <Avatar>
                  <TuneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography fontSize={16} fontWeight={500}>
                    {t("TEXT.DISABLE_AFTER_ERROR")}
                  </Typography>
                }
                secondary={
                  data?.disable_on_error === true
                    ? t("TEXT.ACTIVE")
                    : t("TEXT.INACTIVE")
                }
              />
            </ListItem>
          )}
        {data?.days_of_week && (
          <ListItem sx={{ pt: mobile ? 0 : 1, pb: mobile ? 0 : 1, pl: 0.5 }}>
            <ListItemAvatar sx={{ minWidth: 51 }}>
              <Avatar>
                <FilterListIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography fontSize={16} fontWeight={500}>
                  {t("TEXT.SELECT_DAYS")}
                </Typography>
              }
              secondary={getSelectedDaysString(t, data.days_of_week)}
            />
          </ListItem>
        )}
        {conditions.length > 1 && (
          <ListItem
            sx={{
              pt: mobile ? 0 : 1,
              pb: mobile ? 0 : 1,
              mt: conditions.length > 1 ? 0 : 1,
              pl: 0.5,
            }}
          >
            <ListItemAvatar sx={{ minWidth: 51 }}>
              <Avatar>
                <FilterListIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography fontSize={16} fontWeight={500}>
                  {t("TEXT.RELATIONSHIP_CONDITIONS")}
                </Typography>
              }
              secondary={
                values?.conditions?.operator === "AND"
                  ? t("TEXT.EXECUTE_ALL_CONDITION")
                  : t("TEXT.EXECUTE_ANY_CONDITION")
              }
            />
          </ListItem>
        )}
        {conditions && conditions.length !== 0 ? (
          <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            {conditions.length > 1 && (
              <MobileStepper
                steps={conditions.length}
                position="static"
                activeStep={activeStep}
                sx={{ pb: 0 }}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === conditions.length - 1}
                  >
                    {themeStepp.direction === "rtl" ? (
                      <KeyboardArrowLeft fontSize="large" />
                    ) : (
                      <KeyboardArrowRight fontSize="large" />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {themeStepp.direction === "rtl" ? (
                      <KeyboardArrowRight fontSize="large" />
                    ) : (
                      <KeyboardArrowLeft fontSize="large" />
                    )}
                  </Button>
                }
              />
            )}
            <AutoPlaySwipeableViews
              axis={themeStepp.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {conditions?.map((row: any, i: number) => (
                <ListItem
                  sx={{
                    pt: mobile ? 0 : 1,
                    pb: mobile ? 0 : 1,
                    pr: 0,
                    pl: 0.5,
                    height: 65,
                  }}
                  key={i}
                >
                  <ListItemAvatar sx={{ minWidth: 51 }}>
                    <Avatar>
                      <FilterListIcon />
                    </Avatar>
                  </ListItemAvatar>
                  {row.posSensor === -2 ? (
                    <ListItemText
                      primary={
                        <Typography fontSize={16} fontWeight={500}>
                          {`${t("TEXT.CONDITION")} ${i + 1}`}
                        </Typography>
                      }
                      secondary={`${t("TEXT.IF")} ${t("TEXT.THE")} ${t(
                        "TEXT.TIME"
                      ).toLowerCase()}
                     ${t("TEXT.IS")} ${
                        row.operador === "maior"
                          ? t("TEXT.TIME_AFTER")
                          : row.operador === "menor"
                          ? t("TEXT.TIME_BEFORE")
                          : t("TEXT.BETWEEN")
                      } ${
                        language === "es"
                          ? t("TEXT.TIME_DAS")
                          : language === "pt"
                          ? row.operador === "menor"
                            ? t("TEXT.TIME_DAS")
                            : row.operador === "maior"
                            ? "as"
                            : ""
                          : t("TEXT.THAN")
                      }
                    ${splitTime(row.valorReferencia * 1000, language)}
                    ${row.operador === "entre" ? t("TEXT.AND") : ""}
                    ${
                      row.operador === "entre"
                        ? splitTime(row.valorReferencia_2 * 1000, language)
                        : ""
                    }`}
                    />
                  ) : row.operador === "igual" ||
                    row.operador === "diferente" ||
                    row.operador === "qualquer" ? (
                    <ListItemText
                      secondary={
                        <Box component="span" display="flex">
                          <Box component="span" alignSelf="center">
                            <Typography
                              fontSize={16}
                              fontWeight={500}
                              component="span"
                              sx={{
                                color: theme === "light" ? "black" : "white",
                                display: "grid",
                              }}
                            >
                              {`${t("TEXT.CONDITION")} ${i + 1}`}
                            </Typography>
                            {row.operador !== "qualquer" && (
                              <Typography
                                fontSize={13.5}
                                component="span"
                                alignSelf="center"
                                mr={0.5}
                              >
                                {`${t("TEXT.IF")} ${t("TEXT.THE")}
                            ${t("TEXT.STATE")} 
                            ${t("TEXT.IS")} ${
                                  row.operador === "igual"
                                    ? t("TEXT.EQUAL").toLowerCase()
                                    : t("TEXT.DIFFERENT").toLowerCase()
                                } ${
                                  language === "es"
                                    ? row.operador === "igual"
                                      ? t("TEXT.TO")
                                      : "de"
                                    : language === "pt"
                                    ? row.operador === "igual"
                                      ? t("TEXT.TO")
                                      : "de"
                                    : row.operador === "igual"
                                    ? t("TEXT.TO")
                                    : t("TEXT.TO")
                                }`}
                              </Typography>
                            )}
                            {row.operador === "qualquer" && (
                              <Typography
                                fontSize={13.5}
                                component="span"
                                alignSelf="center"
                                mr={0.5}
                              >
                                {`${t("TEXT.IF")} ${t("TEXT.THE")}
                            ${t("TEXT.STATE")} 
                            ${t("TEXT.ANYONE").toLowerCase()}`}
                              </Typography>
                            )}
                          </Box>
                          <Box alignSelf="center" component="span">
                            {conditionFilteredData[i]?.length !== 0 ? (
                              <Grid
                                container
                                component="span"
                                width={mobile ? 60 : 120}
                                mb={-3.5}
                                spacing={0.18}
                                ml={0.2}
                              >
                                {conditionFilteredData[i]?.map(
                                  (
                                    { icon, description }: any,
                                    index: number
                                  ) => (
                                    <Grid item key={index} component="span">
                                      <Tooltip
                                        title={description.active}
                                        enterTouchDelay={0}
                                      >
                                        <Box component="span">{icon}</Box>
                                      </Tooltip>
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            ) : (
                              <Box component="span">
                                <Box mt={3.5}>
                                  <Tooltip
                                    title={t("TEXT.NO_STATE_SELECTED")}
                                    enterTouchDelay={0}
                                  >
                                    <BrowserNotSupportedIcon
                                      sx={{
                                        color:
                                          theme === "light"
                                            ? "#B2B2B2"
                                            : "gray",
                                        width: "28px",
                                        height: "28px",
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      }
                    />
                  ) : (
                    <ListItemText
                      primary={
                        <Typography fontSize={16} fontWeight={500}>
                          {`${t("TEXT.CONDITION")} ${i + 1}`}
                        </Typography>
                      }
                      secondary={`${t("TEXT.IF")} ${
                        sensorSelected(+row.posSensor)?.description
                      } ${t("TEXT.IS")} ${
                        row.operador === "maior"
                          ? t("TEXT.BIGGER").toLowerCase()
                          : t("TEXT.SMALLER").toLowerCase()
                      } ${t("TEXT.THAN")} 
                          ${finalValueSensorDivider(
                            row.posSensor,
                            row.valorReferencia
                          )}
                          ${sensorSelected(+row.posSensor)?.min}`}
                    />
                  )}
                </ListItem>
              ))}
            </AutoPlaySwipeableViews>
          </Box>
        ) : (
          <Grid container justifyContent="center" mt={mobile ? 0 : 10} mb={6}>
            <Grid item>
              <Alert
                severity="info"
                sx={{
                  alignItems: "center",
                  placeContent: "center",
                  mb: 3,
                }}
              >
                <AlertTitle sx={{ mt: 0.8, fontSize: 15 }}>
                  {t("TEXT.N0_CONDITIONS")}
                </AlertTitle>
              </Alert>
            </Grid>
          </Grid>
        )}
      </List>
    </Modal>
  );
};

export default ModalConditions;
