import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select as MuiSelect,
  SelectChangeEvent,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Chip,
  InputAdornment,
  FormHelperText,
  MobileStepper,
  Tooltip,
  IconButton,
  ListItemIcon,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Add as AddIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  HighlightOff as HighlightOffIcon,
  Check as CheckIcon,
  HelpOutline as HelpOutlineIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../contexts/ToastContext";
import {
  Select,
  TextField,
  yupRequiredString,
  yupString,
} from "../../../components/Form";
import ErrorMessage from "../../../components/ErrorMessage";
import { AuthContext } from "../../../contexts/AuthContext";
import { TOKEN_KEY } from "../../../auth/constants";
import { api } from "../../../services/api";
import { green, red } from "@mui/material/colors";
import Modal from ".";
import { createSector } from "../../../services/data/sector";
import AvatarIcon from "../../../components/AvatarIcon";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import StepperCustom from "../Stepper";
import useIsMobile from "../../../hooks/useIsMobile";
import Spinner from "../../../components/Spinner";
import {
  getControllersOfDeviceByArcsys,
  getNumbersOfDevices,
} from "../../../services/data/arcsys";
import { getCtrlConfig } from "../../../components/Controller/parser";
import { getRawCtrlConfig } from "../../../config";
import { applyDivider } from "../../../components/Controller/parser/utils";
import {
  filteredMaxValues,
  filteredMinValues,
  filteredNameByMac,
  filteredSetpoints,
  uniqKeysById,
} from "../parse";
import ModalRemoveDevicesApplied from "./RemoveDevicesApplied";
import ModalHelpMainSensor from "./Question/HelpMainSensor";
import ModalStandardCtrl from "./Question/StandardCtrl";
import ModalAdditionalCtrl from "./Question/AdditionalCtrl";
import ModalSelectSetpoint from "./Question/SelectSetpoint";
import ModalMaxMin from "./Question/MaxMin";
import ModalInitialSetpoint from "./Question/InitialSetpoint";
import { MqttContext } from "../../../contexts/MqttContext";
import ModalRemoveDeviceApplied from "./RemoveDeviceApplied";

let devicesSelected: any[] = [];
let allDevicesSelected: any[] = [];

let maxSetpointCtrls: any = {};
let minSetpointCtrls: any = {};

interface ModalAddProps {
  handleOpen: boolean;
  handleClose: () => void;
  handleOpenModalSaveSync: (e: string) => void;
  setAddedDevice: (e: any) => void;
  deviceUsers: any;
}

const ModalAdd = ({
  handleOpen,
  handleClose,
  handleOpenModalSaveSync,
  setAddedDevice,
  deviceUsers,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated, userId } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const { mqtt } = React.useContext(MqttContext);
  const [deviceList, setDeviceList] = React.useState<string[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [macId, setMacId] = React.useState<string>();
  const [controllers, setControllers] = React.useState<any>();
  const [macIdSector, setMacIdSector] = React.useState<string>();
  const [controllersSector, setControllersSector] = React.useState<any>();
  const [macIdSectorInitial, setMacIdSectorInitial] = React.useState<string>();
  const [controllersSectorInitial, setControllersSectorInitial] =
    React.useState<any>();
  const [checkedModel, setCheckedModel] = React.useState<string>("");
  const [applyCtrls, setApplyCtrls] = React.useState<boolean>(false);
  const [addInitialValues, setAddInitialValues] = React.useState<any>();
  const [modelIsValid, setModelIsValid] = React.useState<boolean>(true);
  const [pages, setPages] = React.useState<number>(1);
  const [devices, setDevices] = React.useState<any>([]);
  const [loadingDevices, setLoadingDevices] = React.useState(false);
  const [payloadDevices, setPayloadDevices] = React.useState<any>([]);
  const [notShow, setNotShow] = React.useState(false);

  // Filtrar o plano dos dispositivos com atributo PERMISSIONS.DEVICE.SECTORS = true
  const filteredDevices = devices?.filter((f: any) =>
    f.subscriptions.filter(
      (f: any) =>
        f.status === "ACTIVE" && f.plan?.permissions.DEVICE.SECTORS === true
    )
  );

  // Ações de abrir o modal de dúvidas sobre o Sensor Principal
  const [openModalMainSensor, setOpenModalMainSensor] =
    React.useState<boolean>(false);
  const handleOpenModalMainSensor = () => setOpenModalMainSensor(true);
  const handleCloseModalMainSensor = () => {
    setOpenModalMainSensor(false);
  };

  // Ações de abrir o modal de dúvidas sobre o Controlador Padrão
  const [openModalStandardCtrl, setOpenModalStandardCtrl] =
    React.useState<boolean>(false);
  const handleOpenModalStandardCtrl = () => setOpenModalStandardCtrl(true);
  const handleCloseModalStandardCtrl = () => {
    setOpenModalStandardCtrl(false);
  };

  // Ações de abrir o modal de dúvidas sobre os Controladores Adicionais
  const [openModalAdditionalCtrl, setOpenModalAdditionalCtrl] =
    React.useState<boolean>(false);
  const handleOpenModalAdditionalCtrl = () => setOpenModalAdditionalCtrl(true);
  const handleCloseModalAdditionalCtrl = () => {
    setOpenModalAdditionalCtrl(false);
  };

  // Ações do modal de limpar controladores associados
  const [openModalRemoveApplied, setOpenModalRemoveApplied] =
    React.useState(false);
  const handleOpenModalRemoveApplied = () => {
    setOpenModalRemoveApplied(true);
  };
  const handleCloseModalRemoveApplied = () => {
    setOpenModalRemoveApplied(false);
  };

  // Ações do modal de limpar controlador associados específico
  const [appliedCleanedCtrl, setAppliedCleanedCtrl] = React.useState(false);
  const [modalRemoveValue, setModalRemoveValue] = React.useState<any>();
  const [openModalRemoveAppliedCtrl, setOpenModalRemoveAppliedCtrl] =
    React.useState(false);
  const handleOpenModalRemoveAppliedCtrl = (m: any) => {
    setModalRemoveValue(m);
    setOpenModalRemoveAppliedCtrl(true);
  };
  const handleCloseModalRemoveAppliedCtrl = () => {
    setOpenModalRemoveAppliedCtrl(false);
  };

  // Ações de abrir o modal de dúvidas sobre o selecionar o Setpoint
  const [openModalSelectSetpoint, setOpenModalSelectSetpoint] =
    React.useState<boolean>(false);
  const handleOpenModalSelectSetpoint = () => setOpenModalSelectSetpoint(true);
  const handleCloseModalSelectSetpoint = () => {
    setOpenModalSelectSetpoint(false);
  };

  // Ações de abrir o modal de dúvidas sobre os valores máximos e mínimos
  const [openModalMaxMin, setOpenModalMaxMin] = React.useState<boolean>(false);
  const handleOpenModalMaxMin = () => setOpenModalMaxMin(true);
  const handleCloseModalMaxMin = () => {
    setOpenModalMaxMin(false);
  };

  // Ações de abrir o modal de dúvidas sobre o valor inicial do Setpoint
  const [openModalInitialSetpoint, setOpenModalInitialSetpoint] =
    React.useState<boolean>(false);
  const handleOpenModalInitialSetpoint = () =>
    setOpenModalInitialSetpoint(true);
  const handleCloseModalInitialSetpoint = () => {
    setOpenModalInitialSetpoint(false);
  };

  // Limpar controladores associados
  React.useEffect(() => {
    if (!applyCtrls) {
      allDevicesSelected = [];
    }
  }, [applyCtrls]);

  // Limpar controladores associado específico
  React.useEffect(() => {
    if (appliedCleanedCtrl === true) {
      allDevicesSelected = allDevicesSelected.filter(
        (f: any) => f !== modalRemoveValue
      );
      setAppliedCleanedCtrl(false);
    }
  }, [appliedCleanedCtrl, modalRemoveValue]);

  // Adicionar dispositivos na lista
  const handleChange = (event: SelectChangeEvent<typeof deviceList>) => {
    const {
      target: { value },
    } = event;
    setDeviceList(typeof value === "string" ? value.split(",") : value);
  };

  // Filtrar os dispositivos selecionados no Setep 'Controlador Setor'
  React.useEffect(() => {
    devicesSelected = [];
    controllersSector?.forEach((cs: any) => {
      deviceList?.forEach((dl) => {
        if (
          dl.split("-")[0].includes(cs.name) &&
          dl.split("-")[1].includes(cs._id.split("/")[1].split("L")[1])
        ) {
          devicesSelected.push(cs);
        }
      });
    });
  }, [controllersSector, deviceList]);

  // Após selecionar um Dispositivo, resgata a lista de Controladores do Dispositivo Referência
  React.useEffect(() => {
    if (macId) {
      getControllersOfDeviceByArcsys(macId).then((res) => {
        setControllers(
          res.data?.filter(
            (f: any) => f._id.split("/")[1].split("L")[1] !== "36"
          )
        );
      });
    }
  }, [macId]);

  // Após selecionar um Dispositivo, resgata a lista de Controladores do Dispositivo iniciais do setor
  React.useEffect(() => {
    if (macIdSectorInitial) {
      getControllersOfDeviceByArcsys(macIdSectorInitial).then((res) => {
        setControllersSectorInitial(
          res.data?.filter(
            (f: any) =>
              f._id.split("/")[1].split("L")[1] !== "36" &&
              (f.sectorId === null || f.sectorId === undefined)
          )
        );
      });
    }
  }, [macIdSectorInitial]);

  // Após selecionar um Dispositivo, resgata a lista de Controladores do Dispositivo do setor
  React.useEffect(() => {
    if (macIdSector) {
      getControllersOfDeviceByArcsys(macIdSector).then((res) => {
        if (checkedModel !== "") {
          setControllersSector(
            res.data.filter(
              (f: any) =>
                f._id.split("/")[1].split("L")[1] !== "36" &&
                f.model === checkedModel &&
                (f.sectorId === null || f.sectorId === undefined)
            )
          );
        } else {
          setControllersSector(
            res.data.filter(
              (f: any) => f._id.split("/")[1].split("L")[1] !== "36"
            )
          );
        }
      });
    }
  }, [checkedModel, macIdSector]);

  // Aplicar dispositivos selecionados na lista
  const handlePushDevides = () => {
    allDevicesSelected = uniqKeysById([
      ...allDevicesSelected,
      ...devicesSelected,
      addInitialValues,
    ]);

    maxSetpointCtrls = {};
    minSetpointCtrls = {};

    const devicesList = uniqKeysById(allDevicesSelected).map((v: any) => {
      return { ctrlId: v._id, rotulo: v.name };
    });
    devicesList.forEach((device: any) => {
      const deviceMAC = device.ctrlId.split("/")[0];
      const deviceCtrls = mqtt[deviceMAC];
      let setpointValue = null;

      if (deviceCtrls) {
        Object.entries(deviceCtrls).forEach(([ctrl, payload]) => {
          if (ctrl === device.ctrlId.split("/")[1]) {
            for (let index = 1; index <= setpoints.length; index++) {
              const labelSetpoint = `s${index}`;
              // Resgatar valor do setpoint
              const setpoint = (payload as any)?.setpoints[labelSetpoint];
              // Resgatar dados gerais do card
              const ctrlConfig = getCtrlConfig((payload as any)?.modelo);
              // Aplicar o divider no valor do setpoint
              setpointValue = applyDivider(
                setpoint as number,
                +(ctrlConfig.setpoints[index - 1]?.divider ?? 0)
              );
              // Pegar o maior setpoint dentre os controladores do setor
              if (maxSetpointCtrls[`s${index - 1}`]) {
                if (maxSetpointCtrls[`s${index - 1}`] < setpointValue) {
                  maxSetpointCtrls[`s${index - 1}`] = setpointValue;
                }
              } else {
                maxSetpointCtrls[`s${index - 1}`] = setpointValue;
              }
              // Pegar o menor setpoint dentre os controladores do setor
              if (minSetpointCtrls[`s${index - 1}`]) {
                if (minSetpointCtrls[`s${index - 1}`] > setpointValue) {
                  minSetpointCtrls[`s${index - 1}`] = setpointValue;
                }
              } else {
                minSetpointCtrls[`s${index - 1}`] = setpointValue;
              }
            }
          }
        });
      }
    });

    setDeviceList([]);
    toastSuccess(t("TOAST.SUCCESS.SELECTED_CONTROLLERS"));
    setApplyCtrls(true);
  };

  // Resgatar o payload do setpoints do Dispositivo selecionado
  const rawCtrlConfig = getRawCtrlConfig(checkedModel);
  const ctrlConfig = getCtrlConfig(checkedModel);
  const setpoints = ctrlConfig.setpoints;

  // Checar se é um inversor
  const checkInversor =
    rawCtrlConfig?.inversor && rawCtrlConfig?.inversor === true;

  const formik = useFormik({
    initialValues: {
      name: "",
      macReference: "",
      controllerReference: "",
      macSector: "",
      macSectorInitial: "",
      controllerSectorInitial: "",
      setpoint: "",
      resource_value: "",
      default_resource_min: "",
      default_resource_max: "",
    },
    validationSchema: Yup.object({
      name: yupRequiredString,
      macReference: yupRequiredString,
      controllerReference: yupRequiredString,
      macSector: yupString,
      macSectorInitial: yupRequiredString,
      controllerSectorInitial: yupRequiredString,
      setpoint: yupString,
      resource_value: yupString,
      default_resource_min: yupRequiredString,
      default_resource_max: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        // Payload do Dispositivo Referência
        const payloadController = values.controllerReference.split("/");
        const ctrlIdReference = `${payloadController[0]}/${payloadController[1]}`;
        const modelReference = payloadController[2];

        // Payload do Dispositivo do setor
        const devices = uniqKeysById(allDevicesSelected).map((v: any) => {
          return { last_exec_status: null, ctrlId: v._id, rotulo: v.name };
        });

        const setpointSelected = `setpoints/s${+values.setpoint - 1}`;

        let resource_value: any = {};
        let resource_limit: any = {};

        setpoints.forEach((m: any, i: number) => {
          if (values.setpoint === m.code) {
            if (checkInversor) {
              resource_value[setpointSelected] = +values.default_resource_min;
            } else {
              resource_value[setpointSelected] = +values.resource_value;
            }
          } else {
            if (checkInversor) {
              resource_value[`setpoints/s${i}`] = +values.default_resource_min;
            } else {
              resource_value[`setpoints/s${i}`] = filteredSetpoints(
                i,
                values,
                ctrlConfig
              )?.defaultValue;
            }
          }
        });

        setpoints.forEach((m: any, i: number) => {
          if (values.setpoint === m.code) {
            resource_limit[setpointSelected] = {
              min: +values.default_resource_min,
              max: +values.default_resource_max,
            };
          } else {
            resource_limit[`setpoints/s${i}`] = {
              min: filteredMinValues(i, values, ctrlConfig, rawCtrlConfig),
              max: filteredMaxValues(i, values, ctrlConfig, rawCtrlConfig),
            };
          }
        });

        const data = {
          user_id: +userId,
          sector_name: values.name,
          sector_model: checkedModel,
          reference: {
            ctrlId: ctrlIdReference,
            model: modelReference,
          },
          devices,
          default_resource: setpointSelected,
          resource_value,
          resource_limit,
        };

        // Adicionar setor
        createSector(data)
          .then((res) => {
            handleOpenModalSaveSync(res.data._id);
            setAddedDevice(data);
            setApplyCtrls(false);
            formik.resetForm({
              values: {
                name: "",
                macReference: "",
                controllerReference: "",
                macSector: "",
                macSectorInitial: "",
                controllerSectorInitial: "",
                setpoint: "",
                resource_value: "",
                default_resource_min: "",
                default_resource_max: "",
              },
            });
            allDevicesSelected = [];
            setActiveStep(0);
            handleClose();
          })
          .catch(() => {
            toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION_SECTORS"));
          });
      }
    },
  });

  // Resgatar o payload do setpoints do Dispositivo selecionado
  const filteredSetpoint =
    formik.values.setpoint !== ""
      ? ctrlConfig.setpoints[+formik.values.setpoint - 1]
      : ctrlConfig.setpoints[0];

  // Valor mínimo do setpoint
  const min = String(filteredSetpoint?.min);
  const filteredMin = applyDivider(
    min.includes("detalhamento")
      ? rawCtrlConfig.detalhamento[min.split("detalhamento")[1]][1]
      : filteredSetpoint?.min,
    filteredSetpoint?.divider ?? 0
  );
  const minLabel = min.includes("detalhamento")
    ? rawCtrlConfig?.detalhamento[min.split("detalhamento")[1]][0]
    : `${t("TEXT.VALUE")} ${t("TEXT.MINIMUM")}`;

  // Valor máximo do setpoint
  const max = String(filteredSetpoint?.max);
  const filteredMax = applyDivider(
    max.includes("detalhamento")
      ? rawCtrlConfig.detalhamento[max.split("detalhamento")[1]][2]
      : filteredSetpoint?.max,
    filteredSetpoint?.divider ?? 0
  );
  const maxLabel = max.includes("detalhamento")
    ? rawCtrlConfig?.detalhamento[max.split("detalhamento")[1]][0]
    : `${t("TEXT.VALUE")} ${t("TEXT.MAXIMUM")}`;

  // Lista de setpoints
  const optionsSetpoints = setpoints.map((value: any, i: number) => (
    <MenuItem value={value.code} key={i}>
      {value.description}
    </MenuItem>
  ));

  // Range dos setpoints
  const checkMin =
    filteredSetpoint?.divider === 0 || filteredMin?.toString().includes(".")
      ? filteredMin
      : filteredMin + ".0";
  const checkMax =
    filteredSetpoint?.divider === 0 || filteredMax?.toString().includes(".")
      ? filteredMax
      : filteredMax + ".0";
  const separator = filteredSetpoint?.separator;
  const rangeMin = [filteredMin, filteredMax, separator].some(
    (e) => e === undefined
  )
    ? ""
    : !!minSetpointCtrls[`s${+formik.values.setpoint - 1}`]
    ? `${checkMin}${separator}${
        minSetpointCtrls[`s${+formik.values.setpoint - 1}`]
      }`
    : `${checkMin}${separator}${checkMax}`;
  const rangeMax = [filteredMin, filteredMax, separator].some(
    (e) => e === undefined
  )
    ? ""
    : !!maxSetpointCtrls[`s${+formik.values.setpoint - 1}`]
    ? `${
        maxSetpointCtrls[`s${+formik.values.setpoint - 1}`]
      }${separator}${checkMax}`
    : `${checkMin}${separator}${checkMax}`;
  const showRangeInitialValue =
    formik.values.default_resource_min !== "" &&
    formik.values.default_resource_max !== "";
  const rangeInitialValue = showRangeInitialValue
    ? `${formik.values.default_resource_min}${separator}${formik.values.default_resource_max}`
    : "";
  const showRange = !!rangeMin && !!rangeMax && formik.values.setpoint !== "";

  // Ação de mudar para o stepp anterior
  const handleBackStepp = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Ação de mudar para o próximo stepp
  const handleNextStepp = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Lista de Dispositivos do Sensor de Monitoramento
  const optionsMac: any = devices ? (
    devices?.map(
      (value: any) =>
        value && (
          <MenuItem
            value={value.id}
            key={value.id}
            sx={{ pt: 0, pb: 0, borderBottom: "1px solid #F1F1F1" }}
            onClick={() => (allDevicesSelected = [])}
          >
            {!mobile ? (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={red[400]} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ p: 0 }}
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                          <Chip
                            label={value.online ? "Online" : "Offline"}
                            color={value.online ? "success" : "error"}
                            sx={{
                              mr: 1,
                              fontWeight: 700,
                              backgroundColor: value.online
                                ? green[100]
                                : red[100],
                              color: value.online ? green[800] : red[800],
                              textTransform: "uppercase",
                              fontSize: 12,
                            }}
                          />
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ) : (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={red[400]} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                        <Chip
                          component="span"
                          label={value.online ? "Online" : "Offline"}
                          color={value.online ? "success" : "error"}
                          sx={{
                            mt: 0.5,
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor: value.online
                              ? green[100]
                              : red[100],
                            color: value.online ? green[800] : red[800],
                            textTransform: "uppercase",
                            fontSize: 12,
                          }}
                        />
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            )}
          </MenuItem>
        )
    )
  ) : (
    <Spinner />
  );

  // Retornar o payload dos dispositivos
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        // Rota para resgatar a quantidade de dispositivos que irão ser renderizados
        getNumbersOfDevices(true, pages, 10, "", 0, "").then((res) => {
          if (res?.data) {
            const payloadData = res.data.items;
            if (res.data.this_item_list_qtn === res.data.total) {
              setNotShow(true);
            }
            setPayloadDevices(payloadData);
            setDevices((prevOptions: any) => [...prevOptions, ...payloadData]);
            setLoadingDevices(false);
          }
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, token, pages]);

  // Adicionar novos dados no select dos Dispositivos
  const handleLoadMore = () => {
    setPages((prevPage) => prevPage + 1);
  };

  // Adicionar novos dados no select dos Dispositivos
  const addMoreItens = (
    <MenuItem>
      <Button
        onClick={(e: any) => {
          e.stopPropagation();
          handleLoadMore();
        }}
        disabled={loadingDevices}
        fullWidth
        variant="outlined"
      >
        {loadingDevices ? <Spinner size={24} /> : t("TEXT.CHARGE_MORE_DEVICES")}
      </Button>
    </MenuItem>
  );

  // Adicionar o botão 'addMoreItems' na lista de dispositivos
  const allMenuItems =
    notShow === true || payloadDevices.length === 0
      ? optionsMac
      : [...optionsMac, addMoreItens];

  // Lista de Dispositivos inciais do setor
  const optionsMacSectorInitial: any = filteredDevices ? (
    filteredDevices?.map(
      (value: any) =>
        value && (
          <MenuItem
            value={value.id}
            key={value.id}
            sx={{ pt: 0, pb: 0, borderBottom: "1px solid #F1F1F1" }}
            onClick={() => {
              setCheckedModel("");
              setDeviceList([]);
              setAddInitialValues([]);
              allDevicesSelected = [];
              formik.resetForm({
                values: {
                  ...formik.values,
                  macSector: "",
                  controllerSectorInitial: "",
                },
              });
            }}
          >
            {!mobile ? (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={red[400]} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ p: 0 }}
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                          <Chip
                            label={value.online ? "Online" : "Offline"}
                            color={value.online ? "success" : "error"}
                            sx={{
                              mr: 1,
                              fontWeight: 700,
                              backgroundColor: value.online
                                ? green[100]
                                : red[100],
                              color: value.online ? green[800] : red[800],
                              textTransform: "uppercase",
                              fontSize: 12,
                            }}
                          />
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ) : (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={red[400]} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                        <Chip
                          component="span"
                          label={value.online ? "Online" : "Offline"}
                          color={value.online ? "success" : "error"}
                          sx={{
                            mt: 0.5,
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor: value.online
                              ? green[100]
                              : red[100],
                            color: value.online ? green[800] : red[800],
                            textTransform: "uppercase",
                            fontSize: 12,
                          }}
                        />
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            )}
          </MenuItem>
        )
    )
  ) : (
    <Spinner />
  );

  // Lista de Dispositivos adicionais dos Controladores do Setor
  const optionsMacInitial: any = filteredDevices ? (
    filteredDevices?.map(
      (value: any) =>
        value && (
          <MenuItem
            value={value.id}
            key={value.id}
            sx={{ pt: 0, pb: 0, borderBottom: "1px solid #F1F1F1" }}
          >
            {!mobile ? (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={red[400]} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ p: 0 }}
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                          <Chip
                            label={value.online ? "Online" : "Offline"}
                            color={value.online ? "success" : "error"}
                            sx={{
                              mr: 1,
                              fontWeight: 700,
                              backgroundColor: value.online
                                ? green[100]
                                : red[100],
                              color: value.online ? green[800] : red[800],
                              textTransform: "uppercase",
                              fontSize: 12,
                            }}
                          />
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ) : (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={red[400]} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                        <Chip
                          component="span"
                          label={value.online ? "Online" : "Offline"}
                          color={value.online ? "success" : "error"}
                          sx={{
                            mt: 0.5,
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor: value.online
                              ? green[100]
                              : red[100],
                            color: value.online ? green[800] : red[800],
                            textTransform: "uppercase",
                            fontSize: 12,
                          }}
                        />
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            )}
          </MenuItem>
        )
    )
  ) : (
    <Spinner />
  );

  // Lista de Controladores Referência
  const optionsController = controllers?.map((p: any) => (
    <MenuItem
      value={`${p._id}/${p.model}`}
      key={p._id}
      sx={{ pt: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
    >
      <Box display="flex">
        <Chip
          label={p.status === "ACTIVE" ? "Online" : "Offline"}
          color={p.status === "ACTIVE" ? "success" : "error"}
          sx={{
            mr: 1,
            fontWeight: 700,
            backgroundColor: p.status === "ACTIVE" ? green[100] : red[100],
            color: p.status === "ACTIVE" ? green[800] : red[800],
            textTransform: "uppercase",
            fontSize: 12,
          }}
        />
        <Typography
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          width={mobile ? 170 : 460}
          sx={{ ml: 0.5, mt: 0.5 }}
        >
          {`${p.name} - ${p._id.split("/")[1].split("L")[1]}`}
        </Typography>
      </Box>
    </MenuItem>
  ));

  // Ação de clicar no Select do Controlador inicial do Setor
  const handleSelectSectorInitial = (p: any) => {
    setCheckedModel(p.model);
    setAddInitialValues(p);
    // Limpar os selects dos Controladores Adicionais
    setDeviceList([]);
    // Aplicar controlador padrão na lista de dispositivos
    allDevicesSelected = [p];
    maxSetpointCtrls = {};
    minSetpointCtrls = {};

    const ctrlConfigModel = getCtrlConfig(p.model);
    const setpointsModel = ctrlConfigModel.setpoints;
    const devicesList = uniqKeysById(allDevicesSelected).map((v: any) => {
      return { ctrlId: v._id, rotulo: v.name };
    });
    devicesList.forEach((device: any) => {
      const deviceMAC = device.ctrlId.split("/")[0];
      const deviceCtrls = mqtt[deviceMAC];
      let setpointValue = null;
      if (deviceCtrls) {
        Object.entries(deviceCtrls).forEach(([ctrl, payload]) => {
          if (ctrl === device.ctrlId.split("/")[1]) {
            for (let index = 1; index <= setpointsModel.length; index++) {
              const labelSetpoint = `s${index}`;
              // Resgatar valor do setpoint
              const setpoint = (payload as any)?.setpoints[labelSetpoint];
              // Resgatar dados gerais do card
              const ctrlConfig = getCtrlConfig((payload as any)?.modelo);
              // Aplicar o divider no valor do setpoint
              setpointValue = applyDivider(
                setpoint as number,
                +(ctrlConfig.setpoints[index - 1]?.divider ?? 0)
              );
              // Pegar o maior setpoint dentre os controladores do setor
              if (maxSetpointCtrls[`s${index - 1}`]) {
                if (maxSetpointCtrls[`s${index - 1}`] < setpointValue) {
                  maxSetpointCtrls[`s${index - 1}`] = setpointValue;
                }
              } else {
                maxSetpointCtrls[`s${index - 1}`] = setpointValue;
              }
              // Pegar o menor setpoint dentre os controladores do setor
              if (minSetpointCtrls[`s${index - 1}`]) {
                if (minSetpointCtrls[`s${index - 1}`] > setpointValue) {
                  minSetpointCtrls[`s${index - 1}`] = setpointValue;
                }
              } else {
                minSetpointCtrls[`s${index - 1}`] = setpointValue;
              }
            }
          }
        });
      }
    });

    toastSuccess(t("TOAST.SUCCESS.SELECTED_CONTROLLERS"));
    formik.resetForm({
      values: {
        ...formik.values,
        macSector: "",
      },
    });

    const checkIsNotHaveSetpoint = getRawCtrlConfig(p.model)?.setpoints === "";

    // Checar se o primeiro controlador do Setor tem setpoint
    if (checkIsNotHaveSetpoint === true) {
      toastError(t("TOAST.ERROR.NO_SETPOINT"));
      setModelIsValid(false);
    } else {
      setModelIsValid(true);
    }
  };

  // Lista inicial de Controladores do setor
  const optionsControllerSectorInitial = controllersSectorInitial?.map(
    (p: any) => (
      <MenuItem
        value={`${p._id}/${p.model}`}
        onClick={() => handleSelectSectorInitial(p)}
        key={p._id}
        sx={{ pt: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
      >
        <Box display="flex">
          <Chip
            label={p.status === "ACTIVE" ? "Online" : "Offline"}
            color={p.status === "ACTIVE" ? "success" : "error"}
            sx={{
              mr: 1,
              fontWeight: 700,
              backgroundColor: p.status === "ACTIVE" ? green[100] : red[100],
              color: p.status === "ACTIVE" ? green[800] : red[800],
              textTransform: "uppercase",
              fontSize: 12,
            }}
          />
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            width={mobile ? 170 : 460}
            sx={{ ml: 0.5, mt: 0.5 }}
          >
            {`${p.name} - ${p._id.split("/")[1].split("L")[1]}`}
          </Typography>
        </Box>
      </MenuItem>
    )
  );

  // Remover controladores ja associados da lista
  const filteredCtrls = controllersSector?.filter((val: any) => {
    return !allDevicesSelected?.find((val2: any) => {
      return val._id === val2._id;
    });
  });

  // Lista de Controladores do setor
  const optionsControllerSector = filteredCtrls?.map((p: any) => (
    <MenuItem
      value={`${p.name} - ${p._id.split("/")[1].split("L")[1]}`}
      key={p._id}
      sx={{ pt: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
    >
      <Box display="flex">
        <Checkbox
          checked={
            deviceList.indexOf(
              `${p.name} - ${p._id.split("/")[1].split("L")[1]}`
            ) > -1
          }
          sx={{ pt: 0.6, mr: 0.2 }}
        />
        <Chip
          label={p.status === "ACTIVE" ? "Online" : "Offline"}
          color={p.status === "ACTIVE" ? "success" : "error"}
          sx={{
            mr: 1,
            fontWeight: 700,
            backgroundColor: p.status === "ACTIVE" ? green[100] : red[100],
            color: p.status === "ACTIVE" ? green[800] : red[800],
            textTransform: "uppercase",
            fontSize: 12,
          }}
        />
        <Typography
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          width={mobile ? 170 : 460}
          sx={{ ml: 0.5, mt: 0.5 }}
        >
          {`${p.name} - ${p._id.split("/")[1].split("L")[1]}`}
        </Typography>
      </Box>
    </MenuItem>
  ));

  // Resgatar o id do MAC Referência selecionado para dar um .GET na lista de Controladores
  React.useEffect(() => {
    setMacId(formik.values.macReference);
  }, [formik.values.macReference, setMacId]);

  // Resgatar o id do MAC do setor selecionado para dar um .GET na lista de Controladores
  React.useEffect(() => {
    setMacIdSectorInitial(formik.values.macSectorInitial);
  }, [formik.values.macSectorInitial]);

  // Resgatar o id do MAC do setor selecionado para dar um .GET na lista de Controladores
  React.useEffect(() => {
    setMacIdSector(formik.values.macSector);
  }, [formik.values.macSector, setMacIdSector]);

  // Limpar os campos do último Step caso voltar para o Step anterior
  React.useEffect(() => {
    if (activeStep < 3) {
      formik.resetForm({
        values: {
          ...formik.values,
          resource_value: "",
          default_resource_min: "",
          default_resource_max: "",
          setpoint: "",
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    formik.resetForm({
      values: {
        name: "",
        macReference: "",
        controllerReference: "",
        macSector: "",
        macSectorInitial: "",
        controllerSectorInitial: "",
        setpoint: "",
        resource_value: "",
        default_resource_min: "",
        default_resource_max: "",
      },
    });
    setActiveStep(0);
    handleClose();
  };

  // Limpar o select dos 'Controladores do Setor'
  const handleClearSelectedCtrls = () => {
    setDeviceList([]);
    toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
  };

  // Validação do primeiro Step
  const nameIsValid = formik.values.name !== "";

  // Validação do segundo Step
  const ctrlReferenceIsValid =
    formik.values.macReference !== "" &&
    formik.values.controllerReference !== "";

  // Checar se o primeiro controlador do Setor tem setpoint
  const checkIsNotHaveSetpoint =
    getRawCtrlConfig(checkedModel)?.setpoints === "";

  // Validação do terceiro Step
  const ctrlSectorIsValid =
    formik.values.controllerSectorInitial !== "" &&
    allDevicesSelected.length !== 0 &&
    checkIsNotHaveSetpoint === false;

  // Checar se a unidade do setpoint do quarto step é igual a ' Hz'
  const checkUnitHz =
    filteredSetpoint?.unitOfMeasurement !== " Hz"
      ? formik.values.resource_value !== "" &&
        +formik.values.resource_value >= +formik.values.default_resource_min &&
        +formik.values.resource_value <= +formik.values.default_resource_max
      : true;

  // Validação do quarto Step
  const valuesIsValid =
    !!minSetpointCtrls[`s${+formik.values.setpoint - 1}`] &&
    !!maxSetpointCtrls[`s${+formik.values.setpoint - 1}`]
      ? formik.values.default_resource_min !== "" &&
        +formik.values.default_resource_min >= +filteredMin &&
        +formik.values.default_resource_min <=
          minSetpointCtrls[`s${+formik.values.setpoint - 1}`] &&
        formik.values.default_resource_max !== "" &&
        +formik.values.default_resource_max >=
          maxSetpointCtrls[`s${+formik.values.setpoint - 1}`] &&
        +formik.values.default_resource_max <= +filteredMax &&
        checkUnitHz &&
        formik.values.setpoint !== ""
      : formik.values.default_resource_min !== "" &&
        +formik.values.default_resource_min >= +filteredMin &&
        +formik.values.default_resource_min <= +filteredMax &&
        formik.values.default_resource_max !== "" &&
        +formik.values.default_resource_max >= +filteredMin &&
        +formik.values.default_resource_max <= +filteredMax &&
        checkUnitHz &&
        formik.values.setpoint !== "";

  // Validação do botão de 'Aplicar'
  const isValidApply = formik.values.macSector !== "";

  // Validação do Step em geral
  const isFormValid = formik.dirty && formik.isValid && valuesIsValid;

  return (
    <Modal
      open={handleOpen}
      handleClose={closeModal}
      width={mobile ? 340 : 650}
    >
      <Box
        sx={{
          maxHeight: mobile ? 650 : window.screen.width < 1600 ? 600 : 1200,
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        {!mobile && (
          <>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                pt: 3.5,
                pb: 3.5,
                borderRadius: 4,
                mb: 2,
                backgroundColor: red[100],
              }}
              elevation={0}
            >
              <Avatar
                sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}
              >
                <AddIcon sx={{ fontSize: 65 }} />
              </Avatar>
            </Paper>
            <Typography fontSize={21} fontWeight={700} mb={1}>
              {t("TEXT.ADD")} {t("TEXT.SECTOR")}
            </Typography>
          </>
        )}
        <form onSubmit={formik.handleSubmit}>
          {mobile ? (
            <>
              <MobileStepper
                steps={4}
                position="static"
                activeStep={activeStep}
                sx={{ mt: -2 }}
                nextButton={
                  activeStep === 3 ? (
                    <Button
                      type="submit"
                      disabled={!isFormValid}
                      sx={{ mr: 1 }}
                    >
                      {t("BUTTON.SAVE")}
                    </Button>
                  ) : (
                    <Button
                      onClick={handleNextStepp}
                      disabled={
                        activeStep === 0
                          ? !nameIsValid
                          : activeStep === 1
                          ? !ctrlReferenceIsValid
                          : activeStep === 2
                          ? !ctrlSectorIsValid
                          : activeStep === 3
                          ? !valuesIsValid
                          : isFormValid
                      }
                      sx={{ mr: 1 }}
                    >
                      <Box display="flex">
                        <Box fontWeight={700}>{t("TEXT.NEXT")}</Box>
                        <KeyboardArrowRight />
                      </Box>
                    </Button>
                  )
                }
                backButton={
                  activeStep === 0 ? (
                    <Button
                      onClick={handleClose}
                      sx={{ mr: 1, fontWeight: 700 }}
                    >
                      {t("BUTTON.CANCEL")}
                    </Button>
                  ) : (
                    <Button onClick={handleBackStepp} sx={{ mr: 1 }}>
                      <Box display="flex">
                        <KeyboardArrowLeft />
                        <Box fontWeight={700}>{t("BUTTON.RETURN")}</Box>
                      </Box>
                    </Button>
                  )
                }
              />
              <Typography fontSize={21} fontWeight={700} mb={1}>
                {t("TEXT.ADD")} {t("TEXT.SECTOR")}
              </Typography>
            </>
          ) : (
            <StepperCustom activeStep={activeStep} />
          )}
          {activeStep === 0 ? (
            <Box display="grid">
              <InputLabel sx={{ mb: 1, mt: -1 }}> {t("TEXT.NAME")}:</InputLabel>
              <TextField
                formik={formik}
                disabled={false}
                label={t("TEXT.NAME")}
                name="name"
                maxLength={100}
              />
              {formik.errors.name && formik.touched.name ? (
                <ErrorMessage>{formik.errors.name}</ErrorMessage>
              ) : null}
            </Box>
          ) : activeStep === 1 ? (
            <>
              <Box display="flex" mb={1}>
                <InputLabel sx={{ alignSelf: "center" }}>
                  {t("TEXT.MAIN_SENSOR")}:
                </InputLabel>
                <IconButton color="primary" onClick={handleOpenModalMainSensor}>
                  <HelpOutlineIcon color="info" />
                </IconButton>
              </Box>
              <Box>
                <Select
                  formik={formik}
                  label={t("TEXT.SELECT_DEVICE")}
                  name="macReference"
                  options={allMenuItems}
                  isDisabled={devices?.length === 0}
                  sx={
                    formik.values.macReference !== ""
                      ? {
                          "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              p: 0,
                            },
                          "& .css-qiwgdb": {
                            p: 0,
                          },
                        }
                      : {}
                  }
                />
              </Box>
              <Box mt={2} mb={2}>
                <Select
                  formik={formik}
                  isDisabled={!optionsController || controllers?.length === 0}
                  label={t("TEXT.SELECT_CONTROLLER")}
                  name="controllerReference"
                  options={optionsController}
                />
              </Box>
            </>
          ) : activeStep === 2 ? (
            <>
              <Box mb={3}>
                <Box display="flex" mb={1}>
                  <InputLabel sx={{ alignSelf: "center" }}>
                    {t("TEXT.SELECT_FIRST_CTRL")}:
                  </InputLabel>
                  <IconButton
                    color="primary"
                    onClick={handleOpenModalStandardCtrl}
                  >
                    <HelpOutlineIcon color="info" />
                  </IconButton>
                </Box>
                <Box>
                  <Select
                    formik={formik}
                    label={t("TEXT.SELECT_DEVICE")}
                    name="macSectorInitial"
                    options={optionsMacSectorInitial}
                    isDisabled={devices?.length === 0}
                    sx={
                      formik.values.macSectorInitial !== ""
                        ? {
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                p: 0,
                              },
                            "& .css-qiwgdb": {
                              p: 0,
                            },
                          }
                        : {}
                    }
                  />
                </Box>
                <Box display="flex" mt={1.5}>
                  <Select
                    formik={formik}
                    isDisabled={
                      formik.values.macSectorInitial === "" ||
                      controllersSectorInitial?.length === 0
                    }
                    label={t("TEXT.SELECT_CONTROLLER")}
                    name="controllerSectorInitial"
                    options={optionsControllerSectorInitial}
                  />
                </Box>
              </Box>
              <Box>
                <Box display="flex" mb={1}>
                  <InputLabel sx={{ alignSelf: "center" }}>
                    {t("TEXT.SELECT_SECOUND_CTRL")}:
                  </InputLabel>
                  <IconButton
                    color="primary"
                    onClick={handleOpenModalAdditionalCtrl}
                  >
                    <HelpOutlineIcon color="info" />
                  </IconButton>
                </Box>
                <Box>
                  <Select
                    formik={formik}
                    label={t("TEXT.SELECT_DEVICE")}
                    name="macSector"
                    options={optionsMacInitial}
                    isDisabled={
                      devices?.length === 0 ||
                      formik.values.controllerSectorInitial === "" ||
                      checkedModel === "" ||
                      !modelIsValid
                    }
                    sx={
                      formik.values.macSector !== ""
                        ? {
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                p: 0,
                              },
                            "& .css-qiwgdb": {
                              p: 0,
                            },
                          }
                        : {}
                    }
                  />
                </Box>
                <Box display="flex" mt={1.5}>
                  <FormControl
                    fullWidth
                    disabled={
                      !isValidApply ||
                      formik.values.controllerSectorInitial === "" ||
                      controllersSector?.length === 0 ||
                      !modelIsValid
                    }
                  >
                    <InputLabel>
                      {mobile
                        ? t("TEXT.CONTROLLERS")
                        : t("TEXT.SELECT_CONTROLLERS")}
                    </InputLabel>
                    <MuiSelect
                      multiple
                      fullWidth
                      value={deviceList}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          label={
                            mobile
                              ? t("TEXT.CONTROLLERS")
                              : t("TEXT.SELECT_CONTROLLERS")
                          }
                        />
                      }
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 450 } } }}
                      disabled={
                        optionsControllerSector?.length === 0 ||
                        formik.values.macSector === ""
                      }
                    >
                      {optionsControllerSector}
                    </MuiSelect>
                  </FormControl>
                  {deviceList.length > 0 && (
                    <Box alignSelf="center">
                      <Tooltip title={t("TEXT.CLEAN") as string}>
                        <IconButton onClick={handleClearSelectedCtrls}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Box ml={deviceList.length > 0 ? 0 : 1} alignSelf="center">
                    <Button
                      variant="contained"
                      onClick={handlePushDevides}
                      color="success"
                      size={mobile ? "small" : "medium"}
                      disabled={deviceList.length === 0 || !modelIsValid}
                    >
                      {t("BUTTON.ASSOCIATE")}
                    </Button>
                  </Box>
                </Box>
                {allDevicesSelected.length > 0 && (
                  <Box mt={2}>
                    <Box width="100%" mt={3}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        mb={0.5}
                      >
                        <InputLabel sx={{ alignSelf: "center", pb: 0.5 }}>
                          {t("TEXT.APPLIED_CONTROLLERS")}:
                        </InputLabel>
                        <Box alignSelf="center" mr={2}>
                          <Tooltip title={t("TEXT.CLEAN") as string}>
                            <IconButton onClick={handleOpenModalRemoveApplied}>
                              <HighlightOffIcon sx={{ fontSize: 25 }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box
                        display="grid"
                        sx={{
                          maxHeight: 180,
                          overflow: "auto",
                          overflowX: "hidden",
                          border: "1px solid rgb(220, 220, 220)",
                          borderRadius: "5px",
                        }}
                      >
                        <Table id="content">
                          <TableBody>
                            {allDevicesSelected &&
                              allDevicesSelected.map((m: any, i: number) => (
                                <TableRow key={i}>
                                  <TableCell
                                    align="left"
                                    colSpan={4}
                                    sx={{ p: 0, pt: 1.5, pb: 0.3 }}
                                  >
                                    <ListItem sx={{ pl: 1, pt: 0 }}>
                                      <ListItemIcon sx={{ mr: -2 }}>
                                        <CheckIcon color="success" />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          <Box display="flex">
                                            <Typography
                                              sx={{ placeSelf: "center" }}
                                            >
                                              {`${m.name} - ${
                                                m._id
                                                  .split("/")[1]
                                                  .split("L")[1]
                                              } - `}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                placeSelf: "center",
                                                ml: 0.5,
                                              }}
                                            >
                                              {filteredNameByMac(
                                                m._id.split("/")[0],
                                                deviceUsers
                                              )}
                                            </Typography>
                                          </Box>
                                        }
                                      />
                                    </ListItem>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    colSpan={4}
                                    sx={{ p: 0 }}
                                  >
                                    <Tooltip title={t("TEXT.REMOVE") as string}>
                                      <IconButton
                                        onClick={() =>
                                          handleOpenModalRemoveAppliedCtrl(m)
                                        }
                                      >
                                        <DeleteOutlineIcon color="error" />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" mb={1}>
                <InputLabel sx={{ alignSelf: "center" }}>
                  {`${t("TEXT.SELECT")} Setpoint`}:
                </InputLabel>
                <IconButton
                  color="primary"
                  onClick={handleOpenModalSelectSetpoint}
                >
                  <HelpOutlineIcon color="info" />
                </IconButton>
              </Box>
              <Box width="100%" mt={1} mb={3}>
                <Select
                  formik={formik}
                  label={`${t("TEXT.SELECT")} Setpoint`}
                  name="setpoint"
                  options={optionsSetpoints}
                  disabled={false}
                />
                {formik.errors.setpoint && formik.touched.setpoint ? (
                  <ErrorMessage>{formik.errors.setpoint}</ErrorMessage>
                ) : null}
              </Box>
              <Box display="flex" mb={1}>
                <InputLabel sx={{ alignSelf: "center" }}>
                  {t("TEXT.LIMITS")}:
                </InputLabel>
                <IconButton color="primary" onClick={handleOpenModalMaxMin}>
                  <HelpOutlineIcon color="info" />
                </IconButton>
              </Box>
              <Box display={mobile ? "grid" : "flex"}>
                <Box width="100%" mr={3}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    disabled={formik.values.setpoint === ""}
                  >
                    <InputLabel>{minLabel}</InputLabel>
                    <OutlinedInput
                      error={
                        formik.values.default_resource_min !== "" &&
                        !(
                          +formik.values.default_resource_min >=
                            +rangeMin?.split(" ")[0] &&
                          +formik.values.default_resource_min <=
                            +rangeMin?.split(" ")[2]
                        )
                      }
                      name="default_resource_min"
                      value={formik.values.default_resource_min}
                      onChange={formik.handleChange}
                      endAdornment={
                        formik.values.setpoint !== "" && (
                          <InputAdornment position="end">
                            {filteredSetpoint?.unitOfMeasurement}
                          </InputAdornment>
                        )
                      }
                      label={minLabel}
                    />
                    {showRange && (
                      <FormHelperText>{`${rangeMin} ${filteredSetpoint?.unitOfMeasurement}`}</FormHelperText>
                    )}
                  </FormControl>
                  {formik.errors.default_resource_min &&
                  formik.touched.default_resource_min ? (
                    <ErrorMessage>
                      {formik.errors.default_resource_min}
                    </ErrorMessage>
                  ) : null}
                </Box>
                <Box width="100%" mt={mobile ? 1.5 : 0}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    disabled={formik.values.setpoint === ""}
                  >
                    <InputLabel>{maxLabel}</InputLabel>
                    <OutlinedInput
                      error={
                        formik.values.default_resource_max !== "" &&
                        !(
                          +formik.values.default_resource_max >=
                            +rangeMax?.split(" ")[0] &&
                          +formik.values.default_resource_max <=
                            +rangeMax?.split(" ")[2]
                        )
                      }
                      name="default_resource_max"
                      value={formik.values.default_resource_max}
                      onChange={formik.handleChange}
                      endAdornment={
                        formik.values.setpoint !== "" && (
                          <InputAdornment position="end">
                            {filteredSetpoint?.unitOfMeasurement}
                          </InputAdornment>
                        )
                      }
                      label={maxLabel}
                    />
                    {showRange && (
                      <FormHelperText>{`${rangeMax} ${filteredSetpoint?.unitOfMeasurement}`}</FormHelperText>
                    )}
                  </FormControl>
                  {formik.errors.default_resource_max &&
                  formik.touched.default_resource_max ? (
                    <ErrorMessage>
                      {formik.errors.default_resource_max}
                    </ErrorMessage>
                  ) : null}
                </Box>
              </Box>
              {filteredSetpoint?.unitOfMeasurement !== " Hz" && (
                <Box width="100%" mr={3}>
                  <Box display="flex" mb={1} mt={2}>
                    {filteredSetpoint?.description ? (
                      <Box display="flex">
                        <InputLabel sx={{ alignSelf: "center", mr: 0.5 }}>
                          {t("TEXT.INITIAL_VALUE")}:
                        </InputLabel>
                        <InputLabel
                          sx={{ alignSelf: "center", fontWeight: 500 }}
                        >
                          {filteredSetpoint?.description}
                        </InputLabel>
                      </Box>
                    ) : (
                      <InputLabel sx={{ alignSelf: "center" }}>
                        {`${t("TEXT.INITIAL_VALUE")}: ${"Setpoint"}`}
                      </InputLabel>
                    )}
                    <IconButton
                      color="primary"
                      onClick={handleOpenModalInitialSetpoint}
                    >
                      <HelpOutlineIcon color="info" />
                    </IconButton>
                  </Box>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    disabled={formik.values.setpoint === ""}
                  >
                    <InputLabel>{t("TEXT.INITIAL_VALUE")}</InputLabel>
                    <OutlinedInput
                      error={
                        formik.values.resource_value !== "" &&
                        !(
                          +formik.values.resource_value >=
                            +formik.values.default_resource_min &&
                          +formik.values.resource_value <=
                            +formik.values.default_resource_max
                        )
                      }
                      name="resource_value"
                      value={formik.values.resource_value}
                      onChange={formik.handleChange}
                      endAdornment={
                        formik.values.setpoint !== "" && (
                          <InputAdornment position="end">
                            {filteredSetpoint?.unitOfMeasurement}
                          </InputAdornment>
                        )
                      }
                      label={t("TEXT.INITIAL_VALUE")}
                    />
                    {showRangeInitialValue && (
                      <FormHelperText>{`${rangeInitialValue} ${filteredSetpoint?.unitOfMeasurement}`}</FormHelperText>
                    )}
                  </FormControl>
                  {formik.errors.resource_value &&
                  formik.touched.resource_value ? (
                    <ErrorMessage>{formik.errors.resource_value}</ErrorMessage>
                  ) : null}
                </Box>
              )}
            </>
          )}
          {!mobile && (
            <Box
              mt={3}
              display="flex"
              justifyContent="space-between"
              pl={3}
              pr={3}
            >
              {activeStep === 0 ? (
                <Button
                  onClick={closeModal}
                  variant="outlined"
                  size={mobile ? "small" : "medium"}
                >
                  {t("BUTTON.CANCEL")}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<ArrowLeftIcon />}
                  onClick={handleBackStepp}
                  size={mobile ? "small" : "medium"}
                  sx={{ mr: 2 }}
                >
                  {t("TEXT.PREVIOUS")}
                </Button>
              )}
              {activeStep === 3 ? (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isFormValid}
                  size={mobile ? "small" : "medium"}
                >
                  {t("BUTTON.CONFIRM")}
                </Button>
              ) : (
                <Button
                  size={mobile ? "small" : "medium"}
                  disabled={
                    activeStep === 0
                      ? !nameIsValid
                      : activeStep === 1
                      ? !ctrlReferenceIsValid
                      : activeStep === 2
                      ? !ctrlSectorIsValid
                      : activeStep === 3
                      ? !valuesIsValid
                      : isFormValid
                  }
                  variant="contained"
                  onClick={handleNextStepp}
                  endIcon={<ArrowRightIcon />}
                >
                  {t("TEXT.NEXT")}
                </Button>
              )}
            </Box>
          )}
        </form>
        {modalRemoveValue && (
          <ModalRemoveDeviceApplied
            handleClose={handleCloseModalRemoveAppliedCtrl}
            setAppliedCleaned={setAppliedCleanedCtrl}
            handleOpen={openModalRemoveAppliedCtrl}
            modalRemoveValue={modalRemoveValue}
            deviceUsers={deviceUsers}
          />
        )}
        <ModalRemoveDevicesApplied
          handleClose={handleCloseModalRemoveApplied}
          handleOpen={openModalRemoveApplied}
          setApplyCtrls={setApplyCtrls}
        />
        <ModalHelpMainSensor
          handleClose={handleCloseModalMainSensor}
          handleOpen={openModalMainSensor}
        />
        <ModalStandardCtrl
          handleClose={handleCloseModalStandardCtrl}
          handleOpen={openModalStandardCtrl}
        />
        <ModalAdditionalCtrl
          handleClose={handleCloseModalAdditionalCtrl}
          handleOpen={openModalAdditionalCtrl}
        />
        <ModalSelectSetpoint
          handleClose={handleCloseModalSelectSetpoint}
          handleOpen={openModalSelectSetpoint}
        />
        <ModalMaxMin
          handleClose={handleCloseModalMaxMin}
          handleOpen={openModalMaxMin}
        />
        <ModalInitialSetpoint
          handleClose={handleCloseModalInitialSetpoint}
          handleOpen={openModalInitialSetpoint}
          filteredSetpoint={filteredSetpoint}
        />
      </Box>
    </Modal>
  );
};

export default ModalAdd;
