import { TextField as TextFieldItens } from "@mui/material";

import { InputProps } from "./types";

function get(object: any, path: string): any {
  let result = object;
  if (!path.includes("sensorConditions")) {
    if (path in result) {
      return result[path];
    }
    return "";
  }
  let nested = path.split(".")[1];
  let index = path.split("[")[1][0];
  return result?.sensorConditions[+index][nested];
}

// Input que recebe a lib Formik
export const TextField = ({
  formik,
  name,
  label,
  maxLength,
  ...rest
}: InputProps) => {
  return (
    <TextFieldItens
      label={label}
      name={name}
      value={get(formik.values, name)}
      onChange={formik?.handleChange}
      onBlur={formik?.handleBlur}
      disabled={formik?.isSubmitting}
      inputProps={{ maxLength }}
      {...rest}
    />
  );
};
