import React from "react";
import { useTranslation } from "react-i18next";

import { Delete as DeleteIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";
import { ToastContext } from "../../../contexts/ToastContext";
import { red } from "@mui/material/colors";
import Modal from "../../Devices/Modal";
import { deleteCtrlFromSector } from "../../../services/data/sector";

interface ModalRemoveCardSectorProps {
  idSector: string;
  ctrlId: string;
  rotulo: string;
  setSectorEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalRemoveCardSector = ({
  setSectorEdited,
  ctrlId,
  handleOpen,
  handleClose,
  rotulo,
  idSector,
}: ModalRemoveCardSectorProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);

  // Excluir cenário
  const handleDelete = (e: any) => {
    e.preventDefault();

    const data = {
      id: idSector,
      ctrlId: ctrlId,
    };

    // Rota para remover controlador do setor
    deleteCtrlFromSector(idSector, ctrlId, data)
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.DELETE_DEVICE"));
        setSectorEdited(true);
        handleClose();
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.DELETE_DEVICE"));
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <DeleteIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.REMOVE_DEVICE")}?
      </Typography>
      <Typography variant="body2">
        {t("TEXT.DELETE_DEVICE_MESSAGE")}
        <strong>{` ${rotulo}`}</strong>?
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={(e) => handleDelete(e)}
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: red[400],
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("TEXT.REMOVE")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: red[50],
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalRemoveCardSector;
