import { getRawCtrlConfig } from "../../config";
import {
  CtrlModels,
  Measures,
  Medidas,
  Setpoints,
} from "../../services/types/ctrls";
import { Graph } from "../../services/types/store";
import { parseCardData } from "../Card/parser";
import { SensorData } from "../Controller/parser/types";

// Retorna as labels do gráfico do modelo JSON
const getSensoresLabels = (obj: any) =>
  Object.values(obj ?? []).map((data: any) => {
    const label: string = data?.[0];
    const unit: string = data?.[1].replace(/\s/g, "");
    const merge = { label, unit };
    return Object.values(merge).toString().replace(",", " (").concat(")");
  });

// Retorna os setpoints do gráfico do modelo JSON
const getSetpointsLabels = (obj: any) =>
  Object.values(obj ?? []).map((data: any) => {
    const label: string = data?.[0];
    const unit: string = data?.[4].replace(/\s/g, "");
    const merge = { label, unit };
    return Object.values(merge).toString().replace(",", " (").concat(")");
  });

export const ctrlSettings = (model: string) => getRawCtrlConfig(model);

// Renomear as chaves de um objeto
export const renameKeys = (obj: any, newKeys: any) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

// Filtrar os timestamps do backend das ultimas 24 horas
export const filterTimesFromBack = (dataOfGraph: any): number[] =>
  dataOfGraph?.map((v: any) => {
    return v.timestamp;
  });

// Labels das medidas
export const medidasLabels = (modelo: CtrlModels) =>
  getSensoresLabels(ctrlSettings(modelo)?.sensores);

// Divide o valor pelo divisor se este for diferente de 0
const applyDivider = (value: number, divider: number) =>
  !!divider ? value / divider : value;

// Converte um array sensores do modelo para um objeto tipado
const getSensorData = (model: CtrlModels) => {
  const sensorData = getRawCtrlConfig(model)?.sensores;
  const info = [] as SensorData[];
  const values = sensorData ? Object.values(sensorData) : [];
  values.forEach((value: any) => {
    const divider = +value[2][1];
    info.push({ divider });
  });
  return info;
};

// Filtrar as medidas com valor diferente de 32767 e divide pelo o divisor no JSON
const objMedidas = (medidas: Medidas, modelo: CtrlModels) => {
  const sensorData = getSensorData(modelo);
  return sensorData.map((data, i) => {
    const { divider } = data;
    return applyDivider(
      Object.values(medidas).filter((f) => f !== 32767)[i],
      divider
    );
  });
};
// Transformar a array das medidas em objeto
const newKeysMedidas = (modelo: CtrlModels) =>
  Object.assign({}, medidasLabels(modelo));

// Renomear as chaves do objeto medidas
export const resultMedidas = (medidas: Medidas, modelo: CtrlModels) =>
  renameKeys(objMedidas(medidas, modelo), newKeysMedidas(modelo));

// Labels dos setpoints
export const setpointLabels = (modelo: CtrlModels) =>
  getSetpointsLabels(ctrlSettings(modelo)?.setpoints);

// Converte um array setpoints do modelo para um objeto tipado
const getsetpointsData = (model: CtrlModels) => {
  const setpointsData = getRawCtrlConfig(model)?.setpoints;
  const info = [] as SensorData[];
  const values = setpointsData ? Object.values(setpointsData) : [];
  values.forEach((value: any) => {
    const divider = +value[6][1];
    info.push({ divider });
  });
  return info;
};

// Pegar os alarmes para a tabela de Registros nos relatórios
export const getAlarm = (measures: Measures) => {
  const alarm = { alarm: parseCardData("light", measures).alarm };
  return alarm;
};

// Pegar os icones para a tabela de Registros nos relatórios
export const getIcons = (measures: Measures) => {
  const alarm = { alarm: parseCardData("light", measures).icons };
  return alarm;
};

// Filtrar os setpoints com valor diferente de 32767 e divide pelo o divisor no JSON
const objSetpoints = (setpoints: Setpoints, modelo: CtrlModels) => {
  const setpointsData = getsetpointsData(modelo);
  return setpointsData.map((data, i) => {
    const { divider } = data;
    return applyDivider(
      Object.values(setpoints).filter((f) => f !== 32767)[i],
      divider
    );
  });
};

// Transformar a array dos setpoints em objeto
const newKeySetpoints = (modelo: CtrlModels) =>
  Object.assign({}, setpointLabels(modelo));

// Renomear as chaves do objeto setpoints
export const resultSetpoints = (setpoints: Setpoints, modelo: CtrlModels) =>
  renameKeys(objSetpoints(setpoints, modelo), newKeySetpoints(modelo));

// Filtrar labels diferente de undefined
export const filterNotNullLabels = (graphDataValues: Graph) =>
  graphDataValues?.graphLabels.filter((v) => v !== undefined);

// Filtrar somente os timestamps para o gráfico
export const filterTimes = (graphDataValues: Graph) =>
  graphDataValues?.times?.map((v) => {
    return v;
  }) ?? [];

// Dados numéricos do gráfico
export const getFields = (list: any, field: any) => {
  return list.reduce((carry: any, item: any) => {
    carry.push(item[field] ?? null);
    return carry;
  }, []);
};

// Array de arrays contendo o rótulo e os valores numéricos do gráfico
export const graphData = (dataGraph: any, graphDataValues: Graph) =>
  dataGraph.map((v: any) => {
    if (!filterNotNullLabels(graphDataValues).includes(v[0])) {
      return ["", []];
    }

    return v;
  });
