import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

import { Alarmes, Color } from "../components/Controller/parser/types";
import { Ctrl, Measures } from "../services/types/ctrls";

interface ParametrosProviderProps {
  children: ReactNode;
}

export interface CardIcon {
  active: boolean;
  description: {
    active: string;
    inactive: string;
  };
  icon: JSX.Element;
}

export interface CardData {
  alarm?: Alarmes;
  color: Color;
  icons: CardIcon[];
  measures: Measures;
}

interface ParametrosData {
  parametrosData: Ctrl | undefined;
  submitParametros: boolean | undefined;
  setSubmitParametros: Dispatch<SetStateAction<boolean | undefined>>;
  setParametrosData: Dispatch<SetStateAction<Ctrl | undefined>>;
}

export const ParametrosContext = createContext({} as ParametrosData);

export const ParametrosProvider = ({ children }: ParametrosProviderProps) => {
  const [parametrosData, setParametrosData] = useState<Ctrl>();
  const [submitParametros, setSubmitParametros] = useState<boolean>();

  const ParametrosProviderValue = useMemo(
    () => ({
      parametrosData,
      submitParametros,
      setSubmitParametros,
      setParametrosData,
    }),
    [parametrosData, submitParametros]
  );

  return (
    <ParametrosContext.Provider value={ParametrosProviderValue}>
      {children}
    </ParametrosContext.Provider>
  );
};
