import React from "react";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AccountTree as AccountTreeIcon,
  Check as CheckIcon,
  Replay as ReplayIcon,
  DeleteOutlined as DeleteOutlineIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";
import AvatarIcon from "../../components/AvatarIcon";
import { green, red } from "@mui/material/colors";
import { AuthContext } from "../../contexts/AuthContext";
import { TOKEN_KEY } from "../../auth/constants";
import { api } from "../../services/api";
import {
  getChangeHistory,
  getModelos,
  getModelsById,
} from "../../services/data/modelo";
import Spinner from "../../components/Spinner";
import { getLanguage } from "../../translations/languages";
import ModalEdit from "./Modal/ModalEdit";
import { convertDateTime, hasOtherKeys } from "./parse";
import ModalRestore from "./Modal/ModalRestore";
import { EditorNotEdited } from "./Editor";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import ModalRemove from "./Modal/ModalRemove";
import JSONEditor from "jsoneditor";

let newActualModel: any = {};

interface EditorProps {
  actualModel: any;
  setSaveButton: (e: boolean) => void;
  lang: string;
  setModelWithError: (e: boolean) => void;
}

// Estrutura de edição do modelo JSON
const Editor = ({
  actualModel,
  setSaveButton,
  lang,
  setModelWithError,
}: EditorProps) => {
  const ref: any = React.useRef();
  const mobile = useIsMobile();
  const [editor, setEditor] = React.useState<JSONEditor>();

  // Armazenar os dados iniciais
  React.useEffect(() => {
    newActualModel = actualModel;
  }, [actualModel]);

  // Alterar os dados com "onChange"
  React.useEffect(() => {
    const newEditor: any = new JSONEditor(ref.current, {
      mode: "code",
      onChangeText: (e) => {
        const parse = { ...newActualModel, ...JSON.parse(e) };
        if (!hasOtherKeys(parse)) {
          newActualModel = parse;
          setModelWithError(false);
        } else {
          setModelWithError(true);
        }
        setSaveButton(true);
      },
      mainMenuBar: false,
    });
    newEditor.set({
      [lang]: newActualModel[lang],
    });
    setEditor(newEditor);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Alterar o idioma
  React.useEffect(() => {
    editor?.set({
      [lang]: newActualModel[lang],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div
      id="jsoneditor"
      ref={ref}
      style={{
        height: mobile ? "500px" : "950px",
      }}
    />
  );
};

const Models = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const { authenticated, token } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [actualModel, setActualModel] = React.useState<any>();
  const [idSelectedModel, setIdSelectedModel] = React.useState<string>("");
  const [historyModels, setHistoryModels] = React.useState<any>();
  const [saveButton, setSaveButton] = React.useState<boolean>(false);
  const [modelsEdited, setModelsEdited] = React.useState<boolean>(false);
  const [importedModel, setImportedModel] = React.useState<boolean>(false);
  const [changeColor, setChangeColor] = React.useState("");
  const [modelWithError, setModelWithError] = React.useState<boolean>(false);

  // Ação de alterar a linguagem dos dados
  const [lang, setLang] = React.useState<string>("pt-br");
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLang(event.target.value as string);
  };

  // Ação de alterar a cor dos itens do 'Histórico de alterações'
  const handleChangeColor = (event: string) => {
    setChangeColor(event);
  };

  React.useEffect(() => {
    const fetchStatus = () => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Resgatar os dados dos modelos
        getModelos().then((res) => {
          setActualModel(JSON.parse(JSON.stringify(res.data.model, null, 2)));
        });

        // Resgatar a lista do histórico de alterações dos modelos JSON
        getChangeHistory().then((res) => {
          const { data } = res;
          setHistoryModels(data);
        });
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
  }, [authenticated, language, token]);

  // Atualizar os dados dos modelos após a edição de qualquer dado
  React.useEffect(() => {
    if (modelsEdited) {
      // Resgatar os dados dos modelos
      getModelos().then((res) => {
        setActualModel(JSON.parse(JSON.stringify(res.data.model, null, 2)));
      });

      // Resgatar a lista do histórico de alterações dos modelos JSON
      getChangeHistory().then((res) => {
        const { data } = res;
        setHistoryModels(data);
      });
      setModelsEdited(false);
    }
  }, [language, modelsEdited]);

  // Ações do modal de editar
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const handleOpenModalEdit = () => {
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Ações do modal de deletar
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenModalDelete = (e: any, id: string, day: string) => {
    e.stopPropagation();
    setModalDeleteValues({ id, day });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ação de resgatar modelo por 'id'
  const handleClickModelHistoric = (id: string) => {
    setActualModel(undefined);
    handleChangeColor(id);
    getModelsById(id).then((res) => {
      const { data } = res;
      setActualModel(data.model);
      setIdSelectedModel(id);
    });
    setImportedModel(false);
    setSaveButton(false);
  };

  // Ações do modal de restaurar versão
  const [openModalRestore, setOpenModalRestore] = React.useState(false);
  const handleOpenModalRestore = () => {
    setOpenModalRestore(true);
  };
  const handleCloseModalRestore = () => {
    setOpenModalRestore(false);
  };

  // Checar se foi selecionado algum payload do histórico
  const checkHandleClickedModels =
    historyModels &&
    idSelectedModel !== "" &&
    historyModels[0]?._id !== idSelectedModel;

  return (
    <Grid container sx={{ placeContent: "center" }}>
      <Box m={3.5} mt={0}>
        <Box position="sticky" top="90px">
          <Card sx={{ mt: 0 }}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 3.5,
                  pb: 3.5,
                  pl: 5,
                  pr: 5,
                  borderRadius: 4,
                  mb: 2.4,
                  backgroundColor: red[100],
                }}
              >
                <Avatar
                  sx={{ width: 130, height: 130, boxShadow: 3, ml: 4, mr: 4 }}
                >
                  <AvatarIcon
                    name={t("TEXT.CHANGE_HISTORY")}
                    width={130}
                    height={130}
                    fontSize={70}
                    bgcolor={red[400]}
                  />
                </Avatar>
              </Box>
              <Typography
                fontSize={22}
                fontWeight={500}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                textAlign="center"
                width={260}
              >
                {t("TEXT.CHANGE_HISTORY")}
              </Typography>
              <Typography width={260} mt={1} pl={1.5} fontSize={15}>
                {t("TEXT.CHANGE_HISTORY_MESSAGE")}
              </Typography>
              <Box>
                <Box display="grid" mt={1}>
                  <List>
                    {historyModels &&
                      historyModels.map((m: any, i: number) => (
                        <ListItem
                          disablePadding
                          key={i}
                          onClick={() => handleClickModelHistoric(m._id)}
                          sx={{
                            "&:hover": {
                              backgroundColor:
                                theme === "light"
                                  ? "#FFF2F2"
                                  : "rgb(50, 53, 70)",
                            },
                          }}
                        >
                          <ListItemButton>
                            <ListItemIcon
                              sx={{
                                mr: -2,
                              }}
                            >
                              <CheckIcon color="success" />
                            </ListItemIcon>
                            <Typography
                              fontWeight={500}
                              fontSize={15}
                              sx={{
                                color:
                                  changeColor === m._id
                                    ? red[400]
                                    : theme === "dark"
                                    ? "#FEFEFE"
                                    : "black",
                              }}
                            >
                              {convertDateTime(m.createdAt, language)}
                            </Typography>
                            {i === 0 ? (
                              <Chip
                                label={t("TEXT.CURRENT")}
                                color="success"
                                size="small"
                                sx={{
                                  ml: 2,
                                  fontWeight: 700,
                                  backgroundColor: green[100],
                                  color: green[800],
                                  textTransform: "uppercase",
                                  fontSize: 11,
                                }}
                              />
                            ) : (
                              <ListItemIcon>
                                <Tooltip title={t("TEXT.REMOVE")}>
                                  <IconButton
                                    color="error"
                                    onClick={(e: any) =>
                                      handleOpenModalDelete(
                                        e,
                                        m._id,
                                        convertDateTime(m.createdAt, language)
                                      )
                                    }
                                    sx={{ ml: 2.5, mt: -0.4 }}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Paper sx={{ p: 0, mb: 2, borderRadius: 3 }} elevation={3}>
          {actualModel === undefined ? (
            <Box
              sx={{
                alignContent: "center",
                minHeight: mobile ? 500 : 1094.5,
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Spinner />
            </Box>
          ) : (
            <Box
              sx={{
                minHeight: 500,
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Box display="flex" justifyContent="space-between" ml={2}>
                <Box display="flex" alignItems="center" p={3.5} pb={1}>
                  <Box sx={{ mr: 1 }}>
                    <AccountTreeIcon fontSize="large" />
                  </Box>
                  <Typography fontSize={mobile ? 25 : 28} gutterBottom>
                    {t("TEXT.MODELS")}
                  </Typography>
                </Box>
                <Box display={mobile ? "grid" : "flex"}>
                  <FormControl
                    variant="standard"
                    sx={{ mt: 1.5, mr: 2, width: 80 }}
                  >
                    <InputLabel>{t("TEXT.TRANSLATIONS")}</InputLabel>
                    <Select
                      value={lang}
                      label={t("TEXT.TRANSLATIONS")}
                      onChange={handleChangeLanguage}
                      disabled={modelWithError}
                    >
                      <MenuItem value="pt-br">pt-br</MenuItem>
                      <MenuItem value="en">en</MenuItem>
                      <MenuItem value="es">es</MenuItem>
                    </Select>
                  </FormControl>
                  <Box alignSelf="center">
                    {checkHandleClickedModels === true ? (
                      <Button
                        size="small"
                        color="warning"
                        startIcon={<ReplayIcon />}
                        onClick={handleOpenModalRestore}
                        sx={{
                          fontSize: 13,
                          fontWeight: 600,
                          mt: mobile ? 1 : 0,
                          mr: mobile ? 1 : 2,
                        }}
                      >
                        {t("TEXT.RESTORE_VERSION")}
                      </Button>
                    ) : (
                      <Box alignSelf="center">
                        <Button
                          size="small"
                          color="warning"
                          disabled={!saveButton || modelWithError}
                          startIcon={<CheckIcon />}
                          onClick={handleOpenModalEdit}
                          sx={{
                            fontSize: 13,
                            fontWeight: 600,
                            mt: mobile ? 1 : 0,
                            mr: mobile ? 0 : 2,
                          }}
                        >
                          {t("BUTTON.SAVE")}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider />
              {modelWithError && (
                <Alert
                  severity="error"
                  sx={{ m: 2, mb: 0, alignItems: "center" }}
                >
                  <Typography>{t("TOAST.ERROR.MODEL_STRUCTURE")}</Typography>
                </Alert>
              )}
              {checkHandleClickedModels !== undefined && (
                <Box p={3.5}>
                  {importedModel === true ||
                  checkHandleClickedModels === true ? (
                    <EditorNotEdited
                      actualModel={actualModel}
                      setActualModel={setActualModel}
                      setSaveButton={setSaveButton}
                      lang={lang}
                    />
                  ) : (
                    <Editor
                      actualModel={actualModel}
                      setSaveButton={setSaveButton}
                      lang={lang}
                      setModelWithError={setModelWithError}
                    />
                  )}
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Grid>
      <ModalEdit
        handleOpen={openModalEdit}
        handleClose={handleCloseModalEdit}
        setModelsEdited={setModelsEdited}
        setSaveButton={setSaveButton}
        data={newActualModel}
      />
      <ModalRestore
        handleOpen={openModalRestore}
        handleClose={handleCloseModalRestore}
        setModelsEdited={setModelsEdited}
        setSaveButton={setSaveButton}
        data={actualModel}
      />
      <ModalRemove
        handleOpen={openModalDelete}
        handleClose={handleCloseModalDelete}
        setModelsEdited={setModelsEdited}
        setSaveButton={setSaveButton}
        id={modalDeleteValues?.id}
        day={modalDeleteValues?.day}
      />
    </Grid>
  );
};

export default Models;
