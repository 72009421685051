import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ArrowRight as ArrowRightIcon,
  LockOutlined,
  PersonOutlined,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContext } from "../../../contexts/ToastContext";
import { DASHBOARD } from "../../../Routes/constants";
import ErrorMessage from "../../ErrorMessage";
import { TextField, yupRequired, yupStringMax } from "../../Form";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../contexts/AuthContext";
import { confirmRegistrationLogin } from "../../../services/data/auth";

interface LocationState {
  from: {
    pathname: string;
  };
}

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = React.useContext(AuthContext);
  const { toastError } = React.useContext(ToastContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const { t } = useTranslation();
  const maxLength = 50;

  // Checar se há um link na URL de compartilhamento de dispositivo
  const queryParameters = new URLSearchParams(window.location.search);
  const tokenUrl = queryParameters.get("token");

  React.useEffect(() => {
    const fetchStatus = () => {
      // Checar se há um link na URL de compartilhamento de dispositivo
      if (tokenUrl !== null) {
        // Confirmar cadastro na página do login
        confirmRegistrationLogin({ token: tokenUrl });
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000000);
    return () => clearInterval(statusInterval);
  }, [tokenUrl]);

  // Login do usuário
  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: Yup.object({
      login: yupRequired(yupStringMax(maxLength)),
      password: yupRequired(yupStringMax(maxLength)),
    }),
    onSubmit: (values, { setSubmitting }) => {
      signIn(values).then(async (success) => {
        if (success) {
          const state = location.state as LocationState;
          const from = state?.from?.pathname || DASHBOARD;
          navigate(from, { replace: true });
        } else {
          toastError(t("TOAST.ERROR.LOGIN"));
          setSubmitting(false);
        }
      });
    },
  });

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Grid item>
        <TextField
          variant="standard"
          formik={formik}
          name="login"
          label={t("TEXT.USER")}
          inputProps={{ maxLength }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ color: "black", pt: 2, pb: 3, pl: 1 }}
              >
                <PersonOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" sx={{ color: "black" }} />
            ),
          }}
          fullWidth
        />
        {formik.errors.login && formik.touched.login ? (
          <ErrorMessage>{formik.errors.login}</ErrorMessage>
        ) : null}
      </Grid>
      <Grid item>
        <FormControl variant="standard" fullWidth>
          <InputLabel>{t("TEXT.PASSWORD")}</InputLabel>
          <Input
            name="password"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            autoComplete="on"
            onChange={formik.handleChange}
            startAdornment={
              <InputAdornment
                position="start"
                sx={{ color: "black", pt: 2, pb: 3, pl: 1 }}
              >
                <LockOutlined />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                position="start"
                sx={{ color: "black", pt: 2, pb: 3, pr: 1 }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOffIcon sx={{ color: "black" }} />
                  ) : (
                    <VisibilityIcon sx={{ color: "black" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{ maxLength }}
          />
        </FormControl>
        {formik.errors.password && formik.touched.password ? (
          <ErrorMessage>{formik.errors.password}</ErrorMessage>
        ) : null}
      </Grid>
      <Grid item>
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          disabled={formik.isSubmitting}
          endIcon={<ArrowRightIcon />}
          sx={{
            justifyContent: "space-between",
            backgroundColor: "#A0181A",
            borderRadius: "8px",
          }}
        >
          <Box ml={2} />
          {formik.isSubmitting ? (
            <Spinner size={26} disableMargin />
          ) : (
            t("TEXT.LOGIN")
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Form;
