import React from "react";

import {
  CardMembership as CardMembershipIcon,
  DashboardOutlined as DashboardOutlinedIcon,
  Devices as DevicesIcon,
  Equalizer as EqualizerIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  PendingActions as PendingActionsIcon,
  ViewAgendaOutlined as ViewAgendaOutlinedIcon,
  Dvr as DvrIcon,
  Settings as SettingsIcon,
  AccountTree as AccountTreeIcon,
  GTranslate as GTranslateIcon,
} from "@mui/icons-material";

import {
  DEVICES,
  DASHBOARD,
  REPORTS,
  USERS,
  PLANS_AND_SUBSCRIPTIONS,
  SCENARIOS,
  SECTORS,
  MACS,
  SETTINGS,
  MODELS,
  TRANSLATIONS,
} from "../../Routes/constants";

interface MenuItemsProps {
  path: string;
  text: string;
  icon: React.ReactNode;
  requireAdmin?: boolean;
  isUserAdmin: boolean;
}

// Itens do menu lateral do Layout
const MenuItems: MenuItemsProps[] = [
  {
    path: DASHBOARD,
    text: "Dashboard",
    icon: <DashboardOutlinedIcon />,
    isUserAdmin: false,
  },
  {
    path: DEVICES,
    text: "TEXT.DEVICES",
    icon: <DevicesIcon />,
    isUserAdmin: false,
  },
  {
    path: REPORTS,
    text: "TEXT.REPORTS",
    icon: <EqualizerIcon />,
    isUserAdmin: false,
  },
  {
    path: USERS,
    text: "TEXT.USERS",
    icon: <PersonOutlineOutlinedIcon />,
    isUserAdmin: false,
  },
  {
    path: PLANS_AND_SUBSCRIPTIONS,
    text: "TEXT.PLANS",
    icon: <CardMembershipIcon />,
    isUserAdmin: false,
  },
  {
    path: SCENARIOS,
    text: "TEXT.SCENARIOS",
    icon: <PendingActionsIcon />,
    isUserAdmin: false,
  },
  {
    path: SECTORS,
    text: "TEXT.SECTORS",
    icon: <ViewAgendaOutlinedIcon />,
    isUserAdmin: false,
  },
  {
    path: MACS,
    text: "TEXT.MACS_ADDRESS",
    icon: <DvrIcon />,
    isUserAdmin: true,
  },
  {
    path: SETTINGS,
    text: "TEXT.SETTINGS",
    icon: <SettingsIcon />,
    isUserAdmin: true,
  },
  {
    path: MODELS,
    text: "TEXT.MODELS",
    icon: <AccountTreeIcon />,
    isUserAdmin: true,
  },
  {
    path: TRANSLATIONS,
    text: "TEXT.TRANSLATIONS",
    icon: <GTranslateIcon />,
    isUserAdmin: true,
  },
];

export default MenuItems;
