import React from "react";

import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Edit as EditIcon,
  PersonOutline as PersonOutlineIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  BusinessOutlined as BusinessOutlinedIcon,
  EmailOutlined as EmailOutlinedIcon,
  LocalPhoneOutlined as LocalPhoneOutlinedIcon,
  AssignmentIndOutlined as AssignmentIndOutlinedIcon,
  VpnKeyOutlined as VpnKeyOutlinedIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  PasswordOutlined as PasswordOutlinedIcon,
  ContentCopy as ContentCopyIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";

import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import ModalEditName from "./Modal/ModalEditName";
import ModalEditCompany from "./Modal/ModalEditCompany";
import ModalEditSurname from "./Modal/ModalEditSurname";
import ModalEditPhone from "./Modal/ModalEditPhone";
import ModalEditLocal from "./Modal/ModalEditLocal";
import ModalEditPassword from "./Modal/ModalEditPassword";
import ModalEditExtraInfo from "./EditExtraInfo/ModalEditExtraInfo";
import { ToastContext } from "../../contexts/ToastContext";
import { getTokenTelegram } from "../../services/data/user";
import ModalTelegramHelp from "./Modal/ModalTelegramHelp";
import { AuthContext } from "../../contexts/AuthContext";

interface BasicInfoProps {
  user: any;
  setDeviceEdited: (e: boolean) => void;
  state: string;
  neighborhood: string;
  street: string;
  city: string;
  setCity: (e: any) => void;
  setStreet: (e: any) => void;
  setNeighborhood: (e: any) => void;
  setState: (e: any) => void;
}

const BasicInfo = ({
  user,
  setDeviceEdited,
  setCity,
  setStreet,
  setNeighborhood,
  setState,
  state,
  neighborhood,
  street,
  city,
}: BasicInfoProps) => {
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1200;
  const { t } = useTranslation();
  const { toastSuccess } = React.useContext(ToastContext);
  const { userArcsys, userId } = React.useContext(AuthContext);
  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  // Formatação do telefone
  const formatPhone = (text: string) =>
    text && text?.length === 11
      ? `(${text[0]}${text[1]}) ${text[2]}${text[3]}${text[4]}${text[5]}${text[6]}-${text[7]}${text[8]}${text[9]}${text[10]}`
      : text?.length === 12
      ? `(${text[0]}${text[1]}${text[2]}) ${text[3]}${text[4]}${text[5]}${text[6]}${text[7]}-${text[8]}${text[9]}${text[10]}${text[11]}`
      : text;

  // Editar empresa
  const [modalEditCompanyValues, setModalEditCompanyValues] =
    React.useState<any>();
  const [openModalEditCompany, setOpenModalEditCompany] = React.useState(false);

  // Editar nome
  const [modalEditNameValues, setModalEditNameValues] = React.useState<any>();
  const [openModalEditName, setOpenModalEditName] = React.useState(false);

  // Editar senha
  const [modalEditPasswordValues, setModalEditPasswordValues] =
    React.useState<any>();
  const [openModalEditPassword, setOpenModalEditPassword] =
    React.useState(false);

  // Editar sobrenome
  const [modalEditSurnameValues, setModalEditSurnameValues] =
    React.useState<any>();
  const [openModalEditSurname, setOpenModalEditSurname] = React.useState(false);

  // Editar telefone
  const [modalEditPhoneValues, setModalEditPhoneValues] = React.useState<any>();
  const [openModalEditPhone, setOpenModalEditPhone] = React.useState(false);

  // Editar local
  const [modalEditLocalValues, setModalEditLocalValues] = React.useState<any>();
  const [openModalEditLocal, setOpenModalEditLocal] = React.useState(false);

  // Editar informações extras
  const [modalEditExtraInfoValues, setModalEditExtraInfoValues] =
    React.useState<any>();
  const [openModalEditExtraInfo, setOpenModalEditExtraInfo] =
    React.useState(false);

  // Visualizar Token Telegram
  const [showTokenTelegram, setShowTokenTelegram] =
    React.useState<Boolean>(false);
  const [tokenTelegram, setTokenTelegram] = React.useState<string>("");

  // Abrir modal de dúvidas sobre o bot do Telegram
  const [openModalTelegramHelp, setOpenModalTelegramHelp] =
    React.useState(false);

  // Resgatar o Token Telegram
  React.useEffect(() => {
    getTokenTelegram().then((res) => {
      const { data } = res;
      setTokenTelegram(data.token);
    });
  }, []);

  // Ações para visualizar o Token Telegram
  const handleClickShowTokenTelegram = () =>
    setShowTokenTelegram((show) => !show);
  const handleMouseDownTokenTelegram = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // Ação de copiar o Token Telegram
  async function copyTokenTelegram() {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(tokenTelegram);
      toastSuccess(t("TOAST.SUCCESS.TOKEN_TELEGRAM_COPIED"));
    }
  }

  // Ações do modal de editar empresa
  const handleOpenModalEditCompany = () => {
    setModalEditCompanyValues({
      user,
    });
    setOpenModalEditCompany(true);
  };
  const handleCloseModalEditCompany = () => {
    setOpenModalEditCompany(false);
  };

  // Ações do modal de editar nome
  const handleOpenModalEditName = () => {
    setModalEditNameValues({
      user,
    });
    setOpenModalEditName(true);
  };
  const handleCloseModalEditName = () => {
    setOpenModalEditName(false);
  };

  // Ações do modal de editar senha
  const handleOpenModalEditPassword = () => {
    setModalEditPasswordValues({
      user,
    });
    setOpenModalEditPassword(true);
  };
  const handleCloseModalEditPassword = () => {
    setOpenModalEditPassword(false);
  };

  // Ações do modal de editar sobrenome
  const handleOpenModalEditSurname = () => {
    setModalEditSurnameValues({
      user,
    });
    setOpenModalEditSurname(true);
  };
  const handleCloseModalEditSurname = () => {
    setOpenModalEditSurname(false);
  };

  // Ações do modal de editar telefone
  const handleOpenModalEditPhone = () => {
    setModalEditPhoneValues({
      user,
    });
    setOpenModalEditPhone(true);
  };
  const handleCloseModalEditPhone = () => {
    setOpenModalEditPhone(false);
  };

  // Ações do modal de editar local
  const handleOpenModalEditLocal = () => {
    setModalEditLocalValues({
      user,
    });
    setOpenModalEditLocal(true);
  };
  const handleCloseModalEditLocal = () => {
    setOpenModalEditLocal(false);
  };

  // Ações do modal de editar informações extras
  const handleOpenModalEditExtraInfo = () => {
    setModalEditExtraInfoValues({
      user,
    });
    setOpenModalEditExtraInfo(true);
  };
  const handleCloseModalEditExtraInfo = () => {
    setOpenModalEditExtraInfo(false);
  };

  // Ações do modal de dúvidas sobre o bot do Telegram
  const handleOpenModalTelegramHelp = () => {
    setOpenModalTelegramHelp(true);
  };
  const handleCloseModalTelegramHelp = () => {
    setOpenModalTelegramHelp(false);
  };

  // Filtrar os Arcsys pendentes e os não Proprietários
  const filterPendingsArcsys = user?.arcsys.filter(
    (f: any) => f.pending === false && f.role !== "OWNER"
  );

  return (
    <>
      <Paper sx={{ p: mobile ? 0 : 3.5, borderRadius: 3 }} elevation={3}>
        <Box
          display="flex"
          alignItems="center"
          pt={mobile ? 2 : 0}
          pl={mobile ? 2 : 0}
          pb={1.5}
        >
          <Box mr={1} mb={0.6}>
            <PersonOutlineIcon fontSize="large" />
          </Box>
          <Typography fontSize={mobile ? 25 : 28} gutterBottom>
            {t("TEXT.BASIC_INFO")}
          </Typography>
        </Box>
        <Table size="small">
          <TableBody>
            {(filterPendingsArcsys?.length > 0 ||
              +userId === user._id ||
              userIsAdmin) && (
              <TableRow hover>
                <TableCell align="left">
                  <Box display="flex">
                    <PersonOutlineIcon />
                    <Typography sx={{ ml: 2 }}>{t("TEXT.NAME")}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Typography>{user.name}</Typography>
                </TableCell>
                <TableCell align="right">
                  {+userId === user._id || userIsAdmin ? (
                    mobile ? (
                      <IconButton
                        color="primary"
                        onClick={handleOpenModalEditName}
                      >
                        <EditIcon color="warning" />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        onClick={handleOpenModalEditName}
                        startIcon={<EditIcon />}
                        sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                      >
                        {t("BUTTON.EDIT")}
                      </Button>
                    )
                  ) : (
                    <Box sx={{ pt: 6 }}></Box>
                  )}
                </TableCell>
              </TableRow>
            )}
            {(filterPendingsArcsys?.length > 0 ||
              +userId === user._id ||
              userIsAdmin) && (
              <TableRow hover>
                <TableCell align="left">
                  <Box display="flex">
                    <PeopleAltOutlinedIcon />
                    <Typography sx={{ ml: 2 }}>{t("TEXT.SURNAME")}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Typography>{user.surname}</Typography>
                </TableCell>
                <TableCell align="right">
                  {+userId === user._id || userIsAdmin ? (
                    mobile ? (
                      <IconButton
                        color="primary"
                        onClick={handleOpenModalEditSurname}
                      >
                        <EditIcon color="warning" />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        onClick={handleOpenModalEditSurname}
                        startIcon={<EditIcon />}
                        sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                      >
                        {t("BUTTON.EDIT")}
                      </Button>
                    )
                  ) : (
                    <Box sx={{ pt: 6 }}></Box>
                  )}
                </TableCell>
              </TableRow>
            )}
            {(filterPendingsArcsys?.length > 0 ||
              +userId === user._id ||
              userIsAdmin) && (
              <TableRow hover>
                <TableCell align="left">
                  <Box display="flex">
                    <BusinessOutlinedIcon />
                    <Typography sx={{ ml: 2 }}>{t("TEXT.COMPANY")}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Typography>{user.company}</Typography>
                </TableCell>
                <TableCell align="right">
                  {+userId === user._id || userIsAdmin ? (
                    mobile ? (
                      <IconButton
                        color="primary"
                        onClick={handleOpenModalEditCompany}
                      >
                        <EditIcon color="warning" />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        onClick={handleOpenModalEditCompany}
                        startIcon={<EditIcon />}
                        sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                      >
                        {t("BUTTON.EDIT")}
                      </Button>
                    )
                  ) : (
                    <Box sx={{ pt: 6 }}></Box>
                  )}
                </TableCell>
              </TableRow>
            )}
            <TableRow hover>
              <TableCell align="left">
                <Box display="flex">
                  <EmailOutlinedIcon />
                  <Typography sx={{ ml: 2 }}>Email</Typography>
                </Box>
              </TableCell>
              <TableCell align="left" sx={{ pr: 0 }}>
                <Typography sx={{ wordBreak: "break-all" }}>
                  {user.email}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Box sx={{ pt: 6 }}></Box>
              </TableCell>
            </TableRow>
            {(filterPendingsArcsys?.length > 0 ||
              +userId === user._id ||
              userIsAdmin) && (
              <TableRow hover>
                <TableCell align="left">
                  <Box display="flex">
                    <LocalPhoneOutlinedIcon />
                    <Typography sx={{ ml: 2 }}>{t("TEXT.PHONE")}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left" sx={{ pr: 0 }}>
                  <Typography>{formatPhone(user.phone)}</Typography>
                </TableCell>
                <TableCell align="right">
                  {+userId === user._id || userIsAdmin ? (
                    mobile ? (
                      <IconButton
                        color="primary"
                        onClick={handleOpenModalEditPhone}
                      >
                        <EditIcon color="warning" />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        onClick={handleOpenModalEditPhone}
                        startIcon={<EditIcon />}
                        sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                      >
                        {t("BUTTON.EDIT")}
                      </Button>
                    )
                  ) : (
                    <Box sx={{ pt: 6 }}></Box>
                  )}
                </TableCell>
              </TableRow>
            )}
            {(filterPendingsArcsys?.length > 0 ||
              +userId === user._id ||
              userIsAdmin) && (
              <TableRow hover>
                <TableCell align="left">
                  <Box display="flex">
                    <AssignmentIndOutlinedIcon sx={{ alignSelf: "center" }} />
                    <Typography sx={{ ml: 2 }}>{t("TEXT.USERNAME")}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Typography>{user.username}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ pt: 6 }}></Box>
                </TableCell>
              </TableRow>
            )}
            {(+userId === user._id || userIsAdmin) &&
              (filterPendingsArcsys?.length > 0 ||
                +userId === user._id ||
                userIsAdmin) && (
                <TableRow hover>
                  <TableCell align="left">
                    <Box display="flex">
                      <VpnKeyOutlinedIcon sx={{ alignSelf: "center" }} />
                      <Typography sx={{ ml: 2 }}>
                        {t("TEXT.CHANGE_PASSWORD")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="right">
                    {mobile ? (
                      <IconButton
                        color="primary"
                        onClick={handleOpenModalEditPassword}
                      >
                        <EditIcon color="warning" />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        onClick={handleOpenModalEditPassword}
                        startIcon={<EditIcon />}
                        sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                      >
                        {t("BUTTON.EDIT")}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            {(filterPendingsArcsys?.length > 0 ||
              +userId === user._id ||
              userIsAdmin) && (
              <TableRow hover>
                <TableCell align="left">
                  <Box display="flex">
                    <LocationOnOutlinedIcon />
                    <Typography sx={{ ml: 2 }}>Local</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Typography>{user.location}</Typography>
                </TableCell>
                <TableCell align="right">
                  {+userId === user._id || userIsAdmin ? (
                    mobile ? (
                      <IconButton
                        color="primary"
                        onClick={handleOpenModalEditLocal}
                      >
                        <EditIcon color="warning" />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        onClick={handleOpenModalEditLocal}
                        startIcon={<EditIcon />}
                        sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                      >
                        {t("BUTTON.EDIT")}
                      </Button>
                    )
                  ) : (
                    <Box sx={{ pt: 6 }}></Box>
                  )}
                </TableCell>
              </TableRow>
            )}
            {(+userId === user._id || userIsAdmin) &&
              (filterPendingsArcsys?.length > 0 ||
                +userId === user._id ||
                userIsAdmin) && (
                <TableRow hover>
                  <TableCell align="left">
                    <Box display="flex">
                      <InfoOutlinedIcon sx={{ alignSelf: "center" }} />
                      <Typography sx={{ ml: 2 }}>
                        {t("TEXT.EXTRA_INFO")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="right">
                    {+userId === user._id || userIsAdmin ? (
                      mobile ? (
                        <IconButton
                          color="primary"
                          onClick={handleOpenModalEditExtraInfo}
                        >
                          <EditIcon color="warning" />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          color="warning"
                          onClick={handleOpenModalEditExtraInfo}
                          startIcon={<EditIcon />}
                          sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                        >
                          {t("BUTTON.EDIT")}
                        </Button>
                      )
                    ) : (
                      <Box sx={{ pt: 6 }}></Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            {(+userId === user._id || userIsAdmin) && tokenTelegram && (
              <TableRow hover>
                <TableCell align="left">
                  <Box display="flex">
                    <PasswordOutlinedIcon sx={{ alignSelf: "center" }} />
                    <Typography sx={{ ml: 2, alignSelf: "center" }}>
                      {t("TEXT.TOKEN_TELEGRAM")}
                    </Typography>
                    <IconButton
                      color="primary"
                      onClick={handleOpenModalTelegramHelp}
                      sx={{ pl: mobile ? 0 : 1 }}
                    >
                      <HelpOutlineIcon color="info" />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  {tokenTelegram?.length === 6 ? (
                    <Box display="flex">
                      <Typography pt={1} id="myInput" fontSize={15}>
                        {showTokenTelegram ? tokenTelegram : "••••••••••••"}
                      </Typography>
                      {showTokenTelegram && (
                        <Tooltip title={t("TEXT.COPY")}>
                          <IconButton onClick={copyTokenTelegram}>
                            <ContentCopyIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  ) : (
                    <Typography sx={{ pt: 1.5, pb: 1.5 }}>
                      {t("TEXT.REGISTERED")}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="right">
                  {tokenTelegram?.length === 6 && (
                    <Box>
                      {mobile || smallWindow ? (
                        <IconButton
                          color="primary"
                          onClick={handleClickShowTokenTelegram}
                          onMouseDown={handleMouseDownTokenTelegram}
                          sx={{ mt: 1, mb: 1 }}
                        >
                          {showTokenTelegram === true ? (
                            <VisibilityOffIcon color="warning" />
                          ) : (
                            <VisibilityIcon color="warning" />
                          )}
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          onClick={handleClickShowTokenTelegram}
                          onMouseDown={handleMouseDownTokenTelegram}
                          color="warning"
                          startIcon={
                            showTokenTelegram === true ? (
                              <VisibilityOffIcon color="warning" />
                            ) : (
                              <VisibilityIcon color="warning" />
                            )
                          }
                          sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                        >
                          {showTokenTelegram === true
                            ? t("TEXT.HIDE")
                            : t("TEXT.SHOW")}
                        </Button>
                      )}
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      {modalEditNameValues && (
        <ModalEditName
          setDeviceEdited={setDeviceEdited}
          user={modalEditNameValues}
          handleOpen={openModalEditName}
          handleClose={handleCloseModalEditName}
        />
      )}
      {modalEditCompanyValues && (
        <ModalEditCompany
          setDeviceEdited={setDeviceEdited}
          user={modalEditCompanyValues}
          handleOpen={openModalEditCompany}
          handleClose={handleCloseModalEditCompany}
        />
      )}
      {modalEditSurnameValues && (
        <ModalEditSurname
          setDeviceEdited={setDeviceEdited}
          user={modalEditSurnameValues}
          handleOpen={openModalEditSurname}
          handleClose={handleCloseModalEditSurname}
        />
      )}
      {modalEditPhoneValues && (
        <ModalEditPhone
          setDeviceEdited={setDeviceEdited}
          user={modalEditPhoneValues}
          handleOpen={openModalEditPhone}
          handleClose={handleCloseModalEditPhone}
        />
      )}
      {modalEditLocalValues && (
        <ModalEditLocal
          setDeviceEdited={setDeviceEdited}
          user={modalEditLocalValues}
          handleOpen={openModalEditLocal}
          handleClose={handleCloseModalEditLocal}
        />
      )}
      {modalEditPasswordValues && (
        <ModalEditPassword
          setDeviceEdited={setDeviceEdited}
          addr={modalEditPasswordValues.user._id}
          handleOpen={openModalEditPassword}
          handleClose={handleCloseModalEditPassword}
        />
      )}
      {modalEditExtraInfoValues && (
        <ModalEditExtraInfo
          setDeviceEdited={setDeviceEdited}
          user={modalEditExtraInfoValues}
          handleOpen={openModalEditExtraInfo}
          handleClose={handleCloseModalEditExtraInfo}
          city={city}
          street={street}
          neighborhood={neighborhood}
          state={state}
          setCity={setCity}
          setStreet={setStreet}
          setNeighborhood={setNeighborhood}
          setState={setState}
        />
      )}
      {openModalTelegramHelp && (
        <ModalTelegramHelp
          handleOpen={openModalTelegramHelp}
          handleClose={handleCloseModalTelegramHelp}
        />
      )}
    </>
  );
};

export default BasicInfo;
