import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Paper, Skeleton, styled } from "@mui/material";
import { grey, lightBlue } from "@mui/material/colors";

import { modelExists } from "../../config";
import { Color } from "../Controller/parser/types";
import Alarm from "./Alarm";
import Body from "./Body";
import Header from "./Header";
import Placeholder from "./Placeholder";
import { CardData } from "../../contexts/ControllerContext";
import ModalRemoveCardSector from "../../pages/Dashboard/Modal/RemoveCardSector";

interface CardProps {
  id: string | undefined;
  ctrl: string;
  data: CardData | undefined;
  comError: boolean;
  theme?: string | undefined;
  errorSector?: boolean;
  setpointsector?: string;
  sectorId?: string;
  setpointindex?: number;
  setSectorEdited?: (e: boolean) => void;
  checked?: boolean;
}

interface StyledCardProps {
  background: Color;
  setpointsector: string | undefined;
}

const StyledCard = styled(Paper)<StyledCardProps>(
  ({ background, setpointsector }) => ({
    background: background.main,
    height: setpointsector !== undefined ? 290 : 250,
    width: 270,
    color: grey[50],

    "& .header": {
      padding: 8,
      color: lightBlue[50],
      background: background.header,
      textAlign: "center",
      position: "relative",
      "&>p": {
        fontWeight: 800,
      },
    },
  })
);

const noColor = { header: grey[500], main: grey[400] };

const Card = ({
  ctrl,
  id,
  data,
  comError,
  theme,
  errorSector,
  setpointsector,
  sectorId,
  setpointindex,
  setSectorEdited,
  checked,
}: CardProps) => {
  const { t } = useTranslation();
  const cardData = data;

  const alarm = cardData ? cardData.alarm : undefined;
  const color = cardData ? cardData.color : undefined;
  const measures = cardData ? cardData.measures : undefined;

  const modelFound = measures && modelExists(measures?.modelo);

  const comErrorValue = comError;

  const bgColor = modelFound ? color : noColor;
  const showAlarm = modelFound && !!alarm;
  const title = measures
    ? modelFound
      ? measures?.rotulo
      : t("TEXT.MODEL_NOT_FOUND")
    : "";

  // Ações de abrir o modal de deletar card do setor
  const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);
  const handleOpenModalDelete = () => setOpenModalDelete(true);
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const headerComponent = (
    <Header
      id={id}
      ctrl={ctrl}
      title={title}
      addr={measures?.end}
      modelFound={modelFound as boolean}
      modelo={measures?.modelo}
      comError={comErrorValue}
      errorSector={errorSector}
      setpointsector={setpointsector}
      handleOpenModalDelete={handleOpenModalDelete}
      checked={checked}
      alarm={data?.alarm?.id ?? 0}
    />
  );

  return (
    <StyledCard
      background={bgColor ?? noColor}
      setpointsector={setpointsector}
      sx={{
        borderRadius: 3,
        "&:hover":
          theme && theme === "light"
            ? {
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
              }
            : theme && theme === "dark"
            ? {
                boxShadow:
                  "0px 1px 2px 0px rgba(175, 175, 175, 0.8), 1px 2px 4px 0px rgba(175, 175, 175, 0.8), 2px 4px 8px 0px rgba(175, 175, 175, 0.8), 2px 4px 16px 0px rgba(175, 175, 175, 0.8)",
              }
            : null,
      }}
      elevation={3}
    >
      <Grid container direction="row" alignItems="stretch" height="100%">
        <Grid item xs={12} height={42}>
          {headerComponent}
        </Grid>
        {cardData === undefined || measures === undefined ? (
          <Skeleton variant="rounded" width={220} height={165} sx={{ ml: 3 }} />
        ) : (
          <Grid item xs={12} display="inline-grid">
            {showAlarm ? (
              <Alarm alarm={alarm} data={cardData} />
            ) : modelFound ? (
              <Body
                data={cardData}
                setpointsector={setpointsector}
                setpointindex={setpointindex}
              />
            ) : (
              <Placeholder modelo={measures?.modelo} />
            )}
          </Grid>
        )}
      </Grid>
      {sectorId && setSectorEdited && (
        <ModalRemoveCardSector
          handleClose={handleCloseModalDelete}
          handleOpen={openModalDelete}
          rotulo={title}
          ctrlId={ctrl}
          idSector={sectorId}
          setSectorEdited={setSectorEdited}
        />
      )}
    </StyledCard>
  );
};

export default Card;
