import React from "react";
import { Link } from "react-router-dom";

import { Box, Link as MuiLink } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import { parseCardData } from "../../components/Card/parser";
import { COM_ERROR } from "../../components/Controller/constants";
import Card from "../../components/Card";
import { modelExists } from "../../config";
import { cardNull, errorSector, setpointValue } from "../Sectors/parse";

interface ControllerProps {
  value: any;
  setSectorEdited?: (e: boolean) => void;
}

const Controller = ({ value, setSectorEdited }: ControllerProps) => {
  const { theme } = React.useContext(ThemeModeContext);

  // Lista de Controladores
  const card =
    value.ctrls &&
    value.ctrls
      // Ordenar pelos menores endereços no inicio da lista
      ?.sort((a: any, b: any) => a.payload?.end - b.payload?.end)
      // Ordenar pelos controladores online no inicio da lista
      ?.sort((a: any, b: any) => {
        if (a.payload?.alarme === 65536) return 1;
        if (b.payload?.alarme === 65536) return -1;
        return 0;
      })
      .map((device: any, i: number) => {
        const card = parseCardData(theme, device?.payload);
        const modelFound = modelExists(device?.payload?.modelo);
        const comError = card?.alarm?.id === COM_ERROR;
        const url = modelFound ? `/controller/${value.mac}/${device.ctrl}` : "";

        return device.payload === null ? (
          <Grid key={i} pl={0} pr={0}>
            <Box m={1}>
              <Card
                id={value.id}
                sectorId={value.sectorId}
                ctrl={`${value.mac}/${device.ctrl}`}
                data={cardNull(value, device)}
                comError={comError}
                theme={theme}
                errorSector={errorSector(value, device)}
                setpointsector={value.default_resource ? "" : undefined}
                setpointindex={
                  value.default_resource?.split("/")[1].split("s")[1]
                }
                setSectorEdited={setSectorEdited}
                checked={value.checked}
              />
            </Box>
          </Grid>
        ) : (
          device?.payload?.end && device?.payload?.end < 36 && (
            <Grid key={i} pl={0} pr={0}>
              <MuiLink
                component={Link}
                to={url}
                sx={{
                  textDecoration: "inherit",
                  pointerEvents: "auto",
                }}
              >
                <Box m={1}>
                  <Card
                    id={value.id}
                    sectorId={value.sectorId}
                    ctrl={`${value.mac}/${device.ctrl}`}
                    data={card}
                    comError={comError}
                    theme={theme}
                    errorSector={errorSector(value, device)}
                    setpointsector={
                      value.default_resource
                        ? setpointValue(value, device)
                        : undefined
                    }
                    setpointindex={
                      value.default_resource?.split("/")[1].split("s")[1]
                    }
                    setSectorEdited={setSectorEdited}
                    checked={value.checked}
                  />
                </Box>
              </MuiLink>
            </Grid>
          )
        );
      });

  return card;
};

export default Controller;
