import { Box, Grid, Typography } from "@mui/material";

import LanguageSelector from "../../LanguageSelector";
import FormComponent from "./Form";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { RECOVER_ACCESS, REGISTER } from "../../../Routes/constants";
import { useTranslation } from "react-i18next";

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container direction="column" spacing={2} sx={{ mb: 1 }}>
      <Grid item>
        <Header />
      </Grid>
      <Grid item>
        <FormComponent />
      </Grid>
      <Grid item>
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="subtitle2"
            color="primary"
            onClick={() => navigate(REGISTER)}
            sx={{
              cursor: "pointer",
              fontSize: 16,
            }}
          >
            {t("TEXT.CREATE_ACCOUNT")}
          </Typography>
          <Typography
            variant="subtitle2"
            onClick={() => navigate(RECOVER_ACCESS)}
            sx={{
              cursor: "pointer",
              fontSize: 14.5,
            }}
          >
            {t("TEXT.FORGOT_PASSWORD")}?
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex" justifyContent="center">
          <LanguageSelector />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Form;
