import React from "react";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GTranslate as GTranslateIco,
  Check as CheckIcon,
  Replay as ReplayIcon,
  DeleteOutlined as DeleteOutlineIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";
import AvatarIcon from "../../components/AvatarIcon";
import { green, red } from "@mui/material/colors";
import { getLanguage } from "../../translations/languages";
import { AuthContext } from "../../contexts/AuthContext";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import {
  getActualTranalation,
  getChangeHistory,
  getTranslationsById,
} from "../../services/data/translations";
import { convertDateTime } from "../Models/parse";
import Spinner from "../../components/Spinner";
import { EditorNotEdited } from "./Editor";
import ModalEdit from "./Modal/ModalEdit";
import ModalRestore from "./Modal/ModalRestore";
import ModalRemove from "./Modal/ModalRemove";
import JSONEditor from "jsoneditor";

let newActualTranslation: any = {};

interface EditorProps {
  actualTranslation: any;
  setSaveButton: (e: boolean) => void;
  lang: string;
}

// Estrutura de edição do modelo JSON
const Editor = ({ setSaveButton, lang, actualTranslation }: EditorProps) => {
  const ref: any = React.useRef();
  const mobile = useIsMobile();
  const [editor, setEditor] = React.useState<JSONEditor>();

  // Armazenar os dados iniciais
  React.useEffect(() => {
    newActualTranslation = actualTranslation;
  }, [actualTranslation]);

  // Alterar os dados com "onChange"
  React.useEffect(() => {
    const newEditor: any = new JSONEditor(ref.current, {
      mode: "code",
      onChangeText: (e) => {
        newActualTranslation = { ...newActualTranslation, ...JSON.parse(e) };
        setSaveButton(true);
      },
      mainMenuBar: false,
    });
    newEditor.set({
      [lang]: newActualTranslation[lang],
    });
    setEditor(newEditor);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Alterar o idioma
  React.useEffect(() => {
    editor?.set({
      [lang]: newActualTranslation[lang],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div
      id="jsoneditor"
      ref={ref}
      style={{
        height: mobile ? "500px" : "950px",
      }}
    />
  );
};

const Translations = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const { authenticated, token } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [actualTranslation, setActualTranslation] = React.useState<any>();
  const [idSelectedTranslation, setIdSelectedTranslation] =
    React.useState<string>("");
  const [historyTranslations, setHistoryTranslations] = React.useState<any>();
  const [saveButton, setSaveButton] = React.useState<boolean>(false);
  const [translationsEdited, setTranslationsEdited] =
    React.useState<boolean>(false);
  const [importedTranslation, setImportedTranslation] =
    React.useState<boolean>(false);
  const [changeColor, setChangeColor] = React.useState("");

  // Ação de alterar a linguagem dos dados
  const [lang, setLang] = React.useState<string>("pt-br");
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLang(event.target.value as string);
  };

  // Ação de alterar a cor dos itens do 'Histórico de alterações'
  const handleChangeColor = (event: string) => {
    setChangeColor(event);
  };

  React.useEffect(() => {
    const fetchStatus = () => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Resgatar as traduções atuais
        getActualTranalation().then((res) => {
          setActualTranslation(
            JSON.parse(JSON.stringify(res.data.translations, null, 2))
          );
        });

        // Resgatar a lista do histórico de alterações das traduções no modo admin
        getChangeHistory().then((res) => {
          const { data } = res;
          setHistoryTranslations(data);
        });
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
  }, [authenticated, token]);

  // Atualizar os dados das traduções após a edição de qualquer dado
  React.useEffect(() => {
    if (translationsEdited) {
      // Resgatar as traduções atuais
      getActualTranalation().then((res) => {
        setActualTranslation(
          JSON.parse(JSON.stringify(res.data.translations, null, 2))
        );
      });

      // Resgatar a lista do histórico de alterações das traduções no modo admin
      getChangeHistory().then((res) => {
        const { data } = res;
        setHistoryTranslations(data);
      });
      setTranslationsEdited(false);
    }
  }, [language, translationsEdited]);

  // Ações do modal de editar
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const handleOpenModalEdit = () => {
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Ações do modal de deletar
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenModalDelete = (e: any, id: string, day: string) => {
    e.stopPropagation();
    setModalDeleteValues({ id, day });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ação de resgatar as traduções por 'id'
  const handleClickTranslationHistoric = (id: string) => {
    setActualTranslation(undefined);
    handleChangeColor(id);
    getTranslationsById(id).then((res) => {
      setActualTranslation(
        JSON.parse(JSON.stringify(res.data.translations, null, 2))
      );
      setIdSelectedTranslation(id);
    });
    setImportedTranslation(false);
    setSaveButton(false);
  };

  // Ações do modal de restaurar versão
  const [openModalRestore, setOpenModalRestore] = React.useState(false);
  const handleOpenModalRestore = () => {
    setOpenModalRestore(true);
  };
  const handleCloseModalRestore = () => {
    setOpenModalRestore(false);
  };

  // Checar se foi selecionado algum payload do histórico
  const checkHandleClickedTranslations =
    historyTranslations &&
    idSelectedTranslation !== "" &&
    historyTranslations[0]?._id !== idSelectedTranslation;

  return (
    <Grid container sx={{ placeContent: "center" }}>
      <Box m={3.5} mt={0}>
        <Box position="sticky" top="90px">
          <Card sx={{ mt: 0 }}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 3.5,
                  pb: 3.5,
                  pl: 5,
                  pr: 5,
                  borderRadius: 4,
                  mb: 2.4,
                  backgroundColor: red[100],
                }}
              >
                <Avatar
                  sx={{ width: 130, height: 130, boxShadow: 3, ml: 4, mr: 4 }}
                >
                  <AvatarIcon
                    name={t("TEXT.CHANGE_HISTORY")}
                    width={130}
                    height={130}
                    fontSize={70}
                    bgcolor={red[400]}
                  />
                </Avatar>
              </Box>
              <Typography
                fontSize={22}
                fontWeight={500}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                textAlign="center"
                width={260}
              >
                {t("TEXT.CHANGE_HISTORY")}
              </Typography>
              <Typography width={260} mt={1} pl={1.5} fontSize={15}>
                {t("TEXT.CHANGE_HISTORY_MESSAGE")}
              </Typography>
              <Box>
                <Box display="grid" mt={1}>
                  <List>
                    {historyTranslations &&
                      historyTranslations.map((m: any, i: number) => (
                        <ListItem
                          disablePadding
                          key={i}
                          onClick={() => handleClickTranslationHistoric(m._id)}
                          sx={{
                            "&:hover": {
                              backgroundColor:
                                theme === "light"
                                  ? "#FFF2F2"
                                  : "rgb(50, 53, 70)",
                            },
                          }}
                        >
                          <ListItemButton>
                            <ListItemIcon
                              sx={{
                                mr: -2,
                              }}
                            >
                              <CheckIcon color="success" />
                            </ListItemIcon>
                            <Typography
                              fontWeight={500}
                              fontSize={15}
                              sx={{
                                color:
                                  changeColor === m._id
                                    ? red[400]
                                    : theme === "dark"
                                    ? "#FEFEFE"
                                    : "black",
                              }}
                            >
                              {convertDateTime(m.createdAt, language)}
                            </Typography>
                            {i === 0 ? (
                              <Chip
                                label={t("TEXT.CURRENT")}
                                color="success"
                                size="small"
                                sx={{
                                  ml: 2,
                                  fontWeight: 700,
                                  backgroundColor: green[100],
                                  color: green[800],
                                  textTransform: "uppercase",
                                  fontSize: 11,
                                }}
                              />
                            ) : (
                              <ListItemIcon>
                                <Tooltip title={t("TEXT.REMOVE")}>
                                  <IconButton
                                    color="error"
                                    onClick={(e: any) =>
                                      handleOpenModalDelete(
                                        e,
                                        m._id,
                                        convertDateTime(m.createdAt, language)
                                      )
                                    }
                                    sx={{ ml: 2.5, mt: -0.4 }}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Paper sx={{ p: 0, mb: 2, borderRadius: 3 }} elevation={3}>
          {actualTranslation === undefined ? (
            <Box
              sx={{
                alignContent: "center",
                minHeight: mobile ? 500 : 1094.5,
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Spinner />
            </Box>
          ) : (
            <Box
              sx={{
                minHeight: 500,
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                ml={mobile ? 0 : 2}
              >
                <Box display="flex" alignItems="center" p={3.5} pb={1}>
                  <Box sx={{ mr: 1 }}>
                    <GTranslateIco fontSize="large" />
                  </Box>
                  <Typography fontSize={mobile ? 25 : 28} gutterBottom>
                    {t("TEXT.TRANSLATIONS")}
                  </Typography>
                </Box>
                <Box display={mobile ? "grid" : "flex"}>
                  <FormControl
                    variant="standard"
                    sx={{ mt: 1.5, mr: mobile ? 0 : 2, width: 80 }}
                  >
                    <InputLabel>{t("TEXT.TRANSLATIONS")}</InputLabel>
                    <Select
                      value={lang}
                      label={t("TEXT.TRANSLATIONS")}
                      onChange={handleChangeLanguage}
                    >
                      <MenuItem value="pt-br">pt-br</MenuItem>
                      <MenuItem value="en">en</MenuItem>
                      <MenuItem value="es">es</MenuItem>
                    </Select>
                  </FormControl>
                  <Box alignSelf="center">
                    {checkHandleClickedTranslations === true ? (
                      <Button
                        size="small"
                        color="warning"
                        startIcon={<ReplayIcon />}
                        onClick={handleOpenModalRestore}
                        sx={{
                          fontSize: 13,
                          fontWeight: 600,
                          mt: mobile ? 1 : 0,
                          mr: mobile ? 0 : 2,
                        }}
                      >
                        {t("TEXT.RESTORE_VERSION")}
                      </Button>
                    ) : (
                      <Box alignSelf="center">
                        <Button
                          size="small"
                          color="warning"
                          disabled={!saveButton}
                          startIcon={<CheckIcon />}
                          onClick={handleOpenModalEdit}
                          sx={{
                            fontSize: 13,
                            fontWeight: 600,
                            mt: mobile ? 1 : 0,
                            mr: mobile ? 0 : 2,
                          }}
                        >
                          {t("BUTTON.SAVE")}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider />
              {checkHandleClickedTranslations !== undefined && (
                <Box p={3.5}>
                  {importedTranslation === true ||
                  checkHandleClickedTranslations === true ? (
                    <EditorNotEdited
                      actualTranslation={actualTranslation}
                      setActualTranslation={setActualTranslation}
                      setSaveButton={setSaveButton}
                      lang={lang}
                    />
                  ) : (
                    <Editor
                      actualTranslation={actualTranslation}
                      setSaveButton={setSaveButton}
                      lang={lang}
                    />
                  )}
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Grid>
      <ModalEdit
        handleOpen={openModalEdit}
        handleClose={handleCloseModalEdit}
        setTranslationsEdited={setTranslationsEdited}
        setSaveButton={setSaveButton}
        data={newActualTranslation}
      />
      <ModalRestore
        handleOpen={openModalRestore}
        handleClose={handleCloseModalRestore}
        setTranslationsEdited={setTranslationsEdited}
        setSaveButton={setSaveButton}
        data={actualTranslation}
      />
      <ModalRemove
        handleOpen={openModalDelete}
        handleClose={handleCloseModalDelete}
        setTranslationsEdited={setTranslationsEdited}
        setSaveButton={setSaveButton}
        id={modalDeleteValues?.id}
        day={modalDeleteValues?.day}
      />
    </Grid>
  );
};

export default Translations;
