import { Param } from "../../../services/types/ctrls";

// Subtítulos dos parâmetros da página do Controlador
export const convertParamToTranslateKey = (param: Param) => {
  switch (param) {
    case "rotulo":
      return "TEXT.NAME";
    case "setpoints":
      return "Setpoints";
    case "alarme":
      return "TEXT.ALARM";
    case "parametros":
      return "TEXT.PARAMS";
    default:
      throw new Error(`Subparam not found: "${param}"`);
  }
};
