import React from "react";

import { Box, Typography } from "@mui/material";

interface TopicTitleProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  placeContent?: string;
}

const TopicTitle = ({ children, icon, placeContent }: TopicTitleProps) => {
  return (
    <Box display="flex" alignItems="center" sx={{ placeContent }}>
      {!!icon && <Box sx={{ mr: 1 }}>{icon}</Box>}
      <Typography variant="h5" fontWeight={600} sx={{ mb: 0.6 }}>
        {children}
      </Typography>
    </Box>
  );
};

export default TopicTitle;
