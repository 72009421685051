import React from "react";

import { Box } from "@mui/material";
import { AuthContext } from "../../../contexts/AuthContext";
import { TOKEN_KEY } from "../../../auth/constants";
import { api } from "../../../services/api";
import { getSelectPlan } from "../../../services/data/subscriptions";
import { useParams } from "react-router-dom";

import { getDevice } from "../../../services/data/arcsys";
import Subscription from "./HireSubscription";

const HireSubscription = () => {
  const { token, authenticated } = React.useContext(AuthContext);
  const [plans, setPlans] = React.useState<any>();
  const params = useParams();
  const mac = params.mac ?? "";
  const id = params.id ?? "";

  const [device, setDevice] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const [city, setCity] = React.useState<string>("");
  const [street, setStreet] = React.useState<string>("");
  const [neighborhood, setNeighborhood] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");
  const [userInfo, setUserInfo] = React.useState<any>();

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

      // Lista de planos disponíveis
      getSelectPlan().then((res) => {
        const { data } = res;
        const filterPlans = data.filter((f: any) => f._id === id)[0];
        setPlans(filterPlans);
      });

      // Resgatar dados gerais do Dispositivo
      getDevice(mac).then((res) => {
        setDevice(res.data);
        // Resgatar os dados do usuário
        setUserInfo(res.data?.owner);
        setCity(res.data?.owner?.info?.address?.city);
        setStreet(res.data?.owner?.info?.address?.street);
        setNeighborhood(res.data?.owner?.info?.address?.neighborhood);
        setState(res.data?.owner?.info?.address?.state);
      });
    }
  }, [authenticated, device?.name, id, mac, token]);

  // Armazenar a cidade digitada no formulário
  const handleChangeCity = (e: string) => {
    setCity(e);
  };

  // Armazenar a rua digitada no formulário
  const handleChangeStreet = (e: string) => {
    setStreet(e);
  };

  // Armazenar o bairro digitada no formulário
  const handleChangeNeighborhood = (e: string) => {
    setNeighborhood(e);
  };

  // Armazenar o estado digitada no formulário
  const handleChangeState = (e: string) => {
    setState(e);
  };

  // Loading do Spinner inical da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (plans && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [plans, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Box>
      {device && plans && userInfo && (
        <Subscription
          device={device}
          plans={plans}
          userInfo={userInfo}
          city={city}
          neighborhood={neighborhood}
          state={state}
          street={street}
          handleChangeCity={(e: string) => handleChangeCity(e)}
          handleChangeNeighborhood={(e: string) => handleChangeNeighborhood(e)}
          handleChangeState={(e: string) => handleChangeState(e)}
          handleChangeStreet={(e: string) => handleChangeStreet(e)}
          setCity={(e: any) => setCity(e)}
          setStreet={(e: any) => setStreet(e)}
          setNeighborhood={(e: any) => setNeighborhood(e)}
          setState={(e: any) => setState(e)}
        />
      )}
    </Box>
  );
};

export default HireSubscription;
