import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddToPhotos as AddToPhotosIcon } from "@mui/icons-material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enLocale from "date-fns/locale/en-GB";
import deLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt-BR";
import { ptBR, esES, enUS } from "@mui/x-date-pickers/locales";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../../../contexts/ToastContext";
import { api } from "../../../../../services/api";
import { TOKEN_KEY } from "../../../../../auth/constants";
import { TextField, yupString } from "../../../../../components/Form";
import { AuthContext } from "../../../../../contexts/AuthContext";
import Modal from "../../../../Devices/Modal";
import ErrorMessage from "../../../../../components/ErrorMessage";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { getLanguage } from "../../../../../translations/languages";
import Switch from "../../../../../components/Switch";
import { red } from "@mui/material/colors";
import { convertToDate } from "../../../../Report/Pages/GeneralInfo/parse";
import { addCoupom } from "../../../../../services/data/coupon";
import {
  yupNumberMin,
  yupRequiredStringMin,
} from "../../../../../components/Form/yup";

interface ModalAddProps {
  setCouponsEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalAdd = ({
  handleOpen,
  handleClose,
  setCouponsEdited,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);
  const [startValue, setStartValue] = React.useState<Date | null>(new Date());
  const [endValue, setEndValue] = React.useState<Date | null>(new Date());

  // Ação de alterar o status
  const [status, setStatus] = React.useState(true);
  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
  };

  // Ação de alterar a renderização da data de expiração
  const [expDate, setExpDate] = React.useState(true);
  const handleChangeExpDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExpDate(event.target.checked);
  };

  // Ação de alterar a renderização da quantidade ilimitada
  const [ilimitDate, setIlimitDate] = React.useState(false);
  const handleChangeIlimitDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIlimitDate(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      value: "",
      quantity: "",
      starting_quantity: null,
    },
    validationSchema: Yup.object({
      code: yupRequiredStringMin(5),
      value: yupNumberMin(1),
      quantity: ilimitDate === true ? yupString : yupNumberMin(1),
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const startDate =
          startValue &&
          convertToDate(startValue?.getTime(), language).replaceAll("/", "-");
        const expiresDate =
          endValue &&
          convertToDate(endValue?.getTime(), language).replaceAll("/", "-");

        const data = {
          status: status === true ? "ACTIVE" : "INACTIVE",
          starts_at: startDate,
          value: +values.value * 100,
          expires_at: expDate === true ? expiresDate : "31-12-2100",
          quantity: ilimitDate === true ? -1 : values.quantity,
          code: values.code.toUpperCase(),
          starting_quantity: ilimitDate === true ? -1 : null,
        };

        // Rota para criar cupom no modo admin
        addCoupom(data)
          .then(() => {
            toastSuccess(t("TOAST.SUCCESS.ADD_COUPON"));
            setCouponsEdited(true);
            formik.resetForm();
            handleClose();
          })
          .catch(() => {
            toastError(t("TOAST.ERROR.ADD_COUPON"));
          });
      }
    },
  });

  // Linguagem do componente Input das datas
  const checkLanguage =
    language === "pt" ? ptLocale : language === "en" ? enLocale : deLocale;

  // Linguagem do componente Input das datas
  const checkLanguageText =
    language === "pt"
      ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      : language === "en"
      ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
      : esES.components.MuiLocalizationProvider.defaultProps.localeText;

  // Alterar a data inicial no Input
  const handleStartDateChange = (start: Date | null) => {
    setStartValue(start);
    if (start !== null) {
      const endValueMax = new Date(start);
      endValueMax.setMonth(start.getMonth() + 1);
      const currentDate = new Date();
      if (endValueMax > currentDate) {
        endValueMax.setTime(currentDate.getTime()); // Set endValue to the current date
      }
      if (endValue !== null && endValue > endValueMax) {
        setEndValue(endValueMax);
      }
      if (endValue !== null && endValue < start) {
        setEndValue(start);
      }
    }
  };

  // Alterar a data final no Input
  const handleEndDateChange = (end: Date | null) => {
    setEndValue(end);
  };

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  // Validação do formulário
  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Modal
      open={handleOpen}
      handleClose={closeModal}
      width={mobile ? 340 : 600}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <AddToPhotosIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("BUTTON.ADD")} {t("TEXT.COUPON")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            onClick={(e: any) => e.stopPropagation()}
            label={t("TEXT.COUPON_CODE")}
            name="code"
            maxLength={100}
            fullWidth
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
          {formik.errors.code && formik.touched.code ? (
            <ErrorMessage>{formik.errors.code}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="flex" mt={2} mb={2}>
          <Box display="grid" width="100%">
            <FormControl fullWidth>
              <InputLabel>{t("TEXT.DISCOUNT_AMOUNT")}</InputLabel>
              <OutlinedInput
                name="value"
                type="number"
                value={formik.values.value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
                label={t("TEXT.DISCOUNT_AMOUNT")}
              />
            </FormControl>
            {formik.errors.value && formik.touched.value ? (
              <ErrorMessage>{formik.errors.value}</ErrorMessage>
            ) : null}
          </Box>
          {!ilimitDate && (
            <Box display="grid" ml={2} width="100%">
              <TextField
                type="number"
                formik={formik}
                disabled={false}
                onClick={(e: any) => e.stopPropagation()}
                label={t("TEXT.AMOUNT_COUPON")}
                name="quantity"
                maxLength={100}
                fullWidth
              />
            </Box>
          )}
        </Box>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={checkLanguage}
          localeText={checkLanguageText}
        >
          <Box display="flex">
            <MobileDatePicker
              label={t("TEXT.START_DATE")}
              onChange={handleStartDateChange}
              value={startValue}
              maxDate={new Date()}
              disabled={false}
              format={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              sx={{ width: "100%" }}
            />
            {expDate === true && (
              <Box ml={2} width="100%">
                <MobileDatePicker
                  label={t("TEXT.EXPIRATION_DATE")}
                  onChange={handleEndDateChange}
                  value={endValue}
                  minDate={startValue as Date}
                  disabled={false}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  format={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
            )}
          </Box>
        </LocalizationProvider>
        <Box display="flex" justifyContent="space-between" ml={2} mr={2} mt={4}>
          <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mr: 1.5 }}
                  checked={status}
                  onChange={handleChangeStatus}
                />
              }
              labelPlacement="end"
              label="Status"
            />
          </Tooltip>
          <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mr: 1.5 }}
                  checked={expDate}
                  onChange={handleChangeExpDate}
                />
              }
              labelPlacement="end"
              label={t("TEXT.EXPIRATION_DATE")}
            />
          </Tooltip>
          <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mr: 1.5 }}
                  checked={ilimitDate}
                  onChange={handleChangeIlimitDate}
                />
              }
              labelPlacement="end"
              label={t("TEXT.LIMITED_QUANTITY")}
            />
          </Tooltip>
        </Box>
        <Box mt={4} display="flex" justifyContent="space-between" pl={3} pr={3}>
          <Button
            onClick={closeModal}
            variant="outlined"
            size={mobile ? "small" : "medium"}
          >
            {t("BUTTON.CANCEL")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid}
            size={mobile ? "small" : "medium"}
          >
            {t("BUTTON.CONFIRM")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalAdd;
