const ACTIVATE_ACCOUNT = "/confirmar-conta";

const DASHBOARD = "/dashboard";

const CONTROLLER = "/controller/:mac/:ctrl";

const LOGIN = "/login";

const DEVICES = "/devices";

const DEVICE_PAGE = "/devices/:mac/:addr";

const RECOVER_ACCESS = "/recuperar-acesso-conta";

const RECOVER_ACCESS_EMAIL = "/recuperar-acesso";

const REGISTER = "/registrar";

const REPORTS = "/reports";

const PROFILE = "/profile";

const USERS = "/users";

const CONFIRM_ASSOCIATION = "/confirmar-associacao?";

const COMPLETE_REGISTER = "/completar-cadastro";

const PLANS_AND_SUBSCRIPTIONS = "/plans-and-subscriptions";

const PLANS_AVAILABLE = "/plans/plans-available";

const HIRE_SUBSCRIPTION = "/plans/checkout";

const SCENARIOS = "/cenarios";

const SECTORS = "/sectors";

const MACS = "/macs";

const SETTINGS = "/settings";

const MODELS = "/models";

const TRANSLATIONS = "/translations";

export {
  ACTIVATE_ACCOUNT,
  CONTROLLER,
  DASHBOARD,
  DEVICES,
  DEVICE_PAGE,
  LOGIN,
  RECOVER_ACCESS,
  RECOVER_ACCESS_EMAIL,
  REGISTER,
  REPORTS,
  PROFILE,
  USERS,
  CONFIRM_ASSOCIATION,
  COMPLETE_REGISTER,
  PLANS_AND_SUBSCRIPTIONS,
  PLANS_AVAILABLE,
  HIRE_SUBSCRIPTION,
  SCENARIOS,
  SECTORS,
  MACS,
  SETTINGS,
  MODELS,
  TRANSLATIONS,
};
