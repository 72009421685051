import { Box, Button, IconButton, Tooltip } from "@mui/material";
import {
  AddToPhotos as AddToPhotosIcon,
  PendingActions as PendingActionsIcon,
  Replay as ReplayIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";
import TopicTitle from "../TopicTitle";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";
import React from "react";
import ModalAdd from "./Modal/ModalAdd";
import ScenariosTable from "./ScenariosTable";
import { ctrlData, getReferenceOrNumber, parseData } from "./parse";
import { useParams } from "react-router-dom";
import { getScenarios } from "../../services/data/scenarios";
import { getCtrlConfig } from "../Controller/parser";
import { Ctrl } from "../../services/types/ctrls";
import { ToastContext } from "../../contexts/ToastContext";
import Spinner from "../Spinner";
import { fileURL, printUserManualAsPDF } from "../../pages/Scenarios/parse";

interface ScenariosProps {
  model: string;
  ctrls?: Ctrl | undefined;
  planPermissions?: any;
  devicePermission: string | undefined;
}

const Scenarios = ({
  model,
  ctrls,
  planPermissions,
  devicePermission,
}: ScenariosProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const params = useParams();
  const { toastError } = React.useContext(ToastContext);
  const ctrlId = `${params?.mac}/${params?.ctrl}`;
  const [scenarios, setScenarios] = React.useState<any[]>([]);
  const [scenarioEdited, setScenarioEdited] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [activeStepEdit, setActiveStepEdit] = React.useState<number>(0);
  const [conditionWork, setConditionWork] = React.useState<string>("AND");
  const [loading, setLoading] = React.useState(true);

  // Ações de abrir o modal de Adicionar Cenário
  const [openModalAdd, setOpenModalAdd] = React.useState<boolean>(false);
  const handleOpenModalAdd = () => setOpenModalAdd(true);
  const handleCloseModalAdd = () => {
    setActiveStep(0);
    setOpenModalAdd(false);
  };

  // Mensagem de erro informando o usuário que ele não tem acesso a funcionalidade dos Cenários
  const handleOpenModalPermission = () =>
    toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION"));

  const ctrlConfig = getCtrlConfig(model);

  const { parametros: parametrosConfig, estado } = ctrlConfig;

  const sensores = ctrlData(model)?.sensores;
  const { acoes } = ctrlConfig;

  ctrlConfig.parametros.forEach(({ min, max }, i) => {
    if (typeof min !== "number") {
      ctrlConfig.parametros[i].min = getReferenceOrNumber(
        min,
        parametrosConfig,
        ctrls?.parametros,
        ctrls?.setpoints
      );
    }
    if (typeof max !== "number") {
      ctrlConfig.parametros[i].max = getReferenceOrNumber(
        max,
        parametrosConfig,
        ctrls?.parametros,
        ctrls?.setpoints
      );
    }
  });

  ctrlConfig.setpoints.forEach(({ min, max }, i) => {
    if (typeof min !== "number") {
      ctrlConfig.setpoints[i].min = getReferenceOrNumber(
        min,
        parametrosConfig,
        ctrls?.parametros,
        ctrls?.setpoints
      );
    }
    if (typeof max !== "number") {
      ctrlConfig.setpoints[i].max = getReferenceOrNumber(
        max,
        parametrosConfig,
        ctrls?.parametros,
        ctrls?.setpoints
      );
    }
  });

  // Resgatar os dados dos cenários
  React.useEffect(() => {
    getScenarios(ctrlId).then((res) => {
      const { data } = res;
      setScenarios(data.reverse());
    });
  }, [ctrlId]);

  // Atualizar os dados dos cenários após a edição ou criação de qualquer dado
  React.useEffect(() => {
    if (scenarioEdited) {
      getScenarios(ctrlId).then((res) => {
        const { data } = res;
        setScenarios(data.reverse());
      });
      setScenarioEdited(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioEdited]);

  // Filtrar o sensor selecionado
  const sensorSelected = (sensor: number) =>
    parseData(sensores).filter((_f: any, i: number) => +i === sensor)[0];

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (scenarios && scenarios?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [loading, scenarios]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Ação de atualizar tabela
  const reloadTable = () => {
    setLoading(true);
    getScenarios(ctrlId).then((res) => {
      const { data } = res;
      setScenarios(data.reverse());
      setLoading(false);
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" ml={2} mr={2} mb={2}>
        <Box mt={1}>
          <TopicTitle icon={<PendingActionsIcon />}>
            {t("TEXT.SCENARIOS")}
          </TopicTitle>
        </Box>
        <Box display="flex">
          <Box mt={mobile ? 0.5 : 1.2} mr={mobile ? 0 : 2}>
            <Tooltip title={t("TEXT.USER_MANUAL")}>
              {mobile ? (
                <IconButton
                  color="warning"
                  onClick={() =>
                    printUserManualAsPDF(
                      `${t("TEXT.USER_MANUAL")}.pdf`,
                      fileURL
                    )
                  }
                  sx={{ mt: mobile ? 0.5 : 0, mr: mobile ? 0.5 : 2 }}
                >
                  <FileDownloadIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  color="warning"
                  startIcon={<FileDownloadIcon />}
                  onClick={() =>
                    printUserManualAsPDF(
                      `${t("TEXT.USER_MANUAL")}.pdf`,
                      fileURL
                    )
                  }
                  sx={{ fontSize: 13, fontWeight: 600 }}
                >
                  {t("TEXT.USER_MANUAL")}
                </Button>
              )}
            </Tooltip>
          </Box>
          {devicePermission !== "VIEW" && (
            <Box mt={mobile ? 0.5 : 1} mr={mobile ? 0 : 2}>
              {planPermissions?.SCENARIOS !== true && (
                <Box>
                  {mobile ? (
                    <IconButton
                      color="warning"
                      onClick={handleOpenModalPermission}
                    >
                      <AddToPhotosIcon />
                    </IconButton>
                  ) : (
                    <Button
                      size="small"
                      color="warning"
                      startIcon={<AddToPhotosIcon />}
                      onClick={handleOpenModalPermission}
                      sx={{ fontSize: 13, fontWeight: 600 }}
                    >
                      {t("BUTTON.ADD")}
                    </Button>
                  )}
                </Box>
              )}
              {ctrls !== undefined &&
                planPermissions?.SCENARIOS === true &&
                scenarios?.length <
                  planPermissions?.NUM_SCENARIOS_CONTROLLER && (
                  <Box>
                    {mobile ? (
                      <IconButton color="warning" onClick={handleOpenModalAdd}>
                        <AddToPhotosIcon />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        startIcon={<AddToPhotosIcon />}
                        onClick={handleOpenModalAdd}
                        sx={{ fontSize: 13, fontWeight: 600 }}
                      >
                        {t("BUTTON.ADD")}
                      </Button>
                    )}
                  </Box>
                )}
            </Box>
          )}
          <Tooltip title={t("TEXT.UPDATE") as string}>
            <IconButton color="warning" onClick={reloadTable}>
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {loading ? (
        <Box m="155px 0px">
          <Spinner />
        </Box>
      ) : (
        scenarios.length !== 0 && (
          <Box>
            {planPermissions?.SCENARIOS === true &&
              planPermissions?.NUM_SCENARIOS_CONTROLLER > 0 && (
                <ScenariosTable
                  sensorSelected={sensorSelected}
                  scenarios={scenarios}
                  setScenarioEdited={setScenarioEdited}
                  model={model}
                  sensores={sensores}
                  acoes={acoes}
                  setpoints={ctrlConfig.setpoints}
                  parametros={ctrlConfig.parametros}
                  activeStep={activeStepEdit}
                  setActiveStep={setActiveStepEdit}
                  conditionWork={conditionWork}
                  setConditionWork={setConditionWork}
                  ctrlIsDefined={ctrls !== undefined}
                  estado={estado}
                  planPermissions={planPermissions}
                  devicePermission={devicePermission}
                />
              )}
          </Box>
        )
      )}
      <ModalAdd
        handleOpen={openModalAdd}
        handleClose={handleCloseModalAdd}
        model={model}
        setScenarioEdited={setScenarioEdited}
        sensores={sensores}
        acoes={acoes}
        setpoints={ctrlConfig.setpoints}
        parametros={ctrlConfig.parametros}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        conditionWork={conditionWork}
        setConditionWork={setConditionWork}
        estado={estado}
        planPermissions={planPermissions}
      />
    </>
  );
};

export default Scenarios;
