import { getRawCtrlConfig } from "../../config";
import { parseIcon } from "./Icons";
import { Parametros, Setpoints } from "../../services/types/ctrls";
import { LanguageCode } from "../../translations/types";
import {
  Estado,
  Params,
  RawGenericArray,
  RawGenericValue,
  RowData,
} from "../Controller/parser/types";
import { withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { red } from "@mui/material/colors";

export const ctrlData = (model: string) => getRawCtrlConfig(model);

export const parseData = (data: RawGenericValue) => {
  if (data === undefined) return [];
  const parseValue = (values: RawGenericArray): Partial<RowData> => {
    const [
      description,
      min,
      max,
      defaultValue,
      unitOfMeasurement,
      separator,
      info,
    ] = values;
    const readOnly = !!info?.readOnly;
    const visible = info?.visible !== false;

    const parsedObject = {
      description,
      max,
      min,
      defaultValue,
      unitOfMeasurement,
      separator,
      readOnly,
      visible,
    };

    return parsedObject;
  };

  const codes = Object.keys(data);
  const parsedData = codes.map((code) => ({
    ...parseValue(data[code]),
    code,
  }));
  return parsedData as RowData[];
};

// Separa a string de referencia e a chave para um objeto
// "setpoint1" => { param: "setpoint", key: "1" }
export const splitCtrlParamKey = (data: string) => {
  const keys = ["alarme", "detalhamento", "setpoint"];
  const param = keys.find((k) => data.indexOf(k) === 0) as Params;
  const key = data.replace(param, "");
  if (!param || !key) throw Error(`Controller param not found: "${data}"`);
  return { param, key };
};

// Verifica se o valor min/max é um número ou referencia a outro valor
// Se for referencia retorna o valor da referência
export const getReferenceOrNumber = (
  value: string | number,
  parametrosConfig: RowData[],
  parametros: Parametros | undefined,
  setpoints: Setpoints | undefined
) => {
  // Alguns valores do modelo não apresentam um min/max mas fazem referencia a
  // outro valor no mesmo modelo
  const getReferenceValue = (referenceValue: string) => {
    const { param, key } = splitCtrlParamKey(referenceValue);

    const getParamtrosIndexByCode = (code: string) =>
      parametrosConfig.findIndex((i) => i.code === code);

    const getSetpointKey = (index: string) =>
      `s${+index - 1}` as keyof Setpoints;

    if (parametros !== undefined && setpoints !== undefined) {
      switch (param) {
        case "detalhamento":
          return parametros[getParamtrosIndexByCode(key)];
        case "setpoint":
          return setpoints[getSetpointKey(key)];
        default:
          throw Error(`Param not found: "${param}"`);
      }
    } else {
      return 0;
    }
  };

  if (typeof value === "string") {
    return getReferenceValue(value);
  }
  return value;
};

export const splitValue = (id: string) => id?.split("/");

export const divider = (filtered: RowData) => filtered?.divider ?? 0;

export const type = (filtered: RowData) => filtered?.type ?? "";

// Converter timestamp em horas HH:MM
const convertToHour = (timestamp: number, language: LanguageCode) => {
  const FullTime = new Date(timestamp).toLocaleDateString(
    language === "en" ? "en-US" : "pt-BR",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  return FullTime;
};

// Extrair o as horas e minutos do Date
export const splitTime = (timestamp: number, language: LanguageCode) =>
  convertToHour(timestamp, language).split(",")[1];

// Resgata os bits ativos para a renderização dos ícones
export const getActiveBits = (num: number): number[] => {
  const activeBits: number[] = [];
  let index = 0;
  while (num > 0) {
    if (num % 2 === 1) {
      activeBits.push(index);
    }
    num = Math.floor(num / 2);
    index++;
  }
  return activeBits;
};

// Ícones
const rawIcons = (estado: Estado[]) => estado?.filter(({ icon }) => !!icon);
const icons = (estado: Estado[], theme: string) =>
  rawIcons(estado)
    ?.map((icon) => ({
      bit: icon.bit,
      description: icon.description,
      icon: parseIcon(icon.icon, theme),
    }))
    .reverse();

// Filtrar somente as condições dos Estados de cada índice
export const filteredConditions = (
  estado: Estado[],
  conditions: any,
  theme: string | undefined
) =>
  conditions.map((m: any) => {
    return getActiveBits(m.valorReferencia).map((v: any) => {
      return icons(estado, theme ?? "light")?.filter(
        (f: any) => f.bit === v
      )[0];
    });
  });

// Transformar a primeira letra da palavra em maiúscula
export const firstLetterUpperCase = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

// Style da borda dos componentes Select
export const selectBorderStyle = {
  "&:after": {
    borderColor: "#D0D0D0",
  },
  "&:before": {
    borderColor: "#D0D0D0",
  },
  "&:hover:not(.Mui-disabled):before": {
    borderColor: "#D0D0D0",
  },
};

// Styles da borda dos componentes TimePicker
export const timePickerBorderStyle = {
  ".css-f9y7aa::before": {
    borderBottom: "1px solid #D0D0D0",
  },
  ".css-f9y7aa::after": {
    borderBottom: "1px solid #D0D0D0",
  },
  ".css-f9y7aa:hover:not(.Mui-disabled)::before": {
    borderBottom: "1px solid #D0D0D0",
  },
  " .css-6figl6-MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "1px solid #D0D0D0",
  },
  " .css-6figl6-MuiInputBase-root-MuiInput-root:after": {
    borderBottom: "1px solid #7A7A7A",
  },
  " .css-6figl6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before":
    {
      borderBottom: "1px solid #7A7A7A",
    },
};

// Style da borda do componente TextField
export const textFieldBorderStyle = {
  ".css-1n07t4n::before": {
    borderBottom: "1px solid #D0D0D0",
  },
  ".css-1n07t4n::after": {
    borderBottom: "1px solid #7A7A7A",
  },
  ".css-1n07t4n:hover:not(.Mui-disabled)::before": {
    borderBottom: "1px solid #7A7A7A",
  },
  ".css-1hykvwr-MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "1px solid #D0D0D0",
  },
  ".css-1hykvwr-MuiInputBase-root-MuiInput-root:after": {
    borderBottom: "1px solid #7A7A7A",
  },
  ".css-1hykvwr-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before":
    {
      borderBottom: "1px solid #7A7A7A",
    },
};

// Resgata os dias da semana preenchidos pelo o usuário
export const getSelectedDaysString = (t: any, daysArray: number[]) => {
  const dayNames = [
    t("TEXT.SUNDAY"),
    t("TEXT.MONDAY"),
    t("TEXT.TUESDAY"),
    t("TEXT.WEDNESDAY"),
    t("TEXT.THURSDAY"),
    t("TEXT.FRIDAY"),
    t("TEXT.SATURDAY"),
  ];
  let selectedDays: string[] = [];

  daysArray.forEach((day, index) => {
    if (day === 1) {
      selectedDays.push(dayNames[index]);
    }
  });

  return selectedDays.join(", ");
};

// Componente dos dias da semana
export const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(1.5),
    padding: theme.spacing(0, 1),
    "&:not(:first-child)": {
      border: "1px solid",
      borderColor: "#692B7C",
      borderRadius: "50%",
    },
    "&:first-child": {
      border: "1px solid",
      borderColor: red[800],
      borderRadius: "50%",
    },
  },
}))(ToggleButtonGroup);

export const StyledToggle = withStyles({
  root: {
    color: red[800],
    "&$selected": {
      color: "white",
      background: red[800],
    },
    "&:hover": {
      borderColor: red[800],
      background: red[100],
    },
    "&:hover$selected": {
      borderColor: red[800],
      background: red[600],
    },
    minWidth: 35,
    maxWidth: 35,
    height: 35,
    textTransform: "unset",
    fontSize: "0.75rem",
  },
  selected: {},
})(ToggleButton);
