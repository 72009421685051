import {
  Avatar,
  Box,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  MobileStepper,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddToPhotos as AddToPhotosIcon,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  LooksOne as LooksOneIcon,
  LooksTwo as LooksTwoIcon,
  Looks3 as Looks3Icon,
} from "@mui/icons-material";
import Modal from "../../../../Devices/Modal";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../../hooks/useIsMobile";
import React from "react";
import { ToastContext } from "../../../../../contexts/ToastContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { AuthContext } from "../../../../../contexts/AuthContext";
import {
  Select,
  TextField,
  yupNumberMin,
  yupRequiredString,
  yupString,
} from "../../../../../components/Form";
import { api } from "../../../../../services/api";
import { TOKEN_KEY } from "../../../../../auth/constants";
import ErrorMessage from "../../../../../components/ErrorMessage";
import StepperCustom from "../Stepper";
import { createAdminPlan } from "../../../../../services/data/subscriptions";
import Switch from "../../../../../components/Switch";

interface ModalAddProps {
  setPlanEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalAddPlan = ({
  handleOpen,
  handleClose,
  setPlanEdited,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);
  const [activeStep, setActiveStep] = React.useState(0);

  // Lista de intervaloes do 'Acesso ao histórico de dados'
  const intervals = [
    {
      name: t("TEXT.MONTHS"),
      value: "MONTHS",
    },
    {
      name: t("TEXT.WEEKS"),
      value: "WEEKS",
    },
    {
      name: t("TEXT.DAYS"),
      value: "DAYS",
    },
    {
      name: t("TEXT.HOURS"),
      value: "HOURS",
    },
    {
      name: t("TEXT.MINUTES"),
      value: "MINUTES",
    },
  ];

  // Ação de mudar para o stepp anterior
  const handleBackStepp = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Ação de mudar para o próximo stepp
  const handleNextStepp = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Ação de alterar o hidden
  const [hidden, setHidden] = React.useState(false);
  const handleChangeHidden = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHidden(event.target.checked);
  };

  // Ação de alterar a 'Alteração de parâmetros de controladores'
  const [ctrlParam, setCtrlParam] = React.useState(false);
  const handleChangeCtrlParam = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCtrlParam(event.target.checked);
  };

  // Ação de alterar os 'Cenários'
  const [scenarios, setScenarios] = React.useState(false);
  const handleChangeScenarios = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setScenarios(event.target.checked);
  };

  // Ação de alterar o 'Compartilhamento de dispositivos'
  const [shareable, setShareable] = React.useState(false);
  const handleChangeShareable = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShareable(event.target.checked);
  };

  // Ação de alterar TABs no modo admin do step de 'Traduções'
  const [optionsTab, setOptionsTab] = React.useState(0);
  const handleChangeOptionsTab = (
    _event: React.SyntheticEvent,
    newValue: number
  ) => {
    setOptionsTab(newValue);
  };

  // Ação de alterar os 'Setors'
  const [setors, setSetors] = React.useState(false);
  const handleChangeSetors = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSetors(event.target.checked);
  };

  // Ação de alterar as 'TAGs'
  const [tags, setTags] = React.useState(false);
  const handleChangeTags = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTags(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      identifier: "",
      order: "",
      value: "",
      interval: "",
      namePt: "",
      benefitsPt: "",
      nameEn: "",
      benefitsEn: "",
      nameEs: "",
      benefitsEs: "",
      accessDeviceHistoryType: "",
      accessDeviceHistory: "",
      numScenariosController: "",
      numConditionsScenarios: "",
      numActionsScenarios: "",
    },
    validationSchema: Yup.object({
      identifier: yupRequiredString,
      order: yupRequiredString,
      value: yupNumberMin(0),
      interval: yupNumberMin(0),
      namePt: yupRequiredString,
      benefitsPt: yupRequiredString,
      nameEn: yupRequiredString,
      benefitsEn: yupRequiredString,
      nameEs: yupRequiredString,
      benefitsEs: yupRequiredString,
      accessDeviceHistoryType: yupRequiredString,
      accessDeviceHistory: yupNumberMin(0),
      numScenariosController: scenarios === true ? yupNumberMin(0) : yupString,
      numConditionsScenarios: scenarios === true ? yupNumberMin(0) : yupString,
      numActionsScenarios: scenarios === true ? yupNumberMin(0) : yupString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const data = {
          data: [
            {
              name: values.namePt,
              benefits: values.benefitsPt.split("\n"),
              lang: "pt-BR",
            },
            {
              name: values.nameEn,
              benefits: values.benefitsEn.split("\n"),
              lang: "en-US",
            },
            {
              name: values.nameEs,
              benefits: values.benefitsEs.split("\n"),
              lang: "es-ES",
            },
          ],
          status: "ACTIVE",
          hidden: hidden,
          identifier: values.identifier,
          order: values.order,
          value_cents: +values.value * 100,
          interval: values.interval,
          interval_type: "MONTHS",
          permissions: {
            DEVICE: {
              CONTROL_PARAMETERS: ctrlParam,
              SHAREABLE: shareable,
              ACCESS_DEVICE_HISTORY: values.accessDeviceHistory,
              ACCESS_DEVICE_HISTORY_TYPE: values.accessDeviceHistoryType,
              CONTROL_TAGS: tags,
              SCENARIOS: scenarios,
              NUM_SCENARIOS_CONTROLLER:
                scenarios === true ? values.numScenariosController : 0,
              NUM_CONDITIONS_SCENARIOS:
                scenarios === true ? values.numConditionsScenarios : 0,
              NUM_ACTIONS_SCENARIOS:
                scenarios === true ? values.numActionsScenarios : 0,
              SECTORS: setors,
            },
          },
        };

        // Rota para criar um novo plano no modo Admin
        createAdminPlan(data)
          .then(() => {
            toastSuccess(t("TOAST.SUCCESS.ADD_PLAN"));
            setPlanEdited(true);
            setActiveStep(0);
            formik.resetForm();
            handleClose();
          })
          .catch(() => {
            toastError(t("TOAST.ERROR.ADD_PLAN"));
          });
      }
    },
  });

  // Lista de intervalos do 'Acesso ao histórico de dados'
  const optionsIntervals = intervals.map((data: any, i: number) => (
    <MenuItem
      value={data.value}
      key={i}
      sx={{ placeContent: "center", borderTop: "1px solid #F0EFEE" }}
    >
      <Typography>{data.name}</Typography>
    </MenuItem>
  ));

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    setActiveStep(0);
    formik.resetForm();
    handleClose();
  };

  // Limpar o último step do formulário ao voltar os steps
  React.useEffect(() => {
    if (activeStep === 1) {
      formik.resetForm({
        values: {
          ...formik.values,
          namePt: "",
          benefitsPt: "",
          nameEn: "",
          benefitsEn: "",
          nameEs: "",
          benefitsEs: "",
        },
      });
    }
    setOptionsTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  // Validação do primeiro step
  const initialDataIsValid =
    formik.values.identifier !== "" &&
    formik.values.value !== "" &&
    formik.values.order !== "" &&
    formik.values.interval !== "";

  // Validação do segundo step
  const permissionIsValid =
    scenarios === true
      ? formik.values.accessDeviceHistory !== "" &&
        formik.values.accessDeviceHistoryType !== "" &&
        formik.values.numScenariosController !== "" &&
        formik.values.numConditionsScenarios !== "" &&
        formik.values.numActionsScenarios !== ""
      : formik.values.accessDeviceHistory !== "" &&
        formik.values.accessDeviceHistoryType !== "";

  // Validação do terceiro step
  const translationsIsValid =
    formik.values.namePt !== "" &&
    formik.values.benefitsPt !== "" &&
    formik.values.nameEn !== "" &&
    formik.values.benefitsEn !== "" &&
    formik.values.nameEs !== "" &&
    formik.values.benefitsEs !== "";

  // Validação do formulário
  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Modal
      open={handleOpen}
      handleClose={closeModal}
      width={mobile ? 340 : 600}
    >
      {!mobile && (
        <>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3.5,
              pb: 3.5,
              borderRadius: 4,
              mb: 2,
              backgroundColor: red[100],
            }}
            elevation={0}
          >
            <Avatar
              sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}
            >
              <AddToPhotosIcon sx={{ fontSize: 65 }} />
            </Avatar>
          </Paper>
          <Typography fontSize={21} fontWeight={700} mb={1}>
            {t("TEXT.ADD")} {t("TEXT.PLAN")}
          </Typography>
        </>
      )}
      <form onSubmit={formik.handleSubmit}>
        {mobile ? (
          <>
            <MobileStepper
              steps={4}
              position="static"
              activeStep={activeStep}
              sx={{ mt: -2 }}
              nextButton={
                activeStep === 2 ? (
                  <Button type="submit" disabled={!isFormValid} sx={{ mr: 1 }}>
                    {t("BUTTON.SAVE")}
                  </Button>
                ) : (
                  <Button
                    onClick={handleNextStepp}
                    disabled={
                      activeStep === 0
                        ? !initialDataIsValid
                        : activeStep === 1
                        ? !permissionIsValid
                        : activeStep === 2
                        ? !translationsIsValid
                        : isFormValid
                    }
                    sx={{ mr: 1 }}
                  >
                    <Box display="flex">
                      <Box fontWeight={700}>{t("TEXT.NEXT")}</Box>
                      <KeyboardArrowRight />
                    </Box>
                  </Button>
                )
              }
              backButton={
                activeStep === 0 ? (
                  <Button onClick={handleClose} sx={{ mr: 1, fontWeight: 700 }}>
                    {t("BUTTON.CANCEL")}
                  </Button>
                ) : (
                  <Button onClick={handleBackStepp} sx={{ mr: 1 }}>
                    <Box display="flex">
                      <KeyboardArrowLeft />
                      <Box fontWeight={700}>{t("BUTTON.RETURN")}</Box>
                    </Box>
                  </Button>
                )
              }
            />
            <Typography fontSize={21} fontWeight={700} mb={1}>
              {t("TEXT.ADD")} {t("TEXT.PLAN")}
            </Typography>
          </>
        ) : (
          <StepperCustom activeStep={activeStep} />
        )}
        {activeStep === 0 ? (
          <>
            <Box display="flex" mt={2} mb={2}>
              <Box display="grid" width="100%">
                <TextField
                  formik={formik}
                  disabled={false}
                  onClick={(e: any) => e.stopPropagation()}
                  label={t("TEXT.IDENTIFIER")}
                  name="identifier"
                  maxLength={100}
                  fullWidth
                />
                {formik.errors.identifier && formik.touched.identifier ? (
                  <ErrorMessage>{formik.errors.identifier}</ErrorMessage>
                ) : null}
              </Box>
              <Box display="grid" ml={2} width="100%">
                <TextField
                  type="number"
                  formik={formik}
                  disabled={false}
                  onClick={(e: any) => e.stopPropagation()}
                  label={t("TEXT.PLAN_POSITION")}
                  name="order"
                  maxLength={100}
                  fullWidth
                />
                {formik.errors.order && formik.touched.order ? (
                  <ErrorMessage>{formik.errors.order}</ErrorMessage>
                ) : null}
              </Box>
            </Box>
            <Box display="flex" mt={2} mb={2}>
              <Box display="grid" width="100%">
                <FormControl fullWidth>
                  <InputLabel>{t("TEXT.VALUE")}</InputLabel>
                  <OutlinedInput
                    name="value"
                    type="number"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    startAdornment={
                      <InputAdornment position="start">R$</InputAdornment>
                    }
                    label={t("TEXT.VALUE")}
                  />
                </FormControl>
                {formik.errors.value && formik.touched.value ? (
                  <ErrorMessage>{formik.errors.value}</ErrorMessage>
                ) : null}
              </Box>
              <Box display="grid" ml={2} width="100%">
                <TextField
                  type="number"
                  formik={formik}
                  disabled={false}
                  label={t("TEXT.PLAN_DURATION")}
                  name="interval"
                  maxLength={100}
                  fullWidth
                />
                {formik.errors.interval && formik.touched.interval ? (
                  <ErrorMessage>{formik.errors.interval}</ErrorMessage>
                ) : null}
              </Box>
            </Box>
            <Box ml={2} mr={2} mt={1}>
              <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ mr: 1.5 }}
                      checked={hidden}
                      onChange={handleChangeHidden}
                    />
                  }
                  labelPlacement="end"
                  label={
                    hidden === true ? t("TEXT.NOT_VISIBLE") : t("TEXT.VISIBLE")
                  }
                />
              </Tooltip>
            </Box>
          </>
        ) : activeStep === 1 ? (
          <Box
            display="grid"
            justifyContent="space-between"
            ml={2}
            mr={2}
            mt={2}
          >
            <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
              <FormControlLabel
                sx={{ mb: 1 }}
                control={
                  <Switch
                    sx={{ mr: 1.5 }}
                    checked={ctrlParam}
                    onChange={handleChangeCtrlParam}
                  />
                }
                labelPlacement="end"
                label={t("TEXT.CHANGING_CTRL_PARAM")}
              />
            </Tooltip>
            <Box width="100%" mb={1}>
              <FormLabel sx={{ textAlign: "left", fontSize: 15 }}>
                {t("TEXT.ACCESS_TO_DATA")}
              </FormLabel>
              <Box display="flex" mt={1.5}>
                <Box display="grid" mr={2} width="100%">
                  <Select
                    formik={formik}
                    label={`${t("TEXT.INTERVAL")}s`}
                    name="accessDeviceHistoryType"
                    options={optionsIntervals}
                    sx={{ textAlign: "center" }}
                  />
                  {formik.errors.accessDeviceHistoryType &&
                  formik.touched.accessDeviceHistoryType ? (
                    <ErrorMessage>
                      {formik.errors.accessDeviceHistoryType}
                    </ErrorMessage>
                  ) : null}
                </Box>
                <Box display="grid" width="100%">
                  <TextField
                    type="number"
                    formik={formik}
                    disabled={false}
                    label={t("TEXT.VALUE")}
                    name="accessDeviceHistory"
                    maxLength={100}
                    fullWidth
                  />
                  {formik.errors.accessDeviceHistory &&
                  formik.touched.accessDeviceHistory ? (
                    <ErrorMessage>
                      {formik.errors.accessDeviceHistory}
                    </ErrorMessage>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box>
              <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                <FormControlLabel
                  sx={{ mb: 1, mt: 1 }}
                  control={
                    <Switch
                      sx={{ mr: 1.5 }}
                      checked={scenarios}
                      onChange={handleChangeScenarios}
                    />
                  }
                  label={t("TEXT.SCENARIOS")}
                />
              </Tooltip>
              <Fade in={scenarios}>
                <Box>
                  {scenarios === true && (
                    <Box>
                      <Box display="grid" width="100%" mt={1}>
                        <TextField
                          formik={formik}
                          type="number"
                          disabled={false}
                          label={t("TEXT.MAXIMUM_SCENARIOS_CTRL")}
                          name="numScenariosController"
                          maxLength={100}
                          fullWidth
                        />
                        {formik.errors.numScenariosController &&
                        formik.touched.numScenariosController ? (
                          <ErrorMessage>
                            {formik.errors.numScenariosController}
                          </ErrorMessage>
                        ) : null}
                      </Box>
                      <Box display="grid" width="100%" mt={2} mb={2}>
                        <TextField
                          formik={formik}
                          type="number"
                          disabled={false}
                          label={t("TEXT.MAXIMUM_CONDITIONS_SCENARIO")}
                          name="numConditionsScenarios"
                          maxLength={100}
                          fullWidth
                        />
                        {formik.errors.numConditionsScenarios &&
                        formik.touched.numConditionsScenarios ? (
                          <ErrorMessage>
                            {formik.errors.numConditionsScenarios}
                          </ErrorMessage>
                        ) : null}
                      </Box>
                      <Box display="grid" width="100%" mb={1}>
                        <TextField
                          formik={formik}
                          type="number"
                          disabled={false}
                          label={t("TEXT.MAXIMUM_CONDITIONS_ACTIONS")}
                          name="numActionsScenarios"
                          maxLength={100}
                          fullWidth
                        />
                        {formik.errors.numActionsScenarios &&
                        formik.touched.numActionsScenarios ? (
                          <ErrorMessage>
                            {formik.errors.numActionsScenarios}
                          </ErrorMessage>
                        ) : null}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Fade>
            </Box>
            <Box>
              <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                <FormControlLabel
                  sx={{ mb: 1, mt: 1 }}
                  control={
                    <Switch
                      sx={{ mr: 1.5 }}
                      checked={shareable}
                      onChange={handleChangeShareable}
                    />
                  }
                  label={t("TEXT.DEVICE_SHARING")}
                />
              </Tooltip>
            </Box>
            <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
              <FormControlLabel
                sx={{ mb: 1, mt: 1 }}
                control={
                  <Switch
                    sx={{ mr: 1.5 }}
                    checked={setors}
                    onChange={handleChangeSetors}
                  />
                }
                label={t("TEXT.SECTORS")}
              />
            </Tooltip>
            <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
              <FormControlLabel
                sx={{ mb: 1, mt: 1 }}
                control={
                  <Switch
                    sx={{ mr: 1.5 }}
                    checked={tags}
                    onChange={handleChangeTags}
                  />
                }
                label="TAGs"
              />
            </Tooltip>
          </Box>
        ) : (
          <>
            <Tabs
              orientation={mobile ? "vertical" : "horizontal"}
              value={optionsTab}
              onChange={handleChangeOptionsTab}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ mt: 2.5 }}
            >
              <Tab
                icon={<LooksOneIcon />}
                label="Português"
                sx={{ pt: 0 }}
                disableRipple
              />
              <Tab
                icon={<LooksTwoIcon />}
                label="English"
                sx={{ pt: 0 }}
                disableRipple
              />
              <Tab
                icon={<Looks3Icon />}
                label="Español"
                sx={{ pt: 0 }}
                disableRipple
              />
            </Tabs>
            {optionsTab === 0 ? (
              <Box display="grid" mt={2}>
                <Box display="grid" width="100%">
                  <TextField
                    formik={formik}
                    disabled={false}
                    label={t("TEXT.NAME")}
                    name="namePt"
                    maxLength={100}
                    fullWidth
                  />
                  {formik.errors.namePt && formik.touched.namePt ? (
                    <ErrorMessage>{formik.errors.namePt}</ErrorMessage>
                  ) : null}
                </Box>
                <Box display="grid" mt={2} width="100%">
                  <TextField
                    formik={formik}
                    disabled={false}
                    label={t("TEXT.BENEFITS")}
                    name="benefitsPt"
                    maxLength={100}
                    fullWidth
                    multiline
                    rows={4}
                  />
                  {formik.errors.benefitsPt && formik.touched.benefitsPt ? (
                    <ErrorMessage>{formik.errors.benefitsPt}</ErrorMessage>
                  ) : null}
                </Box>
              </Box>
            ) : optionsTab === 1 ? (
              <Box display="grid" mt={2}>
                <Box display="grid" width="100%">
                  <TextField
                    formik={formik}
                    disabled={false}
                    label={t("TEXT.NAME")}
                    name="nameEn"
                    maxLength={100}
                    fullWidth
                  />
                  {formik.errors.namePt && formik.touched.namePt ? (
                    <ErrorMessage>{formik.errors.namePt}</ErrorMessage>
                  ) : null}
                </Box>
                <Box display="grid" mt={2} width="100%">
                  <TextField
                    formik={formik}
                    disabled={false}
                    label={t("TEXT.BENEFITS")}
                    name="benefitsEn"
                    maxLength={100}
                    fullWidth
                    multiline
                    rows={4}
                  />
                  {formik.errors.benefitsPt && formik.touched.benefitsPt ? (
                    <ErrorMessage>{formik.errors.benefitsPt}</ErrorMessage>
                  ) : null}
                </Box>
              </Box>
            ) : (
              <Box display="grid" mt={2}>
                <Box display="grid" width="100%">
                  <TextField
                    formik={formik}
                    disabled={false}
                    label={t("TEXT.NAME")}
                    name="nameEs"
                    maxLength={100}
                    fullWidth
                  />
                  {formik.errors.namePt && formik.touched.namePt ? (
                    <ErrorMessage>{formik.errors.namePt}</ErrorMessage>
                  ) : null}
                </Box>
                <Box display="grid" mt={2} width="100%">
                  <TextField
                    formik={formik}
                    disabled={false}
                    label={t("TEXT.BENEFITS")}
                    name="benefitsEs"
                    maxLength={100}
                    fullWidth
                    multiline
                    rows={4}
                  />
                  {formik.errors.benefitsPt && formik.touched.benefitsPt ? (
                    <ErrorMessage>{formik.errors.benefitsPt}</ErrorMessage>
                  ) : null}
                </Box>
              </Box>
            )}
          </>
        )}
        {!mobile && (
          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            pl={3}
            pr={3}
          >
            {activeStep === 0 ? (
              <Button
                onClick={closeModal}
                variant="outlined"
                size={mobile ? "small" : "medium"}
              >
                {t("BUTTON.CANCEL")}
              </Button>
            ) : (
              <Button
                variant="outlined"
                startIcon={<ArrowLeftIcon />}
                onClick={handleBackStepp}
                size={mobile ? "small" : "medium"}
                sx={{ mr: 2 }}
              >
                {t("TEXT.PREVIOUS")}
              </Button>
            )}
            {activeStep === 2 ? (
              <Button
                type="submit"
                variant="contained"
                disabled={!isFormValid}
                size={mobile ? "small" : "medium"}
              >
                {t("BUTTON.CONFIRM")}
              </Button>
            ) : (
              <Button
                size={mobile ? "small" : "medium"}
                disabled={
                  activeStep === 0
                    ? !initialDataIsValid
                    : activeStep === 1
                    ? !permissionIsValid
                    : activeStep === 2
                    ? !translationsIsValid
                    : isFormValid
                }
                variant="contained"
                onClick={handleNextStepp}
                endIcon={<ArrowRightIcon />}
              >
                {t("TEXT.NEXT")}
              </Button>
            )}
          </Box>
        )}
      </form>
    </Modal>
  );
};

export default ModalAddPlan;
