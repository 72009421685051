import React from "react";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AttachMoney as AttachMoneyIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  MoneyOff as MoneyOffIcon,
  Cached as CachedIcon,
  Receipt as ReceiptIcon,
  ReceiptOutlined as ReceiptOutlinedIcon,
  DeleteOutlined as DeleteOutlineIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
} from "@mui/icons-material";

import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import { getDevice } from "../../services/data/arcsys";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import { getLanguage } from "../../translations/languages";
import { convertToDate } from "../Report/Pages/GeneralInfo/parse";
import { blue, green, red } from "@mui/material/colors";
import {
  convertToDatePaymentPending,
  convertToDateTime,
  convertToDateValue,
  enExpiresDate,
  formatValueCents,
  planNamePending,
  selectNameByTranslations,
  selectNameByTranslationsDetails,
} from "./parse";
import Spinner from "../../components/Spinner";
import AvatarIcon from "../../components/AvatarIcon";
import {
  getPendingInvoices,
  getStatement,
} from "../../services/data/subscriptions";
import ModalBenefits from "./Modal/ModalBenefits";
import { HIRE_SUBSCRIPTION, PLANS_AVAILABLE } from "../../Routes/constants";
import { useTranslation } from "react-i18next";
import ModalUnsubscribe from "./Modal/ModalUnsubscribe";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import DataGridTable from "../../components/DataGrid/DataGridTable";
import ModalRemove from "./Modal/ModalRemove";

const PlanDetails = () => {
  const { token, authenticated } = React.useContext(AuthContext);
  const params = useParams();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1200;
  const mediumPage = useIsMediumPage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = getLanguage().code;
  const [device, setDevice] = React.useState<any>();
  const [paymentHistory, setPaymentHistory] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [pendingInvoices, setPendingInvoices] = React.useState<any>();
  const [planEdited, setPlanEdited] = React.useState<boolean>(false);
  const [signatureEdited, setSignatureEdited] = React.useState<boolean>(false);
  const [modalDeletePendingValues, setModalDeletePendingValues] =
    React.useState<any>();
  const [openModalDeletePending, setOpenModalDeletePending] =
    React.useState(false);

  const mac = params.mac ?? "";

  // Ação do modal dos benefícios
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      // Resgatar dados gerais do Dispositivo
      getDevice(mac).then((res) => {
        // Dados do arcsys
        setDevice(res.data);
      });

      // Resgatar o histórico do pagamento e armazenar num useState
      getStatement(mac).then((res: any) => {
        setPaymentHistory(res.data);
      });

      // Resgata a lista de pagamentos pendentes
      getPendingInvoices().then((res) => {
        const { data } = res;
        // Filtrar os MACs de acordo com o MAC do Dispositivo
        const payload = data.filter((f: any) => f.MAC === mac);
        setPendingInvoices(payload);
      });
    }
  }, [authenticated, mac, token]);

  React.useEffect(() => {
    if (signatureEdited) {
      // Atualizar o histórico do pagamento após cancelar assinatura
      getStatement(mac).then((res: any) => {
        setPaymentHistory(res.data);
      });
      // Atualizar os dados do arcsys
      getDevice(mac).then((res) => {
        // Dados do arcsys
        setDevice(res.data);
      });
      setSignatureEdited(false);
    }
  }, [mac, signatureEdited]);

  // Atualizar a lista de pagamentos pendentes após a exclusão de algum pagamento
  React.useEffect(() => {
    if (planEdited) {
      getPendingInvoices().then((res) => {
        const { data } = res;
        // Filtrar os MACs de acordo com o MAC do Dispositivo
        const payload = data.filter((f: any) => f.MAC === mac);
        setPendingInvoices(payload);
      });
      setPlanEdited(false);
    }
  }, [mac, planEdited]);

  // Ações do modal de cancelar o plano
  const handleOpenModalDelete = (id: string, device: string) => {
    setModalDeleteValues({ id, device });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações do modal de deletar
  const handleOpenModalDeletePending = (row: any) => {
    setModalDeletePendingValues({ row });
    setOpenModalDeletePending(true);
  };
  const handleCloseModalDeletePending = () => {
    setOpenModalDeletePending(false);
  };

  // Resgatar os benefícios de acordo com a linguagem selecionada
  const benefits: string[] = React.useMemo(
    () =>
      language === "es"
        ? device &&
          device?.subscriptions.filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
          )[0]?.plan.data[2].benefits
        : language === "en"
        ? device?.subscriptions.filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
          )[0]?.plan.data[1].benefits
        : device?.subscriptions.filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
          )[0]?.plan.data[0].benefits,
    [device, language]
  );

  // Payload do plano filtrado
  const payloadPlan = device?.subscriptions
    .reverse()
    .filter(
      (f: any) => f.status === "ACTIVE" && f.user_id === device.ownerId
    )[0];

  // Checar se o plano é Recorrente
  const checkIfPlanIsRecurrent = payloadPlan?.recorrencia === true;

  // Checar se o plano Recorrente foi cancelado
  const checkIfPlanIsRecurrentIsCanceled = payloadPlan?.recorrencia_cancelada;

  // Resgatar o id da subscription do Plano para cancelar assinatura
  const planIdSubscription = payloadPlan?.plan_subscription_id;

  // Resgatar o nome do Plano
  const planName = React.useMemo(
    () =>
      checkIfPlanIsRecurrent && !checkIfPlanIsRecurrentIsCanceled
        ? `${selectNameByTranslations(payloadPlan, language)} - ${t(
            "TEXT.RECURRENT"
          )}
          `
        : checkIfPlanIsRecurrent && checkIfPlanIsRecurrentIsCanceled === true
        ? selectNameByTranslations(payloadPlan, language)
        : selectNameByTranslations(payloadPlan, language),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [device, language]
  );

  // Titulo da data de expiração no card
  const expDateName =
    checkIfPlanIsRecurrent && !checkIfPlanIsRecurrentIsCanceled
      ? t("TEXT.RENEWAL_DATE")
      : checkIfPlanIsRecurrent && checkIfPlanIsRecurrentIsCanceled === true
      ? t("TEXT.EXPIRATION_DATE")
      : t("TEXT.EXPIRATION_DATE");

  // Id do Plano atual
  const actualPlanId = device?.subscriptions.filter(
    (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
  )[0]?.plan?._id;

  // Resgatar os dados de cada Plano
  const planType = React.useMemo(
    () =>
      device?.subscriptions.filter(
        (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
      )[0],
    [device?.ownerId, device?.subscriptions]
  );

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (device && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [device, loading, paymentHistory]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  // Reorganizar o histórico de pagamentos na tabela
  const paymentSort = React.useMemo(
    () =>
      paymentHistory &&
      paymentHistory
        .sort((a: any, b: any) => {
          return (
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
        })
        .reverse(),
    [paymentHistory]
  );

  // Reorganizar os pagamentos pendentes na tabela
  const pendingInvoicesSort = React.useMemo(
    () =>
      pendingInvoices &&
      pendingInvoices
        .sort((a: any, b: any) => {
          return (
            new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
          );
        })
        .reverse(),
    [pendingInvoices]
  );

  // Data de expiração
  const expiresDate = React.useMemo(
    () =>
      device?.subscriptions
        .filter(
          (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId
        )
        ?.reverse()[0]
        ?.expires_at?.replace("-", "/")
        ?.replace("-", "/")
        .toString(),
    [device?.ownerId, device?.subscriptions]
  );

  // Alterar o formato da data de expiração de acordo com a linguagem
  const convertedExpiresDate =
    language !== "en" ? expiresDate : enExpiresDate(expiresDate);

  // Data de expiração do Plano - 30 dias
  const expDataMinThirtyDays = new Date(
    +convertToDateValue(convertedExpiresDate, language) - 30 * 86400 * 1000
  ).getTime();

  // Checar se falta 30 dias para o plano se expirar
  const checkRenewDate =
    expDataMinThirtyDays < new Date().getTime() ? true : false;

  // Linhas da tabela dos Pagamentos pendentes
  const rows = React.useMemo(
    () =>
      pendingInvoicesSort?.map((row: any, i: number) => {
        return {
          id: i + 1,
          paymentId: row.id,
          due_date: convertToDatePaymentPending(row?.due_date, language),
          planName: planNamePending(row.plan_name, language),
          payment_method:
            row.payment_method === "courtesy"
              ? t("TEXT.COURTESY")
              : row.payment_method === "BOLETO"
              ? t("TEXT.BILLET")
              : row.payment_method === "CREDIT_CARD"
              ? t("TEXT.CREDIT_CARD")
              : row.payment_method === "PIX"
              ? "PIX"
              : t("TEXT.ALL"),
          total_value_cents:
            formatValueCents(row.total_value_cents.toString()) === "R$: 0"
              ? t("TEXT.FREE")
              : formatValueCents(
                  (row.total_value_cents - (row?.discount_cents ?? 0)).toString()
                ),
          secure_url: row.secure_url,
          status:
            row.status === "payment_error"
              ? t("TEXT.FAILURE")
              : Date.parse(row.due_date?.slice(0, -1)) >=
                +new Date().setHours(0, 0, 0, 0)
              ? t("TEXT.PENDING")
              : t("TEXT.EXPIRED"),
          paymentError: row.status === "payment_error",
          mobileMode: "",
        };
      }),
    [language, pendingInvoicesSort, t]
  );

  // Linhas da tabela do Histórico de pagamentos
  const rowsHistory = React.useMemo(
    () =>
      paymentSort?.map((row: any, i: number) => {
        return {
          id: i + 1,
          created_at: convertToDateTime(
            new Date(row?.created_at).getTime(),
            language
          ).replace(",", " - "),
          planName: selectNameByTranslationsDetails(row, language),
          paid_with:
            row.paid_with === "courtesy"
              ? t("TEXT.COURTESY")
              : row.paid_with === "BOLETO"
              ? t("TEXT.BILLET")
              : row.paid_with === "CREDIT_CARD"
              ? t("TEXT.CREDIT_CARD")
              : row.paid_with === "PIX"
              ? "PIX"
              : t("TEXT.ALL"),
          value:
            formatValueCents((row?.value ? row?.value : row.__v).toString()) ===
            "R$: 0"
              ? t("TEXT.FREE")
              : formatValueCents(
                  (
                    (row?.value ? row?.value : row.__v) - (row?.discount ?? 0)
                  ).toString()
                ),
          status: row.status,
          mobileMode: "",
          secure_url: row.secure_url,
        };
      }),
    [language, paymentSort, t]
  );

  // Colunas da tabela do Histórico de pagamentos
  const columnsHistory: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "created_at",
            headerName: t("TEXT.PAY_DAY"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "planName",
            headerName: `${t("TEXT.PLAN")}`,
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "paid_with",
            headerName: t("TEXT.PAYMENT_METHODS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "value",
            headerName: t("TEXT.PAID_AMOUNT"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box display="flex">
                {row.secure_url !== undefined && (
                  <Link href={row.secure_url} target="_blank">
                    <Tooltip title={t("TEXT.ACCESS")}>
                      <IconButton color="info">
                        <ReceiptIcon />
                      </IconButton>
                    </Tooltip>
                  </Link>
                )}
                <Typography
                  mt={
                    row.secure_url !== undefined || row.value !== t("TEXT.FREE")
                      ? 1.5
                      : 0
                  }
                  mr={
                    row.secure_url !== undefined
                      ? 0
                      : row.value !== t("TEXT.FREE")
                      ? 1.5
                      : 0
                  }
                  fontSize={row.value !== t("TEXT.FREE") ? 12 : 14}
                >
                  {row.value}
                </Typography>
              </Box>
            ),
          },
          {
            field: "status",
            headerName: `Status ${t("TEXT.PLAN")}`,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mt={2} mb={2}>
                <Chip
                  label={
                    row.status === "ACTIVE"
                      ? t("TEXT.ACTIVE")
                      : row.status === "INACTIVE"
                      ? t("TEXT.INACTIVE")
                      : t("TEXT.CANCELED")
                  }
                  color={row.status === "ACTIVE" ? "success" : "error"}
                  sx={{
                    mr: 1,
                    fontWeight: 700,
                    backgroundColor:
                      row.status === "ACTIVE" ? green[100] : red[100],
                    color: row.status === "ACTIVE" ? green[800] : red[800],
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                />
              </Box>
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "created_at",
            hide: true,
          },
          {
            field: "paid_with",
            hide: true,
          },
          {
            field: "value",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "planName",
            hide: true,
          },
          {
            field: "mobileMode",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.PAYMENT_HISTORY"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Box display="flex">
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PAY_DAY")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.created_at}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PLAN")}s:
                            </Typography>
                            <Typography variant="body2">
                              {row.planName}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PAYMENT_METHODS")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.paid_with}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.3,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PAID_AMOUNT")}:
                            </Typography>
                            {row.secure_url !== undefined && (
                              <Link href={row.secure_url} target="_blank">
                                <ReceiptIcon color="info" />
                              </Link>
                            )}
                            <Typography variant="body2" ml={0.5} mt={0.2}>
                              {row.value}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={1}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 1,
                                fontSize: 13,
                              }}
                            >
                              Status:
                            </Typography>
                            <Chip
                              label={
                                row.status === "ACTIVE"
                                  ? t("TEXT.ACTIVE")
                                  : row.status === "INACTIVE"
                                  ? t("TEXT.INACTIVE")
                                  : t("TEXT.CANCELED")
                              }
                              color={
                                row.status === "ACTIVE" ? "success" : "error"
                              }
                              sx={{
                                mr: 1,
                                fontWeight: 700,
                                backgroundColor:
                                  row.status === "ACTIVE"
                                    ? green[100]
                                    : red[100],
                                color:
                                  row.status === "ACTIVE"
                                    ? green[800]
                                    : red[800],
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Colunas da tabela dos Pagamentos pendentes
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "due_date",
            headerName: t("TEXT.DUE_DATE"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "planName",
            headerName: `${t("TEXT.PLAN")}s`,
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "payment_method",
            headerName: t("TEXT.PAYMENT_METHODS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "total_value_cents",
            headerName: t("TEXT.AMOUNT"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "secure_url",
            headerName: t("TEXT.PAYMENT_LINK"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <>
                <Link href={row.secure_url} target="_blank">
                  <Tooltip title={t("TEXT.ACCESS")}>
                    <IconButton
                      color={
                        row.status === t("TEXT.PENDING") ||
                        row.status === t("TEXT.FAILURE")
                          ? "info"
                          : "default"
                      }
                    >
                      <ReceiptIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Tooltip title={t("TEXT.DELETE_PENDING_PAYMENT")}>
                  <IconButton
                    sx={{ p: 0 }}
                    color="error"
                    onClick={() => handleOpenModalDeletePending(row)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
          {
            field: "status",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Status",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mt={2} mb={2}>
                <Chip
                  label={row.status}
                  sx={{
                    mr: 1,
                    fontWeight: 700,
                    backgroundColor:
                      row.status === t("TEXT.PENDING") ? blue[100] : red[100],
                    color:
                      row.status === t("TEXT.PENDING") ? blue[800] : red[800],
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                />
              </Box>
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "due_date",
            hide: true,
          },
          {
            field: "payment_method",
            hide: true,
          },
          {
            field: "total_value_cents",
            hide: true,
          },
          {
            field: "secure_url",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "planName",
            hide: true,
          },
          {
            field: "mobileMode",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.OUTSTANDING_PAYMENTS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Box display="flex">
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.DUE_DATE")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.due_date}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PLAN")}s:
                            </Typography>
                            <Typography variant="body2">
                              {row.planName}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PAYMENT_METHODS")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.payment_method === "courtesy"
                                ? t("TEXT.COURTESY")
                                : row.payment_method === "BOLETO"
                                ? t("TEXT.BILLET")
                                : row.payment_method === "CREDIT_CARD"
                                ? t("TEXT.CREDIT_CARD")
                                : row.payment_method === "PIX"
                                ? "PIX"
                                : t("TEXT.ALL")}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.AMOUNT")}:
                            </Typography>
                            <Typography variant="body2">
                              {formatValueCents(
                                row.total_value_cents.toString()
                              ) === "R$: 0"
                                ? t("TEXT.FREE")
                                : formatValueCents(
                                    row.total_value_cents.toString()
                                  )}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography
                              fontWeight={500}
                              sx={{
                                fontSize: 13,
                                placeSelf: "center",
                              }}
                            >
                              {t("TEXT.PAYMENT_LINK")}:
                            </Typography>
                            <Typography variant="body2">
                              <Link href={row.secure_url} target="_blank">
                                <Tooltip title={t("TEXT.ACCESS")}>
                                  <IconButton
                                    color={
                                      row.status === t("TEXT.PENDING") ||
                                      row.status === t("TEXT.FAILURE")
                                        ? "info"
                                        : "default"
                                    }
                                  >
                                    <ReceiptIcon />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                              <IconButton
                                sx={{ p: 0 }}
                                color="error"
                                onClick={() =>
                                  handleOpenModalDeletePending(row)
                                }
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 1,
                                fontSize: 13,
                              }}
                            >
                              Status:
                            </Typography>
                            <Chip
                              label={row.status}
                              sx={{
                                mr: 1,
                                fontWeight: 700,
                                backgroundColor:
                                  row.status === t("TEXT.PENDING")
                                    ? blue[100]
                                    : red[100],
                                color:
                                  row.status === t("TEXT.PENDING")
                                    ? blue[800]
                                    : red[800],
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  return (
    <Grid container sx={{ placeContent: "center" }}>
      <Box m={3.5} mt={0}>
        <Box position="sticky" top="90px">
          {device && (
            <>
              <Card sx={{ mt: 0 }}>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pt: 3.5,
                      pb: 3.5,
                      pl: 5,
                      pr: 5,
                      borderRadius: 4,
                      mb: 2.4,
                      backgroundColor: red[100],
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 120,
                        height: 120,
                        boxShadow: 3,
                        ml: 4,
                        mr: 4,
                      }}
                    >
                      <AvatarIcon
                        name={device.name}
                        width={120}
                        height={120}
                        fontSize={70}
                        bgcolor={red[400]}
                      />
                    </Avatar>
                  </Box>
                  <Box mt={2} textAlign="center">
                    <Typography
                      fontSize={25}
                      fontWeight={500}
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      width={260}
                    >
                      {device.name}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={0.5}
                      mb={0.5}
                    >
                      <Typography fontWeight={500} fontSize={14}>
                        MAC:
                      </Typography>
                      <Typography ml={0.6} fontSize={14}>
                        {device.mac}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
                <Divider />
                <CardContent>
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={0.5}
                      mb={0.5}
                    >
                      <Typography fontWeight={500}>
                        {t("TEXT.PLAN")}:
                      </Typography>
                      <Typography ml={0.6}>{planName}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={0.5}
                      mb={0.5}
                    >
                      <Typography fontWeight={500}>
                        {t("TEXT.ACCESSION_DATE")}:
                      </Typography>
                      <Typography ml={0.6}>
                        {convertToDate(
                          device?.subscriptions
                            .filter(
                              (f: any) =>
                                f.status === "ACTIVE" &&
                                f.user_id === device?.ownerId
                            )
                            .reverse()[0]?.created_at,
                          language
                        )}
                      </Typography>
                    </Box>
                    {!planType?.plan.identifier?.includes("free") && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        mt={0.5}
                        mb={0.5}
                      >
                        <Typography fontWeight={500}>{expDateName}:</Typography>
                        <Typography ml={0.6}>{convertedExpiresDate}</Typography>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="center" mt={1}>
                      <Button
                        sx={{ p: 0, fontSize: 12 }}
                        onClick={handleOpenModal}
                      >
                        {t("TEXT.SEE_ALL_BENEFITS")}
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
                <Divider />
                <CardContent>
                  {planType?.status !== "DELETED" ? (
                    <Box>
                      {!planType?.plan.identifier?.includes("free") ? (
                        <Box sx={{ textAlign: "center" }} mt={1}>
                          {mobile ? (
                            <Stack
                              direction="row"
                              spacing={1}
                              sx={{ placeContent: "center" }}
                            >
                              {!checkIfPlanIsRecurrent && (
                                <IconButton
                                  onClick={() =>
                                    navigate(`${PLANS_AVAILABLE}/${mac}`)
                                  }
                                >
                                  <CurrencyExchangeIcon
                                    color="success"
                                    fontSize="large"
                                  />
                                </IconButton>
                              )}
                              {checkIfPlanIsRecurrent && (
                                <>
                                  {checkIfPlanIsRecurrentIsCanceled === true ? (
                                    <IconButton
                                      onClick={() =>
                                        navigate(`${PLANS_AVAILABLE}/${mac}`)
                                      }
                                    >
                                      <CurrencyExchangeIcon
                                        color="success"
                                        fontSize="large"
                                      />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={() =>
                                        handleOpenModalDelete(
                                          planIdSubscription,
                                          device.name
                                        )
                                      }
                                    >
                                      <MoneyOffIcon
                                        color="error"
                                        fontSize="large"
                                      />
                                    </IconButton>
                                  )}
                                </>
                              )}
                              {checkRenewDate === true &&
                                (payloadPlan?.recorrencia === undefined ||
                                  checkIfPlanIsRecurrentIsCanceled ===
                                    true) && (
                                  <IconButton
                                    onClick={() =>
                                      payloadPlan?.plan?.hidden === true
                                        ? navigate(`${PLANS_AVAILABLE}/${mac}`)
                                        : navigate(
                                            `${HIRE_SUBSCRIPTION}/${mac}/${actualPlanId}`
                                          )
                                    }
                                  >
                                    <PublishedWithChangesIcon
                                      color="info"
                                      fontSize="large"
                                    />
                                  </IconButton>
                                )}
                            </Stack>
                          ) : (
                            <Stack
                              direction="column"
                              spacing={1}
                              sx={{ placeContent: "center" }}
                            >
                              {!checkIfPlanIsRecurrent && (
                                <Button
                                  variant="contained"
                                  color="success"
                                  size="small"
                                  onClick={() =>
                                    navigate(`${PLANS_AVAILABLE}/${mac}`)
                                  }
                                  sx={{ fontSize: 11 }}
                                >
                                  {t("TEXT.ALTER_PLAN")}
                                </Button>
                              )}
                              {checkIfPlanIsRecurrent && (
                                <>
                                  {checkIfPlanIsRecurrentIsCanceled === true ? (
                                    <Button
                                      variant="contained"
                                      color="success"
                                      size="small"
                                      onClick={() =>
                                        navigate(`${PLANS_AVAILABLE}/${mac}`)
                                      }
                                      sx={{ fontSize: 11 }}
                                    >
                                      {t("TEXT.ALTER_PLAN")}
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="error"
                                      onClick={() =>
                                        handleOpenModalDelete(
                                          planIdSubscription,
                                          device.name
                                        )
                                      }
                                      sx={{ fontSize: 11 }}
                                    >
                                      {t("TEXT.UNSUBSCRIBE")}
                                    </Button>
                                  )}
                                </>
                              )}
                              {checkRenewDate === true &&
                                (payloadPlan?.recorrencia === undefined ||
                                  checkIfPlanIsRecurrentIsCanceled ===
                                    true) && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    onClick={() =>
                                      payloadPlan?.plan?.hidden === true
                                        ? navigate(`${PLANS_AVAILABLE}/${mac}`)
                                        : navigate(
                                            `${HIRE_SUBSCRIPTION}/${mac}/${actualPlanId}`
                                          )
                                    }
                                    sx={{
                                      fontSize: 11,
                                      mt: 1.5,
                                      pl: 2.5,
                                      pr: 2.5,
                                    }}
                                  >
                                    {t("TEXT.RENEW_SUBSCRIPTION")}
                                  </Button>
                                )}
                            </Stack>
                          )}
                        </Box>
                      ) : (
                        <Box sx={{ textAlign: "center" }} mt={1}>
                          {mobile ? (
                            <Stack sx={{ placeContent: "center" }}>
                              <IconButton
                                onClick={() =>
                                  navigate(`${PLANS_AVAILABLE}/${mac}`)
                                }
                              >
                                <CurrencyExchangeIcon
                                  color="info"
                                  fontSize="large"
                                />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack
                              direction="column"
                              spacing={1}
                              sx={{ placeContent: "center" }}
                            >
                              <Button
                                variant="contained"
                                color="info"
                                size="small"
                                onClick={() =>
                                  navigate(`${PLANS_AVAILABLE}/${mac}`)
                                }
                                sx={{ fontSize: 11 }}
                              >
                                {t("TEXT.SUBSCRIBE_TO_A_PLAN")}
                              </Button>
                            </Stack>
                          )}
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box mt={1}>
                      {mobile ? (
                        <Stack sx={{ placeContent: "center" }}>
                          <IconButton
                            onClick={() =>
                              navigate(`${PLANS_AVAILABLE}/${mac}`)
                            }
                          >
                            <CachedIcon color="info" fontSize="large" />
                          </IconButton>
                        </Stack>
                      ) : (
                        <Stack sx={{ placeContent: "center", pl: 6, pr: 6 }}>
                          <Button
                            variant="contained"
                            color="info"
                            size="small"
                            onClick={() =>
                              navigate(`${PLANS_AVAILABLE}/${mac}`)
                            }
                            sx={{ fontSize: 11 }}
                          >
                            {t("BUTTON.REACTIVATE_PLAN")}
                          </Button>
                        </Stack>
                      )}
                    </Box>
                  )}
                </CardContent>
              </Card>
            </>
          )}
        </Box>
      </Box>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        {loading ? (
          <Box m="126px 0px">
            <Spinner />
          </Box>
        ) : (
          device && (
            <>
              <Paper
                sx={{
                  p: mobile ? 0 : 3.5,
                  borderRadius: 3,
                  mb: 3,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  pt={mobile ? 2 : 0}
                  pl={mobile ? 2 : 0}
                  pb={1.5}
                >
                  <Box sx={{ mr: 1 }}>
                    <AttachMoneyIcon fontSize="large" />
                  </Box>
                  <Typography fontSize={mobile ? 23 : 28} gutterBottom>
                    {t("TEXT.PAYMENT_HISTORY")}
                  </Typography>
                </Box>
                <Paper
                  style={{
                    height: mobile ? 400 : 440,
                  }}
                  elevation={0}
                >
                  {paymentSort && (
                    <DataGridTable
                      rows={rowsHistory}
                      columns={columnsHistory}
                      message={t("TEXT.NO_PAYMENTS")}
                      getRowHeight={() => "auto"}
                      pageSize={8}
                      rowsPerPageOptions={[10]}
                    />
                  )}
                </Paper>
              </Paper>
              <Paper
                sx={{
                  p: mobile ? 0 : 3.5,
                  borderRadius: 3,
                  mb: 3,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  pt={mobile ? 2 : 0}
                  pl={mobile ? 2 : 0}
                  pb={1.5}
                >
                  <Box sx={{ ml: 0.5, mr: 1.8 }}>
                    <ReceiptOutlinedIcon fontSize="large" />
                  </Box>
                  <Typography fontSize={mobile ? 23 : 28} gutterBottom>
                    {t("TEXT.OUTSTANDING_PAYMENTS")}
                  </Typography>
                </Box>
                <Paper
                  style={{
                    height: mobile ? 400 : 440,
                  }}
                  elevation={0}
                >
                  {pendingInvoicesSort && (
                    <DataGridTable
                      rows={rows}
                      columns={columns}
                      message={t("TEXT.NO_PAYMENTS_OUTSTANDING")}
                      getRowHeight={() => "auto"}
                      pageSize={8}
                      rowsPerPageOptions={[10]}
                      getRowClassName={(params: GridRowParams) => {
                        return params.row.paymentError === true
                          ? "highlight"
                          : "";
                      }}
                    />
                  )}
                </Paper>
              </Paper>
            </>
          )
        )}
      </Grid>
      {device && (
        <ModalBenefits
          benefits={benefits}
          handleClose={handleCloseModal}
          handleOpen={openModal}
        />
      )}
      {modalDeleteValues && (
        <ModalUnsubscribe
          id={modalDeleteValues.id}
          device={modalDeleteValues.device}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
          setSignatureEdited={setSignatureEdited}
        />
      )}
      {modalDeletePendingValues && (
        <ModalRemove
          row={modalDeletePendingValues.row}
          handleOpen={openModalDeletePending}
          handleClose={handleCloseModalDeletePending}
          setPlanEdited={setPlanEdited}
        />
      )}
    </Grid>
  );
};

export default PlanDetails;
