import React from "react";
import { useTranslation } from "react-i18next";

import { LockOpenOutlined as LockOpenOutlinedIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography, Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContext } from "../../../contexts/ToastContext";
import { red } from "@mui/material/colors";
import {
  PasswordVisibility,
  yupRequired,
  yupStringMax,
} from "../../../components/Form";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { TOKEN_KEY } from "../../../auth/constants";
import ErrorMessage from "../../../components/ErrorMessage";
import { Login } from "../../../services/types/auth";
import Modal from "../../Devices/Modal";
import { sectorLock } from "../../../services/data/sector";
import Spinner from "../../../components/Spinner";

interface ModalSwitchLockProps {
  data: any;
  checked: boolean;
  setChecked: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
  setSectorEdited: (e: boolean) => void;
}

const ModalSwitchLock = ({
  data,
  handleOpen,
  checked,
  setChecked,
  handleClose,
  setSectorEdited,
}: ModalSwitchLockProps) => {
  const { t } = useTranslation();
  const { signIn, user, token, authenticated } = React.useContext(AuthContext);
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const maxLength = 20;

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: yupRequired(yupStringMax(maxLength)),
    }),
    onSubmit: (values) => {
      if (authenticated) {
        setLoading(true);
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const validLogin: Login = {
          login: user.login,
          password: values.password,
        };

        // Após digitar a senha da conta, desbloquear alterações do setor
        signIn(validLogin).then(async (success) => {
          if (success) {
            sectorLock(data.id, { locked: !checked })
              .then(() => {
                toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
                setLoading(false);
                setChecked(!checked);
                setSectorEdited(true);
                handleClose();
              })
              .catch(() => {
                toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
                setLoading(false);
              });
          } else {
            toastError(t("TOAST.ERROR.INVALID_PASSWORD"));
            setLoading(false);
          }
        });
      }
    },
  });

  const isFormValid = formik.dirty && formik.isValid && !loading;

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <LockOpenOutlinedIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.UNLOCK_CHANGES")}
      </Typography>
      <Typography sx={{ mt: 1 }} variant="body2">
        {t("TEXT.ENTER_PASSWORD")}:
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <PasswordVisibility
            formik={formik}
            disabled={false}
            onClick={(e: any) => e.stopPropagation()}
            label={t("TEXT.PASSWORD")}
            name="password"
            fullWidth
            maxLength={maxLength}
          />
          {formik.errors.password && formik.touched.password ? (
            <ErrorMessage>{formik.errors.password}</ErrorMessage>
          ) : null}
        </Box>
        <Button
          variant="contained"
          color="error"
          type="submit"
          disabled={!isFormValid}
          fullWidth
          sx={{
            mt: 3,
            borderRadius: 2,
            backgroundColor: red[400],
            fontSize: 12,
            pt: 1,
            pb: 1,
          }}
        >
          {loading === true ? (
            <Spinner disableMargin size={20} />
          ) : (
            t("TEXT.UNLOCK")
          )}
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          sx={{
            backgroundColor: red[50],
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </form>
    </Modal>
  );
};

export default ModalSwitchLock;
