import { useTranslation } from "react-i18next";

import {
  Alert,
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  Loyalty as LoyaltyIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { red } from "@mui/material/colors";
import Modal from "../../Devices/Modal";
import useIsMobile from "../../../hooks/useIsMobile";

interface ModalBenefitsProps {
  benefits: string[];
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalBenefits = ({
  benefits,
  handleOpen,
  handleClose,
}: ModalBenefitsProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 390}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <LoyaltyIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700}>
        {t("TEXT.BENEFITS")}:
      </Typography>
      <Box>
        <Box
          display="grid"
          mt={1}
          sx={{
            maxHeight: mobile ? 300 : 360,
            overflow: benefits?.length < 5 ? "hidden" : "scroll",
            overflowX: "hidden",
          }}
        >
          <List>
            {benefits && benefits.length > 0 ? (
              benefits.map((m, i: number) => (
                <ListItem key={i} sx={{ pl: 1 }}>
                  <ListItemIcon sx={{ mr: -2 }}>
                    <CheckIcon color="success" />
                  </ListItemIcon>
                  <ListItemText primary={m} />
                </ListItem>
              ))
            ) : (
              <Box display="flex" justifyContent="center">
                <Box />
                <Alert
                  severity="info"
                  sx={{
                    textAlign: "center",
                    placeContent: "center",
                    width: 290,
                  }}
                >
                  {t("TEXT.NO_BENEFITS")}
                </Alert>
                <Box />
              </Box>
            )}
          </List>
        </Box>
        <Box display="grid" mt={2}>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: red[50],
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.RETURN")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalBenefits;
