import React from "react";
import { useTranslation } from "react-i18next";

import { Share as ShareIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";

import { ToastContext } from "../../../contexts/ToastContext";
import { AccepsSharedDevice } from "../../../services/data/arcsys";
import Modal from ".";
import { red } from "@mui/material/colors";

interface ModalRemoveProps {
  id: string;
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalAcceptShared = ({
  id,
  setDeviceEdited,
  handleOpen,
  handleClose,
}: ModalRemoveProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);

  const handleAccept = () => {
    // Aceitar o compartilhamento do Dispositivo
    AccepsSharedDevice(id, {
      shared: true,
    })
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.DEVICE_ACCEPTED"));
        handleClose();
        setDeviceEdited(true);
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.DEVICE_ACCEPTED"));
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <ShareIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.ACCEPT_SHARING")}?
      </Typography>
      <Button
        variant="contained"
        color="error"
        onClick={handleAccept}
        fullWidth
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: red[400],
          fontSize: 12,
          pt: 1,
          pb: 1,
          mb: 1,
        }}
      >
        {t("BUTTON.CONFIRM")}
      </Button>
    </Modal>
  );
};

export default ModalAcceptShared;
