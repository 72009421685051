import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  MobileStepper,
  Tooltip,
  FormControlLabel,
} from "@mui/material";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import { useFormik } from "formik";
import {
  TextField,
  yupBoolean,
  yupRequiredString,
} from "../../../components/Form";
import * as Yup from "yup";
import { editConfig } from "../../../services/data/arcsys";
import { ToastContext } from "../../../contexts/ToastContext";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";
import StepperCustom from "../SteppComponent";
import Switch from "../../../components/Switch";
import ErrorMessage from "../../../components/ErrorMessage";
import { convertTime, convertToTs } from "../parse";
import FormInputs from "./FormInputs";

interface FormikProps {
  config: any;
  setConfigEdited: (e: boolean) => void;
}

const Form = ({ config, setConfigEdited }: FormikProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastSuccess, toastError } = React.useContext(ToastContext);
  const [activeStep, setActiveStep] = React.useState(0);

  // Tempo entre alertas de MQTT Broker offline
  const [brokerAlertTime, setBrokerAlertTime] = React.useState({
    hours: +convertTime(+config?.brokerAlertTime).hours,
    minutes: +convertTime(+config?.brokerAlertTime).minutes,
    seconds: +convertTime(+config?.brokerAlertTime).seconds,
  });

  // Tempo para controlador ficar inativo
  const [ctrlInactiveTime, setCtrlInactiveTime] = React.useState({
    hours: +convertTime(+config?.ctrlInactiveTime).hours,
    minutes: +convertTime(+config?.ctrlInactiveTime).minutes,
    seconds: +convertTime(+config?.ctrlInactiveTime).seconds,
  });

  // Tempo para esconder controlador do dashboard:
  const [ctrlHideTime, setCtrlHideTime] = React.useState({
    hours: +convertTime(+config?.ctrlHideTime).hours,
    minutes: +convertTime(+config?.ctrlHideTime).minutes,
    seconds: +convertTime(+config?.ctrlHideTime).seconds,
  });

  // Tempo para dispositivo ficar offline
  const [arcsysOfflineTime, setArcsysOfflineTime] = React.useState({
    hours: +convertTime(+config?.arcsysOfflineTime).hours,
    minutes: +convertTime(+config?.arcsysOfflineTime).minutes,
    seconds: +convertTime(+config?.arcsysOfflineTime).seconds,
  });

  // Tempo padrão para reenvio de alerta:
  const [alertTime, setAlertTime] = React.useState({
    hours: +convertTime(+config?.alertTime).hours,
    minutes: +convertTime(+config?.alertTime).minutes,
    seconds: +convertTime(+config?.alertTime).seconds,
  });

  // Intervalo padrão de gravação de dados
  const [eventSaveIntervalDefault, setEventSaveIntervalDefault] =
    React.useState({
      hours: +convertTime(+config?.eventSaveIntervalDefault).hours,
      minutes: +convertTime(+config?.eventSaveIntervalDefault).minutes,
      seconds: +convertTime(+config?.eventSaveIntervalDefault).seconds,
    });

  // Intervalo máximo de gravação de dados
  const [eventSaveIntervalMax, setEventSaveIntervalMax] = React.useState({
    hours: +convertTime(+config?.eventSaveIntervalMax).hours,
    minutes: +convertTime(+config?.eventSaveIntervalMax).minutes,
    seconds: +convertTime(+config?.eventSaveIntervalMax).seconds,
  });

  const formik = useFormik({
    initialValues: {
      ageonEmail: (config?.ageonEmail as string) ?? "",
      usersPerArcsys: (config?.usersPerArcsys as string) ?? "",
      validateMACs: (config?.validateMACs as boolean) ?? false,
    },
    validationSchema: Yup.object().shape({
      ageonEmail: yupRequiredString,
      usersPerArcsys: yupRequiredString,
      validateMACs: yupBoolean,
    }),

    onSubmit: (values) => {
      const data = {
        ageonEmail: values.ageonEmail,
        alertTime: convertToTs(alertTime),
        arcsysOfflineTime: convertToTs(arcsysOfflineTime),
        brokerAlertTime: convertToTs(brokerAlertTime),
        ctrlHideTime: convertToTs(ctrlHideTime),
        ctrlInactiveTime: convertToTs(ctrlInactiveTime),
        eventSaveIntervalDefault: convertToTs(eventSaveIntervalDefault),
        eventSaveIntervalMax: convertToTs(eventSaveIntervalMax),
        usersPerArcsys: values.usersPerArcsys,
        validateMACs: values.validateMACs,
        __v: config?._v,
        _id: config?._id,
      };

      // Rota para editar as configurações do sistema
      editConfig(data)
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
          setConfigEdited(true);
          setActiveStep(0);
          setSubmit(false);
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
        });
    },
  });

  // Ação de mudar para o stepp anterior
  const handleBackStepp = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Ação de mudar para o próximo stepp
  const handleNextStepp = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Ação de finalizar o envio do formulário
  const [submit, setSubmit] = React.useState<boolean>(false);
  const handleSubmit = () => {
    setSubmit(true);
  };

  return (
    <Box
      sx={{
        maxHeight: mobile ? 650 : window.screen.width < 1600 ? 600 : 1200,
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        {mobile ? (
          <>
            <MobileStepper
              steps={2}
              position="static"
              activeStep={activeStep}
              sx={{ mt: -2 }}
              nextButton={
                activeStep === 1 ? (
                  <Button
                    type={!submit ? "button" : "submit"}
                    onClick={handleSubmit}
                    sx={{ mr: 1 }}
                  >
                    {t("BUTTON.SAVE")}
                  </Button>
                ) : (
                  <Button onClick={handleNextStepp} sx={{ mr: 1 }}>
                    <Box display="flex">
                      <Box fontWeight={700}>{t("TEXT.NEXT")}</Box>
                      <KeyboardArrowRight />
                    </Box>
                  </Button>
                )
              }
              backButton={
                activeStep !== 0 && (
                  <Button onClick={handleBackStepp} sx={{ mr: 1 }}>
                    <Box display="flex">
                      <KeyboardArrowLeft />
                      <Box fontWeight={700}>{t("BUTTON.RETURN")}</Box>
                    </Box>
                  </Button>
                )
              }
            />
            <Typography fontSize={21} fontWeight={700} mb={1}>
              {t("TEXT.ADD")} {t("TEXT.SECTOR")}
            </Typography>
          </>
        ) : (
          <StepperCustom activeStep={activeStep} />
        )}
        {activeStep === 0 ? (
          <Box display="grid">
            <InputLabel sx={{ mb: 1, mt: -1 }}>
              {t("TEXT.VALIDATE_MAC_ADDRESSES")}:
            </InputLabel>
            <Tooltip title="">
              <FormControlLabel
                sx={{ m: 0 }}
                control={
                  <Switch
                    name="validateMACs"
                    checked={formik.values.validateMACs}
                    onChange={formik.handleChange}
                    sx={{ mr: 1 }}
                  />
                }
                label={t("TEXT.DISABLE_ENABLE")}
              />
            </Tooltip>
            <Box display={mobile ? "grid" : "flex"}>
              <Box flex="100%" mr={mobile ? 0 : 2}>
                <InputLabel sx={{ mb: 1.5, mt: 2 }}>
                  {t("TEXT.SETTINGS_EMAIL_MESSAGE")}:
                </InputLabel>
                <Box display="grid">
                  <TextField
                    formik={formik}
                    disabled={false}
                    label="E-mail"
                    name="ageonEmail"
                    maxLength={120}
                    fullWidth
                  />
                  {formik.errors.ageonEmail && formik.touched.ageonEmail ? (
                    <ErrorMessage>{formik.errors.ageonEmail}</ErrorMessage>
                  ) : null}
                </Box>
              </Box>
              <Box flex="100%">
                <InputLabel sx={{ mb: 1.5, mt: 2 }}>
                  {t("TEXT.SETTINGS_MAX_USERS")}:
                </InputLabel>
                <Box display="grid">
                  <TextField
                    formik={formik}
                    disabled={false}
                    label={t("TEXT.USERS")}
                    name="usersPerArcsys"
                    type="number"
                    fullWidth
                  />
                  {formik.errors.usersPerArcsys &&
                  formik.touched.usersPerArcsys ? (
                    <ErrorMessage>{formik.errors.usersPerArcsys}</ErrorMessage>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <FormInputs
              title={`${t("TEXT.SETTINGS_TIME_MQTT")}:`}
              hours={String(brokerAlertTime.hours)}
              minutes={String(brokerAlertTime.minutes)}
              seconds={String(brokerAlertTime.seconds)}
              setValue={setBrokerAlertTime}
            />
          </Box>
        ) : (
          <>
            <FormInputs
              title={`${t("TEXT.SETTINGS_TIME_CTRL_INACTIVE")}:`}
              hours={String(ctrlInactiveTime.hours)}
              minutes={String(ctrlInactiveTime.minutes)}
              seconds={String(ctrlInactiveTime.seconds)}
              setValue={setCtrlInactiveTime}
            />
            <FormInputs
              title={`${t("TEXT.SETTINGS_TIME_CTRL_DASHBOARD")}:`}
              hours={String(ctrlHideTime.hours)}
              minutes={String(ctrlHideTime.minutes)}
              seconds={String(ctrlHideTime.seconds)}
              setValue={setCtrlHideTime}
            />
            <FormInputs
              title={`${t("TEXT.SETTINGS_TIME_DEVICE_OFFLINE")}:`}
              hours={String(arcsysOfflineTime.hours)}
              minutes={String(arcsysOfflineTime.minutes)}
              seconds={String(arcsysOfflineTime.seconds)}
              setValue={setArcsysOfflineTime}
            />
            <FormInputs
              title={`${t("TEXT.SETTINGS_ALERT_DEFAULT")}:`}
              hours={String(alertTime.hours)}
              minutes={String(alertTime.minutes)}
              seconds={String(alertTime.seconds)}
              setValue={setAlertTime}
            />
            <FormInputs
              title={`${t("TEXT.SETTINGS_DEFAULT_DATA")}:`}
              hours={String(eventSaveIntervalDefault.hours)}
              minutes={String(eventSaveIntervalDefault.minutes)}
              seconds={String(eventSaveIntervalDefault.seconds)}
              setValue={setEventSaveIntervalDefault}
            />
            <FormInputs
              title={`${t("TEXT.SETTINGS_MAX_DATA_INTERVAL")}:`}
              hours={String(eventSaveIntervalMax.hours)}
              minutes={String(eventSaveIntervalMax.minutes)}
              seconds={String(eventSaveIntervalMax.seconds)}
              setValue={setEventSaveIntervalMax}
            />
          </>
        )}
        {!mobile && (
          <Box
            mt={3}
            display="flex"
            justifyContent={activeStep !== 1 ? "right" : "space-between"}
            pl={3}
            pr={3}
          >
            {activeStep !== 0 && (
              <Button
                variant="outlined"
                startIcon={<ArrowLeftIcon />}
                onClick={handleBackStepp}
                size={mobile ? "small" : "medium"}
                sx={{ mr: 2 }}
              >
                {t("TEXT.PREVIOUS")}
              </Button>
            )}
            {activeStep === 1 ? (
              <Button
                type={!submit ? "button" : "submit"}
                onClick={handleSubmit}
                variant="contained"
                size={mobile ? "small" : "medium"}
              >
                {t("BUTTON.CONFIRM")}
              </Button>
            ) : (
              <Button
                size={mobile ? "small" : "medium"}
                variant="contained"
                onClick={handleNextStepp}
                endIcon={<ArrowRightIcon />}
              >
                {t("TEXT.NEXT")}
              </Button>
            )}
          </Box>
        )}
      </form>
    </Box>
  );
};

export default Form;
