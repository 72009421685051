import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

interface ErrorMessageProps {
  children: string;
}

const ErrorMessage = ({ children }: ErrorMessageProps) => {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" color="error">
      {t(children)}
    </Typography>
  );
};

export default ErrorMessage;
