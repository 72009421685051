import { Avatar } from "@mui/material";

interface AvatarIconProps {
  name: string;
  width?: number;
  height?: number;
  fontSize?: number;
  bgcolor: string;
  border?: string;
  color?: string;
}

const AvatarIcon = ({
  name,
  width,
  height,
  bgcolor,
  fontSize,
  border,
  color,
}: AvatarIconProps) => {
  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: bgcolor,
        width: width,
        height: height,
        fontSize: fontSize,
        border: border,
        color: color,
      },
      children: `${name.toUpperCase().split(" ")[0][0]}`,
    };
  };

  return <Avatar {...stringAvatar(name)} />;
};
export default AvatarIcon;
