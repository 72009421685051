import React from "react";
import { useTranslation } from "react-i18next";

import { Edit as EditIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { getCtrlOption } from "../../../../config";
import { ControllerEditContext } from "../../../../contexts/ControllerEditContext";
import { Param } from "../../../../services/types/ctrls";
import ErrorMessage from "../../../ErrorMessage";
import { Select, yupNumberMinMax, yupRequiredStringMax } from "../../../Form";
import { applyDivider } from "../../parser/utils";
import Modal from "./Modal";
import { useParams } from "react-router-dom";
import { SetRequestCtrlData } from "../../../../services/data/ctrls";
import { red } from "@mui/material/colors";
import { AuthContext } from "../../../../contexts/AuthContext";

const ModalEdit = () => {
  const { t } = useTranslation();
  const maxLength = 15;

  const { client } = React.useContext(AuthContext);
  const { paramToEdit, setParamToEdit } = React.useContext(
    ControllerEditContext
  );

  const initialValue = paramToEdit?.value;
  const description = paramToEdit?.description;
  const unitOfMeasurement = paramToEdit?.unitOfMeasurement;
  const divider = paramToEdit?.divider ?? 0;
  const min = applyDivider(paramToEdit?.min ?? 0, divider);
  const max = applyDivider(paramToEdit?.max ?? 0, divider);
  const type = paramToEdit?.type ?? "";
  const checkMin =
    divider === 0 || min.toString().includes(".") ? min : min + ".0";
  const checkMax =
    divider === 0 || max.toString().includes(".") ? max : max + ".0";
  // Valor inicial do formulário
  const checkFormikValue: string | number | undefined =
    initialValue === "oFF" && type.includes("OFF")
      ? "oFF"
      : divider === 0 || initialValue?.toString().includes(".")
      ? initialValue
      : initialValue + ".0";
  const separator = paramToEdit?.separator;
  const param = paramToEdit?.param as unknown as Param;
  const params = useParams();

  const open = initialValue !== undefined;
  const range = [min, max, separator].some((e) => e === undefined)
    ? ""
    : `${checkMin}${separator}${checkMax}`;

  const handleClose = () => {
    setParamToEdit(undefined);
  };

  // Submit das edições de cada parâmetro do Controlador
  const formik = useFormik({
    initialValues: {
      value: checkFormikValue ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      value:
        param === "rotulo"
          ? yupRequiredStringMax(maxLength)
          : yupNumberMinMax(min, max),
    }),
    onSubmit: (values) => {
      if (open) {
        // Editar parâmetro da página do Controlador
        SetRequestCtrlData(params, paramToEdit, values, client);
        handleClose();
      }
    },
  });

  const handleNumOff = () => {
    if (open && paramToEdit) {
      const value: any = {
        value: type === "NUMOFF" ? min - 1 : min,
      };
      SetRequestCtrlData(params, paramToEdit, value, client);
      handleClose();
    }
  };

  const checkIsDisabled = formik.values.value === 0 && type.includes("OFF");
  // Checar se o número no input pode aceitar valores fracionados
  const checkDivider =
    divider === 0 && formik.values.value.toString().includes(".");

  // Checar se o rótulo contém caracter especial
  const checkParamRotulo =
    // eslint-disable-next-line no-useless-escape
    formik.values.value.toString()?.match(/[^0-9a-zA-Z:\-_.,\{\}\"\' ]/gi) ===
    null;

  // Validação final do modal
  const isFormValid =
    param === "rotulo"
      ? !formik.isSubmitting &&
        formik.dirty &&
        formik.isValid &&
        !checkDivider &&
        checkParamRotulo
      : !formik.isSubmitting && formik.dirty && formik.isValid && !checkDivider;

  const options = getCtrlOption(`d${type}`) ?? getCtrlOption(type);
  const optionsMap =
    options &&
    Object.entries(options).map(([key, label]) => (
      <MenuItem key={key} value={key}>
        {label as string}
      </MenuItem>
    ));
  const showRange = !!range && !options;

  const Input = !options ? (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{t("TEXT.VALUE")}</InputLabel>
      <OutlinedInput
        name="value"
        value={formik.values.value}
        onChange={formik.handleChange}
        disabled={formik.isSubmitting}
        endAdornment={
          <InputAdornment position="end">{unitOfMeasurement}</InputAdornment>
        }
        label={t("TEXT.VALUE")}
      />
      {showRange && (
        <FormHelperText>{`${range} ${unitOfMeasurement}`}</FormHelperText>
      )}
    </FormControl>
  ) : (
    <Select
      formik={formik}
      label={t("TEXT.VALUE")}
      name="value"
      options={optionsMap}
    />
  );

  return (
    <Modal open={open} handleClose={handleClose} width={350}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {description}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          {Input}
          {formik.errors.value && formik.touched.value ? (
            <ErrorMessage>{formik.errors.value}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={checkIsDisabled ? isFormValid : !isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: red[400],
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          {type.includes("OFF") && (
            <Button
              onClick={handleNumOff}
              fullWidth
              disabled={formik.values.value === "oFF"}
              sx={{
                backgroundColor: red[50],
                mt: 1,
                borderRadius: 2,
                fontSize: 12,
                pt: 1,
                pb: 1,
                color: "black",
              }}
            >
              {t("BUTTON.DISABLE")}
            </Button>
          )}
        </Box>
      </form>
    </Modal>
  );
};

export default React.memo(ModalEdit);
