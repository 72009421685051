import { SnackbarKey } from "notistack";
import { NO_DATA_VALUE } from "../../components/Controller/constants";
import { getSectorsByIdSector } from "../../services/data/sector";
import { getCtrlConfig } from "../../components/Controller/parser";
import { applyDivider } from "../../components/Controller/parser/utils";
import { CardData } from "../../contexts/ControllerContext";
import { CtrlConfig } from "../../components/Controller/parser/types";

// Checar se há erro de comunicação nas medidas inteiras
export const checkIfIsComIntegar = (
  valueMeasure: number | string,
  nullValue: string
) => (valueMeasure === NO_DATA_VALUE ? nullValue : `${valueMeasure}.0`);

// Checar se há erro de comunicação nas medidas não inteiras
export const checkIfIsComNotIntegar = (
  valueMeasure: number | string,
  nullValue: string
) =>
  valueMeasure === 3276.7 || valueMeasure === NO_DATA_VALUE
    ? nullValue
    : valueMeasure;

// Checar se há erro de comunicação na média das medidas
export const checkIfIsComNotAvg = (valueMeasure: number) =>
  valueMeasure === 3276.7 || valueMeasure === NO_DATA_VALUE
    ? "--"
    : valueMeasure.toFixed(1);

// Checar se há erro de comunicação nas unidades média das medidas
export const checkIfIsComUnitAvg = (
  valueMeasure: number,
  unit: string | undefined
) => (valueMeasure === 3276.7 || valueMeasure === NO_DATA_VALUE ? "" : unit);

// Checar se há erro de comunicação nas unidades das medidas
export const checkIfIsComUnit = (
  valueMeasure: number | string,
  unit: string | undefined
) => (valueMeasure === 3276.7 || valueMeasure === NO_DATA_VALUE ? "" : unit);

// Valor do setpoint com unidade no Gauge
export const valueWithUnit = (value: number, unit: string) => {
  return value?.toFixed(1) + unit;
};

// Ação de aplicar o interval das 'Ações' e do 'Aplicar'
export const getSectors = (
  data: any,
  interval: NodeJS.Timer,
  isIntervalActive: React.MutableRefObject<boolean>,
  setSubmitting: (e: boolean) => void,
  toastSuccess: (message: string) => SnackbarKey,
  toastError: (message: string) => SnackbarKey,
  setSectorEdited: (e: boolean) => void,
  errorMessage: string,
  errorMessage2: string,
  successMessage: string
) => {
  try {
    // Resgatar os setores por id do setor
    getSectorsByIdSector(data.id)
      .then((res) => {
        const { data } = res;

        // Se comand_control.running for igual a finished, cancelar o interval
        if (data.comand_control.running === "finished") {
          // Cancelar o interval
          clearInterval(interval);
          isIntervalActive.current = false;
          setSubmitting(false); // Cancelar spinner no botão

          // Varrer a lista de devices do setor e verificar o last_exec_status
          const devicesLastStatus = data.devices.filter(
            (f: any) => f.last_exec_status === false
          );

          if (!isIntervalActive.current) {
            // Se todos forem true, informar sucesso para o usuário
            if (devicesLastStatus.length === 0) {
              toastSuccess(successMessage);
              setSectorEdited(true);
              // Se houver qualquer um com false, informar erro ao usuário
            } else {
              toastError(`${errorMessage2}: ${devicesLastStatus.length}`);
              setSectorEdited(true);
            }
          }
        }
      })
      .catch((e) => {
        if (e?.response?.status === 404) clearInterval(interval);

        toastError(errorMessage);
        setSectorEdited(true);
      });
  } catch (error) {
    console.log(error);
  }
};

// Checar se valor é inteiro
export const checkIfIsInt = (value: string) =>
  value.includes(".") ? value : `${value}.0`;

// Filtrar o dispositivo do setor
const filterDeviceSector = (value: any, device: any) =>
  value.devices?.filter((f: any) => f.ctrlId.split("/")[1] === device.ctrl)[0];

// Resgatar o rótulo do setor
export const rotuloSector = (value: any, device: any) =>
  filterDeviceSector(value, device)?.rotulo;

// Filtrar o último status do setor
export const errorSector = (value: any, device: any) =>
  filterDeviceSector(value, device)?.last_exec_status;

// Estrutura com 'MAC/ctrlId'
const ctrlIdStructure = (mac: string, ctrlId: string) => `${mac}/${ctrlId}`;

// Valor do setpoint do setor
export const setpointValue = (value: any, device: any) => {
  // Setpoint do setor
  // Resgatar o index do setpoint do mqtt
  const indexSetpoint: number = value.default_resource
    ?.split("/")[1]
    ?.split("s")[1];

  // Resgatar valor do setpoint
  const setpoint = Object.values(
    value.ctrls.filter(
      (f: any) =>
        ctrlIdStructure(f.mac, f.ctrl) ===
        ctrlIdStructure(device.mac, device.ctrl)
    )[0]?.payload?.setpoints || {}
  )[indexSetpoint];

  // Resgatar dados gerais do card
  const ctrlConfig = getCtrlConfig(value.model);

  // Aplicar o divider no valor do setpoint
  const setpointValue = applyDivider(
    setpoint as number,
    +(ctrlConfig.setpoints[indexSetpoint]?.divider ?? 0)
  );

  return checkIfIsInt(String(setpointValue));
};

// Payload de um card offline com os dados do setor
export const cardNull = (value: any, device: any) => {
  const cardNull: CardData = {
    alarm: {
      code: "COM",
      color: "fundo_cinza",
      description: "offline",
      icon: "" as any,
      id: 65536,
      position: [1],
    },
    color: {
      main: "#9e9e9e",
      header: "#757575",
    },
    measures: {
      mac: value.mac,
      estado: 0,
      ctrl: "",
      lastMsg: 0,
      end: device.ctrl.split("L")[1],
      modelo: value.model,
      rotulo: rotuloSector(value, device),
      alarme: 65536,
      medidas: {
        m0: 0,
        m1: 0,
        m2: 0,
        m3: 0,
        m4: 0,
      },
      setpoints: {
        s0: 0,
        s1: 0,
        s2: 0,
        s3: 0,
        s4: 0,
      },
    },
    icons: [],
  };
  return cardNull;
};

// Remover itens duplicados de uma array pelo o id
export const uniqKeysById = (data: any) => {
  const ids = data.map(({ _id }: any) => _id);
  const filtered = data.filter(
    ({ _id }: any, index: number) => !ids.includes(_id, index + 1)
  );
  return filtered;
};

// Remover itens duplicados de uma array pelo o ctrlId
export const uniqKeysByCtrlId = (data: any) => {
  const ctrlIds = data.map(({ ctrlId }: any) => ctrlId);
  const filtered = data.filter(
    ({ ctrlId }: any, index: number) => !ctrlIds.includes(ctrlId, index + 1)
  );
  return filtered;
};

// Filtrar o nome do dispositivo pelo o MAC
export const filteredNameByMac = (mac: string, devices: any) =>
  devices?.filter((f: any) => f.mac === mac)[0]?.name;

// Resgatar o payload do setpoints do Dispositivo selecionado
export const filteredSetpoints = (
  i: number,
  values: any,
  ctrlConfig: CtrlConfig
) =>
  values.setpoint !== ""
    ? ctrlConfig.setpoints[+values.setpoint - 1]
    : ctrlConfig.setpoints[i];

// Valor mínimo do setpoint
export const min = (i: number, values: any, ctrlConfig: CtrlConfig) =>
  String(filteredSetpoints(i, values, ctrlConfig)?.min);
export const filteredMinValues = (
  i: number,
  values: any,
  ctrlConfig: CtrlConfig,
  rawCtrlConfig: any
) =>
  applyDivider(
    min(i, values, ctrlConfig).includes("detalhamento")
      ? rawCtrlConfig.detalhamento[
          min(i, values, ctrlConfig).split("detalhamento")[1]
        ][1]
      : filteredSetpoints(i, values, ctrlConfig)?.min,
    filteredSetpoints(i, values, ctrlConfig)?.divider ?? 0
  );

// Valor máximo do setpoint
export const max = (i: number, values: any, ctrlConfig: CtrlConfig) =>
  String(filteredSetpoints(i, values, ctrlConfig)?.max);
export const filteredMaxValues = (
  i: number,
  values: any,
  ctrlConfig: CtrlConfig,
  rawCtrlConfig: any
) =>
  applyDivider(
    max(i, values, ctrlConfig).includes("detalhamento")
      ? rawCtrlConfig.detalhamento[
          max(i, values, ctrlConfig).split("detalhamento")[1]
        ][2]
      : filteredSetpoints(i, values, ctrlConfig)?.max,
    filteredSetpoints(i, values, ctrlConfig)?.divider ?? 0
  );

// URL do manual do usuário
export const fileURL =
  "https://manuais-arcsys-cloud.s3.sa-east-1.amazonaws.com/Manual+Setores.pdf";
