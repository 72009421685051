import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ExpandMore as ExpandMoreIcon,
  AspectRatioRounded as AspectRatioRoundedIcon,
  Memory as MemoryIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Alert,
  AlertTitle,
  Divider,
  IconButton,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
  OutlinedInput,
} from "@mui/material";
// import Grid from "@mui/material/Unstable_Grid2";

import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import { ArcsysContext } from "../../contexts/ArcsysProviderContext";
import Spinner from "../../components/Spinner";
import { AccordionMacListContext } from "../../contexts/AccordeonOrder";
import { MqttContext } from "../../contexts/MqttContext";
import Controller from "./Controller";
import ModalExpand from "./ModalExpand";
import SearchBar from "./SearchBar";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import { getDevicesOffline } from "../../services/data/arcsys";
import { getDashboardTags } from "../../services/data/TAGs";
import { green } from "@mui/material/colors";
import { DashboardFilterContext } from "../../contexts/DashboardFilterContext";
import ModalHelpTags from "./Modal/ModalHelpTags";
import { TagsContext } from "../../contexts/TagsContext";
import { cardOffline } from "./OfflineCardMock";

let OrderedMacs: any[] = [];
let myDevicesList: any[] = [];
let DevicesList: any[] = [];
let accordionDashboardMacListOrder: any = {};
let expandCollapse = true;
let accordionOrder = 0;
let renderArcsys: any[] = [];

const Devices = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accordionMacList, setAccordionMacList } = React.useContext(
    AccordionMacListContext
  );
  const { devicesOffline, setDevicesOffline, macsToSubscribe } =
    React.useContext(ArcsysContext);
  const {
    tag,
    setTag,
    selectedDevice,
    setSelectedDevice,
    setSearchedDevice,
    searchedDevice,
    dashboardDeviceEdited,
    setDashboardDeviceEdited,
    isOfflineDevicesStored,
    setIsOfflineDevicesStored,
    orderOfRenderGrater,
    setOrderOfRenderGrater,
  } = React.useContext(DashboardFilterContext);
  const { tagsList, setTagsList, globalTagEdited, setGlobalTagEdited } =
    React.useContext(TagsContext);
  const { token, authenticated, userId } = React.useContext(AuthContext);

  const { theme } = React.useContext(ThemeModeContext);
  const { mqtt } = React.useContext(MqttContext);
  const [open, setOpen] = React.useState(false);
  const [actionValue, setActionValue] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState(true);

  const mobile = useIsMobile();
  const mediumPage = useIsMediumPage();

  // Ações de abrir o modal de dúvidas em relação as TAGs
  const [openModalHelp, setOpenModalHelp] = React.useState<boolean>(false);
  const handleOpenModalHelp = () => setOpenModalHelp(true);
  const handleCloseModalHelp = () => {
    setOpenModalHelp(false);
  };

  // Resgatar as TAGs dos controladores
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

      if (tagsList.length === 0 || globalTagEdited === true) {
        getDashboardTags(+userId).then((res) => {
          const { data } = res;
          setGlobalTagEdited(false);
          setTagsList(data);
        });
      }
    }
  }, [
    authenticated,
    globalTagEdited,
    setGlobalTagEdited,
    setTagsList,
    tagsList.length,
    token,
    userId,
  ]);

  // Lista de TAGs
  const optionsTags = tagsList.map((value: any, i: number) => (
    <MenuItem
      value={value.tag_name}
      key={i}
      sx={{ placeContent: "center", borderTop: "1px solid #F0EFEE" }}
    >
      <Chip
        label={value.tag_name}
        color="success"
        avatar={<LocalOfferOutlinedIcon />}
        sx={{
          fontWeight: 700,
          backgroundColor: green[100],
          color: green[800],
          textTransform: "uppercase",
          fontSize: 12,
          "& .MuiChip-avatar": {
            color: "black",
          },
        }}
      />
    </MenuItem>
  ));

  // Ação de adicionar a TAG
  const handleChangeTAG = (event: SelectChangeEvent<typeof tag>) => {
    const {
      target: { value },
    } = event;

    if (value.includes("") === true) {
      setTag([]);
    } else {
      setTag(typeof value === "string" ? value?.split(",") : value);
    }
  };
  // Ação de remover TAG
  const handleDeleteTag = (value: string[] | string) => {
    if (Array.isArray(tag)) {
      setTag(tag.filter((entry: any) => entry !== value));
    } else {
      setTag([]);
    }
  };

  React.useEffect(() => {
    const fetchStatus = () => {
      // Checar se tem Dispositivo
      if (macsToSubscribe?.length === 0) {
        setLoading(false);
      }

      // Verifica se não tiver dados no MQTT
      if (myDevicesList.length !== 0) {
        setLoading(false);
      }
    };
    const statusInterval = setInterval(fetchStatus, 3000);
    return () => clearInterval(statusInterval);
  }, [macsToSubscribe]);

  // Resgatar os dados de cada Dispositivo
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      if (!isOfflineDevicesStored) {
        // Rota para resgatar os dispositivos offline dos arcsys
        getDevicesOffline().then((res) => {
          const { data } = res;
          setDevicesOffline(data);
          setIsOfflineDevicesStored(true);
        });
      }
    }
  }, [
    authenticated,
    devicesOffline?.length,
    isOfflineDevicesStored,
    setDevicesOffline,
    setIsOfflineDevicesStored,
    token,
  ]);

  // Atualizar os dados após a edição dos controladores na página do dispositivo
  React.useEffect(() => {
    if (dashboardDeviceEdited === true) {
      // Rota para resgatar os dispositivos offline dos arcsys
      getDevicesOffline().then((res) => {
        const { data } = res;
        setDevicesOffline(data);
      });
      setDashboardDeviceEdited(false);
    }
  }, [dashboardDeviceEdited, setDashboardDeviceEdited, setDevicesOffline]);

  React.useEffect(() => {
    const fetchStatus = () => {
      if (mqtt !== undefined) {
        DevicesList = [];
        macsToSubscribe?.forEach((mac_name) => {
          if (accordionDashboardMacListOrder[mac_name.mac] === undefined) {
            accordionDashboardMacListOrder[mac_name.mac] = 0;
          }

          const ctrls = mqtt[mac_name.mac]
            ? Object.entries(mqtt[mac_name.mac]).map(([ctrl, payload]) => ({
                ctrl,
                payload,
              }))
            : [];

          // Step 5: Check if the MAC is in the showOffline list
          const showOfflineList = devicesOffline?.find(
            (d: any) => d.mac === mac_name.mac
          )?.lista;

          const listOfCtrls: any[] = [];
          ctrls.forEach((ctrl) => listOfCtrls.push(ctrl));

          showOfflineList?.forEach((offlineCtrl: any) => {
            const isAlreadyAdded = listOfCtrls.some(
              (ctrl) => offlineCtrl.ctrlId.split("/")[1] === ctrl.ctrl
            );
            if (!isAlreadyAdded) {
              listOfCtrls.push({
                ctrl: offlineCtrl.ctrlId.split("/")[1],
                payload: cardOffline(
                  offlineCtrl.model,
                  offlineCtrl.ctrlId.split("/")[1],
                  offlineCtrl.name
                ),
              });
            }
          });

          DevicesList.push({
            id: mac_name.arcsysId,
            order: accordionDashboardMacListOrder[mac_name],
            mac: mac_name.mac,
            isWebfi: listOfCtrls.some((ctrl) => ctrl.ctrl === "CTRL36"),
            name: mac_name.name,
            ctrls: listOfCtrls,
          });
        });

        // Reorder DevicesList based on the number of controllers
        OrderedMacs = DevicesList.sort((mac1, mac2) => {
          let controllersCount1 = mac1.isWebfi
            ? mac1.ctrls?.length - 1
            : mac1.ctrls?.length;
          let controllersCount2 = mac2.isWebfi
            ? mac2.ctrls?.length - 1
            : mac2.ctrls?.length;
          return orderOfRenderGrater
            ? controllersCount2 - controllersCount1
            : controllersCount1 - controllersCount2;
        });

        // Filtrar o payload das TAGs
        const splitTag = tagsList
          .filter((f) => tag.includes(f.tag_name))
          .map((m) => {
            return m?.ctrl_ids;
          })
          .flat();

        // Creating a map to store MAC addresses and their respective controls
        const macControlsMap = new Map();
        splitTag.forEach((m) => {
          const values = m?.split("/");
          const mac = values ? values[0] : "";
          const ctrl = values ? values[1] : "";
          if (!macControlsMap.has(mac)) {
            macControlsMap.set(mac, new Set());
          }
          macControlsMap.get(mac).add(ctrl);
        });

        // Constructing the final array with unique MACs and all their controls
        const filterByMac = Array.from(macControlsMap)
          .map(([mac, controlsSet]) => {
            const device = OrderedMacs.find((device) => device.mac === mac);
            if (!device) return null;

            const ctrls = Array.from(controlsSet)
              .map((ctrl) =>
                device.ctrls.find(
                  (control: { ctrl: any }) => control.ctrl === ctrl
                )
              )
              .filter((ctrl) => ctrl);

            return { ...device, ctrls };
          })
          .filter((device) => device !== null);

        // Filtrar os arcSys selecionados na barra de pesquisa ou pela as TAGs
        renderArcsys =
          tag.length > 0 && splitTag.toString() === ""
            ? []
            : selectedDevice !== "" && splitTag.toString() !== ""
            ? filterByMac.filter((f: any) =>
                (f.name as String)
                  .toUpperCase()
                  .includes(selectedDevice.toUpperCase())
              )
            : selectedDevice !== "" && splitTag.toString() === ""
            ? OrderedMacs.filter((f: any) =>
                (f.name as String)
                  .toUpperCase()
                  .includes(selectedDevice.toUpperCase())
              )
            : splitTag.toString() !== "" && selectedDevice === ""
            ? filterByMac
            : OrderedMacs;

        const filteredRenderArcsys = renderArcsys.filter(
          (f) => f.ctrls?.length > 0
        );

        myDevicesList = filteredRenderArcsys.sort((a, b) => a.order - b.order);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 3000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mqtt,
    selectedDevice,
    tag,
    tagsList,
    orderOfRenderGrater,
    macsToSubscribe,
  ]);

  // Expandir um Accordion específico
  const handleClickAccordion = (e: any, i: number) => {
    e.stopPropagation();
    setActionValue(i);
    setOpen(true);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 60000);
  }, []);

  // Minimizar / Maximizar o Accordion
  const handleAccordionChange = (mac: string) => () => {
    // Checagem se o MAC está selecionado
    setAccordionMacList((prevState) =>
      prevState.includes(mac)
        ? // Se o MAC já foi selecionado, remova ele
          prevState.filter((markerID) => markerID !== mac)
        : // Se o MAC não foi selecionado, adiciona ele
          [...prevState, mac]
    );

    // Sempre que fechar o accordion, incrementa a ordem
    if (
      accordionDashboardMacListOrder[mac] !== undefined &&
      expandCollapse === true
    ) {
      accordionOrder += 1;
      accordionDashboardMacListOrder[mac] = accordionOrder;
    }
  };

  // Abrir/fechar todos os Accordions
  const handleOpenAllAccordions = () => {
    const filteredMacs = myDevicesList.map((m: any) => {
      return m.mac;
    });

    accordionOrder = 0;
    OrderedMacs?.forEach((device) => {
      accordionDashboardMacListOrder[device.mac] = accordionOrder;
    });

    if (accordionMacList.length > 0) {
      setAccordionMacList([]);
      expandCollapse = true;
    } else {
      setAccordionMacList(filteredMacs);
      expandCollapse = false;
    }
  };

  return (
    <>
      <Box display={mediumPage ? "grid" : "flex"} mb={mobile ? 2 : 3}>
        <Box
          minWidth={200}
          maxWidth={750}
          mr={mobile ? 1 : mediumPage ? 0 : 2.5}
          mb={mobile ? 1.5 : mediumPage ? 3 : 0}
          onClick={() => tagsList.length === 0 && handleOpenModalHelp()}
        >
          <FormControl fullWidth size="small" sx={{ mr: 1 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: theme === "dark" ? "#BFBFBF" : "rgba(0, 0, 0, 0.6)",
                },
                pl:
                  tag.length > 0
                    ? "0%"
                    : mobile
                    ? "40%"
                    : mediumPage
                    ? "45%"
                    : "34%",
                pt: mobile || mediumPage ? 0.8 : 0,
              }}
            >
              TAG
            </InputLabel>
            <Select
              multiple
              disabled={tagsList.length === 0}
              value={tag}
              onChange={handleChangeTAG}
              input={<OutlinedInput label="TAG" />}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: mobile ? 400 : 450 },
                },
              }}
              sx={{
                borderRadius: "19px",
                textAlign: "center",
                pt: tag.toString() !== "" ? 0 : 0.35,
                pb: tag.toString() !== "" ? 0 : 0.35,
                mt: mediumPage ? 0.4 : -0.6,
                ml: mobile ? 1 : 0,
                backgroundColor: theme === "dark" ? "#282828" : "#F7F7F7",
                boxShadow:
                  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    whiteSpace: "break-spaces",
                  }}
                >
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      avatar={<LocalOfferOutlinedIcon />}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onDelete={() => handleDeleteTag(value)}
                      color="success"
                      sx={{
                        fontWeight: 700,
                        backgroundColor: green[100],
                        color: green[800],
                        textTransform: "uppercase",
                        fontSize: 12,
                        m: 0.3,
                        "& .MuiChip-avatar": {
                          color: "black",
                        },
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </Box>
              )}
            >
              <MenuItem
                value=""
                disabled={tag.length === 0}
                sx={{ placeContent: "center" }}
              >
                <Typography fontWeight={500}>{t("TEXT.CLEAN")}</Typography>
              </MenuItem>
              {optionsTags}
            </Select>
          </FormControl>
        </Box>
        <Box flex="100%" alignSelf="center">
          <SearchBar
            expandCollapse={expandCollapse}
            handleOpenAllAccordions={handleOpenAllAccordions}
            setSelectedDevice={setSelectedDevice}
            setSearchedDevice={setSearchedDevice}
            searchedDevice={searchedDevice}
            setOrderOfRenderGrater={setOrderOfRenderGrater}
            orderOfRenderGrater={orderOfRenderGrater}
          />
        </Box>
      </Box>
      {loading ? (
        <>
          <Box m="126px 0px">
            <Spinner />
          </Box>
        </>
      ) : myDevicesList.length === 0 ? (
        <>
          <Grid container justifyContent="center">
            <Grid>
              <Alert
                severity="info"
                sx={{
                  alignItems: "center",
                  placeContent: "center",
                  mt: 8,
                }}
              >
                <AlertTitle sx={{ mt: 0.8 }}>
                  {t("TEXT.NO_DEVICES_ONLINE_TITLE")}.
                </AlertTitle>
              </Alert>
            </Grid>
          </Grid>
        </>
      ) : mobile ? (
        <>
          <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 8, md: 12 }}
            >
              {myDevicesList?.map((v, i) => (
                <Grid item xs={2} sm={4} md={4} key={i} pb={mobile ? 0.5 : 0}>
                  <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={!accordionMacList.includes(v.mac)}
                    sx={{
                      p: 1,
                      pl: 0,
                      pr: 0,
                    }}
                    style={{ borderRadius: "20px" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <Tooltip title={t("TEXT.MINIMIZE_MAXIMIZE")}>
                          <IconButton
                            onClick={handleAccordionChange(v.mac)}
                            onChange={handleAccordionChange(v.mac)}
                          >
                            <ExpandMoreIcon
                              sx={{
                                color: theme === "light" ? "default" : "#FFFF",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ margin: 0 }}
                      sx={{
                        "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded":
                          {
                            margin: 0,
                          },
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box mr={1} alignSelf="center">
                          {v.isWebfi && (
                            <Tooltip title="WebFi">
                              <IconButton
                                onClick={() =>
                                  navigate(`/controller/${v.mac}/CTRL36`)
                                }
                                sx={{ cursor: "pointer" }}
                              >
                                <MemoryIcon fontSize="large" color="primary" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {!v.isWebfi && (
                            <>
                              <IconButton
                                onClick={() => null}
                                sx={{ cursor: "default" }}
                              >
                                <MemoryIcon fontSize="large" />
                              </IconButton>
                            </>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignSelf="center"
                        >
                          <Typography fontSize={19} fontWeight={600}>
                            {v.name}
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <Divider sx={{ mb: 3 }} />
                    {!accordionMacList.includes(v.mac) && (
                      <AccordionDetails sx={{ pl: 1, pr: 0 }}>
                        <Grid
                          container
                          spacing={1}
                          sx={{ placeContent: "center" }}
                        >
                          <Controller value={v} />
                        </Grid>
                      </AccordionDetails>
                    )}
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <Box>
          <Grid container spacing={3}>
            {myDevicesList?.map((v, i) => (
              <Grid
                key={i}
                item
                xs={12}
                sm={
                  window.innerWidth < 1150 ||
                  (myDevicesList.length === 1 &&
                    v.ctrls.length > (v.isWebfi ? 5 : 4))
                    ? 12
                    : 6
                }
              >
                <Accordion
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={!accordionMacList.includes(v.mac)}
                  sx={{
                    width: "100%",
                  }}
                  style={{ borderRadius: "20px" }}
                >
                  <AccordionSummary
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(0deg)",
                      },
                    }}
                    expandIcon={
                      <Box display="inline-grid" pl={2}>
                        <Tooltip title={t("TEXT.MINIMIZE_MAXIMIZE")}>
                          <IconButton
                            onClick={handleAccordionChange(v.mac)}
                            onChange={handleAccordionChange(v.mac)}
                          >
                            <ExpandMoreIcon
                              sx={{
                                color: theme === "light" ? "default" : "#FFFF",
                                transform: !accordionMacList.includes(v.mac)
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("TEXT.EXPAND")}>
                          <IconButton
                            onClick={(e) => handleClickAccordion(e, i)}
                          >
                            <AspectRatioRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ margin: 0, cursor: "default" }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box mr={1} alignSelf="center">
                        {v.isWebfi && (
                          <Tooltip title="WebFi">
                            <IconButton
                              onClick={() =>
                                navigate(`/controller/${v.mac}/CTRL36`)
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              <MemoryIcon fontSize="large" color="primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!v.isWebfi && (
                          <>
                            <IconButton
                              onClick={() => null}
                              sx={{ cursor: "default" }}
                            >
                              <MemoryIcon fontSize="large" />
                            </IconButton>
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignSelf="center"
                      >
                        <Typography fontSize={19} fontWeight={600}>
                          {v.name}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <Divider sx={{ mb: 1 }} />
                  {!accordionMacList.includes(v.mac) && (
                    <AccordionDetails sx={{ ml: 3 }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          placeContent:
                            window.innerWidth > 1547 ? "flex-start" : "center",
                        }}
                      >
                        <Controller value={v} />
                      </Grid>
                    </AccordionDetails>
                  )}
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <ModalExpand
        actionValue={actionValue}
        myDevicesList={myDevicesList}
        open={open}
        setOpen={setOpen}
      />
      <ModalHelpTags
        handleOpen={openModalHelp}
        handleClose={handleCloseModalHelp}
      />
    </>
  );
};

export default Devices;
