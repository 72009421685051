import { User } from "../contexts/AuthContext";
import { TOKEN_KEY, USER_KEY } from "./constants";

type UserData = User & {
  token: string;
};

export const storeUserData = async ({ token, login, name }: UserData) => {
  const user = {
    login,
    name,
  };
  localStorage.setItem(TOKEN_KEY, "Bearer " + token);
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const removeUserData = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
};

const isUserValid = (userData: UserData) => !!userData?.login;

export const getUser = (): User => {
  const emptyUser: User = { login: "", name: "" };
  try {
    const userData = JSON.parse(localStorage.getItem(USER_KEY) ?? "{}");

    if (!isUserValid(userData)) {
      removeUserData();
      return emptyUser;
    }

    return userData;
  } catch {
    removeUserData();
    return emptyUser;
  }
};

export const getTokenFromLocalStorage = () => localStorage.getItem(TOKEN_KEY);

export const isTokenValid = () => !!getTokenFromLocalStorage();
