import { LanguageCode } from "../../../../translations/types";

// Converter timestamp em data DD/MM/AAAA
export const convertToDate = (timestamp: number, language: LanguageCode) => {
  const FullDate = new Date(timestamp)?.toLocaleDateString(
    language === "en" ? "en-US" : "pt-BR",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
  );
  const fullDate = `${FullDate}`;
  return fullDate;
};

//  Adicionar 0 antes em númers de 0 a 9
const addZero = (i: any) => {
  if (i < 10) {
    // eslint-disable-next-line no-param-reassign
    i = `0${i}`;
  }
  return i;
};

// Converter o timestamp em horas:minutos:segundos
export const convertToTime = (timestamp: number) => {
  const date = new Date(timestamp);
  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());
  const time = `${hours}:${minutes}h`;
  return time;
};

// Checar se um número é valido
export const checkIsValid = (x: any) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(x) || !isFinite(x)) {
    return "--";
  }
  return x;
};
