import * as React from "react";

import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import DataGridTable from "./DataGrid/DataGridTable";

interface DataGridServerSideProps {
  rows: any;
  columns: GridColDef[];
  totalPages: number;
  pageNumber: number;
  [x: string]: any;
}

function loadServerRows(data: any): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 250);
  });
}

export const DataGridServerSide = ({
  rows,
  columns,
  totalPages,
  pageNumber,
  ...rest
}: DataGridServerSideProps) => {
  const [rowsServer, setRowsServer] = React.useState<GridRowsProp>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const newRows = await loadServerRows(rows);
      if (!active) {
        return;
      }
      setRowsServer(newRows);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [rows]);

  return (
    <DataGridTable
      {...rows}
      rows={rowsServer}
      columns={columns}
      pageSize={50}
      rowsPerPageOptions={[50]}
      page={pageNumber - 1}
      rowCount={totalPages}
      paginationMode="server"
      loading={loading}
      keepNonExistentRowsSelected
      {...rest}
    />
  );
};

export default DataGridServerSide;
