import React from "react";
import { useTranslation } from "react-i18next";

import { Delete as DeleteIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { ToastContext } from "../../../../contexts/ToastContext";
import Modal from "../../../Devices/Modal";
import { removeTag } from "../../../../services/data/TAGs";

interface ModalDeleteTagProps {
  setTagEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
  data: any;
}

const ModalDeleteTag = ({
  setTagEdited,
  handleOpen,
  handleClose,
  data,
}: ModalDeleteTagProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);

  // Excluir TAG
  const handleDelete = () => {
    // Remover TAG no controlador
    removeTag(data?.data?.tagId)
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.DELETE_TAG"));
        setTagEdited(true);
        handleClose();
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.DELETE_TAG"));
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <DeleteIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("BUTTON.DELETE")} TAG?
      </Typography>
      <Typography variant="body2">
        {t("TEXT.DELETE_TAG_MESSAGE")}
        <strong>{` ${data?.data?.name}`}</strong>?
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={handleDelete}
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: red[400],
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("BUTTON.DELETE")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: red[50],
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalDeleteTag;
