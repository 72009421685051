import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  Loyalty as LoyaltyIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { red } from "@mui/material/colors";
import useIsMobile from "../../../../../hooks/useIsMobile";
import Modal from "../../../../Devices/Modal";

interface ModalPermissionsProps {
  permissions: any;
  handleOpen: boolean;
  handleClose: () => void;
}

interface RowProps {
  title: string;
  permission: number | string;
}

const Row = ({ title, permission }: RowProps) => (
  <Box display="flex">
    <List sx={{ p: 0 }}>
      <ListItem sx={{ pl: 1 }}>
        <ListItemIcon sx={{ mr: -2 }}>
          <CheckIcon color="success" />
        </ListItemIcon>
        {permission === "" ? (
          <ListItemText primary={title} />
        ) : (
          <ListItemText primary={`${title}: ${permission}`} />
        )}
      </ListItem>
    </List>
  </Box>
);

const ModalPermissions = ({
  permissions,
  handleOpen,
  handleClose,
}: ModalPermissionsProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 390}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <LoyaltyIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700}>
        {t("TEXT.PERMISSION")}:
      </Typography>
      <Box
        sx={{
          maxHeight: mobile ? 300 : 400,
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <Box>
          {permissions?.DEVICE?.ACCESS_DEVICE_HISTORY > 0 && (
            <Row
              title={`${t("TEXT.ACCESS_TO_DATA")} (${t(
                "TEXT.MONTHS"
              ).toLowerCase()})`}
              permission={permissions?.DEVICE?.ACCESS_DEVICE_HISTORY}
            />
          )}
          {permissions?.DEVICE?.CONTROL_PARAMETERS === true && (
            <Row title={t("TEXT.CHANGING_CTRL_PARAM")} permission="" />
          )}
          {permissions?.DEVICE?.NUM_ACTIONS_SCENARIOS > 0 && (
            <Row
              title={t("TEXT.ACTIONS_BY_SCENARIO")}
              permission={permissions?.DEVICE?.NUM_ACTIONS_SCENARIOS}
            />
          )}
          {permissions?.DEVICE?.NUM_CONDITIONS_SCENARIOS > 0 && (
            <Row
              title={t("TEXT.CONDITIONALS_BY_SCENARIO")}
              permission={permissions?.DEVICE?.NUM_CONDITIONS_SCENARIOS}
            />
          )}
          {permissions?.DEVICE?.NUM_SCENARIOS_CONTROLLER > 0 && (
            <Row
              title={t("TEXT.SCENARIOS_BY_CTRL")}
              permission={permissions?.DEVICE?.NUM_SCENARIOS_CONTROLLER}
            />
          )}
          {permissions?.DEVICE?.SCENARIOS === true && (
            <Row title={t("TEXT.ACCESS_TO_SCENARIOS")} permission="" />
          )}
          {permissions?.DEVICE?.SHAREABLE === true && (
            <Row title={t("TEXT.ACCESS_TO_SHARE_DEVICE")} permission="" />
          )}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: red[50],
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.RETURN")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalPermissions;
