import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../contexts/ToastContext";
import {
  PasswordVisibility,
  yupOneOf,
  yupRequiredStringMax,
} from "../../../components/Form";
import Modal from "../../Devices/Modal";
import ErrorMessage from "../../../components/ErrorMessage";
import { red } from "@mui/material/colors";
import { changePassword } from "../../../services/data/user";

interface ModalEditPasswordProps {
  addr: number;
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalEditPassword = ({
  addr,
  setDeviceEdited,
  handleOpen,
  handleClose,
}: ModalEditPasswordProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const maxLength = 20;
  const passwordSchema = yupRequiredStringMax(maxLength);

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: passwordSchema,
      newPassword: passwordSchema,
      confirmPassword: yupOneOf(
        [Yup.ref("newPassword")],
        "ERROR.REQUIRED_CONFIRM_NEW_PASSWORD"
      ).max(maxLength),
    }),
    onSubmit: (values) => {
      // Editar a senha do usuário logado
      changePassword(addr, {
        password: values.password,
        newPass: values.newPassword,
        newPassConfirm: values.confirmPassword,
      })
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.PASSWORD_CHANGED"));
          setDeviceEdited(true);
          formik.resetForm({ values });
          handleClose();
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.PASSWORD_CHANGED"));
        });
    },
  });

  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.CHANGE_PASSWORD")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <PasswordVisibility
            name="password"
            formik={formik}
            label={t("TEXT.CURRENT_PASSWORD")}
            fullWidth
            maxLength={maxLength}
          />
          {formik.errors.password && formik.touched.password ? (
            <ErrorMessage>{formik.errors.password}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <PasswordVisibility
            name="newPassword"
            formik={formik}
            label={t("TEXT.NEW_PASSWORD")}
            fullWidth
            maxLength={maxLength}
          />
          {formik.errors.newPassword && formik.touched.newPassword ? (
            <ErrorMessage>{formik.errors.newPassword}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <PasswordVisibility
            name="confirmPassword"
            formik={formik}
            label={t("TEXT.CONFIRM_PASSWORD")}
            fullWidth
            maxLength={maxLength}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
            <ErrorMessage>{formik.errors.confirmPassword}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: red[400],
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: red[50],
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalEditPassword;
