
// Converter timestamp em horas / minutos / segundos
export const convertTime = (time: number) => {
  const hours = Math.floor(time / (60 * 60 * 1000));
  const minutes =
    Math.floor(time / (60 * 1000)) >= 60 ? 0 : Math.floor(time / (60 * 1000));
  const seconds = Math.floor(time / 1000) >= 60 ? 0 : Math.floor(time / 1000);

  return { hours, minutes, seconds };
};

// Converter horas / minutos / segundos em timestamp
export const convertToTs = (value: any) => {
  return (
    value.hours * 60 * 60 * 1000 +
    value.minutes * 60 * 1000 +
    value.seconds * 1000
  );
};
