import {
  Box,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  History as HistoryIcon,
  WarningAmberOutlined as WarningAmberOutlinedIcon,
  ReportGmailerrorred as ReportGmailerrorredIcon,
} from "@mui/icons-material";
import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import AvatarIcon from "../AvatarIcon";
import { grey, red, yellow } from "@mui/material/colors";
import DataGridTable from "../DataGrid/DataGridTable";
import ModalConditions from "./Modal/ModalConditions";
import Switch from "../Switch";
import {
  editScenario,
  getConditionalRecords,
} from "../../services/data/scenarios";
import { ToastContext } from "../../contexts/ToastContext";
import { Acoes, Estado, RowData } from "../Controller/parser/types";
import ModalRemove from "./Modal/ModalRemove";
import ModalEdit from "./Modal/ModalEdit";
import ModalHistory from "./Modal/ModalHistory";
import { applyDivider } from "../Controller/parser/utils";
import { splitValue } from "./parse";

interface ScenariosTableProps {
  sensorSelected: (e: number) => RowData;
  scenarios: any[];
  setScenarioEdited: (e: boolean) => void;
  acoes: Acoes[];
  setpoints: RowData[];
  parametros: RowData[];
  model: string;
  sensores: any;
  activeStep: number;
  setActiveStep: (value: React.SetStateAction<number>) => void;
  conditionWork: string;
  setConditionWork: (e: string) => void;
  ctrlIsDefined: boolean;
  estado: Estado[];
  planPermissions: any;
  devicePermission: string | undefined;
}

const ScenariosTable = ({
  sensorSelected,
  scenarios,
  setScenarioEdited,
  acoes,
  setpoints,
  parametros,
  model,
  sensores,
  activeStep,
  setActiveStep,
  conditionWork,
  setConditionWork,
  ctrlIsDefined,
  estado,
  planPermissions,
  devicePermission,
}: ScenariosTableProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { theme } = React.useContext(ThemeModeContext);
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const [openModalConditions, setOpenModalConditions] = React.useState(false);
  const [modalConditionsValues, setModalConditionsValues] =
    React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [modalEditValues, setModalEditValues] = React.useState<any>();
  const [openModalHistory, setOpenModalHistory] = React.useState(false);
  const [modalHistoryValues, setModalHistoryValues] = React.useState<any>();
  const [dataRecords, setDataRecords] = React.useState<any[]>([]);
  const [loadingModalHistory, setLoadingModalHistory] = React.useState(true);

  // Ações do modal de visualizar os controladores
  const handleOpenModalConditions = (conditions: any) => {
    setModalConditionsValues({ conditions });
    setOpenModalConditions(true);
  };
  const handleCloseModalControllers = () => {
    setModalConditionsValues(false);
  };

  // Alterar o switch de Status do Cenário
  const handleChange = (row: any) => {
    const newData = {
      enabled: !row.enabled,
    };

    editScenario(row.rowId, newData)
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.SCENARIO_STATUS_SWITCH"));
        setScenarioEdited(true);
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.SCENARIO_STATUS_SWITCH"));
      });
  };

  // Ações do modal de excluir cenário
  const handleOpenModalDelete = (e: any, row: any) => {
    e.stopPropagation();

    setModalDeleteValues({ row });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações de abrir o modal de Editar Cenário
  const handleOpenModalEdit = (e: any, row: any) => {
    e.stopPropagation();

    setModalEditValues({ row });
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Ações de abrir o modal do histórico do Cenário
  const handleOpenModalHistory = (e: any, row: any) => {
    e.stopPropagation();

    setModalHistoryValues({ row });

    // Requisição get do histórico do Cenário
    getConditionalRecords(row.ctrlId, row.rowId).then((res) => {
      const { data } = res;
      setDataRecords(data.reverse());
    });
    setOpenModalHistory(true);
    setTimeout(() => {
      setLoadingModalHistory(false);
    }, 500);
  };
  const handleCloseModalHistory = () => {
    setOpenModalHistory(false);
    setLoadingModalHistory(true);
  };

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      scenarios.map((m, i) => {
        // Filtrar o campo 'Setpoints'
        const filterSetpoints = setpoints.filter(
          (_f, i: number) =>
            +i ===
            +splitValue(m.action_obj?.commands[0]?.resource)[1]?.split("s")[1]
        )[0];

        // Filtrar o campo 'Parametros'
        const filterParam = parametros.filter(
          (_f, i: number) =>
            +i === +splitValue(m.action_obj?.commands[0]?.resource)[1]
        )[0];

        return {
          id: i + 1,
          rowId: m._id,
          name: m.name,
          description: m.description,
          histerese: m.histerese,
          enabled: m.enabled,
          trigger_type: m.trigger_type,
          conditions: m.conditions,
          action_obj: m.action_obj,
          operator: m.operator,
          scenarioActions: "",
          ctrlId: m.ctrlId,
          historic: "",
          last_status: m?.last_status,
          disable_on_error: m?.disable_on_error,
          days_of_week: m?.days_of_week,
          isMobile: "",
          setpointDivider: applyDivider(
            m.action_obj?.commands[0].value,
            filterSetpoints?.divider ?? 0
          ),
          paramDivider: applyDivider(
            m.action_obj?.commands[0].value,
            filterParam?.divider ?? 0
          ),
          ctrlModelIsTheSame: model === m.model,
        };
      }),
    [model, parametros, scenarios, setpoints]
  );

  // Colunas da tabela
  const columns: GridColDef[] = !mobile
    ? [
        {
          field: "id",
          hide: true,
        },
        {
          field: "isMobile",
          hide: true,
        },
        {
          field: "name",
          headerName: t("TEXT.NAME"),
          align: "left",
          headerAlign: "left",
          flex: 3,
          renderHeader: () => (
            <p
              style={{
                fontSize: "12px",
                color: theme === "light" ? "#7E7E7E" : "inherit",
                textTransform: "uppercase",
                fontWeight: 700,
                marginLeft: "15px",
              }}
            >
              {t("TEXT.NAME")}
            </p>
          ),
          renderCell: ({ row }: any) => (
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                  <AvatarIcon
                    name={row.name}
                    bgcolor={
                      row?.last_status && row?.last_status !== "SUCESSO"
                        ? yellow[700]
                        : row?.enabled === false
                        ? grey[400]
                        : row.ctrlModelIsTheSame === false
                        ? grey[400]
                        : red[400]
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        sx={{
                          display: "inline",
                          fontWeight: 450,
                          whiteSpace: "break-spaces",
                          color:
                            theme === "light"
                              ? "inherit"
                              : theme === "dark" &&
                                row.ctrlModelIsTheSame === false
                              ? "black"
                              : "white",
                        }}
                      >
                        {row.name}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        sx={{
                          display: "inline",
                          color:
                            theme === "light"
                              ? "inherit"
                              : theme === "dark" &&
                                row.ctrlModelIsTheSame === false
                              ? "black"
                              : "white",
                        }}
                        variant="body2"
                      >
                        {row.description}
                      </Typography>
                    </React.Fragment>
                  }
                />
                {row.ctrlModelIsTheSame === false && (
                  <Box p={1} pl={2}>
                    <Tooltip title={t("TEXT.INCOMPATIBLE_MODEL")}>
                      <ReportGmailerrorredIcon color="error" fontSize="large" />
                    </Tooltip>
                  </Box>
                )}
              </ListItem>
            </List>
          ),
        },
        {
          field: "scenarioActions",
          sortable: false,
          disableColumnMenu: true,
          headerName: t("TEXT.COMMANDS"),
          align: "center",
          headerAlign: "center",
          flex: 1,
          renderCell: ({ row }: any) =>
            devicePermission !== "VIEW" && (
              <Box component="span">
                <Tooltip title={t("TEXT.DELETE_SCENARIO")}>
                  <IconButton
                    sx={{ p: 0 }}
                    color="error"
                    onClick={(e) => handleOpenModalDelete(e, row)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
                {ctrlIsDefined && (
                  <Tooltip title={t("TEXT.EDIT_SCENARIO")}>
                    <IconButton
                      sx={{ p: 0, ml: 1 }}
                      color="warning"
                      onClick={(e) => handleOpenModalEdit(e, row)}
                      disabled={row.ctrlModelIsTheSame === true ? false : true}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ),
        },
        {
          field: "enabled",
          sortable: false,
          disableColumnMenu: true,
          headerName: t("TEXT.ENABLE_SCENARIO"),
          align: "center",
          headerAlign: "center",
          flex: 1,
          renderCell: ({ row }: any) => (
            <Box display="flex" justifyContent="center">
              <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                <FormControlLabel
                  sx={{ m: 0, p: 3 }}
                  control={
                    <Switch
                      checked={
                        row.ctrlModelIsTheSame === true ? row.enabled : false
                      }
                    />
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={() =>
                    devicePermission !== "VIEW" && handleChange(row)
                  }
                  label=""
                  disabled={row.ctrlModelIsTheSame === true ? false : true}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          field: "historic",
          sortable: false,
          disableColumnMenu: true,
          headerName: t("TEXT.HISTORIC"),
          align: "center",
          headerAlign: "center",
          flex: 1,
          renderCell: ({ row }: any) => (
            <Box
              component="span"
              display="flex"
              onClick={(e) => handleOpenModalHistory(e, row)}
              p={3}
            >
              <Tooltip title={t("TEXT.ACCESS")}>
                <IconButton color="info">
                  <HistoryIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </Tooltip>
              {row?.last_status && row?.last_status !== "SUCESSO" && (
                <Box mt={1}>
                  <Tooltip title={t("TEXT.SCENARIO_FAILURE")}>
                    <WarningAmberOutlinedIcon
                      color="warning"
                      sx={{ fontSize: 28 }}
                    />
                  </Tooltip>
                </Box>
              )}
            </Box>
          ),
        },
      ]
    : [
        {
          field: "id",
          hide: true,
        },
        {
          field: "rowId",
          hide: true,
        },
        {
          field: "name",
          hide: true,
        },
        {
          field: "description",
          hide: true,
        },
        {
          field: "histerese",
          hide: true,
        },
        {
          field: "enabled",
          hide: true,
        },
        {
          field: "trigger_type",
          hide: true,
        },
        {
          field: "conditions",
          hide: true,
        },
        {
          field: "action_obj",
          hide: true,
        },
        {
          field: "operator",
          hide: true,
        },
        {
          field: "scenarioActions",
          hide: true,
        },
        {
          field: "ctrlId",
          hide: true,
        },
        {
          field: "historic",
          hide: true,
        },
        {
          field: "last_status",
          hide: true,
        },
        {
          field: "disable_on_error",
          hide: true,
        },
        {
          field: "device",
          hide: true,
        },
        {
          field: "addr",
          hide: true,
        },
        {
          field: "model",
          hide: true,
        },
        {
          field: "isMobile",
          headerName: t("TEXT.SCENARIOS"),
          align: "center",
          headerAlign: "center",
          flex: 1,
          renderCell: ({ row }: any) => (
            <Box mr="auto">
              <List>
                <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon
                      name={row.name}
                      bgcolor={
                        row?.last_status && row?.last_status !== "SUCESSO"
                          ? yellow[700]
                          : row?.enabled === false
                          ? grey[400]
                          : row.ctrlModelIsTheSame === false
                          ? grey[400]
                          : red[400]
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box display="flex">
                        <Box display="grid">
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              color:
                                theme === "light"
                                  ? "inherit"
                                  : theme === "dark" &&
                                    row.ctrlModelIsTheSame === false
                                  ? "black"
                                  : "white",
                            }}
                          >
                            {row.name}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{
                              width: "10px",
                              color:
                                theme === "light"
                                  ? "inherit"
                                  : theme === "dark" &&
                                    row.ctrlModelIsTheSame === false
                                  ? "black"
                                  : "white",
                            }}
                            mt={0.5}
                          >
                            {row.description}
                          </Typography>
                        </Box>
                        {row.ctrlModelIsTheSame === false && (
                          <Box pl={2} alignSelf="center">
                            <Tooltip title={t("TEXT.INCOMPATIBLE_MODEL")}>
                              <ReportGmailerrorredIcon
                                color="error"
                                fontSize="large"
                              />
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} mb={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.3,
                              color:
                                theme === "light"
                                  ? "inherit"
                                  : theme === "dark" &&
                                    row.ctrlModelIsTheSame === false
                                  ? "black"
                                  : "white",
                              fontSize: 13,
                            }}
                          >
                            {t("TEXT.COMMANDS")}:
                          </Typography>
                          {devicePermission !== "VIEW" && (
                            <IconButton
                              sx={{ p: 0 }}
                              color="error"
                              onClick={(e) => handleOpenModalDelete(e, row)}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          )}
                          {devicePermission !== "VIEW" && ctrlIsDefined && (
                            <IconButton
                              sx={{ p: 0, ml: 1 }}
                              color="warning"
                              onClick={(e) => handleOpenModalEdit(e, row)}
                              disabled={
                                row.ctrlModelIsTheSame === true ? false : true
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        </Box>
                        <Box display="flex" mt={0.5} mb={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 2,
                              mt: 0.6,
                              color:
                                theme === "light"
                                  ? "inherit"
                                  : theme === "dark" &&
                                    row.ctrlModelIsTheSame === false
                                  ? "black"
                                  : "white",
                              fontSize: 13,
                            }}
                          >
                            {t("TEXT.ENABLE_SCENARIO")}:
                          </Typography>
                          <Box component="span">
                            <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                              <FormControlLabel
                                sx={{ m: 0, pr: 3 }}
                                control={
                                  <Switch
                                    checked={
                                      row.ctrlModelIsTheSame === true
                                        ? row.enabled
                                        : false
                                    }
                                  />
                                }
                                onClick={(e) => e.stopPropagation()}
                                onChange={() =>
                                  devicePermission !== "VIEW" &&
                                  handleChange(row)
                                }
                                label=""
                                disabled={
                                  row.ctrlModelIsTheSame === true ? false : true
                                }
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box display="flex" mt={0.5} mb={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mt:
                                row?.last_status &&
                                row?.last_status !== "SUCESSO"
                                  ? 1
                                  : 0.6,
                              color:
                                theme === "light"
                                  ? "inherit"
                                  : theme === "dark" &&
                                    row.ctrlModelIsTheSame === false
                                  ? "black"
                                  : "white",
                              fontSize: 13,
                            }}
                          >
                            {t("TEXT.HISTORIC")}:
                          </Typography>
                          <Box display="flex" component="span">
                            <IconButton
                              sx={{ p: 1, pt: 0 }}
                              color="info"
                              onClick={(e) => handleOpenModalHistory(e, row)}
                            >
                              <HistoryIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            {row?.last_status &&
                              row?.last_status !== "SUCESSO" && (
                                <Box
                                  onClick={(e) =>
                                    handleOpenModalHistory(e, row)
                                  }
                                >
                                  <Tooltip title={t("TEXT.SCENARIO_FAILURE")}>
                                    <WarningAmberOutlinedIcon
                                      color="warning"
                                      sx={{ fontSize: 28 }}
                                    />
                                  </Tooltip>
                                </Box>
                              )}
                          </Box>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Box>
          ),
        },
      ];
  return (
    <>
      <Paper
        style={{
          height: 390,
        }}
        elevation={0}
      >
        <DataGridTable
          rows={rows}
          columns={columns}
          message={t("TEXT.NO_SCENARIOS")}
          getRowHeight={() => "auto"}
          pageSize={planPermissions?.NUM_SCENARIOS_CONTROLLER}
          rowsPerPageOptions={[planPermissions?.NUM_SCENARIOS_CONTROLLER]}
          hideFooterPagination
          hideFooterSelectedRowCount
          onRowClick={(params: GridRowParams) => {
            params.row.ctrlModelIsTheSame === true &&
              handleOpenModalConditions(params.row);
          }}
          getRowClassName={(params: GridRowParams) => {
            return params.row.ctrlModelIsTheSame === false ? "highlight" : "";
          }}
        />
      </Paper>
      {modalConditionsValues && (
        <ModalConditions
          sensorSelected={sensorSelected}
          values={modalConditionsValues}
          action_obj={modalConditionsValues?.conditions?.action_obj}
          handleOpen={openModalConditions}
          handleClose={handleCloseModalControllers}
          acoes={acoes}
          setpoints={setpoints}
          parametros={parametros}
          estado={estado}
        />
      )}
      {modalDeleteValues && (
        <ModalRemove
          setScenarioEdited={setScenarioEdited}
          row={modalDeleteValues.row}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
      {modalEditValues && (
        <ModalEdit
          setScenarioEdited={setScenarioEdited}
          row={modalEditValues.row}
          handleOpen={openModalEdit}
          handleClose={handleCloseModalEdit}
          model={model}
          sensores={sensores}
          acoes={acoes}
          setpoints={setpoints}
          parametros={parametros}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          conditionWork={conditionWork}
          setConditionWork={setConditionWork}
          estado={estado}
          planPermissions={planPermissions}
        />
      )}
      {modalHistoryValues && (
        <ModalHistory
          row={modalHistoryValues.row}
          dataRecords={dataRecords}
          handleOpen={openModalHistory}
          handleClose={handleCloseModalHistory}
          sensorSelected={sensorSelected}
          loadingModalHistory={loadingModalHistory}
          estado={estado}
        />
      )}
    </>
  );
};

export default ScenariosTable;
