import React from "react";

import { useTranslation } from "react-i18next";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PublishedWithChanges as PublishedWithChangesIcon,
  HelpOutline as HelpOutlineIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import Modal from "../../Devices/Modal";
import useIsMobile from "../../../hooks/useIsMobile";
import { filteredNameByMac, uniqKeysByCtrlId } from "../parse";
import Spinner from "../../../components/Spinner";
import ModalSaveSync from "./Question/SaveSync";
import { sincronizeSector } from "../../../services/data/sector";
import { ToastContext } from "../../../contexts/ToastContext";
import ModalHelpFrequencyInverters from "./Question/HelpFrequencyInverters";
import { getCtrlConfig } from "../../../components/Controller/parser";
import { getRawCtrlConfig } from "../../../config";

interface MuiChipProps {
  status: string;
  label: string;
  title?: string;
}

// Componente Chip dos status de cada controlador sincronizado
const MuiChip = ({ status, label, title }: MuiChipProps) => (
  <Tooltip title={title}>
    <Chip
      label={label}
      size="small"
      color={status === "ok" ? "success" : "error"}
      sx={{
        mr: 1,
        fontWeight: 700,
        backgroundColor: status === "ok" ? green[100] : red[100],
        color: status === "ok" ? green[800] : red[800],
        textTransform: "uppercase",
        fontSize: 11,
      }}
    />
  </Tooltip>
);

interface ModalAddedDeviceProps {
  handleOpen: boolean;
  handleClose: () => void;
  data: any;
  startIntervalSubmit?: (e: string) => void;
  isEditedSector?: boolean;
  checked: boolean;
  deviceUsers: any;
}

const ModalAddedDevice = ({
  handleOpen,
  handleClose,
  data,
  startIntervalSubmit,
  isEditedSector,
  checked,
  deviceUsers,
}: ModalAddedDeviceProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { toastError } = React.useContext(ToastContext);

  // Ações de abrir o modal de dúvidas sobre salvar a sincronização
  const [openModalSaveSync, setOpenModalSaveSync] =
    React.useState<boolean>(false);
  const handleOpenModalSaveSync = () => setOpenModalSaveSync(true);
  const handleCloseModalSaveSync = () => {
    setOpenModalSaveSync(false);
  };

  // Checar o erro de cada controlador sincronizado
  const checkErrorSync = (row: any) =>
    data?.last_sync_status &&
    data?.last_sync_status[row.ctrlId]?.error?.resource_type?.split("/")[0];

  // Checar se está sincronizando
  const checkIfIsSync =
    data?.last_sync_status &&
    (data?.comand_control?.running !== "finished" ||
      Object.values(data?.last_sync_status).filter((f: any) => f === null)
        .length > 0);

  // Ação de sincronizar o setor
  const handleSynchronizedSector = () => {
    setLoading(false);
    // Iniciar o 'last_exec_status' dos 'devices' como 'null'
    const devices = data.devices.map((m: any) => {
      return {
        last_exec_status: null,
        ctrlId: m.ctrlId,
        rotulo: m.rotulo,
      };
    });

    const dataSync = {
      ...data,
      devices,
    };

    sincronizeSector(data._id, dataSync).catch((err) => {
      const error = err.response.status;
      // Sinalizar que a sincronização está em andamento
      if (error === 400) {
        toastError(t("TEXT.SYNC_IN_PROGRESS"));
      }
    });

    if (startIntervalSubmit) {
      startIntervalSubmit(data._id);
    }
  };

  // Verificação de habilitar/desabilitar o spinner
  React.useEffect(() => {
    if (data?.comand_control?.running === "is_syncing") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [data]);

  // Verificar se o botão de sincronizar deve renderizar
  const renderButtonSync =
    startIntervalSubmit && !checkIfIsSync && !isEditedSector && loading;

  // Chegar se há alguma falha dentre os controladores do setor
  const errorMsgsLength = () =>
    data?.last_sync_status &&
    data?.devices &&
    uniqKeysByCtrlId(data?.devices).filter(
      (row: any) => data?.last_sync_status[row.ctrlId]?.status !== "ok"
    ).length;

  // Ações de abrir o modal de dúvidas sobre os inversores de frequencia
  const [openModalFrequency, setOpenModalFrequency] =
    React.useState<boolean>(false);
  const handleOpenModalFrequency = () => setOpenModalFrequency(true);
  const handleCloseModalFrequency = () => {
    setOpenModalFrequency(false);
  };

  // Resgatar o payload do setpoints do Dispositivo selecionado
  const ctrlConfig = getCtrlConfig(data?.sector_model);
  const rawCtrlConfig = getRawCtrlConfig(data?.sector_model);
  const setpoints = ctrlConfig.setpoints;
  const actualSetpoint =
    setpoints[+data?.default_resource.split("/")[1].split("s")[1]];
  const setpointName = actualSetpoint?.description;
  const max = data?.resource_limit[data?.default_resource]?.max;
  const min = data?.resource_limit[data?.default_resource]?.min;
  const unit = actualSetpoint?.unitOfMeasurement;
  const value = data?.resource_value[data?.default_resource];
  const minLabel =
    rawCtrlConfig?.detalhamento[
      String(actualSetpoint?.min).split("detalhamento")[1]
    ][0];
  const maxLabel =
    rawCtrlConfig?.detalhamento[
      String(actualSetpoint?.max).split("detalhamento")[1]
    ][0];

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 500}
    >
      <Box
        sx={{
          maxHeight: mobile || window.screen.width < 1600 ? 600 : 930,
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 3.5,
            pb: 3.5,
            borderRadius: 4,
            mb: 2,
            backgroundColor: red[100],
          }}
          elevation={0}
        >
          <Avatar
            sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}
          >
            <PublishedWithChangesIcon sx={{ fontSize: 65 }} />
          </Avatar>
        </Paper>
        <Box display="flex" mb={mobile ? 0 : 1}>
          <Typography
            fontSize={mobile ? 18 : 21}
            fontWeight={700}
            mt={mobile ? 0.8 : 0.5}
          >
            {t("TEXT.SYNCHRONIZED_CTRLS")}
          </Typography>
          <IconButton color="primary" onClick={handleOpenModalSaveSync}>
            <HelpOutlineIcon color="info" />
          </IconButton>
        </Box>
        {data && (
          <Box
            p={2}
            mb={2}
            sx={{
              backgroundColor: "#E5F6FD",
              borderRadius: 3,
            }}
          >
            <Typography fontWeight={500}>{t("TEXT.SYNC_ADJUST")}:</Typography>
            <List sx={{ pb: 0 }}>
              {unit !== " Hz" && (
                <ListItem sx={{ p: 0, pl: mobile ? 0 : 1 }}>
                  <ListItemIcon sx={{ mr: -3 }}>
                    <KeyboardArrowRightIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={`${setpointName}: ${value} ${unit}`} />
                </ListItem>
              )}
              <ListItem sx={{ p: 0, pl: mobile ? 0 : 1 }}>
                <ListItemIcon sx={{ mr: -3 }}>
                  <KeyboardArrowRightIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={`${maxLabel}: ${max} ${unit}`} />
              </ListItem>
              <ListItem sx={{ p: 0, pl: mobile ? 0 : 1 }}>
                <ListItemIcon sx={{ mr: -3 }}>
                  <KeyboardArrowRightIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={`${minLabel}: ${min} ${unit}`} />
              </ListItem>
            </List>
            <Typography mt={1}>
              <strong style={{ paddingRight: "5px" }}>
                {t("TEXT.IMPORTANT")}:
              </strong>
              {t("TEXT.SAVE_AND_SYNC_MESSAGE_1_IMPORTANT")}
            </Typography>
          </Box>
        )}
        <Box>
          {data === undefined ? (
            <Spinner />
          ) : (
            <>
              {data?.last_sync_status && (
                <Box
                  display="grid"
                  mt={mobile ? 0 : 1}
                  sx={{
                    maxHeight: mobile || window.screen.width < 1600 ? 220 : 320,
                    overflow: data?.devices?.length < 3 ? "hidden" : "scroll",
                    overflowX: "hidden",
                    border: "1px solid rgb(220, 220, 220)",
                    borderRadius: "5px",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: mobile ? 12 : 14.5,
                            pl: mobile ? 0.8 : 3,
                            pr: 0,
                          }}
                        >
                          {t("TEXT.SYNCHRONIZED_CTRLS")}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: mobile ? 12 : 14.5,
                            pl: mobile ? 0 : 2,
                          }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.devices &&
                        uniqKeysByCtrlId(data?.devices).map(
                          (row: any, i: number) => (
                            <TableRow hover key={i}>
                              <TableCell
                                align="left"
                                sx={{ pl: mobile ? 1 : 3, pr: 0.5 }}
                              >
                                <Typography
                                  fontSize={mobile ? 12 : 15}
                                  sx={{ placeSelf: "center" }}
                                >
                                  {`${row.rotulo} - ${
                                    row.ctrlId?.split("/")[1].split("L")[1]
                                  } - ${filteredNameByMac(
                                    row.ctrlId?.split("/")[0],
                                    deviceUsers
                                  )}`}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ pl: mobile ? 0 : 3 }}
                              >
                                {data?.last_sync_status[row.ctrlId] === null ? (
                                  <Spinner size={20} disableMargin />
                                ) : data?.last_sync_status[row.ctrlId]
                                    ?.status === "ok" ? (
                                  <MuiChip
                                    status="ok"
                                    label={t("TEXT.SUCCESS")}
                                  />
                                ) : data?.last_sync_status[row.ctrlId]
                                    ?.status === "error" ? (
                                  checkErrorSync(row) === "min" ? (
                                    <MuiChip
                                      status="error"
                                      label={`${t("TEXT.ERROR")} ${t(
                                        "TEXT.MINIMUM"
                                      )}`}
                                      title={t("ERROR.MIN")}
                                    />
                                  ) : checkErrorSync(row) === "max" ? (
                                    <MuiChip
                                      status="error"
                                      label={`${t("TEXT.ERROR")} ${t(
                                        "TEXT.MAXIMUM"
                                      )}`}
                                      title={t("ERROR.MAX")}
                                    />
                                  ) : (
                                    <MuiChip
                                      status="error"
                                      label={`${t("TEXT.ERROR")} SETPOINT`}
                                      title={t("ERROR.SETPOINT")}
                                    />
                                  )
                                ) : (
                                  <MuiChip status="error" label="OFFLINE" />
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </Box>
              )}
              {checkIfIsSync || !loading ? (
                <Alert
                  severity="info"
                  sx={{
                    mt: 3.5,
                    ml: mobile ? 0 : 10,
                    mr: mobile ? 0 : 10,
                    alignItems: "center",
                    placeContent: "center",
                  }}
                >
                  <Box>
                    <Typography
                      fontWeight={700}
                      fontSize={15}
                      textAlign="center"
                    >
                      {t("TEXT.SYNC_IN_PROGRESS")}...
                    </Typography>
                    <Box
                      textAlign="center"
                      display="flex"
                      justifyContent="center"
                      mt={1}
                      mb={1}
                    >
                      <LinearProgress color="primary" sx={{ width: "210px" }} />
                    </Box>
                  </Box>
                </Alert>
              ) : data !== undefined && errorMsgsLength() === 0 ? (
                <Alert
                  severity="success"
                  sx={{
                    mt: 3.5,
                    ml: mobile ? 0 : 10,
                    mr: mobile ? 0 : 10,
                    placeContent: "center",
                  }}
                >
                  <Typography fontWeight={700} fontSize={15} textAlign="center">
                    {t("TEXT.SYNC_COMPLETE")}!
                  </Typography>
                </Alert>
              ) : data !== undefined && errorMsgsLength() > 0 ? (
                <Alert
                  severity="error"
                  sx={{
                    mt: 3.5,
                    ml: mobile ? 0 : 8,
                    mr: mobile ? 0 : 8,
                    placeContent: "center",
                  }}
                >
                  <Typography fontWeight={700} fontSize={15} textAlign="center">
                    {t("TEXT.SECTOR_SYNC_FAILED")}
                  </Typography>
                </Alert>
              ) : (
                <Alert
                  severity="info"
                  sx={{
                    mt: 2,
                    ml: mobile ? 0 : 10,
                    mr: mobile ? 0 : 10,
                    placeContent: "center",
                  }}
                >
                  <Typography fontWeight={700} fontSize={15} textAlign="center">
                    {t("TEXT.NO_CONTROLLER_SYNC")}
                  </Typography>
                </Alert>
              )}
            </>
          )}
          {data !== undefined && (
            <Box
              display="flex"
              justifyContent={renderButtonSync ? "space-between" : "center"}
              mt={3.5}
            >
              <Button
                onClick={handleClose}
                sx={{
                  ml: renderButtonSync ? 2 : 0,
                  backgroundColor: red[50],
                  fontSize: 12,
                  p: 1,
                  pl: 3,
                  pr: 3,
                  color: "black",
                }}
              >
                {t("BUTTON.CLOSE")}
              </Button>
              {renderButtonSync && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpenModalFrequency}
                  disabled={checked}
                  sx={{
                    mr: 2,
                    fontSize: 12,
                    pt: 1,
                    pb: 1,
                  }}
                >
                  {t("BUTTON.SYNCHRONIZE")}
                </Button>
              )}
            </Box>
          )}
        </Box>
        <ModalSaveSync
          handleClose={handleCloseModalSaveSync}
          handleOpen={openModalSaveSync}
          isSubmitting={false}
          dataEdited={data}
        />
        <ModalHelpFrequencyInverters
          handleClose={handleCloseModalFrequency}
          handleOpen={openModalFrequency}
          handleSync={handleSynchronizedSector}
        />
      </Box>
    </Modal>
  );
};

export default ModalAddedDevice;
