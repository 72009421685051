import { LanguageCode } from "../../../translations/types";

// Converter timestamp em data DD/MM/AAAA
export const convertToDateInRow = (
  timestamp: number,
  language: LanguageCode
) => {
  const FullDate = new Date(timestamp).toLocaleDateString(
    language === "en" ? "en-US" : "pt-BR",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
  );
  const fullDate = `${FullDate}`;
  return fullDate;
};

// Adicionar o número '0' á esquerda de números entre 0 á 9
export const addZero = (i: any) => {
  if (i < 10) {
    // eslint-disable-next-line no-param-reassign
    i = `0${i}`;
  }
  return i;
};

// Converter timestamp em data YYYY/MM/DD
export const changeDateYear = (timestamp: number) => {
  const d = new Date(timestamp);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
};

// Remove objetos duplicados da array
export const uniqueArray = (arr: any) =>
  arr.filter((value: any, index: number) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      arr.findIndex((obj: any) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
