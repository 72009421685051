import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface WelcomeProviderProps {
  children: ReactNode;
}

interface WelcomeData {
  welcome: boolean;
  setWelcome: Dispatch<SetStateAction<boolean>>;
  isOpenned: boolean;
  setIsOpenned: Dispatch<SetStateAction<boolean>>;
}

export const WelcomeContext = createContext({} as WelcomeData);

export const WelcomeProvider = ({ children }: WelcomeProviderProps) => {
  const [welcome, setWelcome] = useState<boolean>(false);
  const [isOpenned, setIsOpenned] = useState<boolean>(true);

  const WelcomeProviderValue = useMemo(
    () => ({
      welcome,
      isOpenned,
      setWelcome,
      setIsOpenned,
    }),
    [isOpenned, welcome]
  );

  return (
    <WelcomeContext.Provider value={WelcomeProviderValue}>
      {children}
    </WelcomeContext.Provider>
  );
};
