import { Box, Grid, Tooltip } from "@mui/material";

import { CardIcon } from "../../contexts/ControllerContext";

type IconsProps = {
  icons: CardIcon[];
};

const Icons = ({ icons }: IconsProps) => {
  const iconsData = icons
    .map(({ active, icon, description }) => (
      <Grid item key={description.active}>
        <Tooltip
          title={active ? description.active : description.inactive}
          arrow
          enterTouchDelay={0}
        >
          <Box sx={{ opacity: active ? 1 : 0.35 }}>{icon}</Box>
        </Tooltip>
      </Grid>
    ))
    .reverse();

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      m={0}
      pr={1}
      mt={1}
      mb={icons.length > 5 ? 1 : 0}
      width="100%"
    >
      {iconsData}
    </Grid>
  );
};

export default Icons;
