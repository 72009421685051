import Layout from "./components/Layout/Layout";
import { ThemeModeProvider } from "./contexts/ThemeModeContext";
import { ArcsysProvider } from "./contexts/ArcsysProviderContext";
import { ParametrosProvider } from "./contexts/ParametrosContext";

import Router from "./Routes";
import { CurrentMqttSubsProvider } from "./contexts/CurrentMqttSubsContext";
import { AccordionMacListProvider } from "./contexts/AccordeonOrder";
import { WelcomeProvider } from "./contexts/WelcomeContext";
import { MqttProvider } from "./contexts/MqttContext";
import { DashboardFilterProvider } from "./contexts/DashboardFilterContext";
import { TagsProvider } from "./contexts/TagsContext";
import { SectorsProvider } from "./contexts/SectorContext";

const App = () => {
  return (
    <ThemeModeProvider>
      <MqttProvider>
        <ArcsysProvider>
          <SectorsProvider>
            <ParametrosProvider>
              <CurrentMqttSubsProvider>
                <DashboardFilterProvider>
                  <TagsProvider>
                    <AccordionMacListProvider>
                      <WelcomeProvider>
                        <Layout>
                          <Router />
                        </Layout>
                      </WelcomeProvider>
                    </AccordionMacListProvider>
                  </TagsProvider>
                </DashboardFilterProvider>
              </CurrentMqttSubsProvider>
            </ParametrosProvider>
          </SectorsProvider>
        </ArcsysProvider>
      </MqttProvider>
    </ThemeModeProvider>
  );
};

export default App;
