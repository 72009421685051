import React from "react";

import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Replay as ReplayIcon,
  DeleteOutline as DeleteOutlineIcon,
  Person2Outlined as Person2OutlinedIcon,
} from "@mui/icons-material";

import { AuthContext } from "../../contexts/AuthContext";
import { TOKEN_KEY } from "../../auth/constants";
import { api } from "../../services/api";
import { getNumbersOfUsers } from "../../services/data/user";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import AvatarIcon from "../../components/AvatarIcon";
import { green, red } from "@mui/material/colors";
import Spinner from "../../components/Spinner";
import { PROFILE } from "../../Routes/constants";
import { convertToDateInRow } from "../Devices/DevicePage/parse";
import ModalRemove from "./ModalRemove";
import { ToastContext } from "../../contexts/ToastContext";
import DataGridServerSide from "../../components/DataGridServerSide";

const UsersPage = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1200;
  const mediumPage = useIsMediumPage();
  const navigate = useNavigate();
  const { userArcsys, token, authenticated, userId } =
    React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const { toastError } = React.useContext(ToastContext);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<any>();
  const [userEdited, setUserEdited] = React.useState<boolean>(false);
  const [searchedUser, setSearchedUser] = React.useState<string>("");
  const [checked, setChecked] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [asAdmin, setAsAdmin] = React.useState<number>(0);
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");
  const [isSearched, setIsSearched] = React.useState<boolean>(false);
  const [queryType, setQueryType] = React.useState("name");

  // Selecionar o tipo de pesquisa
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryType(event.target.value);
  };

  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  // Checkbox de selecionar todos os usuarios
  const handleChangeAllUsers = () => {
    const check = !checked ?? checked;
    setChecked(check);
    setAsAdmin(check === true ? 1 : 0);
    setSearchedUser("");
    setSearchedClicked("");
  };

  // Barra de pesquisa
  const handleSearch = (e: any) => {
    setSearchedUser(e);
    setIsSearched(true);
  };

  // Limpar barra de pesquisa do Dispositivo
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedUser("");
    setSearchedClicked("");
    setLoading(true);
  };

  React.useEffect(() => {
    if (authenticated) {
      const fetchStatus = () => {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        if (pageNumber > 0) {
          // Rota para resgatar a quantidade de usuários que irão ser renderizados
          getNumbersOfUsers(
            pageNumber,
            searchedUser,
            rowsPerPage,
            asAdmin,
            queryType
          ).then((res) => {
            const { data } = res;
            setUsers(data.items);
            setTotalPages(data.total === 0 ? data.total : data.total - 1);
            if (
              data.items.filter((f: any) => +f._id !== +userId)?.length === 0
            ) {
              setLoading(false);
            }
          });
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    asAdmin,
    authenticated,
    pageNumber,
    rowsPerPage,
    searchedUser,
    token,
    isSearched,
    userId,
  ]);

  // Atualizar os usuários após a edição de qualquer dado
  React.useEffect(() => {
    if (userEdited) {
      if (pageNumber > 0) {
        // Rota para resgatar a quantidade de usuários que irão ser renderizados
        getNumbersOfUsers(
          pageNumber,
          searchedUser,
          rowsPerPage,
          asAdmin,
          queryType
        ).then((res) => {
          const { data } = res;
          setUsers(data.items);
          setTotalPages(data.total === 0 ? data.total : data.total - 1);
          if (data.items.filter((f: any) => +f._id !== +userId)?.length === 0) {
            setLoading(false);
          }
        });
      }
      setUserEdited(false);
    }
  }, [
    asAdmin,
    pageNumber,
    rowsPerPage,
    searchedUser,
    userEdited,
    queryType,
    userId,
  ]);

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      users &&
      users
        .filter((f: any) => +f._id !== +userId)
        .map((m: any, i: number) => {
          return {
            id: i + 1,
            userId: m._id,
            name: m.name,
            username: m.username,
            email: m.email,
            company: m.company,
            startDate: m.creationTs,
            type: userIsAdmin
              ? t("TEXT.USER")
              : +userId === m._id
              ? t("TEXT.OWNER")
              : t("TEXT.SHARED"),
            actions: "",
            isClickable:
              m.arcsys.filter(
                (f: any) => f.role !== "OWNER" && f.pending === false
              ).length > 0,
          };
        }),
    [t, userId, userIsAdmin, users]
  );

  // Filtrar todos os Usuarios com o checkbox
  const checkIfIsAdminRows = React.useMemo(
    () =>
      !userIsAdmin
        ? rows
        : checked === true
        ? rows
        : rows?.filter((f: any) => f.userId !== +userId),
    [checked, rows, userId, userIsAdmin]
  );

  // Ações do modal de deletar
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenModalDelete = (e: any, id: string, user: string) => {
    e.stopPropagation();
    setModalDeleteValues({ id, user });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ação de atribuir id ao userId no localStorage
  const handleAssignUserId = (e: any, id: string) => {
    e.stopPropagation();

    // Armazenar novo id resgatado no localStorage
    // localStorage.setItem("userId", id);

    // // Resgatar os dados do usuário com o novo id atribuido
    // getUserById(+id).then((res) => {
    //   if (res?.data) {
    //     const { data } = res as any;
    //     // Resgatar os arcsys do usuário logado
    //     setUserArcsys(data);

    //     /* // Limpar dados
    //     setDevices([]);
    //     setDevicesOffline([]);
    //     setTagsList([]);
    //     setIsOfflineDevicesStored(false);
    //     setSectors([]);
    //     setMqtt({});
    //     setArcsys([]);
    //     setAccordionMacList([]); */

    //     toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
    //   }
    // });
  };

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "userId",
            hide: true,
          },
          {
            field: "email",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.NAME"),
            align: "left",
            headerAlign: "left",
            flex: 3,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.NAME")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <AvatarIcon name={row.name} bgcolor={red[400]} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                          }}
                        >
                          {row.name}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{ whiteSpace: "break-spaces", display: "inline" }}
                          variant="body2"
                        >
                          {row.email}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "username",
            headerName: t("TEXT.USERNAME"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "company",
            headerName: t("TEXT.COMPANY"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "startDate",
            headerName: t("TEXT.INCLUSION_DATE"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Typography variant="body2">
                {convertToDateInRow(row.startDate, localStorage.lang)}
              </Typography>
            ),
          },
          {
            field: "actions",
            sortable: false,
            hide: userIsAdmin === true && checked === true ? false : true,
            disableColumnMenu: true,
            headerName: t("TEXT.COMMANDS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <>
                <Tooltip title={`${t("BUTTON.DELETE")} ${t("TEXT.USER")}`}>
                  <IconButton
                    sx={{ p: 0 }}
                    color="error"
                    onClick={(e) =>
                      handleOpenModalDelete(e, row.userId, row.name)
                    }
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("TEXT.ASSIGN_ID")}>
                  <IconButton
                    sx={{ p: 0 }}
                    color="error"
                    onClick={(e) => handleAssignUserId(e, row.userId)}
                  >
                    <Person2OutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
          {
            field: "type",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.TYPE"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Chip
                label={row.type}
                color={row.type === t("TEXT.OWNER") ? "success" : "error"}
                sx={{
                  fontWeight: 700,
                  backgroundColor:
                    row.type === t("TEXT.OWNER") || row.type === t("TEXT.USER")
                      ? green[100]
                      : red[100],
                  color:
                    row.type === t("TEXT.OWNER") || row.type === t("TEXT.USER")
                      ? green[800]
                      : red[800],
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
              />
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "userId",
            hide: true,
          },
          {
            field: "username",
            hide: true,
          },
          {
            field: "email",
            hide: true,
          },
          {
            field: "company",
            hide: true,
          },
          {
            field: "startDate",
            hide: true,
          },
          {
            field: "type",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.USERS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ mt: 5.8 }}>
                      <AvatarIcon name={row.name} bgcolor={red[400]} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                            }}
                          >
                            {row.name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography variant="body2" sx={{ width: "10px" }}>
                            {row.email}
                          </Typography>
                          <Box display="flex" mt={0.5}>
                            <Typography
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.COMPANY")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.company}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.5}>
                            <Typography
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.INCLUSION_DATE")}:
                            </Typography>
                            <Typography variant="body2">
                              {convertToDateInRow(
                                row.startDate,
                                localStorage.lang
                              )}
                            </Typography>
                          </Box>
                          {userIsAdmin && checked === true && (
                            <Box display="flex" mt={1}>
                              <Typography
                                sx={{
                                  mr: 0.5,
                                  mt: 0.3,
                                  color: theme === "light" ? "black" : "white",
                                  fontSize: 13,
                                }}
                              >
                                {t("TEXT.COMMANDS")}:
                              </Typography>
                              <Tooltip
                                title={`${t("BUTTON.DELETE")} ${t(
                                  "TEXT.USER"
                                )}`}
                              >
                                <IconButton
                                  sx={{ p: 0 }}
                                  color="error"
                                  onClick={(e) =>
                                    handleOpenModalDelete(
                                      e,
                                      row.userId,
                                      row.name
                                    )
                                  }
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("TEXT.ASSIGN_ID")}>
                                <IconButton
                                  sx={{ p: 0 }}
                                  color="error"
                                  onClick={(e) =>
                                    handleAssignUserId(e, row.userId)
                                  }
                                >
                                  <Person2OutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                          <Chip
                            label={row.type}
                            color={
                              row.type === t("TEXT.OWNER") ? "success" : "error"
                            }
                            sx={{
                              fontWeight: 700,
                              backgroundColor:
                                row.type === t("TEXT.OWNER") ||
                                row.type === t("TEXT.USER")
                                  ? green[100]
                                  : red[100],
                              color:
                                row.type === t("TEXT.OWNER") ||
                                row.type === t("TEXT.USER")
                                  ? green[800]
                                  : red[800],
                              textTransform: "uppercase",
                              fontSize: 12,
                              mt: 1,
                            }}
                          />
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (rows && rows?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [loading, rows]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  // Ação de atualizar tabela
  const reloadTable = () => {
    setLoading(true);
    if (pageNumber > 0) {
      // Rota para resgatar a quantidade de usuários que irão ser renderizados
      getNumbersOfUsers(
        pageNumber,
        searchedUser,
        rowsPerPage,
        asAdmin,
        queryType
      ).then((res) => {
        const { data } = res;
        setUsers(data.items);
        setTotalPages(data.total === 0 ? data.total : data.total - 1);
        setSearchedUser("");
        setSearchedClicked("");
        if (data.items.filter((f: any) => +f._id !== +userId)?.length === 0) {
          setLoading(false);
        }
      });
    }
  };

  // Ação ao alterar a página da tabela de usuários
  const handleChangePage = (newPage: number) => {
    setPageNumber(newPage + 1);
  };

  return (
    <Box display="grid">
      <Box overflow="auto" whiteSpace="nowrap">
        <Box display="flex" justifyContent="center">
          <Card elevation={3} sx={{ width: 1500, p: mobile ? 0 : 1, mb: 2 }}>
            <CardContent sx={{ p: mobile ? 0 : 1 }}>
              <Box display="flex" justifyContent="space-between" ml={2}>
                <Typography
                  fontSize={mobile ? 22 : 25}
                  gutterBottom
                  sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                >
                  {t("TEXT.SHARED_USERS")}
                </Typography>
                <Box display="flex">
                  {!smallWindow && userIsAdmin && (
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={handleChangeAllUsers}
                              name="gilad"
                            />
                          }
                          label={t("TEXT.ALL_USERS")}
                        />
                      </FormGroup>
                    </FormControl>
                  )}
                  <Tooltip title={t("TEXT.UPDATE") as string}>
                    <IconButton
                      color="warning"
                      onClick={reloadTable}
                      sx={{ mt: mobile ? 0.5 : 0 }}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {smallWindow && userIsAdmin && (
                <Box sx={{ textAlign: "center" }} mt={1}>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeAllUsers}
                            name="gilad"
                          />
                        }
                        label={t("TEXT.ALL_USERS")}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              )}
              <Card elevation={0}>
                <CardContent>
                  {loading ? (
                    <Box
                      m={
                        mobile
                          ? "230px 0px"
                          : window.screen.width < 1550
                          ? "240px 0px"
                          : "418px 0px"
                      }
                    >
                      <Spinner />
                    </Box>
                  ) : (
                    <>
                      <FormControl sx={{ mt: 1 }}>
                        <FormLabel>{t("BUTTON.SEARCH")}:</FormLabel>
                        <RadioGroup
                          row
                          value={queryType}
                          onChange={handleChangeSearch}
                        >
                          <FormControlLabel
                            value="name"
                            control={<Radio />}
                            label={t("TEXT.NAME")}
                          />
                          <FormControlLabel
                            value="email"
                            control={<Radio />}
                            label={t("TEXT.EMAIL")}
                          />
                          <FormControlLabel
                            value="username"
                            control={<Radio />}
                            label={t("TEXT.USERNAME")}
                          />
                          <FormControlLabel
                            value="company"
                            control={<Radio />}
                            label={t("TEXT.COMPANY")}
                          />
                        </RadioGroup>
                      </FormControl>
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          mr: mobile ? 2 : 4,
                          borderRadius: 5,
                          width: "100%",
                          mt: 1,
                          mb: 3,
                          backgroundColor:
                            theme === "dark" ? "#181818" : "#F9F9F9",
                        }}
                      >
                        <Tooltip title={t("BUTTON.SEARCH")}>
                          <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                            onClick={(e: any) => {
                              handleSearch(searchedClicked);
                              e.preventDefault();
                              if (isSearched) {
                                setIsSearched(false);
                              }
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                        <InputBase
                          id="myInput"
                          onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                              handleSearch(e.target.value);
                              e.preventDefault();
                              if (isSearched) {
                                setIsSearched(false);
                              }
                            }
                          }}
                          onChange={(e: any) => {
                            setSearchedClicked(e.target.value);
                          }}
                          sx={{ ml: 2, flex: 1 }}
                          placeholder={t("TEXT.SEARCH_USER")}
                        />
                        <Tooltip title={t("TEXT.CLEAN")}>
                          <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                            onClick={handleClearSearch}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Paper>
                      <Paper
                        style={{
                          height: mobile
                            ? 450
                            : mediumPage
                            ? 835
                            : window.screen.width < 1550
                            ? 480
                            : 835,
                          cursor: "pointer",
                        }}
                        elevation={0}
                      >
                        <DataGridServerSide
                          rows={checkIfIsAdminRows}
                          columns={columns}
                          message={t("TEXT.NO_USER")}
                          getRowHeight={() => "auto"}
                          onRowClick={(params: any) => {
                            params.row.isClickable === true
                              ? navigate(`${PROFILE}/${params.row.userId}`)
                              : toastError(
                                  t("TOAST.ERROR.ACCESS_USER_PROFILE")
                                );
                          }}
                          onPageChange={(newPage: number) => {
                            handleChangePage(newPage);
                          }}
                          onPageSizeChange={(rowsPerPage: any) =>
                            setRowsPerPage(rowsPerPage)
                          }
                          pageNumber={pageNumber}
                          totalPages={totalPages}
                          disableSelectionOnClick
                        />
                      </Paper>
                    </>
                  )}
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Box>
      </Box>
      {modalDeleteValues && (
        <ModalRemove
          setUserEdited={setUserEdited}
          id={modalDeleteValues.id}
          name={modalDeleteValues.user}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
    </Box>
  );
};

export default UsersPage;
