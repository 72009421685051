import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { ToastContext } from "../../../../../contexts/ToastContext";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { TextField, yupRequiredString } from "../../../../Form";
import { TOKEN_KEY } from "../../../../../auth/constants";
import { api } from "../../../../../services/api";
import { createTag } from "../../../../../services/data/TAGs";
import Modal from "../Modal";
import { useParams } from "react-router-dom";
import ErrorMessage from "../../../../ErrorMessage";
import { TagsContext } from "../../../../../contexts/TagsContext";

interface ModalAddTagProps {
  handleOpen: boolean;
  handleClose: () => void;
  setTagEdited: (e: boolean) => void;
  ctrlId?: string;
}

const ModalAddTag = ({
  handleOpen,
  handleClose,
  setTagEdited,
  ctrlId,
}: ModalAddTagProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated, userId } = React.useContext(AuthContext);
  const { setGlobalTagEdited } = React.useContext(TagsContext);

  const maxLength = 20;

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const data = {
          user_id: +userId,
          tag_name: values.name.toUpperCase(),
          ctrl_ids: ctrlId
            ? ctrlId
            : params.mac
            ? `${params.mac}/${params.ctrl}`
            : [],
        };

        // Adicionar TAG
        createTag(data)
          .then(() => {
            setTagEdited(true);
            setGlobalTagEdited(true);
            handleClose();
            formik.resetForm();
            toastSuccess(t("TOAST.SUCCESS.CREATE_TAG"));
          })
          .catch(() => {
            toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION"));
          });
      }
    },
  });

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  const isFormValid =
    formik.dirty && formik.isValid && formik.values.name.length < 21;

  return (
    <Modal open={handleOpen} handleClose={closeModal} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <AddCircleOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.CREATE_TAG")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            label={t("TEXT.NAME")}
            name="name"
            fullWidth
            inputProps={{ style: { textTransform: "uppercase" }, maxLength }}
          />
          {formik.errors.name && formik.touched.name ? (
            <ErrorMessage>{formik.errors.name}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: red[400],
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={closeModal}
            fullWidth
            sx={{
              backgroundColor: red[50],
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalAddTag;
