import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Menu as MenuIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Divider,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
} from "@mui/x-data-grid";

import useIsMobile, { useIsMediumPage } from "../../../../hooks/useIsMobile";
import { getLanguage } from "../../../../translations/languages";
import { parseIcon } from "./Icons";
import {
  changePositionOfTitles,
  convertIcons,
  csvFile,
  file,
  filterTitleCsv,
  reorderColumsPdf,
  titlesCsv,
  valuesPrint,
} from "./parse";
import { Data } from "./type";
import { exportToXls } from "../../../../services/data/report";
import { saveAs } from "file-saver";
import DataGridTable from "../../../../components/DataGrid/DataGridTable";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getRawCtrlConfig } from "../../../../config";
import { arcsysLogo, arcsysLogo2 } from "../../parse";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";

interface RecordTableProps {
  beginTs: number;
  endTs: number;
  macId: string;
  mac: string;
  rotulo: string;
  data: Data[];
  modelo: string;
  recordsTitle: string;
}

const RecordTable = ({
  beginTs,
  endTs,
  macId,
  data,
  modelo,
  recordsTitle,
  mac,
  rotulo,
}: RecordTableProps) => {
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeModeContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const mobile = useIsMobile();
  const mediumPage = useIsMediumPage();
  const language = getLanguage().code;
  const checkLanguage = language === "en" ? "en-US" : "pt-BR";
  const checkLanguageToXls =
    language === "en" ? "en" : language === "pt" ? "pt-br" : "es";

  // DD/MM/YYYY
  const date = new Date().toLocaleString(checkLanguage).slice(0, 10);

  // Abre o menu de opções de exportações de arquivo
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Fecha o menu de opções de exportações de arquivo
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Título do arquivo exportado
  const fileName = file(date, language, rotulo, t("TEXT.RECORDS"));

  // XX-XX-XX-XX-XX-XX/CTRLXX
  const ctrlId = `${mac.slice(0, 17)}%2F${mac.slice(18, 25)}`;

  // Ação de exportar arquivo em .xlsx
  const downloadXlsFile = () => {
    exportToXls(macId, ctrlId, endTs, beginTs, checkLanguageToXls, modelo).then(
      (res) => {
        const { data } = res;

        const file = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(file, fileName + ".xlsx");
      }
    );
  };

  // Ação de exportar arquivo em .csv
  const downloadCsvFile = () => {
    csvFile(
      mac.slice(0, 17),
      modelo,
      parseInt(mac.split("CTRL")[1]),
      date,
      convertIcons(data, modelo),
      language,
      rotulo,
      t("TEXT.RECORDS")
    );
    handleClose();
  };

  // Lista de títulos das formas de exportações
  const menuList = ["Download .XLS", "Download .CSV", t("TEXT.PRINT")];

  // Colunas da tabela no modo web
  const columns: any = React.useMemo(
    () =>
      reorderColumsPdf(filterTitleCsv(titlesCsv(data, language)[0]))?.map(
        (v: string) => {
          return v === "icon"
            ? {
                field: "icon",
                hide: true,
              }
            : v === "mobile"
            ? {
                field: "mobile",
                hide: true,
              }
            : v === "Data"
            ? {
                field: v,
                type: "dateTime",
                headerName: t("TEXT.DATE"),
                width: 130,
                align: "center",
                headerAlign: "center",
              }
            : v === "Hora"
            ? {
                field: v,
                type: "dateTime",
                headerName: t("TEXT.HOUR"),
                width: 130,
                align: "center",
                headerAlign: "center",
              }
            : v === "Estado"
            ? {
                field: v === "Estado" ? "state" : v,
                filterOperators: getGridStringOperators().filter(
                  (operator) =>
                    operator.value !== "isAnyOf" &&
                    operator.value !== "isEmpty" &&
                    operator.value !== "isNotEmpty"
                ),
                headerName: t("TEXT.STATE"),
                flex: mobile ? 0 : 1,
                width: mobile ? 220 : 0,
                align: "center",
                renderCell: ({ row }: any) =>
                  row.state === "--" ? (
                    <Box>--</Box>
                  ) : (
                    row.icon.split(",").map((r: string, i: number) => {
                      const valuesKeys = Object.keys(row);
                      for (let i = 0; i < valuesKeys.length; i++) {
                        if (Number.isNaN(row[valuesKeys[i]])) {
                          row[valuesKeys[i]] = "--";
                        }
                      }

                      const title =
                        row.state[i] !== undefined ? row.state[i] : "";
                      return (
                        <Tooltip
                          title={title}
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          enterTouchDelay={0}
                        >
                          <Box mr={0.8}>{parseIcon(r, theme ?? "light")}</Box>
                        </Tooltip>
                      );
                    })
                  ),
                headerAlign: "center",
              }
            : v === "Alarme"
            ? {
                field: v === "Alarme" ? "alarm" : v,
                filterOperators: getGridStringOperators().filter(
                  (operator) =>
                    operator.value !== "isAnyOf" &&
                    operator.value !== "isEmpty" &&
                    operator.value !== "isNotEmpty"
                ),
                headerName: t("TEXT.ALARM"),
                flex: mobile ? 0 : 1,
                width: mobile ? 220 : 0,
                align: "center",
                headerAlign: "center",
              }
            : {
                field: v === "Estado" ? "state" : v === "Alarme" ? "alarm" : v,
                filterOperators: getGridNumericOperators().filter(
                  (operator) =>
                    operator.value !== "isAnyOf" &&
                    operator.value !== "isEmpty" &&
                    operator.value !== "isNotEmpty"
                ),
                type: "number",
                headerName: v,
                flex: mobile ? 0 : 1,
                width: mobile ? 220 : 0,
                align: "center",
                headerAlign: "center",
                renderCell: ({ row }: any) => {
                  return (
                    <Typography>
                      {row[v] === undefined
                        ? "--"
                        : row[v]?.toString().includes(".") ||
                          row[v]?.toString().includes("--")
                        ? row[v]
                        : `${row[v]}.0`}
                    </Typography>
                  );
                },
              };
        }
      ),
    [data, language, mobile, t, theme]
  );

  // Colunas da tabela no modo mobile
  const columnsMobile: GridColDef[] = React.useMemo(
    () => [
      {
        field: "mobile",
        headerName: t("TEXT.RECORDS"),
        align: "center",
        headerAlign: "center",
        flex: 1,
        renderCell: ({ row }: any) => {
          const rowValues: any[] = Object.values(row);
          const rowkeys = Object.keys(row);

          return (
            <Box>
              {rowValues.map(
                (_o, i: number) =>
                  rowkeys[i] !== "id" &&
                  rowkeys[i] !== "icon" &&
                  rowkeys[i] !== "mobile" &&
                  rowkeys[i] !== "time" && (
                    <Box
                      key={i}
                      display="flex"
                      mt={rowkeys[i] === "alarm" ? 0 : 1}
                    >
                      <Typography fontSize={14} fontWeight={600} mr={1}>
                        {rowkeys[i] === "alarm"
                          ? t("TEXT.ALARM")
                          : rowkeys[i] === "state"
                          ? t("TEXT.STATE")
                          : rowkeys[i] === "Data"
                          ? t("TEXT.DATE")
                          : rowkeys[i] === "Hora"
                          ? t("TEXT.HOUR")
                          : rowkeys[i]}
                        :
                      </Typography>
                      {rowkeys[i] === "state" ? (
                        row.state === "--" ? (
                          <Box>--</Box>
                        ) : (
                          row.icon.split(",").map((r: string, i: number) => {
                            const valuesKeys = Object.keys(row);
                            for (let i = 0; i < valuesKeys.length; i++) {
                              if (Number.isNaN(row[valuesKeys[i]])) {
                                row[valuesKeys[i]] = "--";
                              }
                            }

                            return (
                              <Box key={i} mr={0.8}>
                                {parseIcon(r, theme ?? "light")}
                              </Box>
                            );
                          })
                        )
                      ) : (
                        <Box>
                          {rowValues[i] === undefined
                            ? "--"
                            : rowValues[i]?.toString().includes(".") ||
                              rowValues[i]?.toString().includes("--")
                            ? rowValues[i]
                            : `${rowValues[i]}.0`}
                        </Box>
                      )}
                    </Box>
                  )
              )}
            </Box>
          );
        },
      },
    ],
    [t, theme]
  );

  // Linhas da tabela
  const rowsDataGrid: any[] = React.useMemo(
    () => convertIcons(data, modelo),
    [data, modelo]
  );

  // Nome do modelo
  const name = getRawCtrlConfig(modelo)?.nome;

  // Títulos do PDF dos 'Registros'
  const titles: string[] = reorderColumsPdf(
    filterTitleCsv(titlesCsv(data, language)[0])
  )
    ?.filter((f: any) => f !== "id")
    .map((m: any) => {
      if (m === "Estado") {
        return undefined;
      }
      if (m === "Data") {
        return `${t("TEXT.DATE")} - ${t("TEXT.HOUR")}`;
      }
      if (m === "Hora") {
        return undefined;
      }
      if (m === "Alarme") {
        return t("TEXT.ALARM");
      }
      return m;
    })
    .filter((f: any) => f !== undefined);

  // Valores das linhas do PDF ao imprimir tabela de 'Registros'
  const valuesFromPrint: any[] = React.useMemo(
    () => valuesPrint(rowsDataGrid),
    [rowsDataGrid]
  );

  const recordTableRef: any = useRef();

  return (
    <>
      <style>
        {/* Style da exportação de imprimir Relatório */}
        {`.print-header, .print-footer, .show-on-print {
            display: none;
          }
          @page {
            size: A4;
            margin: 1cm;
          }
          @media print {
            .print-content {
              padding-top: 0mm;
              padding-bottom: 0mm;
              margin: 10mm;
              margin-left: 2mm;
              margin-right: 2mm;
            }
            .print-header, .print-footer {
              display: block;
              width: 100%;
              margin-left: 8mm;
            }
            .print-header {
              padding-top: 2mm;
            }
            .print-footer {
              position: relative;
              bottom: 0;
              padding-bottom: 5mm;
            }
            .hide-on-print {
              display: none;
            }
            .show-on-print {
              display: block;
            }
          }
        `}
      </style>
      <Box>
        <Box mb={1} textAlign="right">
          <Divider />
          {rowsDataGrid.length > 0 ? (
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              color="primary"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box mt={5} />
          )}
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            {menuList?.map((option) => (
              <ReactToPrint
                content={() => recordTableRef.current}
                key={option}
                bodyClass={"print-content"}
                documentTitle={rotulo}
              >
                <PrintContextConsumer>
                  {({ handlePrint }: any) => (
                    <MenuItem
                      onClick={() =>
                        option === "Download .XLS"
                          ? downloadXlsFile()
                          : option === "Download .CSV"
                          ? downloadCsvFile()
                          : handlePrint()
                      }
                      sx={{ justifyContent: "center" }}
                    >
                      {option}
                    </MenuItem>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            ))}
          </Menu>
        </Box>
        <Box pt={2} pb={0}>
          <FormLabel sx={{ fontSize: 24 }}>{rotulo}</FormLabel>
        </Box>
        <Typography textAlign="center" pt={0} pb={3} variant="subtitle1">
          {recordsTitle}
        </Typography>
        {rowsDataGrid.length === 0 ? (
          <Box display="inline-table">
            <Alert
              severity="info"
              sx={{
                mt: 3,
                mb: 3,
                textAlign: "center",
                placeContent: "center",
                width: 310,
              }}
            >
              {t("TOAST.INFO.NO_DATA_RECORD")}
            </Alert>
          </Box>
        ) : (
          <Paper
            style={{
              height: mobile
                ? 450
                : mediumPage
                ? 550
                : window.screen.width < 1550
                ? 450
                : 550,
            }}
            elevation={0}
          >
            <DataGridTable
              rows={rowsDataGrid}
              columns={mobile || mediumPage ? columnsMobile : columns}
              message={t("TOAST.INFO.NO_DATA_RECORD")}
              rowHeight={
                mobile || mediumPage
                  ? changePositionOfTitles(data, language)!.length * 28
                  : 70
              }
              rowsPerPageOptions={[10, 50, 100]}
            />
            <div ref={recordTableRef}>
              <div className="print-header">
                <Box display="flex" justifyContent="space-between" mr={5}>
                  <Box>
                    <Box>
                      <FormLabel sx={{ fontSize: 24 }}>{rotulo}</FormLabel>
                    </Box>
                    <Typography pt={0} pb={3} variant="subtitle1">
                      {recordsTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Typography fontWeight="bold">MAC:</Typography>
                      <Typography pl={0.5}>{mac?.slice(0, 17)}</Typography>
                    </Box>
                    <Box display="flex">
                      <Typography fontWeight="bold">
                        {t("TEXT.MODEL")}:
                      </Typography>
                      <Typography pl={0.5}>{name}</Typography>
                    </Box>
                    <Box display="flex">
                      <Typography fontWeight="bold">
                        {t("TEXT.ADDRESS")}:
                      </Typography>
                      <Typography pl={0.5}>{mac?.split("CTRL")[1]}</Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className="print-content">
                <Box pt={2} pb={0} className="hide-on-print">
                  <FormLabel sx={{ fontSize: 24 }}>{rotulo}</FormLabel>
                </Box>
                <Table id="content">
                  <TableHead>
                    <TableRow>
                      {titles?.map((m, i) => (
                        <TableCell align="center" key={i} colSpan={4}>
                          {m}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {valuesFromPrint.map((m: any) => (
                      <TableRow key={m}>
                        {m.map((row: any, i: number) => (
                          <TableCell align="center" key={i} colSpan={4}>
                            {row}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="print-footer">
                <Box display="flex" justifyContent="space-between" mr={7}>
                  <img
                    src={arcsysLogo}
                    alt="ArcSys Cloud"
                    style={{ width: "90px", height: "44px" }}
                  />
                  <img
                    src={arcsysLogo2}
                    alt="ArcSys Cloud"
                    style={{ width: "90px", height: "44px" }}
                  />
                </Box>
              </div>
            </div>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default RecordTable;
