import { Box, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";

interface FormInputsProps {
  title: string;
  hours: string;
  minutes: string;
  seconds: string;
  setValue: (e: any) => void;
}

const FormInputs = ({
  title,
  hours,
  minutes,
  seconds,
  setValue,
}: FormInputsProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Box>
      <InputLabel sx={{ mb: 1.5, mt: 2 }}>{title}</InputLabel>
      <Box display={mobile ? "grid" : "flex"}>
        <TextField
          type="number"
          label={t("TEXT.HOURS")}
          fullWidth
          value={hours}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue({
              hours: +event.target.value,
              minutes: +minutes,
              seconds: +seconds,
            });
          }}
        />
        <TextField
          type="number"
          label={t("TEXT.MINUTES")}
          fullWidth
          value={minutes}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue({
              hours: +hours,
              minutes: +event.target.value,
              seconds: +seconds,
            });
          }}
          sx={mobile ? { mt: 2, mb: 2 } : { ml: 2, mr: 2 }}
        />
        <TextField
          type="number"
          label={t("TEXT.SECONDS")}
          fullWidth
          value={seconds}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue({
              hours: +hours,
              minutes: +minutes,
              seconds: +event.target.value,
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default FormInputs;
