import React from "react";

import {
  Alert,
  Box,
  FormLabel,
  Grid,
  List,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";

interface PlanTermsProps {
  planName: string;
  benefits: string[];
}

const PlanTerms = ({ planName, benefits }: PlanTermsProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { theme } = React.useContext(ThemeModeContext);

  return (
    <>
      <Box mt={2} mb={2} textAlign="center">
        <FormLabel sx={{ fontSize: 19 }}>
          {t("TEXT.PLANS_CONTRACT_TERMS_TITLE")}
        </FormLabel>
      </Box>
      <Box
        ml={mobile ? 0 : 2}
        pt={2}
        pb={2}
        sx={{
          backgroundColor: theme === "light" ? "#F8F8F8" : "#232323",
          borderRadius: "10px",
          border: "1px solid #D4D4D4",
        }}
      >
        <Box pl={3} display="flex" mt={1}>
          <Typography fontWeight={600} fontSize={20} mr={0.8}>
            A.
          </Typography>
          <Typography
            fontWeight={600}
            fontSize={16.5}
            mt={0.1}
            alignSelf="center"
          >
            {t("TEXT.PLANS_CONTRACT_TERMS_SUBTITLE_1")}:
          </Typography>
        </Box>
        <Typography mt={1} pl={3}>
          {t("TEXT.UNDER_THE_PLAN")}
          <strong>{` ${planName}`}</strong>,{" "}
          {t("TEXT.PLANS_PERMISSION_1_SUBTITLE")}:
        </Typography>
        <Box
          display="grid"
          pl={3}
          mb={2.5}
          mt={1}
          sx={{
            maxHeight: 185,
            overflowX: "hidden",
          }}
        >
          <List
            sx={{
              display: "flex",
              flexFlow: "row wrap",
              alignContent: "space-between",
              justifyContent: "left",
              pl: mobile ? 0 : 2,
            }}
          >
            {benefits && benefits.length > 0 ? (
              <Grid
                container
                spacing={{ xs: 3, md: 3 }}
                columns={{ xs: 3, sm: 4, md: 8 }}
              >
                {benefits.map((m, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Box display="flex">
                      <CheckIcon color="success" />
                      <Typography ml={1}>{m}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box display="flex" justifyContent="center">
                <Box />
                <Alert
                  severity="info"
                  sx={{
                    textAlign: "center",
                    placeContent: "center",
                    width: 290,
                  }}
                >
                  {t("TEXT.NO_BENEFITS")}
                </Alert>
                <Box />
              </Box>
            )}
          </List>
        </Box>
        <Box display="flex" mb={2} pl={3}>
          <Typography fontWeight={600} fontSize={20} mr={0.8}>
            B.
          </Typography>
          <Typography
            fontWeight={600}
            fontSize={16.5}
            mt={0.1}
            alignSelf="center"
          >
            {t("TEXT.PLANS_CONTRACT_TERMS_SUBTITLE_2")}:
          </Typography>
        </Box>
        <Box
          pt={2}
          pb={2}
          sx={{
            maxHeight: mobile ? 200 : window.screen.width < 1550 ? 200 : 330,
            overflow: "scroll",
            overflowX: "hidden",
            textAlign: "justify",
            border: "1px solid #D4D4D4",
            borderLeft: "0px",
          }}
        >
          <Stepper orientation="vertical" sx={{ ml: mobile ? 0 : 4 }}>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={500}>
                  {t("TEXT.PLANS_PERMISSION_2_SUBTITLE_1")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.PLANS_PERMISSION_2_TEXT_1")}
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={500}>
                  {t("TEXT.PLANS_PERMISSION_2_SUBTITLE_2")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.PLANS_PERMISSION_2_TEXT_2")}
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={500}>
                  {t("TEXT.PLANS_PERMISSION_2_SUBTITLE_3")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.PLANS_PERMISSION_2_TEXT_3")}
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={500}>
                  {t("TEXT.PLANS_PERMISSION_2_SUBTITLE_4")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.PLANS_PERMISSION_2_TEXT_4")}
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={500}>
                  {t("TEXT.PLANS_PERMISSION_2_SUBTITLE_5")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.PLANS_PERMISSION_2_TEXT_5")}
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={500}>
                  {t("TEXT.PLANS_PERMISSION_2_SUBTITLE_6")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.PLANS_PERMISSION_2_TEXT_6")}
              </StepContent>
            </Step>
          </Stepper>
        </Box>
        <Box display="flex" mb={2} mt={2} pl={3}>
          <Typography fontWeight={600} fontSize={20} mr={0.8}>
            C.
          </Typography>
          <Typography
            fontWeight={600}
            fontSize={16.5}
            mt={0.1}
            alignSelf="center"
          >
            {t("TEXT.PLANS_CONTRACT_TERMS_SUBTITLE_3")}:
          </Typography>
        </Box>
        <Typography mt={1} pl={3} pr={1} textAlign="justify">
          {t("TEXT.PLANS_PERMISSION_3_TEXT")}
        </Typography>
      </Box>
    </>
  );
};

export default PlanTerms;
