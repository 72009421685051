import { Grid } from "@mui/material";
import Structure from "./Structure";

interface CardProps {
  data: any[];
  setSectorEdited: (e: boolean) => void;
  deviceUsers: any;
}

const Card = ({ data, setSectorEdited, deviceUsers }: CardProps) => {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        placeContent: window.innerWidth > 1547 ? "flex-start" : "center",
      }}
    >
      {data &&
        data.length > 0 &&
        data.map((m, i) => {
          return (
            <Structure
              key={i}
              data={m}
              setSectorEdited={setSectorEdited}
              isInModal={false}
              deviceUsers={deviceUsers}
            />
          );
        })}
    </Grid>
  );
};

export default Card;
