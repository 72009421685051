import { Edit as EditIcon } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";

import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";

const EditButton = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return mobile ? (
    <IconButton color="primary" sx={{ p: 0 }}>
      <EditIcon color="warning" />
    </IconButton>
  ) : (
    <Button
      size="small"
      color="warning"
      startIcon={<EditIcon />}
      sx={{ fontSize: 12.5, fontWeight: 600 }}
    >
      {t("BUTTON.EDIT")}
    </Button>
  );
};

export default EditButton;
