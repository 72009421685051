import React from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Loyalty as LoyaltyIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../contexts/ToastContext";
import { Select, yupRequiredString } from "../../../components/Form";
import ErrorMessage from "../../../components/ErrorMessage";
import { red } from "@mui/material/colors";
import Modal from "../../Devices/Modal";
import { addSubscriptionInMAC } from "../../../services/data/subscriptions";
import AvatarIcon from "../../../components/AvatarIcon";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import useIsMobile from "../../../hooks/useIsMobile";
import Spinner from "../../../components/Spinner";
import { TOKEN_KEY } from "../../../auth/constants";
import { getNumbersOfDevices } from "../../../services/data/arcsys";
import { api } from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthContext";

interface ModalAddPlanProps {
  plan: string;
  benefits: any[];
  setPlanEdited: (e: boolean) => void;
  id: string;
  handleOpen: boolean;
  handleClose: () => void;
  userIsAdmin: boolean;
}

const ModalAddPlan = ({
  benefits,
  plan,
  setPlanEdited,
  id,
  handleOpen,
  handleClose,
  userIsAdmin,
}: ModalAddPlanProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { theme } = React.useContext(ThemeModeContext);
  const { token, authenticated } = React.useContext(AuthContext);
  const [pages, setPages] = React.useState<number>(1);
  const [devices, setDevices] = React.useState<any>([]);
  const [loadingDevices, setLoadingDevices] = React.useState(false);
  const [payloadDevices, setPayloadDevices] = React.useState<any>([]);
  const [notShow, setNotShow] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      mac: "",
    },
    validationSchema: Yup.object({
      mac: yupRequiredString,
    }),
    onSubmit: (values) => {
      // Adicionar endereço MAC no Plano disponível selecionado
      addSubscriptionInMAC(values.mac, id)
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.ADD_PLAN"));
          setPlanEdited(true);
          handleClose();
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.ADD_PLAN"));
        });
    },
  });

  // Lista de endereço MACs disponíveis
  const options = devices.map((value: any, i: number) => (
    <MenuItem value={value.mac} key={i}>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemAvatar sx={{ alignSelf: "center", mt: 0 }}>
            <AvatarIcon name={value.name} bgcolor={red[400]} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  sx={{
                    display: "inline",
                    fontWeight: 450,
                    whiteSpace: "break-spaces",
                  }}
                >
                  {value.name}
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Box display="flex" mt={0.2} component="span">
                  <Typography
                    component="span"
                    fontWeight={500}
                    sx={{
                      mr: 0.5,
                      mt: 0.1,
                      color: theme === "light" ? "black" : "white",
                      fontSize: 13,
                    }}
                  >
                    MAC:
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ display: "inline" }}
                    variant="body2"
                  >
                    {value.mac}
                  </Typography>
                </Box>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </MenuItem>
  ));

  // Resgatar os Dispositivos para a lista de adicionar Plano
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        // Rota para resgatar a quantidade de dispositivos que irão ser renderizados
        getNumbersOfDevices(true, pages, 10, "Free", 0, "plan").then((res) => {
          if (res?.data) {
            const payloadData = res.data.items;
            if (res.data.this_item_list_qtn === res.data.total) {
              setNotShow(true);
            }
            setPayloadDevices(payloadData);
            setDevices((prevOptions: any) => [...prevOptions, ...payloadData]);
            setLoadingDevices(false);
          }
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, token, userIsAdmin, pages]);

  // Adicionar novos dados no select dos Dispositivos
  const handleLoadMore = () => {
    setPages((prevPage) => prevPage + 1);
  };

  // Adicionar novos dados no select dos Dispositivos
  const addMoreItens = (
    <MenuItem>
      <Button
        onClick={(e: any) => {
          e.stopPropagation();
          handleLoadMore();
        }}
        disabled={loadingDevices}
        fullWidth
        variant="outlined"
      >
        {loadingDevices ? <Spinner size={24} /> : t("TEXT.CHARGE_MORE_DEVICES")}
      </Button>
    </MenuItem>
  );

  // Adicionar o botão 'addMoreItems' na lista de dispositivos
  const allMenuItems =
    notShow === true || payloadDevices.length === 0
      ? options
      : [...options, addMoreItens];

  // Validação do formulário
  const isFormValid = formik.dirty && formik.isValid;

  // Limpa o formulário e fecha o modal
  const handleCloseModal = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      open={handleOpen}
      handleClose={handleCloseModal}
      width={mobile ? 340 : 380}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <AddIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography variant="body2" fontSize={21} mt={1}>
        {t("TEXT.ADD_PLAN")}
        <strong style={{ paddingLeft: "5px" }}>{plan}</strong>?
      </Typography>
      <Box display="grid" mt={3}>
        <Typography fontSize={15} fontWeight={700}>
          {t("TEXT.BENEFITS")}:
        </Typography>
        <Box
          sx={{
            maxHeight: mobile ? 120 : 150,
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <List>
            {benefits && benefits.length > 0 ? (
              benefits.map((m, i: number) => (
                <ListItem key={i}>
                  <ListItemIcon>
                    <LoyaltyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography component="span">{m}</Typography>}
                  />
                </ListItem>
              ))
            ) : (
              <Box display="flex" justifyContent="center">
                <Box />
                <Alert
                  severity="info"
                  sx={{
                    textAlign: "center",
                    placeContent: "center",
                    width: 310,
                  }}
                >
                  {t("TEXT.NO_BENEFITS")}
                </Alert>
                <Box />
              </Box>
            )}
          </List>
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        {devices.length > 0 ? (
          <Box display="grid" mt={1}>
            <Typography fontSize={14.5} fontWeight={700} mb={1.5}>
              {t("TEXT.SELECT_DEVICE")}:
            </Typography>
            <Select
              formik={formik}
              label={t("TEXT.SELECT_DEVICE")}
              name="mac"
              options={allMenuItems}
              disabled={false}
              sx={
                formik.values.mac !== ""
                  ? {
                      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          p: 0,
                        },
                      "& .css-qiwgdb": {
                        p: 0,
                      },
                    }
                  : {}
              }
            />
            {formik.errors.mac && formik.touched.mac ? (
              <ErrorMessage>{formik.errors.mac}</ErrorMessage>
            ) : null}
          </Box>
        ) : (
          <>
            <Divider />
            <Box display="flex" justifyContent="center" mt={2.5}>
              <Alert
                severity="info"
                sx={{
                  textAlign: "center",
                  placeContent: "center",
                  width: 310,
                }}
              >
                {t("TEXT.NO_MAC_ADDRESS")}
              </Alert>
              <Box />
            </Box>
          </>
        )}
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: red[400],
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.ADD")}
          </Button>
          <Button
            onClick={handleCloseModal}
            fullWidth
            sx={{
              backgroundColor: red[50],
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalAddPlan;
