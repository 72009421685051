import * as React from "react";

import {
  Assessment as AssessmentIcon,
  MoreVert as MoreVertIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import Items from "./Items";

export interface ItemsProps {
  text: string;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">> & {
    muiName: string;
  };
  requireAdmin: boolean;
}

const listItems: ItemsProps[] = [
  {
    text: "TEXT.REPORTS",
    icon: AssessmentIcon,
    requireAdmin: false,
  },
  {
    text: "TEXT.REMOVE",
    icon: DeleteOutlineIcon,
    requireAdmin: false,
  },
];

interface CardProps {
  id: string;
  ctrl: string;
  addr: number;
  modelFound: boolean;
  comError: boolean;
  modelo: string;
  setpointsector?: string | undefined;
  handleOpenModalDelete?: () => void;
  checked?: boolean;
}

const Card = ({
  id,
  ctrl,
  addr,
  modelFound,
  comError,
  modelo,
  setpointsector,
  handleOpenModalDelete,
  checked,
}: CardProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Desabilita o redirecionamento ao clicar no link do Card
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    // Desabilita o redirecionamento ao clicar no link do Card
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          pt: 1,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Items
          id={id}
          ctrl={ctrl}
          addr={addr}
          modelFound={modelFound}
          handleClose={handleClose}
          listItems={listItems}
          comError={comError}
          modelo={modelo}
          setpointsector={setpointsector}
          handleOpenModalDelete={handleOpenModalDelete}
          checked={checked}
        />
      </Menu>
    </>
  );
};

export default Card;
