import ControllerContainer from "../containers/Controller/Controller";
import { ControllerProvider } from "../contexts/ControllerContext";
import { ControllerEditProvider } from "../contexts/ControllerEditContext";

// Página do Controlador recebendo o Context
const Controller = () => (
  <ControllerProvider>
    <ControllerEditProvider>
      <ControllerContainer />
    </ControllerEditProvider>
  </ControllerProvider>
);

export default Controller;
