import { getRawCtrlConfig } from "../../../../config";
import { LanguageCode } from "../../../../translations/types";
import { Data } from "./type";

const ctrlSettings = (model: string) => getRawCtrlConfig(model);

//  Adicionar 0 antes em númers de 0 a 9
const addZero = (i: any) => {
  if (i < 10) {
    // eslint-disable-next-line no-param-reassign
    i = `0${i}`;
  }
  return i;
};

// Converter o timestamp em horas:minutos:segundos
const convertToTime = (timestamp: number) => {
  const date = new Date(timestamp);
  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());
  const seconds = addZero(date.getSeconds());
  const time = `${hours}:${minutes}:${seconds}`;
  return time;
};

// Adicionar timestamp na variavel 'Hora'
const addTimeToData = (data: any) =>
  data.map((x: any) => {
    // eslint-disable-next-line no-param-reassign
    x.Hora = convertToTime(x.time);
    return x;
  });

// Adicionar index na variavel 'id'
const addIdToData = (data: any) =>
  addTimeToData(data)
    .filter((f: any) => f.length === undefined)
    .map((x: any, i: any) => {
      // eslint-disable-next-line no-param-reassign
      x.id = i + 1;
      return x;
    });

// Converter timestamp em 'DD/MM/AAAA'
const convertToDateInRow = (timestamp: number, language: LanguageCode) => {
  const FullDate = new Date(timestamp).toLocaleDateString(
    language === "en" ? "en-US" : "pt-BR",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
  );
  const fullDate = `${FullDate}`;
  return fullDate;
};

// Adicionar 'DD/MM/AAAA' na variavel 'Data'
const addDateToData = (data: any, language: LanguageCode) =>
  addIdToData(data).map((x: any) => {
    // eslint-disable-next-line no-param-reassign
    x.Data = convertToDateInRow(x.time, language);
    return x;
  });

// Converter variaveis 'state' e 'alarm' para 'Estado' e 'Alarme'
const titles = (data: any, language: LanguageCode) =>
  addDateToData(data, language).map((k: Data[]) => {
    const newArr = Object.keys(k).map((element) => {
      if (element === "state") {
        return "Estado";
      }
      if (element === "alarm") {
        return "Alarme";
      }
      return element;
    });
    return newArr;
  }) as any[];

// Título dos relatórios
export const reportTitle = (
  firstDate: Date,
  lastDate: Date,
  language: LanguageCode,
  to: string,
  of: string
) =>
  `${of} ${convertToDateInRow(+firstDate, language)} ${to} ${convertToDateInRow(
    +lastDate,
    language
  )}`;

// Filtrar títulos diferentes de 'time' e 'id'
const filterTitle = (data: any, language: LanguageCode) =>
  titles(data, language)[0]?.filter((value: string) => {
    return value !== "time" && value !== "id";
  }) as string[];

// Alterar a posicionamento da array
export const arrayMove = (arr: any, oldIndex: number, newIndex: number) => {
  if (newIndex >= arr?.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr?.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

// Alterar o posicionamento dos títulos para renderizar as colunas em posições diferentes
export const changePositionOfTitles = (data: any, language: LanguageCode) => {
  if (data && data.length > 0) {
    const changeDataAndHora = filterTitle(data, language)?.reverse();
    const searchEstado = changeDataAndHora?.indexOf("Estado");
    const searchAlarme = changeDataAndHora?.indexOf("Alarme");
    const checkLength = changeDataAndHora && changeDataAndHora.length - 1;

    const changeEstado = arrayMove(
      changeDataAndHora,
      searchEstado,
      checkLength
    );
    const checkEstadoLength = changeEstado && changeEstado.length - 1;

    const changeAlarme = arrayMove(
      changeDataAndHora,
      searchAlarme,
      checkEstadoLength
    );

    const addMobileLabel = [...changeAlarme, "mobile"];
    return addMobileLabel;
  }
};

// Converter o 'estado' para binário
const convertStateToBinary = (n: number) =>
  n
    ?.toString(2)
    .split("")
    .reverse()
    .map((i) => +i);

// Converter para binário
const binaryState = (state: any) => convertStateToBinary(state);

// Retorna os valores do alarme do Relatório
const parseTrueElements = (value: any, model: string) => {
  const alarmValues = Object.keys(ctrlSettings(model).estados).reverse();
  const trueElements: any[] = [];
  alarmValues.reduce((accumulator, element, i) => {
    if (binaryState(value.state) && binaryState(value.state)[i] === 1) {
      trueElements.push(element);
    }

    return {
      ...accumulator,
      [element]: binaryState(value.state) && binaryState(value.state)[i],
    };
  }, {});

  const valuesAlarm: any[] = [];
  trueElements.map((v) => {
    valuesAlarm.push(...ctrlSettings(model).estados[v][1]);
    return v;
  });
  return valuesAlarm;
};

// Tratamento dos ícones para renderizar na tabela de Registros
export const convertIcons = (data: any, model: string) => {
  let myData = [];

  if (data && data.length > 0) {
    if (data[0].time > data[data.length - 1].time) {
      data = data.reverse();
    }
  }

  for (let ij = 0; ij < data.length; ij++) {
    const icons = parseTrueElements(data[ij], model).filter((element: string) =>
      element.startsWith("icone")
    );

    const alarm = ctrlSettings(model).alarmes[+data[ij].alarm?.id]
      ? ctrlSettings(model).alarmes[+data[ij].alarm?.id][0]
      : "--";

    const state = parseTrueElements(data[ij], model)
      .filter(
        (element: string) =>
          !element.startsWith("icone") && !element.startsWith("fundo")
      )
      .filter((f) => f !== "");

    const obj_keys = Object.keys(data[ij]);
    for (let i = 0; i < obj_keys.length; i++) {
      if (Number.isNaN(data[ij][obj_keys[i]])) {
        data[ij][obj_keys[i]] = "--";
      }
    }

    if (ij > 0 && Math.abs(data[ij].time - data[ij - 1].time) === 1000) {
    } else {
      myData.push({ ...data[ij], icon: icons.toString(), state, alarm });
    }
  }

  myData.reverse();
  return myData;
};

// Formatação do título do arquivo exportado
const convertedDate = (date: string, language: LanguageCode) =>
  language === "en" ? date.replace(",", "") : date;

// Título do arquivo exportado
export const file = (
  date: string,
  language: LanguageCode,
  rotulo: string,
  translation: string
) => {
  return `${convertedDate(date, language).replace(
    " ",
    ""
  )}_${rotulo}_${translation}`;
};

// Lista dos títulos da exportação para .csv
export const titlesCsv = (data: any, language: LanguageCode) => {
  return addDateToData(data, language).map((v: any) => {
    const values: any = Object.keys(v);
    values.reverse();
    values.pop();
    return values;
  });
};

// Lista dos valores da exportação para .csv
export const valuesCsv = (data: any) => {
  return data.map((v: any) => {
    const values: any = Object.values(v);
    values.reverse();
    values.pop();
    values.shift();
    values.splice(1, 1);
    return values;
  });
};

// Filtrar os títulos da exportação para .csv
export const filterTitleCsv = (data: any) =>
  data
    ?.map((element: string) => {
      if (element === "state") {
        return "Estado";
      }
      if (element === "alarm") {
        return "Alarme";
      }
      if (element === "icon") {
        // eslint-disable-next-line array-callback-return
        return;
      }
      if (element === "id") {
        // eslint-disable-next-line array-callback-return
        return;
      }
      return element;
    })
    .filter((e: any) => e !== undefined) as any[];

// Ação de exportar para .csv
export const csvFile = (
  mac: string,
  model: string,
  address: number,
  date: string,
  data: any,
  language: LanguageCode,
  rotulo: string,
  translation: string
) => {
  let csv = `
  MAC: ${mac}
  Modelo: ${model}
  Rotulo: ${rotulo}
  Endereco: ${address}\n`;

  let header = filterTitleCsv(titlesCsv(data, language)[0]);
  let inverse_header = [...header];
  inverse_header.reverse();
  let new_header = [];
  new_header.push(header[0]);
  new_header.push(header[1]);
  for (let i = 0; i < inverse_header.length - 2; i++) {
    new_header.push(inverse_header[i]);
  }
  csv = csv + new_header.toString() + "\n";

  valuesCsv(addDateToData(data, language)).forEach((row: any) => {
    let inverse_row = [...row];
    inverse_row.reverse();
    let new_row = [];
    new_row.push(row[0]);
    new_row.push(row[1]);
    for (let i = 0; i < inverse_row.length - 2; i++) {
      if (Array.isArray(inverse_row[i])) {
        new_row.push(inverse_row[i].toString().replaceAll(",", ";"));
      } else {
        new_row.push(inverse_row[i]);
      }
    }
    csv += new_row.join(",");
    csv += "\n";
  });

  const hiddenElement = document.createElement("a");
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  hiddenElement.target = "_blank";

  hiddenElement.download = `${convertedDate(date, language).replace(
    " ",
    ""
  )}_${rotulo}_${translation}.csv`;
  hiddenElement.click();
};

// Reordenar colunas do PDF de 'Registros'
export const reorderColumsPdf = (filterTitle: any[]) => {
  let header = filterTitle ?? [];
  let inverse_header = [...header];
  inverse_header.reverse();
  let new_header = [];
  new_header.push(header[0]);
  new_header.push(header[1]);
  for (let i = 0; i < inverse_header.length - 2; i++) {
    new_header.push(inverse_header[i]);
  }
  return new_header;
};

// Valores das linhas do PDF ao imprimir tabela de 'Registros'
export const valuesPrint = (data: any) =>
  valuesCsv(data).map((m: any) => {
    const data = reorderColumsPdf(m).map((value) => {
      if (value === undefined) {
        return "--";
      }
      return value;
    });

    const newValuesFromData = [`${data[0]} - ${data[1]}`, ...data];
    newValuesFromData.splice(1, 1, undefined);
    newValuesFromData.splice(2, 1, undefined);
    newValuesFromData.splice(newValuesFromData.length - 2, 1, undefined);

    return newValuesFromData
      .filter((f: any) => f !== undefined)
      .map((m: any, i: number) => {
        if (
          !m?.toString().includes(".") &&
          m !== newValuesFromData[newValuesFromData.length - 1] &&
          i !== 0 &&
          m !== "--"
        ) {
          return `${m}.0`;
        }
        return m;
      });
  });
