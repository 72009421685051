import { styled } from "@mui/material";

const ErrorMessage = styled("div")({
  margin: 10,

  "&>p": {
    display: "inline",
    fontSize: 20,
    marginLeft: 10,
  },

  "&>svg": {
    width: 15,
    height: 15,
  },
});

export default ErrorMessage;
