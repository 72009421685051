import {
  amber,
  blue,
  red,
  lightGreen,
  orange,
  grey,
} from "@mui/material/colors";

import { getRawCtrlConfig } from "../../../config";
import { CtrlModels, Measures } from "../../../services/types/ctrls";
import {
  AlarmeInferior,
  AlarmeSuperior,
  Apoio,
  Aquecimento,
  Atraso,
  Atualizacao,
  Auto,
  Bluetooth,
  Compressor,
  Comunicacao,
  Degelo,
  Drenagem,
  Eco,
  Erro,
  Memoria,
  Nivel,
  Rele,
  SdCard,
  Swing,
  Ventilador,
  WifiAp,
} from "../Icons";
import {
  Acoes,
  Alarmes,
  Color,
  Estado,
  Estados,
  MeasuresData,
  RawGenericArray,
  RawGenericValue,
  RowData,
  SensorData,
} from "./types";

// Converte os modelos do JSON para um array do respectivo objeto tipado
export const parseData = (data: RawGenericValue) => {
  if (data === undefined) return [];
  const parseValue = (values: RawGenericArray): Partial<RowData> => {
    const [
      description,
      min,
      max,
      defaultValue,
      unitOfMeasurement,
      separator,
      [type, dataType],
      info,
    ] = values;
    const readOnly = !!info?.readOnly;
    const visible = info?.visible !== false;

    const parsedObject = {
      description,
      max,
      min,
      defaultValue,
      unitOfMeasurement,
      separator,
      type,
      readOnly,
      visible,
    };

    let divider = 0;
    switch (type) {
      case "INTBIN":
        Object.assign(parsedObject, { dataType });
        break;
      default:
        divider = dataType;
        Object.assign(parsedObject, { divider });
    }

    return parsedObject;
  };

  const codes = Object.keys(data);
  const parsedData = codes.map((code) => ({
    ...parseValue(data[code]),
    code,
  }));
  return parsedData as RowData[];
};

// Divide o valor pelo divisor se este for diferente de 0
export const applyDivider = (value: number, divider: number) =>
  !!divider ? value / divider : value;

// Converte um array sensores do modelo para um objeto tipado
const getSensorData = (model: CtrlModels) => {
  const sensorData = getRawCtrlConfig(model)?.sensores;
  const info = [] as SensorData[];
  const values = sensorData ? Object.values(sensorData) : [];
  values.forEach((value: any) => {
    const description = value[0].toString();
    const unitOfMeasurement = value[1].toString();
    const divider = +value[2][1];
    info.push({ description, unitOfMeasurement, divider });
  });
  return info;
};

// Descrição, unidade e valor de cada medida dos cards do Dashboard
export const parseMeasures = (measures: Measures) => {
  const { medidas, modelo } = measures;
  const sensorData = getSensorData(modelo);
  const parsedMeasures = [] as MeasuresData[];
  sensorData.forEach((data, i) => {
    const { description, unitOfMeasurement, divider } = data;
    const value = applyDivider(Object.values(medidas)[i] ?? 0, divider);
    parsedMeasures.push({ description, unitOfMeasurement, value });
  });
  return parsedMeasures;
};

// Cores dos cards do Dashboard de acordo com o tema atual
export const parseColor = (theme: string | undefined, color = ""): Color => {
  switch (color) {
    case "fundo_amarelo":
      return {
        main: theme === "light" ? amber[500] : amber[700],
        header: theme === "light" ? amber[600] : amber[800],
      };
    case "fundo_azul":
      return {
        main: theme === "light" ? blue[600] : blue[800],
        header: theme === "light" ? blue[700] : blue[900],
      };
    case "fundo_vermelho":
      return {
        main: theme === "light" ? red[500] : red[700],
        header: theme === "light" ? red[600] : red[900],
      };
    case "fundo_laranja":
      return {
        main: theme === "light" ? orange[600] : orange[800],
        header: theme === "light" ? orange[700] : orange[900],
      };
    case "fundo_verde":
      return {
        main: theme === "light" ? lightGreen[500] : lightGreen[700],
        header: theme === "light" ? lightGreen[600] : lightGreen[800],
      };
    case "fundo_cinza":
    default:
      return {
        main: theme === "light" ? grey[500] : grey[700],
        header: theme === "light" ? grey[600] : grey[800],
      };
  }
};

// Converte strings dos ícones para ícones SVG
export const parseIcon = (icon: string): JSX.Element => {
  switch (icon) {
    case "icone_tabaixo":
      return <AlarmeInferior />;
    case "icone_tacima":
      return <AlarmeSuperior />;
    case "icone_a":
      return <Apoio />;
    case "icone_aquecimento":
      return <Aquecimento />;
    case "icone_atraso":
      return <Atraso />;
    case "icone_atualizacao":
    case "icone_atualz":
      return <Atualizacao />;
    case "icone_apoio":
      return <Apoio />;
    case "icone_auto":
      return <Auto />;
    case "icone_compressor":
    case "icone_refrigeracao":
      return <Compressor />;
    case "icone_comunicacao":
      return <Comunicacao />;
    case "icone_degelo":
      return <Degelo />;
    case "icone_drenagem":
      return <Drenagem />;
    case "icone_erro":
      return <Erro />;
    case "icone_swing":
      return <Swing />;
    case "icone_ventilador":
      return <Ventilador />;
    case "icone_eco":
      return <Eco />;
    case "icone_rele":
      return <Rele />;
    case "icone_nivel":
      return <Nivel />;
    case "icone_wifi_ap":
      return <WifiAp />;
    case "icone_bluetooth":
      return <Bluetooth />;
    case "icone_sd_card":
      return <SdCard />;
    case "icone_memoria":
      return <Memoria />;
    default:
      return <Erro />;
  }
};

// Converte a propriedade alarmes do modelo JSON para um objeto tipado
export const parseAlarmes = (alarmes: any) => {
  const alarms = Object.entries(alarmes).slice(1) as any[];
  return alarms.map((alarm): Alarmes => {
    const [rawId, [description, code, color, rawIcon, , position]] = alarm;

    const id = +rawId;
    const icon = parseIcon(rawIcon);

    return {
      id,
      description,
      code,
      color,
      icon,
      position,
    };
  });
};

// Converte os estados do modelo JSON para um array de objetos do tipo Estado
export const parseEstado = (estados: Estados): Estado[] => {
  return Object.entries(estados)
    .reverse()
    .map(([key, estado]) => {
      const [, , color, icon] = estado[1];
      const bit = +key.replace(/\D/g, "");
      return {
        bit,
        color,
        icon,
        description: { inactive: estado[0][4], active: estado[1][4] },
        rawZero: estado[0],
        rawOne: estado[1],
      };
    });
};

// Converte a propriedade acoes do modelo JSON para um objeto tipado
export const parseAcoes = (acoes: any) => {
  return acoes?.map(({ id, label, tipo, valor, min, max }: Acoes) => {
    return {
      id,
      label,
      tipo,
      valor,
      min,
      max,
    };
  });
};
