import { CardData } from "../../contexts/ControllerContext";

// Payload de um card offline
export const cardNull = (
  mac: any,
  device: any,
  model: string | undefined,
  name: string
) => {
  const cardNull: CardData = {
    alarm: {
      code: "COM",
      color: "fundo_cinza",
      description: "offline",
      icon: "" as any,
      id: 65536,
      position: [1],
    },
    color: {
      main: "#9e9e9e",
      header: "#757575",
    },
    measures: {
      mac: mac,
      estado: 0,
      ctrl: "",
      lastMsg: 0,
      end: device.split("L")[1],
      modelo: model ?? "",
      rotulo: name,
      alarme: 65536,
      medidas: {
        m0: 0,
        m1: 0,
        m2: 0,
        m3: 0,
        m4: 0,
      },
      setpoints: {
        s0: 0,
        s1: 0,
        s2: 0,
        s3: 0,
        s4: 0,
      },
    },
    icons: [],
  };
  return cardNull;
};
