import React from "react";

import {
  Avatar,
  Box,
  CardHeader,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
  LockOutlined as LockOutlinedIcon,
  ArrowRight as ArrowRightIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
} from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import AvatarIcon from "../../../components/AvatarIcon";
import { getSectorsByIdSector } from "../../../services/data/sector";
import ModalSwitchLock from "../Modal/SwitchLock";
import ModalRemoveSector from "../Modal/RemoveSector";
import ModalEdit from "../Modal/Edit";
import ModalAddedDevice from "../Modal/AddedDevice";
import ModalSaveSync from "../Modal/Question/SaveSync";
import ModalLock from "../Modal/Lock";
import Spinner from "../../../components/Spinner";
import { Color } from "../../../components/Controller/parser/types";

interface HeaderProps {
  data: any;
  checked: boolean;
  setChecked: (e: boolean) => void;
  setSectorEdited: (e: boolean) => void;
  setDeviceRemoved: (e: boolean) => void;
  setValue: (e: number) => void;
  bgColor: string;
  color: Color;
  sector_model: string;
  sector_reference_model: string;
  devices: any;
  handleLoadMore: () => void;
  loadingDevices: boolean;
  openModalEdit: boolean;
  setOpenModalEdit: (e: boolean) => void;
  setIsOpennedSelect: (e: boolean) => void;
  payloadDevices: any[];
  notShow: boolean;
  deviceUsers: any;
}

const Header = ({
  data,
  checked,
  setChecked,
  setSectorEdited,
  setDeviceRemoved,
  setValue,
  bgColor,
  color,
  sector_model,
  sector_reference_model,
  devices,
  handleLoadMore,
  loadingDevices,
  openModalEdit,
  setOpenModalEdit,
  setIsOpennedSelect,
  payloadDevices,
  notShow,
  deviceUsers,
}: HeaderProps) => {
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeModeContext);
  const [openMenu, setOpenMenu] = React.useState<null | HTMLElement>(null);
  const [isEditedSector, setIsEditedSector] = React.useState<boolean>(false);
  const [isEditAssociateCtrls, setIsEditAssociateCtrls] =
    React.useState<boolean>(false);

  // Ações de abrir o modal de alterar o estado do 'Bloqueio de alterações'
  const [openModalSwitch, setOpenModalSwitch] = React.useState<boolean>(false);
  const handleOpenModalSwitch = () => setOpenModalSwitch(true);
  const handleCloseModalSwitch = () => {
    setOpenModalSwitch(false);
  };

  // Ações de abrir o modal de deletar setor
  const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);
  const handleOpenModalDelete = () => {
    setOpenModalDelete(true);
    handleCloseMenu();
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações de abrir o modal de editar setor
  const [modalEditValues, setModalEditValues] = React.useState<any>();
  const handleOpenModalEdit = () => {
    setModalEditValues({ data: data });
    setOpenModalEdit(true);
    handleCloseMenu();
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Ações de abrir o modal de 'Bloqueio de alterações'
  const [openModalLock, setOpenModalLock] = React.useState<boolean>(false);
  const handleOpenModalLock = () => setOpenModalLock(true);
  const handleCloseModalLock = () => {
    setOpenModalLock(false);
  };

  // Ação de abrir o menu do setor
  const open = Boolean(openMenu);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  // Ações de sincronizar os Dispositivos do setor editado
  let intervalIdSubmit: NodeJS.Timer;
  const [submittingAdd, setSubmittingAdd] = React.useState(false);
  const [addedDeviceSync, setAddedDeviceSync] = React.useState<any>();
  const isIntervalActiveSubmit = React.useRef(false);
  const handleOpenModaladdedDevice = () => {
    setIsEditedSector(false);
    setAddedDeviceSync(data);
    setSubmittingAdd(true);
  };
  const handleCloseModaladdedDevice = () => {
    setSubmittingAdd(false);
    setAddedDeviceSync(undefined);
  };
  const checkContentStatusSubmit = async (id: string) => {
    try {
      // Resgatar os setores por id do setor
      getSectorsByIdSector(id)
        .then((res) => {
          const { data } = res;

          setAddedDeviceSync(data);

          // Se comand_control.running for igual a finished, cancelar o interval
          if (data.comand_control.running === "finished") {
            // Varrer a lista de devices do setor e verificar o last_exec_status
            const devicesLastStatus = data.devices.filter(
              (f: any) => f.last_exec_status === null
            );
            // Se todos forem diferentes de 'null', cancelar interval
            if (devicesLastStatus.length === 0) {
              clearInterval(intervalIdSubmit);
              isIntervalActiveSubmit.current = false;
            }
            setSectorEdited(true);
          }
        })
        .catch((e) => {
          if (e.response.status === 404) clearInterval(intervalIdSubmit);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const startIntervalSubmit = (id: string) => {
    if (!isIntervalActiveSubmit.current) {
      intervalIdSubmit = setInterval(() => checkContentStatusSubmit(id), 5000);
      setSubmittingAdd(true);
      isIntervalActiveSubmit.current = true;
    }
  };

  // Ações de abrir o modal de sincronizar
  const [openModalSaveSync, setOpenModalSaveSync] =
    React.useState<boolean>(false);
  const [modalSyncValue, setModalSyncValue] = React.useState<any>();
  const handleOpenModalSaveSync = (id: string, dataEdited: any) => {
    setOpenModalSaveSync(true);
    setModalSyncValue({ id, dataEdited });
  };
  const handleCloseModalSaveSync = () => {
    setOpenModalSaveSync(false);
  };

  return (
    <>
      <CardHeader
        sx={{
          backgroundColor: bgColor,
          pt: 1.2,
          pb: 1.7,
        }}
        avatar={
          <AvatarIcon
            name={data.sector_name}
            bgcolor={color.header}
            border="1px solid white"
            color="white"
            fontSize={22}
          />
        }
        action={
          <Box display="flex">
            <Box>
              {isEditAssociateCtrls === true ? (
                <Box mt={1.6} ml={1.85} mr={1.2}>
                  <Spinner disableMargin size={20} />
                </Box>
              ) : (
                <Tooltip
                  onClick={handleOpenModaladdedDevice}
                  title={t("TEXT.SYNC_SECTOR")}
                >
                  <IconButton>
                    <PublishedWithChangesIcon
                      sx={{ fontSize: 28, color: "white" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box>
              {!checked ? (
                <Tooltip title={t("TEXT.LOCK_CHANGES")}>
                  <IconButton onClick={handleOpenModalLock}>
                    <LockOpenOutlinedIcon
                      sx={{ fontSize: 28, color: "white" }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <Avatar
                  sx={{
                    backgroundColor: color.main,
                    mt: 0.2,
                    ml: 0.3,
                    mr: 0.3,
                  }}
                >
                  <Tooltip title={t("TEXT.UNLOCK_CHANGES")}>
                    <IconButton
                      color={color.header === red[600] ? "default" : "error"}
                      onClick={handleOpenModalSwitch}
                    >
                      <LockOutlinedIcon
                        sx={{
                          fontSize: 28,
                          opacity:
                            theme === "light" && color.header === red[600]
                              ? "50%"
                              : "100%",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Avatar>
              )}
            </Box>
            <IconButton
              sx={{ color: "white" }}
              onClick={handleOpenMenu}
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        }
        title={
          <Tooltip title={data.sector_name.length > 9 ? data.sector_name : ""}>
            <Typography
              fontSize={20}
              fontWeight={500}
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              textAlign="center"
              width={130}
              sx={{ color: "white", textAlignLast: "left" }}
            >
              {data.sector_name}
            </Typography>
          </Tooltip>
        }
      />
      <Menu
        anchorEl={openMenu}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{
            fontSize: 14,
            cursor: "default",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Box display="flex">
            <Typography>{t("TEXT.SECTOR_MODEL")}:</Typography>
            <Typography ml={0.7} fontWeight={450}>
              {sector_model}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: 14,
            cursor: "default",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Box display="flex">
            <Typography>{t("TEXT.SECTOR_REFERENCE")}:</Typography>
            <Typography ml={0.7} fontWeight={450}>
              {sector_reference_model}
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{
            fontSize: 14,
            cursor: "pointer",
            pt: 0,
            pb: 0,
          }}
          disabled={checked}
          onClick={handleOpenModalDelete}
        >
          <ListItemIcon
            style={{
              minWidth: "31px",
            }}
          >
            <ArrowRightIcon />
          </ListItemIcon>
          <Box display="flex" alignItems="center">
            <Box mr={0.7}>
              <DeleteOutlineIcon sx={{ mt: 0.5 }} color="primary" />
            </Box>
            <Typography>{`${t("TEXT.REMOVE")} ${t("TEXT.SECTOR")}`}</Typography>
          </Box>
        </MenuItem>
        {isEditAssociateCtrls === true ? (
          <MenuItem
            sx={{
              fontSize: 14,
              cursor: "default",
              pt: 0.5,
              pb: 0,
            }}
          >
            <ListItemIcon
              style={{
                minWidth: "31px",
              }}
            >
              <ArrowRightIcon />
            </ListItemIcon>
            <Box pl={0.5}>
              <Spinner disableMargin size={20} />
            </Box>
          </MenuItem>
        ) : (
          <MenuItem
            sx={{
              fontSize: 14,
              cursor: "pointer",
              pt: 0.5,
              pb: 0,
            }}
            disabled={checked}
            onClick={handleOpenModalEdit}
          >
            <ListItemIcon
              style={{
                minWidth: "31px",
              }}
            >
              <ArrowRightIcon />
            </ListItemIcon>
            <Box display="flex" alignItems="center">
              <Box mr={0.8}>
                <EditIcon sx={{ mt: 0.5 }} color="primary" />
              </Box>
              <Typography>{`${t("TEXT.EDIT")} ${t("TEXT.SECTOR")}`}</Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
      <Divider />
      <ModalSwitchLock
        checked={checked}
        data={data}
        handleClose={handleCloseModalSwitch}
        handleOpen={openModalSwitch}
        setSectorEdited={setSectorEdited}
        setChecked={setChecked}
      />
      <ModalRemoveSector
        handleClose={handleCloseModalDelete}
        data={data}
        handleOpen={openModalDelete}
        setSectorEdited={setSectorEdited}
        setDeviceRemoved={setDeviceRemoved}
      />
      {modalEditValues && (
        <ModalEdit
          handleClose={handleCloseModalEdit}
          data={modalEditValues.data}
          handleOpen={openModalEdit}
          handleOpenModalSaveSync={handleOpenModalSaveSync}
          setValue={setValue}
          setIsEditedSector={setIsEditedSector}
          setIsEditAssociateCtrls={setIsEditAssociateCtrls}
          setSectorEdited={setSectorEdited}
          devices={devices}
          handleLoadMore={handleLoadMore}
          loadingDevices={loadingDevices}
          setIsOpennedSelect={setIsOpennedSelect}
          payloadDevices={payloadDevices}
          notShow={notShow}
          deviceUsers={deviceUsers}
        />
      )}
      <ModalAddedDevice
        data={addedDeviceSync}
        handleClose={handleCloseModaladdedDevice}
        handleOpen={submittingAdd}
        startIntervalSubmit={startIntervalSubmit}
        isEditedSector={isEditedSector}
        checked={checked}
        deviceUsers={deviceUsers}
      />
      <ModalSaveSync
        handleClose={handleCloseModalSaveSync}
        handleOpen={openModalSaveSync}
        isSubmitting={true}
        modalSyncValue={modalSyncValue?.id}
        dataEdited={modalSyncValue?.dataEdited}
        startIntervalSubmit={startIntervalSubmit}
        setSectorEdited={setSectorEdited}
      />
      <ModalLock
        handleClose={handleCloseModalLock}
        handleOpen={openModalLock}
        checked={checked}
        data={data}
        setChecked={setChecked}
        setSectorEdited={setSectorEdited}
      />
    </>
  );
};

export default Header;
