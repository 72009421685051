import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface CurrentMqttSubsProviderProps {
  children: ReactNode;
}

interface CurrentMqttSubsData {
  currentMqtt: string | undefined;
  setCurrentMqtt: Dispatch<SetStateAction<string | undefined>>;
}

export const CurrentMqttSubsContext = createContext({} as CurrentMqttSubsData);

export const CurrentMqttSubsProvider = ({
  children,
}: CurrentMqttSubsProviderProps) => {
  const [currentMqtt, setCurrentMqtt] = useState<string>();

  const CurrentMqttSubsProviderValue = useMemo(
    () => ({
      currentMqtt,
      setCurrentMqtt,
    }),
    [currentMqtt]
  );

  return (
    <CurrentMqttSubsContext.Provider value={CurrentMqttSubsProviderValue}>
      {children}
    </CurrentMqttSubsContext.Provider>
  );
};
