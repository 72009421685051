import Box from "@mui/material/Box";
import { Slider as MuiSlider } from "@mui/material";
import { styled } from "@mui/material/styles";

// Style customizado do componente Slider
const PrettoSlider = styled(MuiSlider)({
  color: "#D8D8D8",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 30,
    width: 10,
    backgroundColor: "#909090",
    border: "2px solid currentColor",
    borderRadius: "35%",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 25,
    height: 25,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#B5B5B5",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

interface SlideProps {
  minimum: number;
  maximum: number;
  unit: string;
  value: any;
}
const Slider = ({ minimum, maximum, unit, value }: SlideProps) => {
  const min_max = [
    {
      value: minimum,
      label: minimum + unit,
    },
    {
      value: maximum,
      label: maximum + unit,
    },
  ];

  return (
    <Box sx={{ width: 200, float: "right" }}>
      <PrettoSlider
        value={value}
        marks={min_max}
        disabled={false}
        valueLabelDisplay="auto"
        min={min_max[0].value}
        max={min_max[1].value}
        sx={{ cursor: "default" }}
      />
    </Box>
  );
};

export default Slider;
