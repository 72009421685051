const SCENARIOS_ROUTE = "/conditional";

// Rota para criar o cenário
export const CREATE_SCENARIOS_ROUTE = `${SCENARIOS_ROUTE}/create`;

// Rota do histórico de uma Ação dos cenários
const CONDITIONAL_RECORDS_ROUTE = "/conditionalrecords";

// Rota para resgatar todos os cenários de um usuário
export const getScenariosByUserRoute = (
  general: string,
  page: number,
  rowsPerPage: number
) =>
  `${SCENARIOS_ROUTE}/byuser?general=${general}&page=${page}&offset=${rowsPerPage}`;

// Rota para obter os dados dos cenários
export const getScenariosRoute = (ctrlId: string) =>
  `${SCENARIOS_ROUTE}/${ctrlId}`;

// Rota para editar cenário
export const editScenarioRoute = (id: string) => `${SCENARIOS_ROUTE}/${id}`;

// Rota para excluir cenário
export const deleteScenarioRoute = (id: string) => `${SCENARIOS_ROUTE}/${id}`;

// Rota para obter os dados do histórico de uma Ação dos cenários
export const getConditionalRecordsRoute = (ctrlId: string, id: string) =>
  `${CONDITIONAL_RECORDS_ROUTE}/${ctrlId}?conditional_id=${id}`;
