import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import { ToastContext } from "../../../contexts/ToastContext";
import Modal from "../../Devices/Modal";
import { red } from "@mui/material/colors";
import { editTranslationsJson } from "../../../services/data/translations";

interface ModalAddProps {
  setTranslationsEdited: (e: boolean) => void;
  setSaveButton: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
  data: any;
}

const ModalEdit = ({
  setTranslationsEdited,
  setSaveButton,
  handleOpen,
  handleClose,
  data,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);

  // Ação de editar traduções
  const handleEditTranslation = () => {
    editTranslationsJson(data)
      .then(() => {
        setTranslationsEdited(true);
        setSaveButton(false);
        toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
        handleClose();
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <CheckIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("BUTTON.SAVE")} {t("TEXT.TRANSLATIONS").toLowerCase()}
      </Typography>
      <Typography variant="body2">
        Tem certeza que deseja alterar as traduções?
      </Typography>
      <Box display="grid" mt={2}>
        <Button
          variant="contained"
          type="button"
          onClick={handleEditTranslation}
          sx={{
            mt: 2,
            borderRadius: 2,
            backgroundColor: red[400],
            fontSize: 12,
            pt: 1,
            pb: 1,
          }}
          fullWidth
        >
          {t("BUTTON.SAVE")}
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          sx={{
            backgroundColor: red[50],
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalEdit;
