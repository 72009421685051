import React from "react";

import { useTranslation } from "react-i18next";

import { Alert, Box, Skeleton, Typography } from "@mui/material";
import Modal from ".";

interface ModalWebFiConnectProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalWebFiConnect = ({
  handleOpen,
  handleClose,
}: ModalWebFiConnectProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3400);
  }, []);

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Typography fontSize={21} fontWeight={700}>
        Download WebFi Connect
      </Typography>
      <Box textAlign="center" mb={1} mt={1}>
        {loading ? (
          <Skeleton
            variant="rounded"
            width={260}
            height={245}
            sx={{ mb: 1.5, ml: 2 }}
          />
        ) : (
          <img
            src="/webfi_connect_qrcode.png"
            alt="logo"
            style={{
              height: "250px",
              borderRadius: "8px",
            }}
          />
        )}
        <Typography
          fontSize={15}
          fontWeight={500}
          color="primary"
          mt={-0.5}
          mb={1}
        >
          WebFi Connect
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={500}
          fontSize={13}
          mt={1}
          mb={-1.5}
        >
          {t("TEXT.AVAILABLE_FOR_PLATAFORMS")}:
        </Typography>
        <Box display="flex" justifyContent="center">
          {loading ? (
            <Skeleton
              variant="rounded"
              width={100}
              height={32}
              sx={{ mr: 0.5, mt: 1.85 }}
            />
          ) : (
            <a
              href="https://apps.apple.com/us/app/webfi-connect/id6572300567"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/logo_app_store.png"
                alt="logo"
                style={{
                  height: "60px",
                }}
              />
            </a>
          )}
          {loading ? (
            <Skeleton
              variant="rounded"
              width={100}
              height={32}
              sx={{ mt: 1.85, mb: 2.5 }}
            />
          ) : (
            <a
              href="https://play.google.com/store/apps/details?id=com.wifi_ap_test"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/logo_google_play.png"
                alt="logo"
                style={{
                  height: "33px",
                  marginLeft: "3px",
                  marginTop: "13.4px",
                }}
              />
            </a>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box />
        <Alert
          severity="warning"
          sx={{
            alignItems: "center",
            placeContent: "center",
            width: 310,
          }}
        >
          <Typography fontSize={14}>{t("TEXT.EXCLUSIVE_FOR_WEBFI")}</Typography>
        </Alert>
        <Box />
      </Box>
    </Modal>
  );
};

export default ModalWebFiConnect;
