import React from "react";

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import { InputProps } from "./types";

// Input de senha que recebe a lib Formik
export const PasswordVisibility = ({
  formik,
  name,
  label,
  maxLength,
  ...rest
}: InputProps) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <FormControl variant="outlined" {...rest}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        name={name}
        type={showPassword ? "text" : "password"}
        value={formik?.values[name]}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        autoComplete="off"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        inputProps={{ maxLength }}
      />
    </FormControl>
  );
};
