import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  ACTIVATE_ACCOUNT,
  COMPLETE_REGISTER,
  CONFIRM_ASSOCIATION,
  CONTROLLER,
  DASHBOARD,
  DEVICES,
  DEVICE_PAGE,
  HIRE_SUBSCRIPTION,
  LOGIN,
  PLANS_AND_SUBSCRIPTIONS,
  PLANS_AVAILABLE,
  PROFILE,
  RECOVER_ACCESS,
  RECOVER_ACCESS_EMAIL,
  REGISTER,
  REPORTS,
  SCENARIOS,
  USERS,
  SECTORS,
  MACS,
  SETTINGS,
  MODELS,
  TRANSLATIONS,
} from "./constants";
import Dashboard from "../pages/Dashboard/index";
import Login from "../pages/Login";
import { AuthContext } from "../contexts/AuthContext";
import Controller from "../pages/Controller";
import Devices from "../pages/Devices";
import DevicePage from "../pages/Devices/DevicePage";
import Report from "../pages/Report";
import Register from "../pages/Register";
import RecoverAccess from "../pages/RecoveryAccess";
import RecoveryAccessFromEmail from "../pages/RecoveryAccess/RecoveryAccessFromEmail";
import ActivateAccount from "../pages/ActivateAccount/ActivateAccount";
import Profile from "../pages/Profile";
import Users from "../pages/Users";
import CompleteRegister from "../pages/Register/CompleteRegister";
import PlansAndSubscriptions from "../pages/PlansAndSubscriptions";
import PlanDetails from "../pages/PlansAndSubscriptions/PlanDetails";
import PlansAvailable from "../pages/PlansAndSubscriptions/PlansAvailable";
import HireSubscription from "../pages/PlansAndSubscriptions/HireSubscription";
import ScenariosPage from "../pages/Scenarios/Index";
import Sectors from "../pages/Sectors";
import Macs from "../pages/Macs";
import Settings from "../pages/Settings";
import Models from "../pages/Models";
import Translations from "../pages/Translations";

interface RouteProps {
  children: JSX.Element;
}

// Checagem se a rota está autenticada
const RequireAuth = ({ children }: RouteProps) => {
  const { authenticated } = React.useContext(AuthContext);
  const location = useLocation();

  return authenticated ? (
    children
  ) : (
    <Navigate to={LOGIN} state={{ from: location }} replace />
  );
};

// Todas as rotas do Cloud
const Router = () => (
  <Routes>
    <Route
      path={DASHBOARD}
      element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      }
    />
    <Route
      path={CONTROLLER}
      element={
        <RequireAuth>
          <Controller />
        </RequireAuth>
      }
    />
    <Route
      path={DEVICES}
      element={
        <RequireAuth>
          <Devices />
        </RequireAuth>
      }
    />
    <Route
      path={DEVICE_PAGE}
      element={
        <RequireAuth>
          <DevicePage />
        </RequireAuth>
      }
    />
    <Route
      path={REPORTS}
      element={
        <RequireAuth>
          <Report />
        </RequireAuth>
      }
    />
    <Route
      path={`${REPORTS}/:id/:mac/:ctrl/:model`}
      element={
        <RequireAuth>
          <Report />
        </RequireAuth>
      }
    />
    <Route
      path={USERS}
      element={
        <RequireAuth>
          <Users />
        </RequireAuth>
      }
    />
    <Route
      path={PROFILE}
      element={
        <RequireAuth>
          <Profile />
        </RequireAuth>
      }
    />
    <Route
      path={`${PROFILE}/:addr`}
      element={
        <RequireAuth>
          <Profile />
        </RequireAuth>
      }
    />
    <Route
      path={PLANS_AND_SUBSCRIPTIONS}
      element={
        <RequireAuth>
          <PlansAndSubscriptions />
        </RequireAuth>
      }
    />
    <Route
      path={`${PLANS_AND_SUBSCRIPTIONS}/:mac/:id`}
      element={
        <RequireAuth>
          <PlanDetails />
        </RequireAuth>
      }
    />
    <Route
      path={`${HIRE_SUBSCRIPTION}/:mac/:id`}
      element={
        <RequireAuth>
          <HireSubscription />
        </RequireAuth>
      }
    />
    <Route
      path={`${PLANS_AVAILABLE}/:mac`}
      element={
        <RequireAuth>
          <PlansAvailable />
        </RequireAuth>
      }
    />
    <Route
      path={SCENARIOS}
      element={
        <RequireAuth>
          <ScenariosPage />
        </RequireAuth>
      }
    />
    <Route
      path={SECTORS}
      element={
        <RequireAuth>
          <Sectors />
        </RequireAuth>
      }
    />
    <Route
      path={MACS}
      element={
        <RequireAuth>
          <Macs />
        </RequireAuth>
      }
    />
    <Route
      path={SETTINGS}
      element={
        <RequireAuth>
          <Settings />
        </RequireAuth>
      }
    />
    <Route
      path={MODELS}
      element={
        <RequireAuth>
          <Models />
        </RequireAuth>
      }
    />
    <Route
      path={TRANSLATIONS}
      element={
        <RequireAuth>
          <Translations />
        </RequireAuth>
      }
    />
    <Route path={COMPLETE_REGISTER} element={<CompleteRegister />} />
    <Route path={CONFIRM_ASSOCIATION} element={<Login />} />
    <Route path={LOGIN} element={<Login />} />
    <Route path={REGISTER} element={<Register />} />
    <Route path={RECOVER_ACCESS} element={<RecoverAccess />} />
    <Route path={RECOVER_ACCESS_EMAIL} element={<RecoveryAccessFromEmail />} />
    <Route path={ACTIVATE_ACCOUNT} element={<ActivateAccount />} />
    <Route path="*" element={<Navigate to={DASHBOARD} />} />
  </Routes>
);

export default Router;
