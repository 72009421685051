import { getRawCtrlConfig } from "../../../config";
import { CtrlModels } from "../../../services/types/ctrls";
import { CtrlConfig, RawConfig } from "./types";
import { parseAcoes, parseAlarmes, parseData, parseEstado } from "./utils";

const emptyCtrl = {
  alarme: [],
  alarmes: [],
  parametros: [],
  setpoints: [],
  estado: [],
  nome: "",
  acoes: [],
};

// Dados gerais da página do Controlador
export const getCtrlConfig = (model: CtrlModels): CtrlConfig => {
  // Dados gerais dos cards do Dashboard
  const ctrlConfig = getRawCtrlConfig(model) as unknown as RawConfig;

  if (!ctrlConfig) {
    return emptyCtrl;
  }

  const {
    detalhamento: rawParametros,
    setpoints: rawSetpoints,
    caixaalarme: rawAlarme,
    alarmes: rawAlarmes,
    estados: rawEstados,
    acoes: rawAcoes,
    nome,
  } = ctrlConfig;

  const parametros = parseData(rawParametros);
  const setpoints = parseData(rawSetpoints);
  const alarme = parseData(rawAlarme);
  const alarmes = parseAlarmes(rawAlarmes);
  const estado = parseEstado(rawEstados);
  const acoes = parseAcoes(rawAcoes);

  return { acoes, alarme, alarmes, estado, nome, parametros, setpoints };
};
