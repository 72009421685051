import { api } from "../api";
import {
  CREATE_SCENARIOS_ROUTE,
  getScenariosByUserRoute,
  deleteScenarioRoute,
  editScenarioRoute,
  getConditionalRecordsRoute,
  getScenariosRoute,
} from "../routes/scenarios";

// Criar o cenário
export const createScenario = async (data: any) =>
  api.post<any>(CREATE_SCENARIOS_ROUTE, data);

// Obtem os dados dos cenários
export const getScenarios = (ctrlId: string) =>
  api.get<any>(getScenariosRoute(ctrlId));

// Editar cenário
export const editScenario = async (id: string, data: any) =>
  api.put<void>(editScenarioRoute(id), data);

// Excluir cenário
export const deleteScenario = async (id: string) =>
  api.delete<any>(deleteScenarioRoute(id));

// Obtem os dados do histórico de uma Ação dos cenários
export const getConditionalRecords = (ctrlId: string, id: string) =>
  api.get<any>(getConditionalRecordsRoute(ctrlId, id));

// Obtem todos os cenários de um usuário
export const scenariosById = (
  general: string,
  page: number,
  rowsPerPage: number
) => api.get<any>(getScenariosByUserRoute(general, page, rowsPerPage));
