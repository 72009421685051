import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import Modal from "../../Devices/Modal";
import { red } from "@mui/material/colors";
import { formatValueCents, planName } from "../parse";
import { getLanguage } from "../../../translations/languages";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  HIRE_SUBSCRIPTION,
  PLANS_AND_SUBSCRIPTIONS,
} from "../../../Routes/constants";
import { submitPlanFree } from "../../../services/data/subscriptions";
import { ToastContext } from "../../../contexts/ToastContext";
import useIsMobile from "../../../hooks/useIsMobile";
import "../index.css";

interface ModalSelectPlanProps {
  currentPlan: any;
  changePlan: any;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalSelectPlan = ({
  currentPlan,
  changePlan,
  handleOpen,
  handleClose,
}: ModalSelectPlanProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const { theme } = React.useContext(ThemeModeContext);
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const navigate = useNavigate();
  const params = useParams();
  const mac = params.mac ?? "";

  const handleSelectPlan = () => {
    // Se o Plano custar '0' Reais é alterado o Plano
    // Se não, irá redirecionar para a página de pagamento
    if (changePlan?.value_cents !== 0) {
      navigate(`${HIRE_SUBSCRIPTION}/${mac}/${changePlan._id}`);
    } else {
      submitPlanFree({
        plan: changePlan._id,
        status: changePlan.status,
        mac: mac,
      })
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.ADD_PLAN"));
          navigate(PLANS_AND_SUBSCRIPTIONS);
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.ADD_PLAN"));
        });
    }
  };

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 360}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.ALTER_PLAN")}
      </Typography>
      <Typography
        fontSize={mobile ? 15 : 16}
        sx={{ mt: 1, mb: 1 }}
        variant="body2"
      >
        {t("TEXT.ALTER_PLAN_MESSAGE")}?
      </Typography>
      <Box>
        <Typography fontSize={15} sx={{ mt: 1, mb: 1 }} variant="body2">
          {t("TEXT.CURRENT_PLAN_SUBTITLE")}:
        </Typography>
        <Paper
          className={
            currentPlan?.value_cents < 10001
              ? "table free"
              : currentPlan?.value_cents > 9999 &&
                currentPlan?.value_cents < 20000
              ? "table standard"
              : "table premium"
          }
          sx={{ p: 1.5, pl: 2, borderRadius: "20px" }}
          elevation={2}
        >
          <Box display="flex" justifyContent="center" mb={-2}>
            <Typography fontWeight={600} fontSize={23}>
              {currentPlan?.value_cents === 0
                ? t("TEXT.FREE")
                : formatValueCents(currentPlan?.value_cents.toString())}
            </Typography>
            <Typography
              variant="caption"
              fontSize="0.875rem"
              fontWeight={500}
              sx={{
                ml: 0.4,
                mt: 0.9,
                color: theme === "light" ? "rgb(108, 115, 127)" : "#FFFF",
                fontFamily:
                  "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
              }}
            >
              {`/${currentPlan?.interval} ${t("TEXT.MONTHS")}`}
            </Typography>
          </Box>
          <Box className="package-name">
            <Typography
              sx={{
                position: "absolute",
                left: "50%",
                fontSize: mobile ? "18px" : "20px",
                fontWeight: 500,
                background: theme === "light" ? "#fff" : "#252525",
                padding: "0 15px",
                transform: "translate(-50%, -50%)",
              }}
            >
              {planName(currentPlan, language)}
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Box mt={3}>
        <Typography fontSize={15} sx={{ mb: 1 }} variant="body2">
          {t("TEXT.CHANGE_PLAN_SUBTITLE")}:
        </Typography>
        <Paper
          className={
            changePlan?.value_cents < 10001
              ? "table free"
              : changePlan?.value_cents > 9999 &&
                changePlan?.value_cents < 20000
              ? "table standard"
              : "table premium"
          }
          sx={{ p: 1.5, pl: 2, borderRadius: "20px" }}
          elevation={2}
        >
          <Box display="flex" justifyContent="center" mb={-2}>
            <Typography fontWeight={600} fontSize={23}>
              {changePlan?.value_cents === 0
                ? t("TEXT.FREE")
                : formatValueCents(changePlan?.value_cents.toString())}
            </Typography>
            <Typography
              variant="caption"
              fontSize="0.875rem"
              fontWeight={500}
              sx={{
                ml: 0.4,
                mt: 0.9,
                color: theme === "light" ? "rgb(108, 115, 127)" : "#FFFF",
                fontFamily:
                  "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
              }}
            >
              {`/${changePlan?.interval} ${t("TEXT.MONTHS")}`}
            </Typography>
          </Box>
          <Box className="package-name">
            <Typography
              sx={{
                position: "absolute",
                left: "50%",
                fontSize: mobile ? "18px" : "20px",
                fontWeight: 500,
                background: theme === "light" ? "#fff" : "#252525",
                padding: "0 15px",
                transform: "translate(-50%, -50%)",
              }}
            >
              {planName(changePlan, language)}
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Box display="grid" mt={1}>
        <Button
          onClick={handleSelectPlan}
          variant="contained"
          sx={{
            mt: 2,
            borderRadius: 2,
            backgroundColor: red[400],
            fontSize: 12,
            pt: 1,
            pb: 1,
          }}
          fullWidth
        >
          {t("BUTTON.CONCLUDE")}
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          sx={{
            backgroundColor: red[50],
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalSelectPlan;
