import { CtrlModels } from "../services/types/ctrls";

let ctrlConfig: any;
let ctrlOptions: any;

// Resgata os dados do JSON
export const updateCtrl = (ageonRef: any) => {
  if (ageonRef) {
    ctrlConfig = JSON.parse(ageonRef).arcsysContr;
    ctrlOptions = JSON.parse(ageonRef).arcsysOPC;
  }
};

// Resgata os dados gerais do Controlador
export const getRawCtrlConfig = (model: CtrlModels) =>
  ctrlConfig && ctrlConfig[model];

// Regata as opções para editar o parâmetro do Controlador
export const getCtrlOption = (option: string) => option && ctrlOptions[option];

// Checa se o modelo do JSON existe
export const modelExists = (model: CtrlModels) => model && !!ctrlConfig[model];
