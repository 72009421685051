import { useTranslation } from "react-i18next";

import { ArrowForwardIosSharp as ArrowForwardIosSharpIcon } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  Box,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import useIsMobile, { useIsMediumPage } from "../../../../hooks/useIsMobile";
import { checkIsValid, convertToDate, convertToTime } from "./parse";
import { getLanguage } from "../../../../translations/languages";

type RowProps = {
  data: any;
  print: boolean;
};

// Componente Accordion customizado
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

// Componente AccordionSummary (header do Accordion) customizado
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const Row = ({ data, print }: RowProps) => {
  const { t } = useTranslation();
  // pt || en || es
  const language = getLanguage().code;
  // Se a tela está no modo mobile
  const mobile = useIsMobile();
  // Se a tela está no modo tablet
  const mediumPage = useIsMediumPage();

  // Checar se o valor é inteiro
  const checkIfIsInt = (value: number) =>
    checkIsValid(value)?.toString().includes(".")
      ? checkIsValid(value)
      : `${checkIsValid(value)}.0`;

  return data && !print
    ? data?.map((v: any, i: number) => (
        <Accordion sx={{ borderRadius: 0 }} key={i} defaultExpanded>
          <AccordionSummary>
            <Typography
              variant="body1"
              sx={{
                color: grey[600],
              }}
            >
              <strong>{`${v.title} (${v.unit})`}</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={mobile ? { p: 0 } : null}>
            <Table
              sx={{ minWidth: mobile ? 200 : 400 }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={
                      mobile
                        ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                        : null
                    }
                  >
                    {t("TEXT.DATE")}
                  </TableCell>
                  <TableCell
                    sx={
                      mobile
                        ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                        : null
                    }
                  >
                    {t("TEXT.MAXIMUM")}
                  </TableCell>
                  <TableCell
                    sx={
                      mobile
                        ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                        : null
                    }
                  >
                    {t("TEXT.MINIMUM")}
                  </TableCell>
                  <TableCell
                    sx={
                      mobile
                        ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                        : null
                    }
                  >
                    {t("TEXT.AVERAGE")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {v.data?.map((row: any, index: number) => (
                  <TableRow key={index} hover>
                    <TableCell
                      sx={
                        mobile
                          ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                          : null
                      }
                    >
                      {convertToDate(row.day, language)}
                    </TableCell>
                    <TableCell
                      sx={
                        mobile
                          ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                          : null
                      }
                    >
                      {mobile || mediumPage ? (
                        <Box display="grid">
                          <Box>{checkIfIsInt(row.max?.value)}</Box>
                          <Box>
                            {checkIsValid(row.min?.timestamp) === "--"
                              ? "--"
                              : convertToTime(row.max?.timestamp)}
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          {`${checkIfIsInt(row.max?.value)} - ${
                            checkIsValid(row.min?.timestamp) === "--"
                              ? "--"
                              : convertToTime(row.max?.timestamp)
                          }`}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      sx={
                        mobile
                          ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                          : null
                      }
                    >
                      {mobile || mediumPage ? (
                        <Box display="grid">
                          <Box>{checkIfIsInt(row.min?.value)}</Box>
                          <Box>
                            {checkIsValid(row.min?.timestamp) === "--"
                              ? "--"
                              : convertToTime(row.min?.timestamp)}
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          {`${checkIfIsInt(row.min?.value)} - ${
                            checkIsValid(row.min?.timestamp) === "--"
                              ? "--"
                              : convertToTime(row.min?.timestamp)
                          }`}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      sx={
                        mobile
                          ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                          : null
                      }
                    >
                      {row.avg?.toString().includes(".")
                        ? row.avg
                        : `${row.avg}.0`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ))
    : data?.map((v: any, i: number) => (
        <Box key={i}>
          <hr style={{ margin: "20px" }}></hr>
          <Typography
            variant="body1"
            sx={{
              color: grey[600],
            }}
          >
            <strong>{`${v?.title} (${v?.unit})`}</strong>
          </Typography>
          <Table
            sx={{ minWidth: mobile ? 200 : 400 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={
                    mobile ? { p: 0, pb: 1, pt: 1, textAlign: "center" } : null
                  }
                >
                  {t("TEXT.DATE")}
                </TableCell>
                <TableCell
                  sx={
                    mobile ? { p: 0, pb: 1, pt: 1, textAlign: "center" } : null
                  }
                >
                  {t("TEXT.MAXIMUM")}
                </TableCell>
                <TableCell
                  sx={
                    mobile ? { p: 0, pb: 1, pt: 1, textAlign: "center" } : null
                  }
                >
                  {t("TEXT.MINIMUM")}
                </TableCell>
                <TableCell
                  sx={
                    mobile ? { p: 0, pb: 1, pt: 1, textAlign: "center" } : null
                  }
                >
                  {t("TEXT.AVERAGE")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {v.data?.map((row: any, index: number) => (
                <TableRow key={index} hover>
                  <TableCell
                    sx={
                      mobile
                        ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                        : null
                    }
                  >
                    {convertToDate(row.day, language)}
                  </TableCell>
                  <TableCell
                    sx={
                      mobile
                        ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                        : null
                    }
                  >
                    {mobile || mediumPage ? (
                      <Box display="grid">
                        <Box>{checkIfIsInt(row.max?.value)}</Box>
                        <Box>
                          {checkIsValid(row.min?.timestamp) === "--"
                            ? "--"
                            : convertToTime(row.max?.timestamp)}
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        {checkIfIsInt(row.max?.value)} -
                        {checkIsValid(row.min?.timestamp) === "--"
                          ? "--"
                          : convertToTime(row.max?.timestamp)}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    sx={
                      mobile
                        ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                        : null
                    }
                  >
                    {mobile || mediumPage ? (
                      <Box display="grid">
                        <Box>{checkIfIsInt(row.min?.value)}</Box>
                        <Box>
                          {checkIsValid(row.min?.timestamp) === "--"
                            ? "--"
                            : convertToTime(row.min?.timestamp)}
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        {checkIfIsInt(row.min?.value)} -
                        {checkIsValid(row.min?.timestamp) === "--"
                          ? "--"
                          : convertToTime(row.min?.timestamp)}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    sx={
                      mobile
                        ? { p: 0, pb: 1, pt: 1, textAlign: "center" }
                        : null
                    }
                  >
                    {row.avg?.toString().includes(".")
                      ? row.avg
                      : `${row.avg}.0`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ));
};

export default Row;
