import React from "react";

import {
  Box,
  Card,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import {
  GridOn as GridOnIcon,
  ArrowRight as ArrowRightIcon,
  LeaderboardOutlined as LeaderboardOutlinedIcon,
} from "@mui/icons-material";
import Modal from "../../../components/Modal";
import Controller from "../../Dashboard/Controller";
import { useTranslation } from "react-i18next";
import Structure from "../Card/Structure";
import useIsMobile from "../../../hooks/useIsMobile";
import { MeasuresData } from "../../../components/Controller/parser/types";
import { checkIfIsComNotAvg, checkIfIsComUnitAvg } from "../parse";
import { ControllerProvider } from "../../../contexts/ControllerContext";
import { ControllerEditProvider } from "../../../contexts/ControllerEditContext";
import ActionsTable from "../../../components/Controller/SettingsTable/Action";
import TopicTitle from "../../../components/TopicTitle";

interface ModalDevicesProps {
  handleOpen: boolean;
  handleClose: () => void;
  data: any;
  setSectorEdited: (e: boolean) => void;
  mainMeasureAvg: MeasuresData;
  secondaryMeasuresAvg: MeasuresData[];
  checked: boolean;
  startIntervalAction: () => void;
  submittingAction: boolean;
  actionsIsDisabled: boolean;
  devices: any;
  deviceUsers: any;
}

const ModalDevices = ({
  handleOpen,
  handleClose,
  data,
  setSectorEdited,
  mainMeasureAvg,
  secondaryMeasuresAvg,
  checked,
  startIntervalAction,
  submittingAction,
  actionsIsDisabled,
  devices,
  deviceUsers,
}: ModalDevicesProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const [loading, setLoading] = React.useState(true);

  // Filtrar o 'id' de cada controlador
  const filteredId = devices.filter((f: any) => f.mac === data.mac)[0]?.id;

  // Dados dos controladores
  const value = {
    ctrls: data.ctrls,
    mac: data.mac,
    model: data.sector_model,
    devices: data.devices,
    id: filteredId,
    sectorId: data._id,
    default_resource: data.default_resource,
    setSectorEdited: setSectorEdited,
    checked,
  };

  // Loading do Spinner inicial das médias
  React.useEffect(() => {
    const fetchStatus = () => {
      if (mainMeasureAvg && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [loading, mainMeasureAvg]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  return (
    <Modal open={handleOpen} handleClose={handleClose}>
      <Box sx={{ display: "flex" }} mb={2.5} mt={1} ml={2}>
        <Box alignSelf="center">
          <Box>
            <GridOnIcon />
          </Box>
        </Box>
        <Box alignSelf="center" ml={2} mb={0.8}>
          <Typography fontSize={20} fontWeight={600}>
            {t("TEXT.DEVICES")}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box display="flex">
        {!mobile && (
          <Grid container flex="30%">
            <Box m={3.5} mt={0}>
              <Structure
                data={data}
                setSectorEdited={setSectorEdited}
                isInModal={true}
                deviceUsers={deviceUsers}
              />
              <Paper
                sx={{
                  m: 2,
                  mt: "25px",
                  p: 1,
                  borderRadius: 3,
                  maxWidth: 328,
                }}
                elevation={2}
              >
                <ControllerProvider>
                  <ControllerEditProvider>
                    <ActionsTable
                      model={data.sector_model as string}
                      permissionToEdit={true}
                      devicePermission={true}
                      ctrlId={data.reference.ctrlId}
                      idSector={data._id}
                      startIntervalAction={startIntervalAction}
                      submittingAction={submittingAction}
                      actionsIsDisabled={actionsIsDisabled}
                    />
                  </ControllerEditProvider>
                </ControllerProvider>
              </Paper>
              <Card
                sx={{
                  m: 2,
                  mt: 3,
                  width: 328,
                  borderRadius: 3,
                }}
                elevation={2}
              >
                <Box p={2.5} pb={1}>
                  <TopicTitle icon={<LeaderboardOutlinedIcon />}>
                    {secondaryMeasuresAvg?.length === 0
                      ? t("TEXT.AVERAGE")
                      : `${t("TEXT.AVERAGE")}s`}
                  </TopicTitle>
                </Box>
                <Divider />
                <Box
                  minHeight={secondaryMeasuresAvg?.length === 0 ? 70 : 90}
                  pt={1}
                  pb={secondaryMeasuresAvg?.length === 0 ? 0 : 2}
                >
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={30}
                      sx={{ borderRadius: "5px", ml: 5, mt: 1.2 }}
                    />
                  ) : mainMeasureAvg ? (
                    <Table
                      id="content"
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ p: 0, pl: 1 }}>
                            <ArrowRightIcon sx={{ pt: 0.4 }} />
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={4}
                            sx={{ p: 0, pb: 0.3 }}
                          >
                            <Typography fontSize={16} fontWeight={500}>
                              {`${mainMeasureAvg.description}:`}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            colSpan={4}
                            sx={{
                              pr:
                                checkIfIsComNotAvg(mainMeasureAvg.value) ===
                                "--"
                                  ? 4
                                  : 2,
                            }}
                          >
                            <Typography fontSize={15} fontWeight={500}>
                              {`${checkIfIsComNotAvg(
                                mainMeasureAvg.value
                              )} ${checkIfIsComUnitAvg(
                                mainMeasureAvg.value,
                                mainMeasureAvg.unitOfMeasurement
                              )}`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 17,
                        mt: 1,
                      }}
                    >
                      {t("TEXT.OFFLINE_DEVICES")}
                    </Typography>
                  )}
                  <Table
                    id="content"
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableBody>
                      {secondaryMeasuresAvg &&
                        secondaryMeasuresAvg.map((m: any, i: number) => (
                          <TableRow key={i}>
                            <TableCell sx={{ p: 0, pl: 1 }}>
                              <ArrowRightIcon sx={{ pt: 0.4 }} />
                            </TableCell>
                            <TableCell
                              align="left"
                              colSpan={4}
                              sx={{ p: 0, pb: 0.3 }}
                            >
                              <Typography fontSize={16} fontWeight={500}>
                                {`${m.description}:`}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              colSpan={4}
                              sx={{
                                pr:
                                  checkIfIsComNotAvg(m.value) === "--" ? 4 : 2,
                              }}
                            >
                              <Typography fontSize={15} fontWeight={500}>
                                {`${checkIfIsComNotAvg(
                                  m.value
                                )} ${checkIfIsComUnitAvg(
                                  m.value,
                                  m.unitOfMeasurement
                                )}`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Card>
            </Box>
          </Grid>
        )}
        <Grid item flex="70%">
          <Box position="sticky" top="82px">
            <Grid container>
              <Controller value={value} setSectorEdited={setSectorEdited} />
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalDevices;
