// Ação de exportar para .pdf o Manual do usuário
export const printUserManualAsPDF = (fileName: string, fileURL: string) => {
  // Abrir PDF em uma nova guia
  if (!(window as any)?.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  } else if (
    !!(window as any)?.ActiveXObject &&
    (document as any)?.execCommand
  ) {
    var _window = window.open(fileURL, "_blank");
    (_window as any)?.document.close();
    (_window as any)?.document.execCommand("SaveAs", true, fileName || fileURL);
    (_window as any)?.close();
  }
};

// URL do manual do usuário
export const fileURL =
  "https://manuais-arcsys-cloud.s3.sa-east-1.amazonaws.com/Manual+Cen%C3%A1rios.pdf";
