import { LanguageCode } from "../../../translations/types";

// Converter formato 'dd-mm-yyyy' para 'new Date()'
export const convertStringToDate = (dateString: string) => {
  // Separar valores da string 'dd-mm-yyyy'
  const [day, month, year] = dateString.split("-");

  // converter para a estrutura 'mm/dd/yyyy'
  const convertedDate = `${month}/${day}/${year}`;

  // Criar estrutura com 'new Date()'
  return new Date(convertedDate);
};

// Converter formato 'new Date()' para 'dd-mm-yyyy'
export const convertDateToString = (language: LanguageCode) => {
  const fullDate = new Date()
    .toLocaleDateString(language === "en" ? "en-US" : "pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replaceAll("/", "-");

  return fullDate;
};
