import React from "react";
import { useTranslation } from "react-i18next";

import { HighlightOff as HighlightOffIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";

import { ToastContext } from "../../../contexts/ToastContext";
import Modal from "../../Devices/Modal";
import { red } from "@mui/material/colors";

interface ModalRemoveDevicesAppliedProps {
  handleOpen: boolean;
  handleClose: () => void;
  setApplyCtrls?: (e: boolean) => void;
  setAppliedCleaned?: (e: boolean) => void;
}

const ModalRemoveDevicesApplied = ({
  handleOpen,
  handleClose,
  setApplyCtrls,
  setAppliedCleaned,
}: ModalRemoveDevicesAppliedProps) => {
  const { t } = useTranslation();
  const { toastSuccess } = React.useContext(ToastContext);

  // Ação de limpar a lista de dispositivos associados
  const handleClearApply = () => {
    toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
    if (setApplyCtrls) {
      setApplyCtrls(false);
    }
    if (setAppliedCleaned) {
      setAppliedCleaned(true);
    }
    handleClose();
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={350}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <HighlightOffIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={17.5} fontWeight={700} mb={1}>
        {t("TEXT.REMOVE_APPLIED_CTRLS")}?
      </Typography>
      <Typography variant="body2">
        {t("TEXT.REMOVE_APPLIED_CTRLS_MESSAGE")}?
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={() => handleClearApply()}
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: red[400],
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("TEXT.CLEAN")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: red[50],
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalRemoveDevicesApplied;
