import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { Select, TextField } from "../../../Form";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";
import { RowData } from "../../../Controller/parser/types";
import { FormikProps } from "formik";
import React from "react";
import { applyDivider } from "../../../Controller/parser/utils";
import { divider } from "../../parse";
import ModalTelegramHelp from "../../../../pages/Profile/Modal/ModalTelegramHelp";

interface StepTwoProps {
  formik: FormikProps<any>;
  acoes: any;
  parametros: RowData[];
  filteredParam: RowData;
  filteredAction: any;
  filteredSetpoint: any;
  optionsParamNotInt: any;
  conditionAction: string;
  setConditionAction: (e: string) => void;
  setpoints: any;
}

const StepTwo = ({
  formik,
  acoes,
  parametros,
  filteredAction,
  filteredParam,
  filteredSetpoint,
  optionsParamNotInt,
  conditionAction,
  setConditionAction,
  setpoints,
}: StepTwoProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  // Ações do modal de dúvidas sobre o bot do Telegram
  const [openModalTelegramHelp, setOpenModalTelegramHelp] =
    React.useState(false);
  const handleOpenModalTelegramHelp = () => {
    setOpenModalTelegramHelp(true);
  };
  const handleCloseModalTelegramHelp = () => {
    setOpenModalTelegramHelp(false);
  };

  // Lista de Setpoints
  const optionsSetpoints = setpoints.map(
    (value: any, i: number) =>
      value.readOnly === false &&
      value.visible === true && (
        <MenuItem value={i} key={i}>
          {value.description}
        </MenuItem>
      )
  );

  // Lista de Ações
  const optionsActions = acoes?.map((value: any, i: number) => (
    <MenuItem value={value.id} key={i}>
      {value.label}
    </MenuItem>
  ));

  // Range da ação selecionada
  const actionRange = `${filteredAction?.min} a ${filteredAction?.max}`;

  // Range do setpoint selecionado
  const minSetpoint = applyDivider(
    filteredSetpoint?.min ?? 0,
    divider(filteredSetpoint)
  );
  const maxSetpoint = applyDivider(
    filteredSetpoint?.max ?? 0,
    divider(filteredSetpoint)
  );

  // Checa se o Setpoint é inteiro
  const isMinSetpointIsInt =
    divider(filteredSetpoint) === 0 || !Number.isInteger(minSetpoint)
      ? minSetpoint
      : `${minSetpoint}.0`;

  // Checa se o Setpoint é inteiro
  const isMaxSetpointIsInt =
    divider(filteredSetpoint) === 0 || !Number.isInteger(maxSetpoint)
      ? maxSetpoint
      : `${maxSetpoint}.0`;

  const setpointRange = `${isMinSetpointIsInt} a ${isMaxSetpointIsInt}`;

  // Range do parametro selecionado
  const minParam = applyDivider(
    filteredParam?.min ?? 0,
    divider(filteredParam)
  );
  const maxParam = applyDivider(
    filteredParam?.max ?? 0,
    divider(filteredParam)
  );

  // Checa se o parametro é inteiro
  const isMinParamIsInt =
    divider(filteredParam) === 0 || !Number.isInteger(minParam)
      ? minParam
      : `${minParam}.0`;

  // Checa se o parametro é inteiro
  const isMaxParamIsInt =
    divider(filteredParam) === 0 || !Number.isInteger(maxParam)
      ? maxParam
      : `${maxParam}.0`;

  const paramtrosRange = `${isMinParamIsInt} a ${isMaxParamIsInt}`;

  // Lista de Parametros
  const optionsParams = parametros.map(
    (value: any, i: number) =>
      value.readOnly === false &&
      value.visible === true && (
        <MenuItem value={i} key={i}>
          {value.description}
        </MenuItem>
      )
  );

  // Lista de valores do parametro selecionado (quando não for do tipo 'INT')
  const optionsMap =
    optionsParamNotInt &&
    Object.entries(optionsParamNotInt).map(([key, label]) => (
      <MenuItem key={key} value={key}>
        {label as string}
      </MenuItem>
    ));

  // Alterar a forma de Ação
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditionAction((event.target as HTMLInputElement).value);
  };

  return (
    <Box>
      <Box mt={3} ml={2}>
        <FormControl>
          <FormLabel sx={{ textAlign: "left", fontSize: 18 }}>
            {t("TEXT.TYPE_OF_ACTION")}
          </FormLabel>
          <RadioGroup
            value={conditionAction}
            onChange={handleChange}
            sx={{
              placeContent: "left",
              mt: 0,
              mb: 1,
            }}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              label={t("TEXT.TAKE_ACTION_COMMAND")}
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={t("TEXT.PARAMETER_CHANGE")}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={t("TEXT.SETPOINT_CHANGE")}
            />
            <Box display="flex">
              <FormControlLabel
                value="3"
                control={<Radio />}
                label={t("TEXT.TELEGRAM_MESSAGE")}
                sx={{ mr: 0 }}
              />
              <IconButton color="primary" onClick={handleOpenModalTelegramHelp}>
                <HelpOutlineIcon color="info" />
              </IconButton>
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
      {conditionAction === "0" ? (
        <Box display={mobile ? "grid" : "flex"} mt={2}>
          <Box
            display="grid"
            width={filteredAction?.tipo !== "number" || mobile ? "100%" : "70%"}
            mr={filteredAction?.tipo !== "number" || mobile ? 0 : 2}
          >
            <Select
              formik={formik}
              label={t("TEXT.COMMANDS").split("s")[0]}
              name="action"
              options={optionsActions}
              disabled={acoes === undefined || acoes?.length === 0}
            />
          </Box>
          {filteredAction?.tipo === "number" && (
            <Box
              display="grid"
              width={mobile ? "100%" : "30%"}
              mt={mobile ? 1 : 0}
            >
              <TextField
                formik={formik}
                disabled={false}
                label={t("TEXT.VALUE")}
                name="valueAction"
                type="number"
                fullWidth
              />
              <FormHelperText>{actionRange}</FormHelperText>
            </Box>
          )}
        </Box>
      ) : conditionAction === "1" ? (
        <Box display={mobile ? "grid" : "flex"} mt={2}>
          <Box
            display="grid"
            width={mobile || formik.values.param === "" ? "100%" : "70%"}
            mr={2}
            mb={mobile ? 1 : 0}
          >
            <Select
              formik={formik}
              label={t("TEXT.PARAMS")}
              name="param"
              options={optionsParams as JSX.Element[]}
              disabled={parametros === undefined || parametros.length === 0}
            />
          </Box>
          {formik.values.param !== "" && (
            <Box width={mobile ? "100%" : "50%"}>
              {!optionsParamNotInt ? (
                <Box width="100%">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{t("TEXT.VALUE")}</InputLabel>
                    <OutlinedInput
                      name="valueParam"
                      value={formik.values.valueParam}
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          {filteredParam?.unitOfMeasurement}
                        </InputAdornment>
                      }
                      label={t("TEXT.VALUE")}
                    />
                    <FormHelperText>
                      {`${paramtrosRange} ${filteredParam?.unitOfMeasurement}`}
                    </FormHelperText>
                  </FormControl>
                </Box>
              ) : (
                <Box width="100%">
                  <Select
                    formik={formik}
                    label={t("TEXT.VALUE")}
                    name="valueParam"
                    options={optionsMap}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : conditionAction === "2" ? (
        <Box display={mobile ? "grid" : "flex"} mt={2}>
          <Box
            display="grid"
            width={formik.values.setpoint === "" || mobile ? "100%" : "70%"}
            mr={formik.values.setpoint === "" || mobile ? 0 : 2}
          >
            <Select
              formik={formik}
              label="Setpoints"
              name="setpoint"
              options={optionsSetpoints}
              disabled={setpoints === undefined || setpoints.length === 0}
            />
          </Box>
          {formik.values.setpoint !== "" && (
            <Box
              display="grid"
              width={mobile ? "100%" : "30%"}
              mt={mobile ? 1 : 0}
            >
              <TextField
                formik={formik}
                disabled={false}
                label={t("TEXT.VALUE")}
                name="valueSetpoint"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {filteredSetpoint?.unitOfMeasurement}
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText>
                {`${setpointRange} ${filteredSetpoint?.unitOfMeasurement}`}
              </FormHelperText>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Box>
            <TextField
              formik={formik}
              disabled={false}
              label={t("TEXT.MESSAGE")}
              name="valueNotification"
              maxLength={500}
              fullWidth
            />
          </Box>
        </Box>
      )}
      {openModalTelegramHelp && (
        <ModalTelegramHelp
          handleOpen={openModalTelegramHelp}
          handleClose={handleCloseModalTelegramHelp}
        />
      )}
    </Box>
  );
};

export default StepTwo;
