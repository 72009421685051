import { Box, Typography } from "@mui/material";

type PlaceholderProps = {
  modelo: string;
};

const Placeholder = ({ modelo }: PlaceholderProps) => (
  <Box display="flex" height="100%" alignItems="center" justifyContent="center">
    <Typography variant="h4" textAlign="center">
      {modelo}
    </Typography>
  </Box>
);

export default Placeholder;
