const AUTH_ROUTE = "/auth";

export const LOGIN_ROUTE = `${AUTH_ROUTE}/login`;

export const LOGOUT_ROUTE = `${AUTH_ROUTE}/logout`;

export const FETCH_ME_ROUTE = `${AUTH_ROUTE}/fetchme2`;

export const REGISTER_ROUTE = `${AUTH_ROUTE}/register`;

export const FORGOT_ROUTE = `${AUTH_ROUTE}/forgot`;

export const RESET_ROUTE = `${AUTH_ROUTE}/reset`;

export const CANCEL_REGISTRATION_ROUTE = `${AUTH_ROUTE}/cancel-registration`;

export const CONFIRM_REGISTRATION_ROUTE = `${AUTH_ROUTE}/confirm`;

export const COMPLETE_REGISTRATION_ROUTE = `${AUTH_ROUTE}/complete-registration`;

export const CONFIRM_REGISTRATION_LOGIN_ROUTE = `${AUTH_ROUTE}/confirm-link`;

// Rota para resgatar o email do usuario via token
export const getConfirmRegistrationEmailRoute = (token: string) =>
  `${AUTH_ROUTE}/registration-info?token=${token}`;

// Rota para checar o login
export const STATUS_CHECK_ROUTE = `${AUTH_ROUTE}/statuscheck`;
