import { CardData } from "../../contexts/ControllerContext";
import { Measures } from "../../services/types/ctrls";
import { COM_ERROR, OFFLINE_ERROR } from "../Controller/constants";
import { getCtrlConfig } from "../Controller/parser";
import { Estado } from "../Controller/parser/types";
import { parseColor, parseIcon } from "../Controller/parser/utils";

// Converte o inteiro do estado em binário e deixa os bits de trás pra frente
const convertStateToBinary = (n: number) =>
  n
    ?.toString(2)
    .split("")
    .reverse()
    .map((i) => +i);

// Converte o inteiro do alarme em binário e retorna os codigos de alarmes ativos (potencias de base dois)
const convertAlarmToBinaryAndGetCodes = (n: number) => {
  const binaryArray = n
    ?.toString(2)
    .split("")
    .reverse()
    .map((i) => +i);

  let powerOfTwoList: number[] = [];

  binaryArray?.forEach((bit, index) => {
    if (bit === 1) {
      powerOfTwoList.push(Math.pow(2, index));
    }
  });

  return powerOfTwoList.reverse();
};

// Resgata as cores dos estados de cada modelo no JSON
const getStateColor = (state: number, states: Estado[]) => {
  if (state !== undefined) {
    if (state === null) {
      return "fundo_cinza";
    }
    let binaryState = state.toString(2).split("").reverse();
    while (binaryState.length < states.length) binaryState.push("0");

    let color = "";
    for (let i = 0; i < binaryState.length; i++) {
      if (states[i]?.rawOne && states[i]?.rawZero) {
        if (binaryState[i] === "1") {
          if (states[i].rawOne[2] !== "") {
            color = states[i].color;
          }
        } else {
          if (states[i].rawZero[2] !== "") {
            color = states[i].rawZero[2] as string;
          }
        }
      } else {
        return "fundo_cinza";
      }
    }
    return color;
  }
};

// Resgata os dados 'crus' dos ícones (bits, color e icon)
const getRawIcons = (measures: Measures) => {
  const ctrlConfig = getCtrlConfig(measures?.modelo);
  const { estado } = ctrlConfig;
  const icons = estado.filter(({ icon }) => !!icon);
  return icons;
};

// Converte o estado para binário e retorna os dados dos ícones
const parseIcons = (states: Estado[], state: number) => {
  const binaryState = convertStateToBinary(state);
  const icons = states
    .map((icon) => ({
      active: !!binaryState?.[icon.bit],
      description: icon.description,
      icon: parseIcon(icon.icon),
    }))
    .reverse();
  return icons;
};

// Resgata as cores dos estados de cada modelo no JSON
const getRawColor = (measures: Measures) => {
  const ctrlConfig = getCtrlConfig(measures?.modelo);
  const { estado } = ctrlConfig;
  return getStateColor(measures?.estado, estado);
};

// Resgata os alarmes de cada modelo no JSON
const getAlarm = (measures: Measures) => {
  const ctrlConfig = getCtrlConfig(measures?.modelo);
  const { alarmes } = ctrlConfig;
  if (measures?.alarme === OFFLINE_ERROR || measures?.alarme === COM_ERROR) {
    return alarmes.find((alarme) => alarme.id === COM_ERROR);
  }
  const AlarmList = convertAlarmToBinaryAndGetCodes(measures?.alarme);
  if (AlarmList.length > 0) {
    return alarmes.find((alarme) => alarme.id === AlarmList[0]);
  } else {
    return alarmes.find((alarme) => alarme.id === measures?.alarme);
  }
};

// Dados gerais dos cards do Dashboard
export const parseCardData = (
  theme: string | undefined,
  measures: Measures
): CardData => {
  const rawIcons = getRawIcons(measures);
  const alarm = getAlarm(measures);
  const rawColor = !!alarm
    ? measures?.alarme === OFFLINE_ERROR
      ? "fundo_cinza"
      : alarm.color
    : getRawColor(measures);
  if (measures?.alarme === OFFLINE_ERROR && !!alarm) {
    alarm.id = OFFLINE_ERROR;
  }
  const color = parseColor(theme, rawColor);
  const icons = parseIcons(rawIcons, measures?.estado);
  return { alarm, color, measures, icons };
};

// Verificar se a string é igual a "  " ou " " ou ""
export const checkIfIsUndefinedString = (value: string | undefined) =>
  value === "" || value === " " || value === "  ";
