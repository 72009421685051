import React from "react";

import { Box, Button } from "@mui/material";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import ActionsTable from "../../Controller/SettingsTable/Action";
import { ControllerEditProvider } from "../../../contexts/ControllerEditContext";
import { ControllerProvider } from "../../../contexts/ControllerContext";
import useIsMobile from "../../../hooks/useIsMobile";
import Modal from ".";
import { getDevice } from "../../../services/data/arcsys";
import { AuthContext } from "../../../contexts/AuthContext";

interface ModalActionsProps {
  handleOpen: boolean;
  handleClose: (e: any) => void;
  model: string;
  ctrl: string;
  alarm: number;
}

const ModalActions = ({
  handleOpen,
  handleClose,
  model,
  ctrl,
  alarm,
}: ModalActionsProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { userId } = React.useContext(AuthContext);
  const [permissionToEdit, setPermissionToEdit] =
    React.useState<boolean>(false);
  const [devicePermission, setDevicePermission] = React.useState<string>();
  const offlineError = alarm === 65535;

  React.useEffect(() => {
    // Dispositivo filtrado pelo o endereço MAC
    getDevice(ctrl.split("/")[0]).then((res) => {
      const { data } = res;

      // Checar a permissão do usuário
      const filterPermissions = data?.users.filter(
        (f: any) => f.id === +userId
      )[0];

      setDevicePermission(filterPermissions?.role);

      // Checar se os parametros tem permissão para serem editados
      const permissionToEdit =
        data?.subscriptions
          .filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === data?.ownerId
          )
          .reverse()[0]?.plan.permissions.DEVICE.CONTROL_PARAMETERS ?? false;
      setPermissionToEdit(permissionToEdit);
    });
  }, [ctrl, userId]);

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : 550}
    >
      <ControllerProvider>
        <ControllerEditProvider>
          <ActionsTable
            model={model}
            permissionToEdit={permissionToEdit}
            devicePermission={devicePermission !== "VIEW"}
            offlineError={offlineError}
            ctrl={ctrl}
            handleCloseModalActionCtrl={(e: any) => handleClose(e)}
          />
        </ControllerEditProvider>
      </ControllerProvider>
      <Box mt={3} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: red[50],
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalActions;
