import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import {
  loginBackground,
  loginComponentBackground,
} from "../../components/Login/BackgroundImages";
import React from "react";
import Terms from "./Terms";
import ModalCancel from "./Modal/ModalCancel";
import { confirmRegistration } from "../../services/data/auth";
import { getLanguage } from "../../translations/languages";
import { ToastContext } from "../../contexts/ToastContext";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../Routes/constants";
import useIsMobile from "../../hooks/useIsMobile";
import { AuthContext } from "../../contexts/AuthContext";

const ActivateAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mobile = useIsMobile();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { signOut } = React.useContext(AuthContext);
  const language = getLanguage().code;
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    const fetchStatus = () => {
      signOut();
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Checkbox dos termos de uso
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const checkLanguage =
    language === "en" ? "en" : language === "pt" ? "pt-br" : "es";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Ativar a conta do novo usuário
  const handleConfirmRegistration = () => {
    confirmRegistration({ token: token, language: checkLanguage })
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.ACCOUNT_CREATED"));
        navigate(LOGIN);
      })
      .catch((err) => {
        const error = err.response.status;
        if (error === 500) {
          toastError(t("TOAST.ERROR.MAC_IN_USE"));
        } else if (error === 403) {
          toastError(t("TOAST.ERROR.INVALID_TOKEN"));
        } else {
          toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
        }
      });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        backgroundImage: loginBackground,
        backgroundSize: "cover",
      }}
    >
      <Grid item>
        <Card
          sx={{
            minWidth: 350,
            maxWidth: 700,
            p: 2,
            backgroundImage: loginComponentBackground,
            ml: 2,
            mr: 2,
          }}
          elevation={5}
        >
          <CardContent sx={{ position: "relative", overflow: "auto" }}>
            <Typography variant="h5" fontWeight={700} sx={{ mb: 2 }}>
              {t("TEXT.LICENSE_TERMS")}
            </Typography>
            <Terms />
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label={t("TEXT.TERMS_CHECKBOX_MESSAGE")}
            />
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Button
                type="submit"
                variant="outlined"
                onClick={handleOpen}
                size={mobile ? "small" : "medium"}
                sx={{ mr: 1, fontSize: mobile ? 12 : 13 }}
              >
                {t("TEXT.CANCEL_REGISTRATION")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleConfirmRegistration}
                disabled={!checked}
                size={mobile ? "small" : "medium"}
                sx={{ fontSize: mobile ? 12 : 13 }}
              >
                {t("TEXT.ACCEPT_TERMS")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <ModalCancel handleOpen={open} handleClose={handleClose} />
    </Grid>
  );
};

export default ActivateAccount;
