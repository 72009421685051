import { Card } from "@mui/material";
import React from "react";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import useIsMobile from "../../../hooks/useIsMobile";
import Body from "./Body";
import Header from "./Header";
import Footer from "./Footer";
import { ToastContext } from "../../../contexts/ToastContext";
import { useTranslation } from "react-i18next";
import { getNumbersOfDevices } from "../../../services/data/arcsys";
import { getSectors } from "../parse";
import { getSectorsByIdSector } from "../../../services/data/sector";
import { parseCardData } from "../../../components/Card/parser";
import { getRawCtrlConfig, modelExists } from "../../../config";
import { parseMeasures } from "../../../components/Controller/parser/utils";
import { grey } from "@mui/material/colors";
import { getCtrlConfig } from "../../../components/Controller/parser";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { TOKEN_KEY } from "../../../auth/constants";

interface StructureProps {
  data: any;
  setSectorEdited: (e: boolean) => void;
  isInModal: boolean;
  deviceUsers: any;
}

const Structure = ({
  data,
  setSectorEdited,
  isInModal,
  deviceUsers,
}: StructureProps) => {
  let intervalIdApply: NodeJS.Timer;
  let intervalIdAction: NodeJS.Timer;
  const mobile = useIsMobile();
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeModeContext);
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);
  const [checked, setChecked] = React.useState(false);
  const [submittingApply, setSubmittingApply] = React.useState(false);
  const [submittingAction, setSubmittingAction] = React.useState(false);
  const [changingSetpoint, setChangingSetpoit] = React.useState(false);
  const [deviceRemoved, setDeviceRemoved] = React.useState(false);
  const [openModalEdit, setOpenModalEdit] = React.useState<boolean>(false);
  const [pages, setPages] = React.useState<number>(1);
  const [devices, setDevices] = React.useState<any>([]);
  const [loadingDevices, setLoadingDevices] = React.useState(false);
  const [isOpennedSelect, setIsOpennedSelect] = React.useState(false);
  const [payloadDevices, setPayloadDevices] = React.useState<any>([]);
  const [notShow, setNotShow] = React.useState(false);
  const [value, setValue] = React.useState<number>(
    data.resource_value[data.default_resource]
  );
  const isIntervalActiveApply = React.useRef(false);
  const isIntervalActiveAction = React.useRef(false);

  // Resgatar dados dos controladores via payload do MQTT
  const card = parseCardData(theme, data.sectorCtrl?.payload);
  const color = card?.color;
  const measures = card?.measures;
  const modelFound = modelExists(measures?.modelo);
  const bgColor = modelFound ? color.main : grey[500];
  const parsedMeasures = measures && parseMeasures(measures);
  const mainMeasure = parsedMeasures?.shift()!;
  const secondaryMeasures = parsedMeasures;

  // Resgatar dados da média dos controladores para o footer
  const measuresAvg = {
    ...measures,
    modelo: data.sector_model,
    medidas: data.avg_devices,
  };
  const parsedMeasuresAvg = measures && parseMeasures(measuresAvg);
  const mainMeasureAvg = parsedMeasuresAvg?.shift()!;
  const secondaryMeasuresAvg = parsedMeasuresAvg;

  // Resgatar dados dos dos controladores para o header
  const bgColorHeader = modelFound ? color.header : grey[600];
  const sector_model = getCtrlConfig(data.sector_model).nome;
  const sector_reference_model = getCtrlConfig(data.reference.model).nome;

  // Dispositivos com erro
  const devicesErrorLength: number = data.devices.filter(
    (f: any) => f.last_exec_status === false
  ).length;

  // Retornar o payload dos dispositivos
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        if (openModalEdit) {
          // Rota para resgatar a quantidade de dispositivos que irão ser renderizados
          getNumbersOfDevices(
            true,
            pages,
            10,
            "",
            0,
            "",
            data?.reference?.ctrlId.split("/")[0]
          ).then((res) => {
            if (res?.data) {
              const payloadData = res.data.items;
              if (res.data.this_item_list_qtn === res.data.total) {
                setNotShow(true);
              }
              setPayloadDevices(payloadData);
              setDevices((prevOptions: any) => [
                ...prevOptions,
                ...payloadData,
              ]);
              setLoadingDevices(false);
            }
          });
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, token, pages, openModalEdit]);

  // Atualiza o payload dos dispositivos conforme avançar os steps
  React.useEffect(() => {
    if (isOpennedSelect) {
      setDevices([]);
      setPages(1);
      // Rota para resgatar a quantidade de dispositivos que irão ser renderizados
      getNumbersOfDevices(
        true,
        pages,
        10,
        "",
        0,
        "",
        data?.reference?.ctrlId.split("/")[0]
      ).then((res) => {
        if (res?.data) {
          const payloadData = res.data.items;
          if (res.data.this_item_list_qtn === res.data.total) {
            setNotShow(true);
          }
          setPayloadDevices(payloadData);
          setDevices((prevOptions: any) => [...prevOptions, ...payloadData]);
          setLoadingDevices(false);
        }
      });
      setIsOpennedSelect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, token, pages, isOpennedSelect]);

  // Adicionar novos dados no select dos Dispositivos
  const handleLoadMore = () => {
    setPages((prevPage) => prevPage + 1);
  };

  // Estado inicial do cadeado de 'desabilitar/habilitar' alterações
  React.useEffect(() => {
    setChecked(data.locked);
  }, [data.locked]);

  // Atualizar o valor do setpoint do setor após a exclusão de algum setor
  React.useEffect(() => {
    if (deviceRemoved) {
      getSectorsByIdSector(data.id).then((res) => {
        const { data } = res;
        setValue(+data.resource_value[data.default_resource]);
        setDeviceRemoved(false);
        setSectorEdited(true);
      });
    }
  }, [data, deviceRemoved, setSectorEdited]);

  // Interval do botão 'Aplicar'
  const checkContentStatusApply = async () => {
    getSectors(
      data,
      intervalIdApply,
      isIntervalActiveApply,
      setSubmittingApply,
      toastSuccess,
      toastError,
      setSectorEdited,
      t("ERROR.OPERATION_IN_PROGRESS"),
      t("TOAST.ERROR.CONTROLLER_CHANGED"),
      t("TOAST.SUCCESS.ALTERNATE_ACTION")
    );
  };

  // Interval das ações
  const checkContentStatusAction = async () => {
    getSectors(
      data,
      intervalIdAction,
      isIntervalActiveAction,
      setSubmittingAction,
      toastSuccess,
      toastError,
      setSectorEdited,
      t("ERROR.OPERATION_IN_PROGRESS"),
      t("TOAST.ERROR.CONTROLLER_CHANGED"),
      t("TOAST.SUCCESS.ALTERNATE_ACTION")
    );
  };

  // Após pressionar o botão 'Aplicar', habilitar um spinner e um interval de 5s
  const startIntervalApply = () => {
    if (!isIntervalActiveApply.current) {
      intervalIdApply = setInterval(checkContentStatusApply, 5000);
      setSubmittingApply(true);
      isIntervalActiveApply.current = true;
    }
  };

  // Após enviar alguma ação, habilitar um spinner e um interval de 5s
  const startIntervalAction = () => {
    if (!isIntervalActiveAction.current) {
      intervalIdAction = setInterval(checkContentStatusAction, 5000);
      setSubmittingAction(true);
      isIntervalActiveAction.current = true;
    }
  };

  // Resgatar os dados do Setpoint
  const ctrlConfig = getCtrlConfig(data.sector_model);
  const rawCtrlConfig = getRawCtrlConfig(data.sector_model);

  // Index do setpoint
  const indexOfSetpoint = ((data.default_resource &&
    +data.default_resource?.split("/")[1]?.split("s")[1]) ??
    0) as number;
  // Setpoint filtrado
  const filteredSetpoint = ctrlConfig.setpoints[indexOfSetpoint];

  // Filtrar controladores online
  const ctrls = data.ctrls ?? [];
  const filteredCtrls = ctrls.filter((f: any) => f.ctrl.includes("CTRL"));
  const devicesOnline = filteredCtrls?.filter(
    (f: any) => f.online === true
  ).length;

  // Filtrar controladores offline
  const devicesOffline = data.devices.length - devicesOnline;

  // Checar se todos os controladores estão offline
  const allDevicesOffline = devicesOffline === data.devices.length;

  // Validação do botão de incrementar, decrementar e aplicar Setpoint
  const editSetpointIsValid =
    (data?.device_inverter_off === true &&
      rawCtrlConfig?.inversor &&
      rawCtrlConfig?.inversor === true) ||
    allDevicesOffline;

  return (
    <Card
      sx={{
        m: mobile ? 0 : 2,
        mb: mobile ? 3 : 2,
        width: 328,
        borderRadius: 3,
        "&:hover":
          theme === "light"
            ? {
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
              }
            : theme === "dark"
            ? {
                boxShadow:
                  "0px 1px 2px 0px rgba(175, 175, 175, 0.8), 1px 2px 4px 0px rgba(175, 175, 175, 0.8), 2px 4px 8px 0px rgba(175, 175, 175, 0.8), 2px 4px 16px 0px rgba(175, 175, 175, 0.8)",
              }
            : null,
      }}
      elevation={3}
    >
      <Header
        data={data}
        checked={checked}
        setChecked={setChecked}
        setSectorEdited={setSectorEdited}
        setValue={setValue}
        setDeviceRemoved={setDeviceRemoved}
        bgColor={bgColorHeader}
        color={color}
        sector_model={sector_model}
        sector_reference_model={sector_reference_model}
        devices={devices}
        handleLoadMore={handleLoadMore}
        loadingDevices={loadingDevices}
        openModalEdit={openModalEdit}
        setOpenModalEdit={setOpenModalEdit}
        setIsOpennedSelect={setIsOpennedSelect}
        payloadDevices={payloadDevices}
        notShow={notShow}
        deviceUsers={deviceUsers}
      />
      <Body
        data={data}
        checked={checked}
        submittingApply={submittingApply}
        startIntervalApply={startIntervalApply}
        devicesError={devicesErrorLength}
        value={value}
        setValue={setValue}
        changingSetpoint={changingSetpoint}
        bgColor={bgColor}
        mainMeasure={mainMeasure}
        secondaryMeasures={secondaryMeasures}
        filteredSetpoint={filteredSetpoint}
        editSetpointIsValid={editSetpointIsValid}
        devicesOffline={devicesOffline}
      />
      <Footer
        checked={checked}
        data={data}
        submittingAction={submittingAction}
        startIntervalAction={startIntervalAction}
        setSectorEdited={setSectorEdited}
        isInModal={isInModal}
        setValue={setValue}
        setChangingSetpoit={setChangingSetpoit}
        bgColor={bgColor}
        mainMeasureAvg={mainMeasureAvg}
        secondaryMeasuresAvg={secondaryMeasuresAvg}
        ctrlConfig={ctrlConfig}
        editSetpointIsValid={editSetpointIsValid}
        allDevicesOffline={allDevicesOffline}
        devices={devices}
        deviceUsers={deviceUsers}
      />
    </Card>
  );
};

export default Structure;
