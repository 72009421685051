import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { ToastContext } from "../../../contexts/ToastContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { yupRequiredString } from "../../../components/Form";
import { TOKEN_KEY } from "../../../auth/constants";
import { api } from "../../../services/api";
import Modal from "../../Devices/Modal";
import { editUserById } from "../../../services/data/user";
import {
  countryListBr,
  countryListEn,
  countryListEs,
} from "../../Register/parse";
import { getLanguage } from "../../../translations/languages";
import { AutoComplete } from "../../../components/Form/AutoComplete";
import { useParams } from "react-router-dom";

interface ModalEditLocalProps {
  user: any;
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalEditLocal = ({
  user,
  setDeviceEdited,
  handleOpen,
  handleClose,
}: ModalEditLocalProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { userArcsys, token, authenticated } = React.useContext(AuthContext);
  const language = getLanguage().code;
  const params = useParams();
  const id = params?.addr && +params?.addr;
  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  const formik = useFormik({
    initialValues: {
      location: (user.user.location as string) ?? "",
    },
    validationSchema: Yup.object({
      location: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const filtereduser = user.user;

        // Novos dados do usuario
        const newUserData = userIsAdmin
          ? {
              ...filtereduser,
              location: values.location,
            }
          : {
              ...user,
              location: values.location,
            };

        if (id) {
          // Editar o país do usuário logado
          editUserById(id, newUserData)
            .then(() => {
              toastSuccess(t("TOAST.SUCCESS.EDITED_USER"));
              setDeviceEdited(true);
              handleClose();
            })
            .catch(() => {
              toastError(t("TOAST.ERROR.EDITED_USER"));
            });
        }
      }
    },
  });

  const isFormValid =
    formik.values.location === (user.user.location as string) ||
    formik.values.location === "" ||
    formik.values.location === null;

  const checkOptionsLanguage =
    language === "en"
      ? countryListEn
      : language === "pt"
      ? countryListBr
      : countryListEs;

  const options = checkOptionsLanguage.map((value: string) => value);

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.EDIT")} Local
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <AutoComplete
            formik={formik}
            label={t("TEXT.COUNTRY")}
            name="location"
            options={options}
            disabled={false}
          />
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: red[400],
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: red[50],
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalEditLocal;
