import { Alert, Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { red } from "@mui/material/colors";
import Modal from "../../../Devices/Modal";
import useIsMobile from "../../../../hooks/useIsMobile";

interface ModalHelpFrequencyInvertersProps {
  handleOpen: boolean;
  handleClose: () => void;
  handleSync: () => void;
}

const ModalHelpFrequencyInverters = ({
  handleOpen,
  handleClose,
  handleSync,
}: ModalHelpFrequencyInvertersProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  const handleSyncSector = () => {
    handleSync();
    handleClose();
  };

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : 450}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <HelpOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Alert severity="warning" sx={{ mt: 2, mb: 2, alignItems: "center" }}>
        <Typography>
          <strong style={{ paddingRight: "5px" }}>
            {t("TEXT.FREQUENCY_INVERTERS")}:
          </strong>
          {t("TEXT.FREQUENCY_INVERTERS_MESSAGE")}
        </Typography>
        <Typography mt={1.2}>
          {`${t("TEXT.FREQUENCY_INVERTERS_MESSAGE_2")} ${t("TEXT.CONFIRM")}`}.
        </Typography>
      </Alert>
      <Box mt={2} justifyContent="space-between" display="flex">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: red[50],
            mt: 1,
            ml: 2,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.CLOSE")}
        </Button>
        <Button
          variant="contained"
          onClick={handleSyncSector}
          sx={{
            mt: 1,
            mr: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
          }}
        >
          {t("BUTTON.CONFIRM")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalHelpFrequencyInverters;
