import React, { useRef } from "react";
import {
  exportComponentAsPNG,
  exportComponentAsJPEG,
} from "react-component-export-image";

import {
  Dvr as DvrIcon,
  InfoOutlined,
  Menu as MenuIcon,
  Timeline as TimelineIcon,
} from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  Divider,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";
import Spinner from "../../../components/Spinner";
import ReportGraph from "./ReportGraph/index";
import RecordTable from "./RecordTable";
import { getModelos } from "../../../services/data/modelo";
import { updateCtrl } from "../../../config";
import {
  FormDateProps,
  Graph as GraphType,
} from "../../../services/types/store";
import {
  filterNotNullLabels,
  filterTimesFromBack,
  getAlarm,
  getFields,
  medidasLabels,
  resultMedidas,
  resultSetpoints,
  setpointLabels,
} from "../../../components/Graph/parser";
import { reportTitle } from "./RecordTable/parse";
import { getLanguage } from "../../../translations/languages";
import GeneralInfo from "./GeneralInfo";
import { AuthContext } from "../../../contexts/AuthContext";
import { TOKEN_KEY } from "../../../auth/constants";
import { api } from "../../../services/api";
import { getCtrlConfig } from "../../../components/Controller/parser";

type ReportStructureProps = {
  macId: string;
  mac: string;
  updateLoading: boolean;
  formValue: any;
  model: string;
  name: string;
  formDate: FormDateProps | undefined;
};

type IconsDescription = {
  [key: string]: number | null;
};

let graphStructure: any[] = [];
let recordStructure: any[] = [];
let columns: any = [];

const ReportStructure = ({
  macId,
  mac,
  updateLoading,
  formValue,
  model,
  name,
  formDate,
}: ReportStructureProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const [reportOption, setReportOption] = React.useState(0);
  const [radioButton, setRadioButton] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dataGraph, setDataGraph] = React.useState<any>([]);
  const [graphLabels, setGraphLabels] = React.useState<string[]>([]);
  const openMenu = Boolean(anchorEl);
  const graphRef: any = useRef();
  const { authenticated, token } = React.useContext(AuthContext);

  const checkLanguage = language === "en" ? "en-US" : "pt-BR";
  const date = new Date().toLocaleString(checkLanguage).slice(0, 10);
  const convertedDate = language === "en" ? date.replace(",", "") : date;

  // Título do arquivo de exportação
  const exportTitle = `${convertedDate.replace(" ", "")}_${model}_Graph`;

  // Ação de exportar para .jpeg
  const printAsJPEG = () => {
    exportComponentAsJPEG(graphRef, {
      fileName: exportTitle,
    });
  };

  // Ação de exportar para .png
  const printAsPNG = () => {
    exportComponentAsPNG(graphRef, {
      fileName: exportTitle,
    });
  };

  // Abre o menu de opções de exportações de arquivo
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Fecha o menu de opções de exportações de arquivo
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Altera o tipo de relatório (Gráfico || Registros || Informações Gerais)
  const handleChange = (_: any, value: number) => {
    setReportOption(value);
  };

  // Carrega o componente Spinner após alterar o tipo de Relatório
  const updateRadioButtons = () => {
    setRadioButton(true);
    setTimeout(() => {
      setRadioButton(false);
    }, 800);
  };

  React.useEffect(() => {
    const fetchStatus = () => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Se a key 'ageonRef' do localStorage for indefinida:
        // Resgata os modelos JSON conforme a linguagem selecionada
        if (localStorage.ageonRef === undefined) {
          getModelos().then((res) => {
            if (localStorage.lang === "pt") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model["pt-br"])
              );
            } else if (localStorage.lang === "en") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.en)
              );
            } else {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.es)
              );
            }
            updateCtrl(localStorage.ageonRef);
          });
        } else {
          updateCtrl(localStorage.ageonRef);
        }
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  React.useEffect(() => {
    graphStructure = [];
    const ctrlConfig = getCtrlConfig(model !== undefined ? model : "");
    const { estado } = ctrlConfig;
    const rawIcons = estado.filter(({ icon }) => !!icon);
    recordStructure = [];
    formValue?.map((v: any) => {
      const { data } = v;
      let icons_labels: string[] = [];
      const icons_description: IconsDescription = {};
      rawIcons.forEach((icon) => {
        icons_description[icon.description.active] =
          data.estado !== null ? (data.estado & (1 << icon.bit) ? 1 : 0) : null;
        icons_labels.push(icon.description.active);
      });
      // Adiciona os dados do gráfico em uma array
      graphStructure.push({
        ...resultMedidas(data.medidas, data.modelo),
        ...resultSetpoints(data.setpoints, data.modelo),
        ...icons_description,
      });
      // Adiciona os dados do Relatório Registro em uma array
      recordStructure.push({
        time: v.timestamp,
        ...resultMedidas(data.medidas, data.modelo),
        ...resultSetpoints(data.setpoints, data.modelo),
        state: data.estado,
        ...getAlarm(data),
      });
      // Checa se os Setpoints forem iguais a '0', se não for, adiciona em um useState
      if (setpointLabels(data.modelo).length === 0) {
        setGraphLabels([...medidasLabels(data.modelo), ...icons_labels]);
      } else {
        setGraphLabels([
          ...medidasLabels(data.modelo),
          ...setpointLabels(data.modelo),
          ...icons_labels,
        ]);
      }
      return v;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  // Dados gerias do gráfico
  const graphDataValues: GraphType = React.useMemo(
    () => ({
      graphLabels: graphLabels,
      data: graphStructure,
      times: filterTimesFromBack(formValue),
    }),
    [formValue, graphLabels]
  );

  React.useEffect(() => {
    columns = [];
    // Array de arrays contendo o rótulo e os valores numéricos do gráfico
    filterNotNullLabels(graphDataValues)?.forEach((v) => {
      return columns.push([
        v,
        ...getFields(graphDataValues?.data, v).map((v: number) => {
          return v;
        }),
      ]);
    });
    setDataGraph(columns);
  }, [graphDataValues]);

  // Lista dos títulos da forma de exportação do gráfico
  const menuList = ["Download .JPG", "Download .PNG"];

  // Data inicial do formulário
  const firstDate = formDate && new Date(formDate?.dateStart);

  // Data final do formulário
  const lastDate = formDate && new Date(formDate?.dateEnd);

  return !updateLoading ? (
    <Card>
      <CardContent sx={{ p: 0, pt: 2, pb: 2, textAlign: "center" }}>
        <Box display="flex" justifyContent="space-around">
          <Tabs
            value={reportOption}
            onClick={updateRadioButtons}
            onChange={handleChange}
            sx={{
              fontSize: 20,
              pb: 2,
              placeContent: "center",
            }}
          >
            <Tab
              icon={<TimelineIcon />}
              label={t("TEXT.GRAPH")}
              disableRipple
              sx={{ pt: 0 }}
            />
            <Tab
              icon={<DvrIcon />}
              label={t("TEXT.RECORDS")}
              sx={{ pt: 0, pl: mobile ? 2.5 : 5, pr: mobile ? 2.5 : 5 }}
              disableRipple
            />
            <Tab
              icon={<InfoOutlined />}
              label={t("TEXT.GENERAL_INFO")}
              sx={{ width: 120, mt: 2, pt: 0 }}
              disableRipple
            />
          </Tabs>
        </Box>
        {reportOption === 0 ? (
          radioButton ? (
            <Box pt={25} pb={25}>
              <Spinner size={100} />
            </Box>
          ) : dataGraph.length === 0 ? (
            <Box display="inline-table">
              <Box pt={9} pb={0}>
                <FormLabel sx={{ fontSize: 24 }}>{name}</FormLabel>
              </Box>
              <Typography textAlign="center" pt={0} pb={3} variant="subtitle1">
                {firstDate &&
                  lastDate &&
                  reportTitle(
                    firstDate,
                    lastDate,
                    language,
                    t("TEXT.TO"),
                    t("TEXT.OF")
                  )}
              </Typography>
              <Alert
                severity="info"
                sx={{
                  mt: 3,
                  mb: 3,
                  textAlign: "center",
                  placeContent: "center",
                  width: 310,
                }}
              >
                {t("TOAST.INFO.NO_DATA_RECORD")}
              </Alert>
            </Box>
          ) : (
            <Card elevation={0}>
              <CardContent sx={{ p: 0, pt: "16px", pb: "16px" }}>
                <Divider sx={{ ml: 2, mr: 2 }} />
                <Box height="auto" flexDirection="column" mb={2}>
                  {formValue && (
                    <>
                      <Box mb={1} mr={3} textAlign="right">
                        {dataGraph.length > 0 &&
                          graphDataValues &&
                          graphDataValues?.times?.length !== 0 && (
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={openMenu ? "long-menu" : undefined}
                              aria-expanded={openMenu ? "true" : undefined}
                              aria-haspopup="true"
                              color="primary"
                              onClick={handleClick}
                            >
                              <MenuIcon />
                            </IconButton>
                          )}
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleCloseMenu}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          {menuList.map((option) => (
                            <MenuItem
                              key={option}
                              onClick={() =>
                                option === "Download .JPG"
                                  ? printAsJPEG()
                                  : option === "Download .PNG" && printAsPNG()
                              }
                              sx={{ justifyContent: "center" }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </Box>
                      <div ref={graphRef}>
                        <div id="photo" style={{ textAlign: "center" }}>
                          <Box
                            pt={
                              dataGraph.length > 0 &&
                              graphDataValues &&
                              graphDataValues?.times?.length !== 0
                                ? 2
                                : 5
                            }
                            pb={0}
                          >
                            <FormLabel sx={{ fontSize: 24 }}>{name}</FormLabel>
                          </Box>
                          <Typography
                            textAlign="center"
                            pt={0}
                            pb={3}
                            variant="subtitle1"
                          >
                            {firstDate &&
                              lastDate &&
                              reportTitle(
                                firstDate,
                                lastDate,
                                language,
                                t("TEXT.TO"),
                                t("TEXT.OF")
                              )}
                          </Typography>
                          <ReportGraph
                            formValue={formValue}
                            dataGraph={dataGraph}
                            graphDataValues={graphDataValues}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          )
        ) : reportOption === 1 ? (
          radioButton ? (
            <Box pt={25} pb={25}>
              <Spinner size={100} />
            </Box>
          ) : (
            <Card elevation={0}>
              <CardContent>
                <RecordTable
                  macId={macId}
                  beginTs={formDate ? formDate.dateStart.getTime() : 0}
                  endTs={formDate ? formDate.dateEnd.getTime() : 0}
                  mac={mac}
                  rotulo={name}
                  modelo={model}
                  data={recordStructure}
                  recordsTitle={
                    (firstDate &&
                      lastDate &&
                      reportTitle(
                        firstDate,
                        lastDate,
                        language,
                        t("TEXT.TO"),
                        t("TEXT.OF")
                      )) ??
                    ""
                  }
                />
              </CardContent>
            </Card>
          )
        ) : (
          reportOption === 2 &&
          (radioButton ? (
            <Box pt={25} pb={25}>
              <Spinner size={100} />
            </Box>
          ) : (
            <Card elevation={0}>
              <CardContent>
                <GeneralInfo
                  macId={macId}
                  beginTs={formDate ? formDate.dateStart.getTime() : 0}
                  endTs={formDate ? formDate.dateEnd.getTime() : 0}
                  mac={mac}
                  rotulo={name}
                  modelo={model}
                  generalInfoTitle={
                    (firstDate &&
                      lastDate &&
                      reportTitle(
                        firstDate,
                        lastDate,
                        language,
                        t("TEXT.TO"),
                        t("TEXT.OF")
                      )) ??
                    ""
                  }
                />
              </CardContent>
            </Card>
          ))
        )}
      </CardContent>
    </Card>
  ) : (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <Spinner size={100} />
    </Backdrop>
  );
};

export default ReportStructure;
