import { Box, Grid, Tooltip, Typography } from "@mui/material";

import { CardData } from "../../../contexts/ControllerContext";
import { COM_ERROR, OFFLINE_ERROR } from "../../Controller/constants";
import { Alarmes, MeasuresData } from "../../Controller/parser/types";
import { parseMeasures } from "../../Controller/parser/utils";
import Icons from "../Icons";
import { MainMeasure, SecondaryMeasures } from "../Measures";
import ErrorMessage from "./ErrorMessage";
import { useTranslation } from "react-i18next";

type AlarmProps = {
  alarm: Alarmes;
  data: CardData;
};

const selectPosition = (positionValue: string, value: string) =>
  positionValue === value;

// Cenários de erros de acordo com o modelo JSON
const Alarm = ({ alarm, data }: AlarmProps) => {
  const { t } = useTranslation();
  const { id, code, description, position, icon } = alarm;
  const { icons, measures } = data;
  const valuePosition = position?.toString();

  const parsedMeasures = parseMeasures(measures);
  const mainMeasure = parsedMeasures.shift()!;
  const secondaryMeasures = parsedMeasures;

  const comErrorId = id === COM_ERROR;
  const offlineErrorId = id === OFFLINE_ERROR;
  const codeNull = code === null;
  const valuePositionOne = selectPosition(valuePosition, "1");
  const valuePositionTwo = selectPosition(valuePosition, "2");
  const valuePositionThree = selectPosition(valuePosition, "3");
  const valuePositionOneAndTwo = selectPosition(valuePosition, "1,2");

  const secondaryMeasuresThree = [
    ...parsedMeasures.slice(0, 1),
    { value: code },
    ...parsedMeasures.slice(2, 3),
  ] as unknown as MeasuresData[];

  return comErrorId ? (
    <Box display="flex" alignItems="center" flexDirection="column" mt={-10}>
      <ErrorMessage>
        {icon}
        <Typography>{description}</Typography>
      </ErrorMessage>
      <Typography variant="h3">{code}</Typography>
    </Box>
  ) : offlineErrorId ? (
    <Box display="flex" alignItems="center" flexDirection="column" mt={-10}>
      <ErrorMessage>
        {icon}
        <Typography>{t("TEXT.OFFLINE_DEVICE")}</Typography>
      </ErrorMessage>
      <Typography fontSize={30}>OFFLINE</Typography>
    </Box>
  ) : codeNull ? (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Grid container spacing={1} sx={{ placeContent: "center" }}>
        <Grid item sx={{ placeSelf: "center" }}>
          <Box pt={1}>{icon}</Box>
        </Grid>
        <Grid item alignSelf="center" sx={{ width: 170 }}>
          {description}
        </Grid>
      </Grid>
      <MainMeasure measure={mainMeasure} measures={secondaryMeasures} />
      <SecondaryMeasures variant="h6" measures={secondaryMeasures} />
      <Box mt={1}>
        <Icons icons={icons} />
      </Box>
    </Box>
  ) : code !== "Lim" ? (
    <Box display="flex" alignItems="center" flexDirection="column" pt={2}>
      {valuePositionOne ? (
        <>
          <Tooltip title={description} arrow enterTouchDelay={0}>
            <Typography variant="h3">{code}</Typography>
          </Tooltip>
          <Box mt={2} mb={2}>
            <SecondaryMeasures variant="h6" measures={secondaryMeasures} />
          </Box>
        </>
      ) : valuePositionTwo ? (
        <>
          <MainMeasure measure={mainMeasure} measures={secondaryMeasures} />
          <Tooltip title={description} arrow sx={{ mb: 2 }} enterTouchDelay={0}>
            <Typography variant="h6">{code}</Typography>
          </Tooltip>
        </>
      ) : valuePositionThree ? (
        <Box width={270}>
          <MainMeasure measure={mainMeasure} measures={secondaryMeasures} />
          <Box mb={2}>
            <SecondaryMeasures variant="h6" measures={secondaryMeasuresThree} />
          </Box>
        </Box>
      ) : valuePositionOneAndTwo ? (
        <>
          <Tooltip title={description} arrow enterTouchDelay={0}>
            <Typography variant="h3">E1</Typography>
          </Tooltip>
          <Tooltip
            title={description}
            arrow
            sx={{ mt: 2, mb: 2 }}
            enterTouchDelay={0}
          >
            <Typography variant="h6">E2</Typography>
          </Tooltip>
        </>
      ) : code === "E1/E2" ? (
        <>
          <Tooltip title={description} arrow enterTouchDelay={0}>
            <Typography variant="h3">E1</Typography>
          </Tooltip>
          <Tooltip
            title={description}
            arrow
            sx={{ mt: 2, mb: 2 }}
            enterTouchDelay={0}
          >
            <Typography variant="h6">E2</Typography>
          </Tooltip>
        </>
      ) : (
        code === "E2" && (
          <Tooltip
            title={description}
            arrow
            sx={{ mt: 2, mb: 2 }}
            enterTouchDelay={0}
          >
            <Typography variant="h3">E2</Typography>
          </Tooltip>
        )
      )}
      <Box mt={2}>
        <Icons icons={icons} />
      </Box>
    </Box>
  ) : (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Grid container spacing={2} sx={{ placeContent: "center" }}>
        <Grid item sx={{ placeSelf: "center" }}>
          <Box pt={1}>{icon}</Box>
        </Grid>
        <Grid item alignSelf="center" sx={{ width: 160 }}>
          {description}
        </Grid>
      </Grid>
      <MainMeasure measure={mainMeasure} measures={secondaryMeasures} />
      <SecondaryMeasures variant="h6" measures={secondaryMeasures} />
      <Icons icons={icons} />
    </Box>
  );
};

export default Alarm;
