import { Grid } from "@mui/material";

const Header = () => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <img
          src="/arcsys-logo.png"
          alt="Arcsys logo"
          style={{ width: 170, paddingTop: 10, paddingBottom: 10 }}
        />
      </Grid>
    </Grid>
  );
};

export default Header;
