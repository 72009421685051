import React from "react";

import { Box, Button } from "@mui/material";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import Modal from "../../Devices/Modal";
import ActionsTable from "../../../components/Controller/SettingsTable/Action";
import { ControllerEditProvider } from "../../../contexts/ControllerEditContext";
import { ControllerProvider } from "../../../contexts/ControllerContext";
import useIsMobile from "../../../hooks/useIsMobile";

interface ModalActionsProps {
  handleOpen: boolean;
  handleClose: () => void;
  data: any;
  startIntervalAction: () => void;
}

const ModalActions = ({
  handleOpen,
  handleClose,
  data,
  startIntervalAction,
}: ModalActionsProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : 500}
    >
      <ControllerProvider>
        <ControllerEditProvider>
          <ActionsTable
            model={data.sector_model as string}
            permissionToEdit={true}
            devicePermission={true}
            ctrlId={data.reference.ctrlId}
            idSector={data._id}
            startIntervalAction={startIntervalAction}
            handleCloseModalAction={handleClose}
          />
        </ControllerEditProvider>
      </ControllerProvider>
      <Box mt={3} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: red[50],
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalActions;
