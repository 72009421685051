import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, MobileStepper, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from ".";
import { yupBoolean, yupInteger, yupString } from "../../Form";
import CustomizedSteppers from "../Stepper";
import useIsMobile from "../../../hooks/useIsMobile";
import StepZero from "./ModalSteps/StepZero";
import StepOne from "./ModalSteps/StepOne";
import { divider, firstLetterUpperCase, parseData, type } from "../parse";
import StepTwo from "./ModalSteps/StepTwo";
import { applyDivider, parseAcoes } from "../../Controller/parser/utils";
import StepThree from "./ModalSteps/StepThree";
import { getCtrlOption } from "../../../config";
import { editScenario } from "../../../services/data/scenarios";
import { ToastContext } from "../../../contexts/ToastContext";
import { useParams } from "react-router-dom";
import { Acoes, Estado, RowData } from "../../Controller/parser/types";
import SwipeableViews from "react-swipeable-views";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import StepFour from "./ModalSteps/StepFour";

interface ModalEditProps {
  handleOpen: boolean;
  handleClose: () => void;
  model: string;
  setScenarioEdited: (e: boolean) => void;
  row: any;
  sensores: any;
  acoes: Acoes[];
  setpoints: RowData[];
  parametros: RowData[];
  activeStep: number;
  setActiveStep: (value: React.SetStateAction<number>) => void;
  conditionWork: string;
  setConditionWork: (e: string) => void;
  estado: Estado[];
  planPermissions: any;
}

const ModalEdit = ({
  handleOpen,
  handleClose,
  model,
  setScenarioEdited,
  sensores,
  acoes,
  setpoints,
  parametros,
  activeStep,
  setActiveStep,
  row,
  conditionWork,
  setConditionWork,
  estado,
  planPermissions,
}: ModalEditProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const [conditionsLength, setconditionsLength] = React.useState<number>(1);
  const [conditionAction, setConditionAction] = React.useState<string>("0");
  const [active, setActive] = React.useState<string>("true");
  const [singleShot, setSingleShot] = React.useState<string>("0");
  const params = useParams();
  const ctrlId = `${params?.mac}/${params?.ctrl}`;
  const [paramDivider, setParamDivider] = React.useState<number>(0);
  const [setpointDivider, setSetpointDivider] = React.useState<number>(0);
  const [intervalTypeHour, setIntervalTypeHour] =
    React.useState<boolean>(false);
  const [timeUnit, setTimeUnit] = React.useState("minutes");
  const [sensorCondition, setSensorCondition] = React.useState<
    {
      sensor: string;
      operator: string;
      operatorIcon: string;
      condValue: string;
      timeCondition: string;
      timeConditionFinal: string;
    }[]
  >([
    {
      sensor: "",
      operator: "",
      operatorIcon: "",
      condValue: "",
      timeCondition: "",
      timeConditionFinal: "",
    },
  ]);

  // Ações de avançar ou voltar stepp das condições no modo mobile
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  let initialSensorConditions = Array.from(
    { length: planPermissions?.NUM_CONDITIONS_SCENARIOS },
    () => ({
      sensor: "",
      operator: "",
      operatorIcon: "",
      condValue: "",
      timeCondition: "",
      timeConditionFinal: "",
    })
  );
  const initialSelectedState = Array.from(
    { length: planPermissions?.NUM_CONDITIONS_SCENARIOS },
    () => "Sensor"
  );
  let initialSelectedIcon = Array.from(
    { length: planPermissions?.NUM_CONDITIONS_SCENARIOS },
    () => 0
  );
  const initialSensorDividerState = Array.from(
    { length: planPermissions?.NUM_CONDITIONS_SCENARIOS },
    () => 0
  );
  const isValidForm = Array(planPermissions?.NUM_CONDITIONS_SCENARIOS).fill(
    false
  );

  const [sensorDivider, setSensorDivider] = React.useState<number[]>(
    initialSensorDividerState
  );
  const [selected, setSelected] =
    React.useState<string[]>(initialSelectedState);
  const [checkedIcon, setCheckedIcon] =
    React.useState<number[]>(initialSelectedIcon);

  // Alterar para 'ativa/inativa' as Configurações Adicionais
  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive((event.target as HTMLInputElement).value);
  };

  // Alterar o disparo único ou contínuo das Configurações Adicionais
  const handleChangeSingleShot = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSingleShot((event.target as HTMLInputElement).value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      sensorConditions: initialSensorConditions,
      conditionWork: "",
      action: "",
      valueAction: "",
      param: "",
      valueParam: "",
      hysteresis: "",
      setpoint: "",
      valueSetpoint: "",
      valueNotification: "",
      disable_on_error: false,
      daysOfWeek: [1, 1, 1, 1, 1, 1, 1],
    },
    validationSchema: Yup.object({
      name: yupString,
      description: yupString,
      sensorConditions: Yup.array().of(
        Yup.object().shape({
          sensor: yupString,
          operator: yupString,
          operatorIcon: yupString,
          condValue: yupString,
          timeCondition: yupString,
          timeConditionFinal: yupString,
        })
      ),
      conditionWork: yupString,
      action: yupString,
      valueAction: yupString,
      param: yupString,
      valueParam: yupString,
      hysteresis:
        singleShot === "1"
          ? intervalTypeHour
            ? yupInteger(1, 24)
            : yupInteger(5, 1440)
          : yupString,
      setpoint: yupString,
      valueSetpoint: yupString,
      valueNotification: yupString,
      disable_on_error: yupBoolean,
    }),
    onSubmit: (values) => {
      const conditions = values.sensorConditions
        .slice(0, conditionsLength)
        .map((condition, index) => {
          const {
            sensor,
            operator,
            operatorIcon,
            condValue,
            timeCondition,
            timeConditionFinal,
          } = condition;
          const posSensor =
            selected[index] === firstLetterUpperCase(t("TEXT.STATE"))
              ? -1
              : selected[index] === firstLetterUpperCase(t("TEXT.TIME"))
              ? -2
              : sensor;
          const operador =
            selected[index] === firstLetterUpperCase(t("TEXT.STATE"))
              ? operatorIcon
              : operator;
          const valorReferencia =
            selected[index] === firstLetterUpperCase(t("TEXT.TIME"))
              ? +timeCondition
              : selected[index] === firstLetterUpperCase(t("TEXT.STATE"))
              ? checkedIcon[index]
              : sensorDivider[index] > 0
              ? +condValue * sensorDivider[index]
              : +condValue;
          const valorReferencia_2 = +timeConditionFinal;
          const finalValue =
            timeConditionFinal !== ""
              ? { posSensor, operador, valorReferencia, valorReferencia_2 }
              : { posSensor, operador, valorReferencia };
          return finalValue;
        });
      const data = {
        conditions,
        commands:
          conditionAction === "0"
            ? [
                {
                  resource: `acoes/${values.action}`,
                  value: values.valueAction === "" ? 1 : values.valueAction,
                  ctrlId: ctrlId,
                },
              ]
            : conditionAction === "1"
            ? [
                {
                  resource: `parametros/${values.param}`,
                  value:
                    paramDivider > 0
                      ? +values.valueParam * paramDivider
                      : +values.valueParam,
                  ctrlId: ctrlId,
                },
              ]
            : conditionAction === "2"
            ? [
                {
                  resource: `setpoints/s${values.setpoint}`,
                  value:
                    setpointDivider > 0
                      ? +values.valueSetpoint * setpointDivider
                      : +values.valueSetpoint,
                  ctrlId: ctrlId,
                },
              ]
            : [
                {
                  resource: `notification`,
                  value: values.valueNotification,
                  ctrlId: ctrlId,
                },
              ],
        name: values.name,
        ctrlId: ctrlId,
        model: model,
        operator: conditionWork,
        description: values.description,
        enabled: active === "true" ? true : false,
        trigger_type: singleShot === "0" ? "single" : "cyclic",
        histerese:
          singleShot === "2"
            ? 1440
            : intervalTypeHour === true
            ? (parseInt(values.hysteresis) * 60).toString()
            : values.hysteresis.toString(),
        disable_on_error: values.disable_on_error,
        days_of_week: values.daysOfWeek,
      };

      editScenario(row.rowId, data)
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.SCENARIO_CHANGED"));
          handleClose();
          setScenarioEdited(true);
          setActiveStep(0);
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.SCENARIO_CHANGED"));
        });
    },
  });

  const handleSetSelected = (index: number, e: string) => {
    const newSelected = [...selected];
    newSelected[index] = e;
    setSelected(newSelected);
  };

  const handleSetCheckedIcon = (index: number, e: number) => {
    const newCheckedIcon = [...checkedIcon];
    newCheckedIcon[index] = e;
    setCheckedIcon(newCheckedIcon);
  };

  // Checa se o parametro é inteiro
  const isParamIsInt =
    paramDivider === 0 || !Number.isInteger(+row?.paramDivider)
      ? row?.paramDivider
      : `${row?.paramDivider}.0`;

  // Checa se o Setpoint é inteiro
  const isSetpointIsInt =
    paramDivider === 0 || !Number.isInteger(+row?.setpointDivider)
      ? row?.setpointDivider
      : `${row?.setpointDivider}.0`;

  // Valor inicial do formulário
  React.useEffect(() => {
    formik.resetForm({
      values: {
        name: row?.name ?? "",
        description: row?.description ?? "",
        sensorConditions: sensorCondition,
        conditionWork: row.operator ?? "",
        action:
          row?.action_obj?.commands[0].resource.split("/")[0] === "acoes"
            ? row?.action_obj?.commands[0].resource.split("/")[1]
            : "",
        valueAction:
          row?.action_obj?.commands[0].resource.split("/")[0] === "acoes"
            ? row?.action_obj?.commands[0].value
            : "",
        param:
          row?.action_obj?.commands[0].resource.split("/")[0] === "parametros"
            ? row?.action_obj?.commands[0].resource.split("/")[1]
            : "",
        valueParam:
          row?.action_obj?.commands[0].resource.split("/")[0] === "parametros"
            ? isParamIsInt
            : "",
        hysteresis: singleShot !== "2" && row?.histerese ? row?.histerese : "",
        setpoint:
          row?.action_obj?.commands[0].resource.split("/")[0] === "setpoints"
            ? row?.action_obj?.commands[0].resource.split("/")[1].split("s")[1]
            : "",
        valueSetpoint:
          row?.action_obj?.commands[0].resource.split("/")[0] === "setpoints"
            ? isSetpointIsInt
            : "",
        valueNotification:
          row?.action_obj?.commands[0].value &&
          row?.action_obj?.commands[0].resource === "notification"
            ? row?.action_obj?.commands[0].value
            : "",
        disable_on_error: row.disable_on_error ?? false,
        daysOfWeek: row.days_of_week ?? [1, 1, 1, 1, 1, 1, 1],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, t, sensorCondition]);

  // Alterar a data inicial da condicional
  const handleDateChange = (index: number, date: Date | null) => {
    const time = date && new Date(date).getTime();
    formik.setFieldValue(
      `sensorConditions[${index}].timeCondition`,
      (time as number) / 1000
    );
  };

  // Alterar a data final da condicional
  const handleDateFinalChange = (index: number, date: Date | null) => {
    const time = date && new Date(date).getTime();
    formik.setFieldValue(
      `sensorConditions[${index}].timeConditionFinal`,
      (time as number) / 1000
    );
  };

  // Alterar o switch de "Desabilitar após o erro'"
  const [checked, setChecked] = React.useState(false);
  const handleChangeDisableError = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event.target.checked);
    formik.setFieldValue("disable_on_error", event.target.checked);
  };

  // Filtrar a Ação selecionada
  const filteredAction = acoes?.filter(
    (f: any) => +f.id === +formik.values.action
  )[0];

  // Filtrar a Setpoint selecionada
  const filteredSetpoint = setpoints?.filter(
    (f: any, i: number) => +i === +formik.values.setpoint
  )[0];

  // Filtrar o Parametro selecionada
  const filteredParam = parametros?.filter(
    (_f: any, i: number) => +i === +formik.values.param
  )[0];

  const filterSelectedSensor = (index: number) => {
    return parseData(sensores)?.filter(
      (_f: any, i: number) =>
        +i === +formik.values.sensorConditions[index]?.sensor
    )[0];
  };

  React.useEffect(() => {
    setParamDivider(filteredParam?.divider ?? 0);
    setSetpointDivider(filteredSetpoint?.divider ?? 0);
  }, [
    filteredParam?.divider,
    filteredSetpoint?.divider,
    setParamDivider,
    setSetpointDivider,
  ]);

  // Checar se o setpoint é do tipo 'INT'
  const optionsSetpointNotInt =
    getCtrlOption(`d${type(filteredSetpoint)}`) ??
    getCtrlOption(type(filteredSetpoint));

  // Checar se o parametro é do tipo 'INT'
  const optionsParamNotInt =
    getCtrlOption(`d${type(filteredParam)}`) ??
    getCtrlOption(type(filteredParam));

  // Configuração do Parâmetro
  const minParam = applyDivider(
    filteredParam?.min ?? 0,
    divider(filteredParam)
  );
  const maxParam = applyDivider(
    filteredParam?.max ?? 0,
    divider(filteredParam)
  );

  // Configuração do Setpoint
  const minSetpoint = applyDivider(
    filteredSetpoint?.min ?? 0,
    divider(filteredSetpoint)
  );
  const maxSetpoint = applyDivider(
    filteredSetpoint?.max ?? 0,
    divider(filteredSetpoint)
  );

  // Títulos dos Steps
  const steps = [
    t("TEXT.SCENARIOS_IDENTIFICATION"),
    t("TEXT.DEFINING_CONDITIONS"),
    t("TEXT.DEFINITION_ACTIONS"),
    t("TEXT.ADDITIONAL_SETTINGS"),
    t("TEXT.SCENARIOS_SUMMARY"),
  ];

  // Adicionar mais condições ao cenário
  const handleAddConditions = () => {
    setconditionsLength((conditions) => conditions + 1);
  };

  // Remover condição do cenário
  const handleRemoveConditions = () => {
    setconditionsLength((conditions) => conditions - 1);
  };

  // Alterar para o próximo Step
  const handleNext = (e: any) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Alterar para o Step anterior
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    formik.resetForm();
    setActiveStep(0);
    handleClose();
  };

  const validFormStepOne = () => {
    for (let i = 0; i < planPermissions?.NUM_CONDITIONS_SCENARIOS; i++) {
      isValidForm[i] = true;
    }
    for (let i = 0; i < conditionsLength; i++) {
      isValidForm[i] =
        selected[i] === firstLetterUpperCase(t("TEXT.TIME")) &&
        formik.values.sensorConditions[i]?.operator === "entre"
          ? formik.values.sensorConditions[i]?.timeCondition !== "" &&
            formik.values.sensorConditions[i]?.timeConditionFinal !== "" &&
            +formik.values.sensorConditions[i]?.timeConditionFinal >
              +formik.values.sensorConditions[i]?.timeCondition &&
            formik.values.sensorConditions[i]?.operator !== ""
          : selected[i] === firstLetterUpperCase(t("TEXT.TIME")) &&
            formik.values.sensorConditions[i]?.operator !== "entre"
          ? formik.values.sensorConditions[i]?.timeCondition !== "" &&
            formik.values.sensorConditions[i]?.operator !== ""
          : selected[i] === "Sensor"
          ? formik.values.sensorConditions[i]?.sensor !== "" &&
            formik.values.sensorConditions[i]?.operator !== "" &&
            formik.values.sensorConditions[i]?.condValue !== "" &&
            +formik.values.sensorConditions[i]?.condValue <= 65535 &&
            +formik.values.sensorConditions[i]?.condValue >= -65535
          : formik.values.sensorConditions[i]?.operatorIcon === "qualquer"
          ? checkedIcon[i] !== 0
          : formik.values.sensorConditions[i]?.operatorIcon !== "";
    }
    return isValidForm.every(Boolean);
  };

  // Validação do primeiro Step
  const isFormStemZeroIsValid =
    formik.values.name !== "" && formik.values.description !== "";

  // Validação do segundo Step
  const isFormStepOneIsValid = validFormStepOne();

  // Validação do terceiro Step
  const isFormStemTwoIsValid =
    conditionAction === "0" && filteredAction?.tipo === "number"
      ? formik.values.action !== "" &&
        formik.values.valueAction !== "" &&
        filteredAction?.max !== undefined &&
        filteredAction?.min !== undefined &&
        +formik.values.valueAction <= filteredAction?.max &&
        +formik.values.valueAction >= filteredAction?.min
      : conditionAction === "0" && filteredAction?.tipo !== "number"
      ? formik.values.action !== ""
      : conditionAction === "1" &&
        formik.values.param !== "" &&
        !optionsParamNotInt
      ? formik.values.valueParam !== "" &&
        +formik.values.valueParam <= maxParam &&
        +formik.values.valueParam >= minParam
      : conditionAction === "1" &&
        formik.values.param !== "" &&
        formik.values.param !== ""
      ? formik.values.param !== ""
      : conditionAction === "2" &&
        formik.values.setpoint !== "" &&
        !optionsSetpointNotInt
      ? formik.values.valueSetpoint !== "" &&
        +formik.values.valueSetpoint <= maxSetpoint &&
        +formik.values.valueSetpoint >= minSetpoint
      : conditionAction === "3"
      ? formik.values.valueNotification !== "" &&
        !formik.values.valueNotification
          .toString()
          // eslint-disable-next-line no-useless-escape
          ?.match(/['"]+/)
      : formik.values.setpoint !== "";

  // Validação do quarto Step
  const isFormStemThreeIsValid =
    !formik.values.hysteresis.toString().includes(".") &&
    formik.values.daysOfWeek.includes(1)
      ? singleShot === "1"
        ? intervalTypeHour
          ? +formik.values.hysteresis > 0 && +formik.values.hysteresis < 25
          : +formik.values.hysteresis > 4 && +formik.values.hysteresis < 1441
        : true
      : false;

  return (
    <Modal
      open={handleOpen}
      handleClose={closeModal}
      width={mobile ? 340 : 700}
    >
      <form onSubmit={formik.handleSubmit}>
        {!mobile ? (
          <Typography fontSize={21} fontWeight={700} mb={2}>
            {t("TEXT.EDIT_SCENARIO")}
          </Typography>
        ) : (
          <Box mb={2} sx={{ borderBottom: "1px solid lightGray" }}>
            <MobileStepper
              steps={steps.length}
              position="static"
              activeStep={activeStep}
              sx={{ mt: -4 }}
              nextButton={
                activeStep === 5 ? (
                  <Button
                    type="submit"
                    disabled={!isFormStemThreeIsValid}
                    sx={{ mr: 1 }}
                  >
                    {t("BUTTON.SAVE")}
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => handleNext(e)}
                    disabled={
                      activeStep === 0
                        ? !isFormStemZeroIsValid
                        : activeStep === 1
                        ? !isFormStepOneIsValid
                        : activeStep === 2
                        ? !isFormStemTwoIsValid
                        : activeStep === 3
                        ? !isFormStemThreeIsValid
                        : false
                    }
                    sx={{ mr: 1 }}
                  >
                    <Box display="flex">
                      <Box fontWeight={700}>{t("TEXT.NEXT")}</Box>
                      <KeyboardArrowRight />
                    </Box>
                  </Button>
                )
              }
              backButton={
                activeStep === 0 ? (
                  <Button onClick={handleClose} sx={{ mr: 1, fontWeight: 700 }}>
                    {t("BUTTON.CANCEL")}
                  </Button>
                ) : (
                  <Button onClick={handleBack} sx={{ mr: 1 }}>
                    <Box display="flex">
                      <KeyboardArrowLeft />
                      <Box fontWeight={700}>{t("BUTTON.RETURN")}</Box>
                    </Box>
                  </Button>
                )
              }
            />
          </Box>
        )}
        {mobile ? (
          <Box height={480}>
            <Typography textAlign="center" fontWeight={700} m={1}>
              {steps[activeStep]}
            </Typography>
            <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
              <StepZero
                formik={formik}
                setConditiosLength={setconditionsLength}
                setConditionAction={setConditionAction}
                row={row}
                setActive={setActive}
                setSingleShot={setSingleShot}
                setSelected={setSelected}
                setSensorDivider={setSensorDivider}
                filteredSensor={filterSelectedSensor}
                setChecked={setChecked}
                setConditionWork={setConditionWork}
                setSensorCondition={setSensorCondition}
                setCheckedIcon={setCheckedIcon}
                initialSelectedIcon={initialSelectedIcon}
                initialSensorConditions={initialSensorConditions}
                planPermissions={planPermissions}
                maxConditiosLength={planPermissions?.NUM_CONDITIONS_SCENARIOS}
                setTimeUnit={setTimeUnit}
              />
              <StepOne
                formik={formik}
                conditiosLength={conditionsLength}
                maxConditiosLength={planPermissions?.NUM_CONDITIONS_SCENARIOS}
                handleAddConditions={handleAddConditions}
                handleRemoveConditions={handleRemoveConditions}
                sensores={parseData(sensores)}
                conditionWork={conditionWork}
                setConditionWork={setConditionWork}
                isFormStepOneIsValid={isFormStepOneIsValid}
                setSelected={handleSetSelected}
                selected={selected}
                handleDateChange={handleDateChange}
                handleDateFinalChange={handleDateFinalChange}
                estado={estado}
                checked={checkedIcon}
                setChecked={handleSetCheckedIcon}
                row={row}
              />
              <StepTwo
                formik={formik}
                acoes={parseAcoes(acoes)}
                parametros={parametros}
                filteredAction={filteredAction}
                filteredParam={filteredParam}
                optionsParamNotInt={optionsParamNotInt}
                conditionAction={conditionAction}
                setConditionAction={setConditionAction}
                setpoints={setpoints}
                filteredSetpoint={filteredSetpoint}
              />
              <StepThree
                formik={formik}
                active={active}
                singleShot={singleShot}
                handleChangeActive={handleChangeActive}
                handleChangeSingleShot={handleChangeSingleShot}
                handleChangeDisableError={handleChangeDisableError}
                checked={checked}
                setIntervalTypeHour={setIntervalTypeHour}
                timeUnit={timeUnit}
                setTimeUnit={setTimeUnit}
              />
              <StepFour
                formik={formik}
                selected={selected}
                conditionsLength={conditionsLength}
                checkedIcon={checkedIcon}
                sensorDivider={sensorDivider}
                estado={estado}
                sensores={sensores}
                conditionAction={conditionAction}
                acoes={parseAcoes(acoes)}
                parametros={parametros}
                setpoints={setpoints}
                conditionWork={conditionWork}
                timeUnit={timeUnit}
                setIntervalTypeHour={setIntervalTypeHour}
                singleShot={singleShot}
              />
            </SwipeableViews>
          </Box>
        ) : (
          <>
            <CustomizedSteppers activeStep={activeStep} steps={steps} />
            {activeStep === 0 ? (
              <StepZero
                formik={formik}
                setConditiosLength={setconditionsLength}
                setConditionAction={setConditionAction}
                row={row}
                setActive={setActive}
                setSingleShot={setSingleShot}
                setSelected={setSelected}
                setSensorDivider={setSensorDivider}
                filteredSensor={filterSelectedSensor}
                setChecked={setChecked}
                setConditionWork={setConditionWork}
                setSensorCondition={setSensorCondition}
                setCheckedIcon={setCheckedIcon}
                initialSelectedIcon={initialSelectedIcon}
                initialSensorConditions={initialSensorConditions}
                planPermissions={planPermissions}
                maxConditiosLength={planPermissions?.NUM_CONDITIONS_SCENARIOS}
                setTimeUnit={setTimeUnit}
              />
            ) : activeStep === 1 ? (
              <StepOne
                formik={formik}
                conditiosLength={conditionsLength}
                maxConditiosLength={planPermissions?.NUM_CONDITIONS_SCENARIOS}
                handleAddConditions={handleAddConditions}
                handleRemoveConditions={handleRemoveConditions}
                sensores={parseData(sensores)}
                conditionWork={conditionWork}
                setConditionWork={setConditionWork}
                isFormStepOneIsValid={isFormStepOneIsValid}
                setSelected={handleSetSelected}
                selected={selected}
                handleDateChange={handleDateChange}
                handleDateFinalChange={handleDateFinalChange}
                estado={estado}
                checked={checkedIcon}
                setChecked={handleSetCheckedIcon}
                row={row}
              />
            ) : activeStep === 2 ? (
              <StepTwo
                formik={formik}
                acoes={parseAcoes(acoes)}
                parametros={parametros}
                filteredAction={filteredAction}
                filteredParam={filteredParam}
                optionsParamNotInt={optionsParamNotInt}
                conditionAction={conditionAction}
                setConditionAction={setConditionAction}
                setpoints={setpoints}
                filteredSetpoint={filteredSetpoint}
              />
            ) : activeStep === 3 ? (
              <StepThree
                formik={formik}
                active={active}
                singleShot={singleShot}
                handleChangeActive={handleChangeActive}
                handleChangeSingleShot={handleChangeSingleShot}
                handleChangeDisableError={handleChangeDisableError}
                checked={checked}
                setIntervalTypeHour={setIntervalTypeHour}
                timeUnit={timeUnit}
                setTimeUnit={setTimeUnit}
              />
            ) : (
              <StepFour
                formik={formik}
                selected={selected}
                conditionsLength={conditionsLength}
                checkedIcon={checkedIcon}
                sensorDivider={sensorDivider}
                estado={estado}
                sensores={sensores}
                conditionAction={conditionAction}
                acoes={parseAcoes(acoes)}
                parametros={parametros}
                setpoints={setpoints}
                conditionWork={conditionWork}
                timeUnit={timeUnit}
                setIntervalTypeHour={setIntervalTypeHour}
                singleShot={singleShot}
              />
            )}
          </>
        )}
        {!mobile && (
          <Box sx={{ mt: 4 }} textAlign="center">
            {activeStep === 0 ? (
              <Button onClick={handleClose} sx={{ mr: 1 }} variant="outlined">
                {t("BUTTON.CANCEL")}
              </Button>
            ) : (
              <Button onClick={handleBack} sx={{ mr: 1 }} variant="outlined">
                {t("BUTTON.RETURN")}
              </Button>
            )}
            {activeStep === 4 ? (
              <Button type="submit" variant="contained" sx={{ mr: 1 }}>
                {t("BUTTON.SAVE")}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={(e) => handleNext(e)}
                disabled={
                  activeStep === 0
                    ? !isFormStemZeroIsValid
                    : activeStep === 1
                    ? !isFormStepOneIsValid
                    : activeStep === 2
                    ? !isFormStemTwoIsValid
                    : activeStep === 3
                    ? !isFormStemThreeIsValid
                    : false
                }
                sx={{ mr: 1 }}
              >
                {t("TEXT.NEXT")}
              </Button>
            )}
          </Box>
        )}
      </form>
    </Modal>
  );
};

export default ModalEdit;
