import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { ToastContext } from "../../../contexts/ToastContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { TextField, yupNumberMin, yupString } from "../../../components/Form";
import { TOKEN_KEY } from "../../../auth/constants";
import { api } from "../../../services/api";
import Modal from "../../Devices/Modal";
import ErrorMessage from "../../../components/ErrorMessage";
import { editUserById } from "../../../services/data/user";
import CepMask from "../../../components/Masks/CepMask";
import { getCep } from "../../../services/data/searchCep";
import CpfMask from "../../../components/Masks/CpfMast";
import CnpjMask from "../../../components/Masks/CnpjMask";
import useIsMobile from "../../../hooks/useIsMobile";
import CustomizedSteppers from "./Stepper";
import { useParams } from "react-router-dom";

interface ModalEditExtraInfoProps {
  user: any;
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
  state: string;
  neighborhood: string;
  street: string;
  city: string;
  setCity: (e: any) => void;
  setStreet: (e: any) => void;
  setNeighborhood: (e: any) => void;
  setState: (e: any) => void;
}

const ModalEditExtraInfo = ({
  user,
  setDeviceEdited,
  handleOpen,
  handleClose,
  setCity,
  setStreet,
  setNeighborhood,
  setState,
  state,
  neighborhood,
  street,
  city,
}: ModalEditExtraInfoProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated, userId } = React.useContext(AuthContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const params = useParams();
  const id = params?.addr && +params?.addr;

  const checkIfLocationIsBrasil =
    user.user.location === "Brasil" || user.user.location === "Brazil";

  const formikPhysicalPerson = useFormik({
    initialValues: {
      cpf: user.user.info?.cpf ?? "",
      cep: user.user.info?.address.cep ?? "",
      number: user.user.info?.address.number ?? "",
      complement: user.user.info?.address.complement ?? "",
    },
    validationSchema: Yup.object({
      cpf: yupString,
      cep: yupString,
      number: yupNumberMin(0),
      complement: yupString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        if (id) {
          // Editar as Informações Extras do usuário logado
          editUserById(id, {
            info: {
              personType: "PERSON",
              address: {
                city: city,
                complement: values.complement,
                neighborhood: neighborhood,
                number: values.number,
                cep: values.cep,
                state: state,
                street: street,
                fetching: true,
              },
              cpf: values.cpf,
              cnpj: "",
              legalName: "",
              stateInsc: "",
              phone: user.user.phone,
            },
          })
            .then(() => {
              toastSuccess(t("TOAST.SUCCESS.EDITED_USER"));
              setDeviceEdited(true);
              setActiveStep(0);
              handleClose();
            })
            .catch(() => {
              toastError(t("TOAST.ERROR.EDITED_USER"));
            });
        }
      }
    },
  });

  // Checkbox de pessia física / jurídica
  const [selectedType, setSelectedType] = React.useState(
    formikPhysicalPerson.values.cpf === "" ? "legal" : "physycal"
  );

  // Resgatar o endereço via CEP do formulário da pessoa física
  const getPhysicalAdress = async () => {
    await getCep(formikPhysicalPerson.values.cep)
      .then((res) => {
        const { data } = res;
        if (data.erro === "true") {
          toastError(t("TOAST.ERROR.ZIP_CODE"));
        } else {
          toastSuccess(t("TOAST.SUCCESS.ZIP_CODE"));
          setCity(data.localidade);
          setStreet(data.logradouro);
          setNeighborhood(data.bairro);
          setState(data.uf);
        }
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.ZIP_CODE"));
      });
  };

  const formikLegalPerson = useFormik({
    initialValues: {
      cnpj: (user.user.info?.cnpj as string) ?? "",
      legalName: (user.user?.info?.legalName as string) ?? "",
      stateInsc: (user.user?.info?.stateInsc as string) ?? "",
      cep: user.user.info?.address?.cep ?? "",
      number: user.user.info?.address?.number ?? "",
      complement: user.user.info?.address?.complement ?? "",
    },
    validationSchema: Yup.object({
      cnpj: yupString,
      legalName: yupString,
      stateInsc: yupString,
      cep: yupString,
      number: yupString,
      complement: yupString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Editar as Informações Extras do usuário logado
        editUserById(+userId, {
          info: {
            personType: "PERSON",
            address: {
              city: city,
              complement: values.complement,
              neighborhood: neighborhood,
              number: values.number,
              cep: values.cep,
              state: state,
              street: street,
              fetching: true,
            },
            cpf: "",
            cnpj: values.cnpj,
            legalName: values.legalName,
            stateInsc: values.stateInsc,
            phone: user.user.phone,
          },
        })
          .then(() => {
            toastSuccess(t("TOAST.SUCCESS.EDITED_USER"));
            setDeviceEdited(true);
            handleClose();
          })
          .catch(() => {
            toastError(t("TOAST.ERROR.EDITED_USER"));
          });
      }
    },
  });

  // Resgatar o endereço via CEP do formulário da pessoa jurídica
  const getLegalAdress = async () => {
    await getCep(formikLegalPerson.values.cep)
      .then((res) => {
        const { data } = res;
        if (data.erro === true) {
          toastError(t("TOAST.ERROR.ZIP_CODE"));
        } else {
          toastSuccess(t("TOAST.SUCCESS.ZIP_CODE"));
          setCity(data.localidade);
          setStreet(data.logradouro);
          setNeighborhood(data.bairro);
          setState(data.uf);
        }
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.ZIP_CODE"));
      });
  };

  // Armazenar a cidade digitada no formulário
  const handleChangeCity = (e: string) => {
    setCity(e);
  };

  // Armazenar a rua digitada no formulário
  const handleChangeStreet = (e: string) => {
    setStreet(e);
  };

  // Armazenar o bairro digitado no formulário
  const handleChangeNeighborhood = (e: string) => {
    setNeighborhood(e);
  };

  // Armazenar o estado digitado no formulário
  const handleChangeState = (e: string) => {
    setState(e);
  };

  // Selecionar Pessoa Física ou Jurídica
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };

  // Validações
  const docIsValid =
    selectedType === "physycal"
      ? formikPhysicalPerson.values.cpf !== "" &&
        !formikPhysicalPerson.values.cpf.includes("_")
      : formikLegalPerson.values.cnpj !== "" &&
        !formikLegalPerson.values.cnpj.includes("_") &&
        formikLegalPerson.values.legalName !== "" &&
        formikLegalPerson.values.stateInsc !== "";

  // Validação do furmulário da pessoa física
  const isFormPhysicalPersonValid =
    (formikPhysicalPerson.dirty ||
      city !== "" ||
      street !== "" ||
      neighborhood !== "" ||
      state !== "") &&
    formikPhysicalPerson.values.cep !== "" &&
    !formikPhysicalPerson.values.cep.includes("_") &&
    formikPhysicalPerson.isValid;

  // Validação do furmulário da pessoa jurídica
  const isFormLegalPersonValid =
    (formikLegalPerson.dirty ||
      city !== "" ||
      street !== "" ||
      neighborhood !== "" ||
      state !== "") &&
    formikLegalPerson.values.cep !== "" &&
    !formikLegalPerson.values.cep.includes("_") &&
    formikLegalPerson.isValid;

  // Alterar para o próximo Step
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Alterar para o Step anterior
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 500}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {`${t("TEXT.EDIT")} ${t("TEXT.EXTRA_INFO")}`}
      </Typography>
      <Box
        sx={{
          maxHeight: mobile || window.screen.width < 1550 ? 380 : "100%",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        <CustomizedSteppers activeStep={activeStep} />
        {activeStep === 0 ? (
          <Box mt={2} textAlign={mobile ? "left" : "center"}>
            <FormControl sx={{ pl: 1 }}>
              <FormLabel sx={{ mb: 1 }}>{t("TEXT.TYPE")}</FormLabel>
              <RadioGroup row value={selectedType} onChange={handleChange}>
                <FormControlLabel
                  value="physycal"
                  control={<Radio />}
                  label={t("TEXT.PHYSICAL_PERSON")}
                />
                <FormControlLabel
                  value="legal"
                  control={<Radio />}
                  label={t("TEXT.LEGAL_PERSON")}
                />
              </RadioGroup>
            </FormControl>
            <Box sx={{ mb: 2, mt: 3 }} textAlign="center">
              <Button onClick={handleClose} sx={{ mr: 1 }} variant="outlined">
                {t("BUTTON.CANCEL")}
              </Button>
              <Button variant="contained" onClick={handleNext} sx={{ mr: 1 }}>
                {t("TEXT.NEXT")}
              </Button>
            </Box>
          </Box>
        ) : activeStep === 1 ? (
          <Box mb={2}>
            {selectedType === "physycal" ? (
              <form onSubmit={formikPhysicalPerson.handleSubmit}>
                {checkIfLocationIsBrasil && (
                  <Box display="grid" mt={2}>
                    <TextField
                      formik={formikPhysicalPerson}
                      disabled={false}
                      label="CPF"
                      name="cpf"
                      fullWidth
                      InputProps={{
                        inputComponent: CpfMask,
                      }}
                    />
                  </Box>
                )}
              </form>
            ) : (
              <form onSubmit={formikLegalPerson.handleSubmit}>
                {checkIfLocationIsBrasil && (
                  <Box display="grid" mt={2}>
                    <TextField
                      formik={formikLegalPerson}
                      disabled={false}
                      label="CNPJ"
                      name="cnpj"
                      fullWidth
                      InputProps={{
                        inputComponent: CnpjMask,
                      }}
                    />
                  </Box>
                )}
                <Box display="grid" mt={2}>
                  <TextField
                    formik={formikLegalPerson}
                    disabled={false}
                    label={t("TEXT.LEGAL_NAME")}
                    name="legalName"
                    fullWidth
                  />
                  {formikLegalPerson.errors.legalName &&
                  formikLegalPerson.touched.legalName ? (
                    <ErrorMessage>
                      {formikLegalPerson.errors.legalName}
                    </ErrorMessage>
                  ) : null}
                </Box>
                <Box display="grid" mt={2}>
                  <TextField
                    formik={formikLegalPerson}
                    disabled={false}
                    label={t("TEXT.STATE_INSC")}
                    name="stateInsc"
                    fullWidth
                  />
                  {formikLegalPerson.errors.stateInsc &&
                  formikLegalPerson.touched.stateInsc ? (
                    <ErrorMessage>
                      {formikLegalPerson.errors.stateInsc}
                    </ErrorMessage>
                  ) : null}
                </Box>
              </form>
            )}
            <Box sx={{ mb: 2, mt: 3 }} textAlign="center">
              <Button onClick={handleBack} sx={{ mr: 1 }}>
                {t("TEXT.PREVIOUS")}
              </Button>
              <Button
                variant="contained"
                disabled={!docIsValid}
                onClick={handleNext}
                sx={{ mr: 1 }}
              >
                {t("TEXT.NEXT")}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box pr={1}>
            {selectedType === "physycal" ? (
              <form onSubmit={formikPhysicalPerson.handleSubmit}>
                {checkIfLocationIsBrasil && (
                  <Box display="flex" mt={2}>
                    <Box display="grid" width="100%">
                      <TextField
                        formik={formikPhysicalPerson}
                        label={t("TEXT.ZIP_CODE")}
                        disabled={false}
                        name="cep"
                        fullWidth
                        InputProps={{
                          inputComponent: CepMask,
                        }}
                      />
                    </Box>
                    <Box
                      display="grid"
                      mt={0.5}
                      mb={0.5}
                      width={mobile ? "65%" : "35%"}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        sx={{
                          ml: 2,
                          fontSize: 12,
                          mt: 0.7,
                          mb: 0.7,
                        }}
                        onClick={getPhysicalAdress}
                      >
                        {t("BUTTON.SEARCH")}
                      </Button>
                    </Box>
                  </Box>
                )}
                <Box display="grid" mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("TEXT.CITY")}</InputLabel>
                    <OutlinedInput
                      value={city}
                      onChange={(e) => handleChangeCity(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label={t("TEXT.CITY")}
                    />
                  </FormControl>
                </Box>
                <Box display="grid" mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("TEXT.STATE_USER_REGISTRATION")}</InputLabel>
                    <OutlinedInput
                      value={state}
                      onChange={(e) => handleChangeState(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label={t("TEXT.STATE_USER_REGISTRATION")}
                    />
                  </FormControl>
                </Box>
                <Box display="grid" mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("TEXT.NEIGHBORHOOD")}</InputLabel>
                    <OutlinedInput
                      value={neighborhood}
                      onChange={(e) => handleChangeNeighborhood(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label={t("TEXT.NEIGHBORHOOD")}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("TEXT.STREET")}</InputLabel>
                    <OutlinedInput
                      value={street}
                      onChange={(e) => handleChangeStreet(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label={t("TEXT.STREET")}
                    />
                  </FormControl>
                  <Box display="grid" ml={2} flex="40%">
                    <TextField
                      formik={formikPhysicalPerson}
                      disabled={false}
                      label="Nº"
                      name="number"
                      type="number"
                    />
                  </Box>
                </Box>
                <Box display="grid" mt={2}>
                  <TextField
                    formik={formikPhysicalPerson}
                    disabled={false}
                    label={t("TEXT.COMPLEMENT")}
                    name="complement"
                    fullWidth
                  />
                </Box>
                <Box sx={{ mt: 2 }} textAlign="center">
                  <Button onClick={handleBack} sx={{ mr: 1 }}>
                    {t("TEXT.PREVIOUS")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isFormPhysicalPersonValid}
                    sx={{ mr: 1 }}
                  >
                    {t("BUTTON.SAVE")}
                  </Button>
                </Box>
              </form>
            ) : (
              <form onSubmit={formikLegalPerson.handleSubmit}>
                {checkIfLocationIsBrasil && (
                  <Box display="flex" mt={2}>
                    <Box display="grid" width="100%">
                      <TextField
                        formik={formikLegalPerson}
                        label={t("TEXT.ZIP_CODE")}
                        disabled={false}
                        name="cep"
                        fullWidth
                        InputProps={{
                          inputComponent: CepMask,
                        }}
                      />
                    </Box>
                    <Box
                      display="grid"
                      mt={0.5}
                      mb={0.5}
                      width={mobile ? "65%" : "35%"}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        sx={{
                          ml: 2,
                          fontSize: 12,
                          mt: 0.7,
                          mb: 0.7,
                        }}
                        onClick={getLegalAdress}
                      >
                        {t("BUTTON.SEARCH")}
                      </Button>
                    </Box>
                  </Box>
                )}
                <Box display="grid" mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("TEXT.CITY")}</InputLabel>
                    <OutlinedInput
                      value={city}
                      onChange={(e) => handleChangeCity(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label={t("TEXT.CITY")}
                    />
                  </FormControl>
                </Box>
                <Box display="grid" mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("TEXT.STATE_USER_REGISTRATION")}</InputLabel>
                    <OutlinedInput
                      value={state}
                      onChange={(e) => handleChangeState(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label={t("TEXT.STATE_USER_REGISTRATION")}
                    />
                  </FormControl>
                </Box>
                <Box display="grid" mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("TEXT.NEIGHBORHOOD")}</InputLabel>
                    <OutlinedInput
                      value={neighborhood}
                      onChange={(e) => handleChangeNeighborhood(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label={t("TEXT.NEIGHBORHOOD")}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("TEXT.STREET")}</InputLabel>
                    <OutlinedInput
                      value={street}
                      onChange={(e) => handleChangeStreet(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label={t("TEXT.STREET")}
                    />
                  </FormControl>
                  <Box display="grid" ml={2} flex="40%">
                    <TextField
                      formik={formikLegalPerson}
                      disabled={false}
                      label="Nº"
                      name="number"
                      type="number"
                    />
                  </Box>
                </Box>
                <Box display="grid" mt={2}>
                  <TextField
                    formik={formikLegalPerson}
                    disabled={false}
                    label={t("TEXT.COMPLEMENT")}
                    name="complement"
                    fullWidth
                  />
                </Box>
                <Box sx={{ mt: 2 }} textAlign="center">
                  <Button onClick={handleBack} sx={{ mr: 1 }}>
                    {t("TEXT.PREVIOUS")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isFormLegalPersonValid}
                    sx={{ mr: 1 }}
                  >
                    {t("BUTTON.SAVE")}
                  </Button>
                </Box>
              </form>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ModalEditExtraInfo;
