import { api } from "../api";
import {
  AGEON_REF_ROUTE,
  CHANGE_HISTORY_ROUTE,
  MODELOS_ROUTE,
  getModelsByIdRoute,
} from "../routes/modelo";

// Resgatar os modelos JSON
export const getModelos = () => api.get<any>(MODELOS_ROUTE);

// Rota para editar modelos JSON no modo admin
export const editModelosJson = async (data: any) =>
  api.post<any>(AGEON_REF_ROUTE, data);

// Resgatar a lista do histórico de alterações dos modelos JSON
export const getChangeHistory = () => api.get<any>(CHANGE_HISTORY_ROUTE);

// Resgatar modelo por 'id'
export const getModelsById = (id: string) =>
  api.get<any>(getModelsByIdRoute(id));

// Rota para remover item do histórico
export const deleteHistoricById = (id: string) =>
  api.delete<any>(getModelsByIdRoute(id));
