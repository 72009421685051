export type ThemeCode = "light" | "dark";

const DEFAULT_THEME = "light";

const themes = ["light", "dark"];

// Função que retorna o tema ('light' || 'dark')
export const getTheme = () => {
  const theme = (localStorage.getItem("theme") ?? DEFAULT_THEME) as ThemeCode;
  return themes.some((o) => o === theme) ? theme : DEFAULT_THEME;
};
