import { Alert, Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Modal from "../../../../pages/Devices/Modal";
import { red } from "@mui/material/colors";
import useIsMobile from "../../../../hooks/useIsMobile";

interface ModalAnyConditionProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalAnyCondition = ({
  handleOpen,
  handleClose,
}: ModalAnyConditionProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 380}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <HelpOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.EXECUTE_ANY_CONDITION")}
      </Typography>
      <Alert severity="info" sx={{ mt: 2, mb: 2, alignItems: "center" }}>
        <Typography>{t("TEXT.EXECUTE_ANY_CONDITION_MESSAGE")}</Typography>
      </Alert>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: red[50],
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalAnyCondition;
