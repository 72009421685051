import {
  Avatar,
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { red } from "@mui/material/colors";
import Modal from "../../Devices/Modal";
import useIsMobile from "../../../hooks/useIsMobile";

interface ModalQuestionRecurrenceProps {
  handleOpen: boolean;
  handleClose: () => void;
  plan: string;
}

const ModalQuestionRecurrence = ({
  handleOpen,
  handleClose,
  plan,
}: ModalQuestionRecurrenceProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 600}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <HelpOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.RECURRING_SUBSCRIPTION")}
      </Typography>
      <Typography mt={1} mb={1.5}>
        {t("TEXT.RECURRING_SUBSCRIPTION_MODAL_SUBTITLE")}:
      </Typography>
      <Box
        pt={2}
        pb={2}
        pl={mobile ? 1 : 0}
        sx={{
          maxHeight: mobile ? 200 : window.screen.width < 1550 ? 250 : 530,
          overflow: "scroll",
          overflowX: "hidden",
          backgroundColor: "#E5F6FD",
          borderRadius: 3,
        }}
      >
        <Stepper orientation="vertical" sx={{ ml: mobile ? 0 : 3 }}>
          <Step active>
            <StepLabel>
              <Typography fontSize={16} fontWeight={500}>
                {t("TEXT.UNINTERRUPTED_SERVICE")}:
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography>
                {t("TEXT.FUNCTIONALITIES")}
                <strong>{` ${plan}`}</strong>{" "}
                {t("TEXT.UNINTERRUPTED_SERVICE_MESSAGE")}
              </Typography>
            </StepContent>
          </Step>
          <Step active>
            <StepLabel>
              <Typography fontSize={16} fontWeight={500}>
                {t("TEXT.SAVE_TIME")}:
              </Typography>
            </StepLabel>
            <StepContent sx={{ whiteSpace: "break-spaces" }}>
              {t("TEXT.SAVE_TIME_MESSAGE")}
            </StepContent>
          </Step>
          <Step active>
            <StepLabel>
              <Typography fontSize={16} fontWeight={500}>
                {t("TEXT.CONTROL")}:
              </Typography>
            </StepLabel>
            <StepContent sx={{ whiteSpace: "break-spaces" }}>
              {t("TEXT.CONTROL_MESSAGE")}
            </StepContent>
          </Step>
          <Step active>
            <StepLabel>
              <Typography fontSize={16} fontWeight={500}>
                {t("TEXT.STICKY_NOTES")}:
              </Typography>
            </StepLabel>
            <StepContent sx={{ whiteSpace: "break-spaces" }}>
              {t("TEXT.STICKY_NOTES_MESSAGE")}
            </StepContent>
          </Step>
        </Stepper>
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: red[50],
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalQuestionRecurrence;
