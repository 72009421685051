import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import Modal from "../Devices/Modal";
import useIsMobile from "../../hooks/useIsMobile";
import React from "react";

interface ModalWelcomeProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalWelcome = ({ handleOpen, handleClose }: ModalWelcomeProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : 500}
    >
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.WECOME_MESSAGE_ONE")}
      </Typography>
      <Typography mt={2} mb={2}>
        {t("TEXT.WECOME_MESSAGE_TWO")}
      </Typography>
      <Typography mb={2}>{t("TEXT.WECOME_MESSAGE_THREE")}</Typography>
      <Typography mb={2}>{t("TEXT.WECOME_MESSAGE_FOUR")}</Typography>
      <Typography mb={2}>{t("TEXT.WECOME_MESSAGE_FIVE")}</Typography>
    </Modal>
  );
};

export default ModalWelcome;
