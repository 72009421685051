import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  HelpOutline as HelpOutlineIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { red } from "@mui/material/colors";
import Modal from "../Devices/Modal";

interface ModalAddProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalMac = ({ handleOpen, handleClose }: ModalAddProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <HelpOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={2}>
        {t("TEXT.MAC_NAME")}
      </Typography>
      <Typography fontSize={15} fontWeight={500}>
        {t("TEXT.MAC_ADDRESS_INFO_TITLE")}:
      </Typography>
      <List>
        <ListItem disablePadding>
          <ListItemIcon>
            <CheckIcon color="success" />
          </ListItemIcon>
          <ListItemText
            primary={t("TEXT.MAC_ADDRESS_INFO_ONE")}
            primaryTypographyProps={{
              variant: "body2",
              fontWeight: "medium",
            }}
          />
        </ListItem>
        <ListItem disablePadding sx={{ pt: 1, pb: 1 }}>
          <ListItemIcon>
            <CheckIcon color="success" />
          </ListItemIcon>
          <ListItemText
            primary={t("TEXT.MAC_ADDRESS_INFO_TWO")}
            primaryTypographyProps={{
              variant: "body2",
              fontWeight: "medium",
            }}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <CheckIcon color="success" />
          </ListItemIcon>
          <ListItemText
            primary={`${t("TEXT.EXAMPLE")}: AB-CD-EF-12-34-56`}
            primaryTypographyProps={{
              variant: "body2",
              fontWeight: "medium",
            }}
          />
        </ListItem>
      </List>
      <Box display="grid">
        <Button
          onClick={handleClose}
          fullWidth
          sx={{
            backgroundColor: red[50],
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalMac;
