export const TAGS_ROUTE = "/tags";

// Rota para resgatar as TAGs criadas por 'id' do usuário
export const getTagNamesByIdRoute = (id: number) =>
  `${TAGS_ROUTE}/namesbyuser/${id}`;

// Rota para selecionar TAG no controlador
export const ADD_TAG_TO_CTRL_ROUTE = `${TAGS_ROUTE}/addtag`;

// Rota para remover TAG no controlador
export const DELETE_TAG_TO_CTRL_ROUTE = `${TAGS_ROUTE}/removefromtag`;

// Rota para remover TAG no perfil do usuário
export const removeTagRoute = (id: string) => `${TAGS_ROUTE}/${id}`;

// Rota para resgatar a TAG selecionada na página do controlador
export const getCtrlTagByIdRoute = (ctrl: string, id: string) =>
  `${TAGS_ROUTE}/tagbyctrl/${ctrl}/${id}`;

// Rota para resgatar a TAG selecionada na página do Dashboard
export const getDashboardTagsRoute = (id: number) => `${TAGS_ROUTE}/user/${id}`;

// Rota para editar TAG
export const editTagRoute = (id: string) => `${TAGS_ROUTE}/${id}`;
