import React from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tab,
  TablePaginationProps,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddToPhotos as AddToPhotosIcon,
  Clear as ClearIcon,
  Dvr as DvrIcon,
  Tv as TvIcon,
  Search as SearchIcon,
  Replay as ReplayIcon,
  ConnectedTv as ConnectedTvIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import { getMacListAdmin } from "../../services/data/mac";
import MuiPagination from "@mui/material/Pagination";
import { GridColDef, GridPagination } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import Spinner from "../../components/Spinner";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import CustomNoRowsOverlay from "../../components/DataGrid/CustomNoRowsOverlay";
import { green, red } from "@mui/material/colors";
import AvatarIcon from "../../components/AvatarIcon";
import { convertToDateTime } from "../PlansAndSubscriptions/parse";
import { getLanguage } from "../../translations/languages";
import ModalAdd from "./ModalAdd";
import ModalRemove from "./ModalRemove";
import DataGridServerSide from "../../components/DataGridServerSide";

const Macs = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1200;
  const mediumPage = useIsMediumPage();
  const language = getLanguage().code;
  const { token, authenticated } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [loading, setLoading] = React.useState(true);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [searchedMac, setSearchedMac] = React.useState<string>("");
  const [macList, setMaclist] = React.useState<any[]>([]);
  const [macEdited, setMacEdited] = React.useState<boolean>(false);
  const [macOption, setMacOption] = React.useState(0);
  const [status, setStatus] = React.useState<string>("");
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        if (pageNumber > 0) {
          // Rota que retorna a lista de endereços MACs gerais para o admin
          getMacListAdmin(pageNumber, rowsPerPage, searchedMac, status).then(
            (res) => {
              if (res?.data) {
                setMaclist(
                  res.data.items.sort((s: any) =>
                    s.status === "UNREGISTERED" ? -1 : 1
                  )
                );
                setTotalPages(res.data.total);
                setLoading(false);
              }
            }
          );
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, pageNumber, rowsPerPage, searchedMac, status, token]);

  // Ação ao alterar a página da tabela de MACs
  const handleChangePage = (value: number) => {
    setPageNumber(value);
  };

  // Atualizar os dados de MACs após a adição ou exclusão de qualquer MAC
  React.useEffect(() => {
    if (macEdited) {
      if (pageNumber > 0) {
        // Rota que retorna a lista de endereços MACs gerais para o admin
        getMacListAdmin(pageNumber, rowsPerPage, searchedMac, status).then(
          (res) => {
            if (res?.data) {
              setMaclist(
                res.data.items.sort((s: any) =>
                  s.status === "UNREGISTERED" ? -1 : 1
                )
              );
              setTotalPages(res.data.total);
              setLoading(false);
            }
          }
        );
      }
      setMacEdited(false);
    }
  }, [macEdited, pageNumber, rowsPerPage, searchedMac, status]);

  // Ações do modal de deletar
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenModalDelete = (e: any, mac: string) => {
    e.stopPropagation();
    setModalDeleteValues({ mac });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações do modal de adicionar
  const [openModalAdd, setOpenModalAdd] = React.useState(false);
  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  };
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      macList.length > 0
        ? macList.map((m, i) => {
            const userData = m.userId[0];
            const name = `${userData?.name} ${userData?.surname}`;

            return {
              id: i + 1,
              mac: m._id,
              creationDate: convertToDateTime(+m.creationTs, language).replace(
                ",",
                " - "
              ),
              username: m.status === "REGISTERED" ? name : "--",
              status: m.status,
              actions: "",
            };
          })
        : [],
    [language, macList]
  );

  //  Filtrar pela a barra de pesquisa
  const searchRows = React.useMemo(
    () =>
      rows.filter(
        (f: any) =>
          f.mac.toUpperCase().includes(searchedMac.toUpperCase()) ||
          f.mac.toLowerCase().includes(searchedMac.toLowerCase())
      ),
    [rows, searchedMac]
  );

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "mac",
            headerName: t("TEXT.MAC_ADDRESS"),
            align: "left",
            headerAlign: "left",
            flex: 2,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.MAC_ADDRESS")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ alignItems: "center" }}>
                  <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                    <AvatarIcon
                      name={row.status === "REGISTERED" ? row.username : "D"}
                      bgcolor={red[400]}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.mac}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "username",
            headerName: t("TEXT.OWNER"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "creationDate",
            headerName: t("TEXT.REGISTRATION_DATE"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Typography>{row.creationDate}</Typography>
            ),
          },
          {
            field: "actions",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.ACTIONS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <>
                <Tooltip title={t("TEXT.REMOVE_MAC_ADDRESS")}>
                  <IconButton
                    sx={{ p: 0 }}
                    color="error"
                    onClick={(e) => handleOpenModalDelete(e, row.mac)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
          {
            field: "status",
            headerName: "Status",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Chip
                label={
                  row.status === "REGISTERED"
                    ? t("TEXT.REGISTERED")
                    : t("TEXT.AVAILABLE")
                }
                color={row.online ? "success" : "error"}
                sx={{
                  fontWeight: 700,
                  backgroundColor:
                    row.status === "REGISTERED" ? red[100] : green[100],
                  color: row.status === "REGISTERED" ? red[800] : green[800],
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
              />
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "creationDate",
            hide: true,
          },
          {
            field: "username",
            hide: true,
          },
          {
            field: "actions",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "mac",
            headerName: t("TEXT.DEVICES"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ alignSelf: "center" }}>
                      <AvatarIcon
                        name={row.status === "REGISTERED" ? row.username : "D"}
                        bgcolor={red[400]}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.mac}
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Box display="flex" mt={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.OWNER")}:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.username}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.2,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.REGISTRATION_DATE")}:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.creationDate}
                            </Typography>
                          </Box>
                          <Box display="flex" component="span">
                            <Box mt={1} mb={1} component="span">
                              <Tooltip title={t("TEXT.REMOVE_MAC_ADDRESS")}>
                                <IconButton
                                  sx={{ p: 0 }}
                                  color="error"
                                  onClick={(e) =>
                                    handleOpenModalDelete(e, row.mac)
                                  }
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Chip
                              label={
                                row.status === "REGISTERED"
                                  ? t("TEXT.REGISTERED")
                                  : t("TEXT.AVAILABLE")
                              }
                              color={row.online ? "success" : "error"}
                              sx={{
                                fontWeight: 700,
                                backgroundColor:
                                  row.status === "REGISTERED"
                                    ? red[100]
                                    : green[100],
                                color:
                                  row.status === "REGISTERED"
                                    ? red[800]
                                    : green[800],
                                textTransform: "uppercase",
                                fontSize: 12,
                                mt: 0.4,
                                ml: 0.4,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Alterar tipo de filtro da tabela
  const handleChangeFilter = (_: any, value: number) => {
    setMacOption(value);
    setStatus(value === 0 ? "" : value === 1 ? "UNREGISTERED" : "REGISTERED");
    setPageNumber(1);
  };

  // Ação de atualizar tabela
  const reloadTable = () => {
    setLoading(true);
    if (pageNumber > 0) {
      // Rota que retorna a lista de endereços MACs gerais para o admin
      getMacListAdmin(pageNumber, rowsPerPage, searchedMac, status).then(
        (res) => {
          if (res?.data) {
            setMaclist(
              res.data.items.sort((s: any) =>
                s.status === "UNREGISTERED" ? -1 : 1
              )
            );
            setTotalPages(res.data.total);
            setSearchedMac("");
            setSearchedClicked("");
          }
        }
      );
    }
  };

  // Limpar barra de pesquisa do Dispositivo
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedMac("");
    setSearchedClicked("");
    setLoading(true);
  };

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (rows.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [rows.length, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 60000);
  }, []);

  const count = Math.floor(
    totalPages % rowsPerPage !== 0
      ? totalPages / rowsPerPage + 1
      : totalPages / rowsPerPage
  );

  // Componente de paginação do Datagrid
  const Pagination = ({
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) => {
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={count}
        page={pageNumber}
        onChange={(_event, newPage) => {
          handleChangePage(newPage);
        }}
      />
    );
  };

  const CustomPagination = (props: any) => {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  };

  // Paginação do DataGrid
  const rowsFrom =
    pageNumber === 1 ? pageNumber : (pageNumber - 1) * rowsPerPage + 1;
  const rowsTo =
    pageNumber === 1 && searchedMac !== ""
      ? searchRows?.length
      : pageNumber === 1 && searchedMac === ""
      ? rows.length
      : rowsPerPage * pageNumber > totalPages
      ? totalPages
      : rowsPerPage * pageNumber;

  const labelDisplayedRows = () => {
    return `${rowsFrom}–${rowsTo} ${t("TEXT.OF").toLowerCase()} ${totalPages}`;
  };

  return (
    <Box display="grid">
      <Box overflow="auto" whiteSpace="nowrap">
        <Box display="flex" justifyContent="center">
          <Card elevation={3} sx={{ width: 1500, p: mobile ? 0 : 1, mb: 2 }}>
            <CardContent
              sx={{ p: mobile ? 0 : 1 }}
              style={{ paddingBottom: "0px" }}
            >
              <Box display="flex" justifyContent="space-between" ml={2}>
                <Typography
                  fontSize={mobile ? 22 : 25}
                  gutterBottom
                  sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                >
                  {t("TEXT.MACS_ADDRESS")}
                </Typography>
                <Box display="flex">
                  <Button
                    size="small"
                    color="warning"
                    startIcon={<AddToPhotosIcon />}
                    onClick={handleOpenModalAdd}
                    sx={{
                      fontSize: 13,
                      fontWeight: 600,
                      mt: mobile ? 1 : 0,
                      mr: mobile ? 1 : 2,
                    }}
                  >
                    {t("BUTTON.ADD")}
                  </Button>
                  <Tooltip title={t("TEXT.UPDATE") as string}>
                    <IconButton
                      color="warning"
                      onClick={reloadTable}
                      sx={{ mt: mobile ? 0.5 : 0 }}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Card elevation={0}>
                <CardContent>
                  {loading ? (
                    <Box
                      m={
                        mobile
                          ? "230px 0px"
                          : window.screen.width < 1550
                          ? "290px 0px"
                          : "470px 0px"
                      }
                    >
                      <Spinner />
                    </Box>
                  ) : (
                    <>
                      {!mobile && (
                        <Tabs
                          value={macOption}
                          onChange={handleChangeFilter}
                          orientation="horizontal"
                          sx={{
                            fontSize: 20,
                            pb: 2,
                            pt: 2,
                            placeContent: "center",
                          }}
                        >
                          <Tab
                            icon={<DvrIcon />}
                            label={t("TEXT.ALL")}
                            disableRipple
                            sx={{ pt: 0 }}
                          />
                          <Tab
                            icon={<TvIcon />}
                            label={t("TEXT.AVAILABLE")}
                            disableRipple
                            sx={{ pt: 0 }}
                          />
                          <Tab
                            icon={<ConnectedTvIcon />}
                            label={t("TEXT.REGISTERED")}
                            sx={{ pt: 0 }}
                            disableRipple
                          />
                        </Tabs>
                      )}
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          mr: mobile ? 2 : 4,
                          borderRadius: 5,
                          width: "100%",
                          mt: 1,
                          mb: 3,
                          backgroundColor:
                            theme === "dark" ? "#181818" : "#F9F9F9",
                        }}
                      >
                        <Tooltip title={t("BUTTON.SEARCH")}>
                          <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                            onClick={(e: any) => {
                              setSearchedMac(searchedClicked);
                              e.preventDefault();
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                        <InputBase
                          id="myInput"
                          onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                              setSearchedMac(e.target.value);
                              e.preventDefault();
                            }
                          }}
                          onChange={(e: any) => {
                            setSearchedClicked(e.target.value);
                          }}
                          sx={{ ml: 2, flex: 1 }}
                          placeholder={t("TEXT.SEARCH_MAC")}
                        />
                        <Tooltip title={t("TEXT.CLEAN")}>
                          <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                            onClick={handleClearSearch}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Paper>
                      <Paper
                        style={{
                          height: mobile
                            ? 500
                            : mediumPage
                            ? 835
                            : window.screen.width < 1550
                            ? 480
                            : 835,
                        }}
                        elevation={0}
                      >
                        <DataGridServerSide
                          rows={searchedMac === "" ? rows : searchRows}
                          columns={columns}
                          message={t("TEXT.NO_MAC_ADDRESS")}
                          getRowHeight={() => "auto"}
                          pageNumber={pageNumber}
                          totalPages={totalPages}
                          onPageChange={(newPage: number) => {
                            handleChangePage(newPage);
                          }}
                          onPageSizeChange={(rowsPerPage: any) =>
                            setRowsPerPage(rowsPerPage)
                          }
                          components={{
                            NoRowsOverlay: () =>
                              CustomNoRowsOverlay(t("TEXT.NO_MAC_ADDRESS")),
                            Pagination: CustomPagination,
                          }}
                          localeText={{
                            MuiTablePagination: {
                              labelDisplayedRows,
                            },
                          }}
                        />
                      </Paper>
                    </>
                  )}
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <ModalAdd
        setMacEdited={setMacEdited}
        handleOpen={openModalAdd}
        handleClose={handleCloseModalAdd}
      />
      {modalDeleteValues && (
        <ModalRemove
          setMacEdited={setMacEdited}
          mac={modalDeleteValues.mac}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
    </Box>
  );
};

export default Macs;
