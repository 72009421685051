import { Autocomplete, TextField } from "@mui/material";

import { AutoCompleteProps } from "./types";

// Input de autocomplete que recebe a lib Formik
export const AutoComplete = ({
  formik,
  name,
  label,
  options,
  isDisabled,
  ...rest
}: AutoCompleteProps) => {
  return (
    <Autocomplete
      options={options}
      value={formik?.values[name]}
      defaultValue={formik?.values[name]}
      onChange={(_event, value) => (formik.values[name] = value!)}
      onBlur={formik.handleBlur}
      {...rest}
      renderInput={(params: any) => (
        <TextField {...params} fullWidth label={label} name={name} />
      )}
    />
  );
};
