import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface SectorsProviderProps {
  children: ReactNode;
}

interface SectorsData {
  sectors: any[] | [];
  setSectors: Dispatch<SetStateAction<any[] | []>>;
}

export const SectorsContext = createContext({} as SectorsData);

export const SectorsProvider = ({ children }: SectorsProviderProps) => {
  const [sectors, setSectors] = useState<any[]>([]);

  const SectorsProviderValue = useMemo(
    () => ({
      sectors,
      setSectors,
    }),
    [sectors]
  );

  return (
    <SectorsContext.Provider value={SectorsProviderValue}>
      {children}
    </SectorsContext.Provider>
  );
};
