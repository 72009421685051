export const COUPON_ROUTE = "/coupon";

// Resgatar os dados de um cupom específico pelo o nome
export const getCouponByNameRoute = (coupon: string) =>
  `${COUPON_ROUTE}/${coupon}`;

// Rota para editar um cupom por 'id' no modo Admin
export const editCouponByIdRoute = (id: string) => `${COUPON_ROUTE}/${id}`;

// Rota para remover um cupom por 'id' no modo Admin
export const removeCouponByIdRoute = (id: string) => `${COUPON_ROUTE}/${id}`;
