import React from "react";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { LocationOnOutlined as LocationOnOutlinedIcon } from "@mui/icons-material";

import { red } from "@mui/material/colors";
import AvatarIcon from "../../components/AvatarIcon";
import { getUserById } from "../../services/data/user";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import Spinner from "../../components/Spinner";
import Devices from "./Devices/index.tsx";
import BasicInfo from "./BasicInfo";
import { useParams } from "react-router-dom";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import { useTranslation } from "react-i18next";
import Tag from "./Tag";

const Profile = () => {
  const { t } = useTranslation();
  const { token, authenticated, userArcsys, setUserArcsys, userId } =
    React.useContext(AuthContext);
  const [user, setUser] = React.useState<any>();
  const [city, setCity] = React.useState<string>("");
  const [street, setStreet] = React.useState<string>("");
  const [neighborhood, setNeighborhood] = React.useState<string>("");
  const { theme } = React.useContext(ThemeModeContext);
  const [state, setState] = React.useState<string>("");
  const params = useParams();
  const id = params?.addr && +params?.addr;
  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  // Atualizar dispositivo
  const [deviceEdited, setDeviceEdited] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (authenticated) {
      const fetchStatus = () => {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Resgatar os dados do usuário
        if (+userId !== id) {
          if (id) {
            getUserById(id).then((res) => {
              if (res?.data) {
                const { data } = res;
                setUser(data);
                setCity((data as any)?.info?.address?.city);
                setStreet((data as any)?.info?.address?.street);
                setNeighborhood((data as any)?.info?.address?.neighborhood);
                setState((data as any)?.info?.address?.state);
              }
            });
          }
        } else {
          setUser(userArcsys);
          setCity((userArcsys as any)?.info?.address?.city);
          setStreet((userArcsys as any)?.info?.address?.street);
          setNeighborhood((userArcsys as any)?.info?.address?.neighborhood);
          setState((userArcsys as any)?.info?.address?.state);
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, id, token, userArcsys, userId]);

  // Atualizar os dados do usuário na tela após a edição de algum dado
  React.useEffect(() => {
    if (deviceEdited && id) {
      // Resgatar os dados do usuário
      getUserById(id).then((res) => {
        if (res?.data) {
          const { data } = res as any;
          setUser(data);
          setCity((data as any)?.info?.address?.city);
          setStreet((data as any)?.info?.address?.street);
          setNeighborhood((data as any)?.info?.address?.neighborhood);
          setState((data as any)?.info?.address?.state);
          if (+userId === id) {
            setUserArcsys(data);
          }
        }
      });
      setDeviceEdited(false);
    }
  }, [deviceEdited, id, setUserArcsys, userArcsys, userId, userIsAdmin]);

  // Quantidade de Arcsys do usuário
  const userDevices = React.useMemo(
    () => user && user.arcsys.filter((f: any) => f.id !== null)?.length,
    [user]
  );

  return user ? (
    <>
      <Grid container sx={{ placeContent: "center" }}>
        <Box m={3.5} mt={0}>
          <Box position="sticky" top="90px">
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pt: 3.5,
                    pb: 3.5,
                    pl: 5,
                    pr: 5,
                    borderRadius: 4,
                    mb: 2.4,
                    backgroundColor: red[100],
                  }}
                >
                  <Avatar
                    sx={{ width: 120, height: 120, boxShadow: 3, ml: 4, mr: 4 }}
                  >
                    <AvatarIcon
                      name={user.name}
                      width={120}
                      height={120}
                      fontSize={70}
                      bgcolor={red[400]}
                    />
                  </Avatar>
                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="h6"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    width={260}
                  >
                    {`${user.name} ${user.surname}`}
                  </Typography>
                </Box>
                <Box mt={1} display="flex" justifyContent="center" width={260}>
                  <LocationOnOutlinedIcon />
                  <Typography
                    variant="caption"
                    color={theme === "light" ? "rgb(50, 50, 93)" : "white"}
                    fontWeight="300 !important"
                    sx={{ fontSize: 15, display: "flex", ml: 0.4 }}
                  >
                    {user?.info?.address?.state
                      ? `${user?.info?.address?.state}, ${user?.location}`
                      : user?.location}
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardContent>
                <Box display="flex" justifyContent="center" width={260}>
                  <Box>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      textAlign="center"
                    >
                      {userDevices}
                    </Typography>
                    <Typography>{t("TEXT.DEVICES")}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <BasicInfo
            user={user}
            setDeviceEdited={setDeviceEdited}
            city={city}
            street={street}
            neighborhood={neighborhood}
            state={state}
            setCity={setCity}
            setStreet={setStreet}
            setNeighborhood={setNeighborhood}
            setState={setState}
          />
          {+userId === user._id && <Tag />}
          <Devices
            user={user}
            setDeviceEdited={setDeviceEdited}
            deviceEdited={deviceEdited}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <Box m="126px 0px">
      <Spinner />
    </Box>
  );
};

export default Profile;
