// eslint-disable-next-line import/no-anonymous-default-export
export default {
  en: {
    translation: {
      BUTTON: {
        ADD: "Add",
        APPLY: "Apply",
        ASSOCIATE: "Associate",
        CANCEL: "Cancel",
        CLOSE: "Close",
        CONCLUDE: "Conclude",
        CONFIRM: "Confirm",
        CONFIRM_CANCELLATION: "Confirm cancellation",
        CREATE_NEW_CONDITION: "Create new condition",
        DELETE: "Delete",
        DISABLE: "Disable",
        EDIT: "Edit",
        HOME_PAGE: "Home page",
        REACTIVATE_PLAN: "Reactivate plan",
        REMOVE_NEW_CONDITION: "Remove new condition",
        RESET_PASSWORD: "Reset password",
        RETURN: "Return",
        SAVE: "Save",
        SEARCH: "Search",
        SEND: "Send",
        SYNCHRONIZE: "Synchronization",
        UNSHARE: "Unshare",
      },
      ERROR: {
        INVALID_ADDRESS: "Invalid address",
        INVALID_EMAIL: "Invalid email",
        MIN: "Error due to minimum setpoint",
        MAX: "Error due to maximum setpoint",
        OPERATION_IN_PROGRESS: "Operation in progress",
        PASSWORD: "The password must contain at least 6 characters",
        INVALID_CHARACTERS: "Has invalid characters",
        REQUIRED_CONFIRM_NEW_PASSWORD: "The passwords don't match",
        REQUIRED_FIELD: "Required field",
        SETPOINT: "Setpoint error",
        VALUE_NOT_ALLOWED: "Value not allowed",
      },
      TEXT: {
        ABLE: "Able",
        ACCEPT_SHARING: "Accept sharing",
        ACCEPT_TERMS: "Accept terms",
        ACCESS_CODE: "Access Code",
        ACCESS_TELEGRAM: "Access",
        ACCESS_TELEGRAM_MESSAGE: "Access Telegram via",
        ACCESS_TO_DATA: "Access to data history",
        ACCESS_TO_SCENARIOS: "Access to scenarios",
        ACCESS_TO_SHARE_DEVICE: "Access to share devices",
        ACCESSION_DATE: "Accession date",
        ACCOMPLISHED: "Accomplished",
        ACTION: "Action",
        ACTIONS: "Actions",
        ACTIONS_BY_SCENARIO: "Actions by scenario",
        ACTIVE: "Active",
        ACTIVE_SUBSCRIPTION_MESSAGE:
          "You do not have any Arcsys with an active subscription",
        ACCESS: "Access",
        ADD: "Add",
        ADD_DEVICE: "Add Device",
        ADD_MAC_ADDRESS: "Add MAC adress",
        ADD_PLAN: "Add plan",
        ADD_SCENARIO: "Add Scenario",
        ADDITIONAL_CTRLS: "Additional Controllers",
        ADDITIONAL_CTRLS_SUBTITLE:
          "Choose the other devices of the same standard model to make up your sector. These controllers will be adjusted collectively through the industry panel.",
        ADDITIONAL_SETTINGS: "Additional Settings",
        ADDRESS: "Address",
        ADDRESS_INFO: "Address Information",
        ADMIN_AREA: "Admin Area",
        AFTER: "After",
        ALARM: "Alarm",
        ALARMERROR: "Alarm/Error",
        ALERTS_EMAIL_TITLE: "Receive email alerts",
        ALL: "All",
        ALL_DEVICES: "All Devices",
        ALL_USERS: "All users",
        ALTER: "Alter",
        ALTER_PLAN: "Alter plan",
        ALTER_PLAN_MESSAGE: "Are you sure you want to change plans",
        AMOUNT: "Amount to pay",
        PAID_AMOUNT: "Paid amount",
        AMOUNT_COUPON: "Amount",
        AND: "and",
        ANYONE: "includes",
        APPLIED_CONTROLLERS: "Associate Controllers",
        APPLY_SETPOINT_MESSAGE:
          "When confirmed, the setpoint of all controllers in the sector will be changed.",
        ASCENDING_ORDER: "Ascending order",
        ASSIGN_ID: "Assign id to userId in localStorage",
        ASSOCIATES: "Associates",
        ATTENTION: "Attention",
        AVAILABLE: "Available",
        AVAILABLE_FOR_PLATAFORMS: "Available for platforms",
        AVAILABLE_PLURAL: "Available",
        AVERAGE: "Average",
        BASIC_INFO: "Basic Information",
        BEGINNING_PLAN: "Beginning of the Plan",
        BENEFITS: "Benefits",
        BETA_PHASE_SUBTITLE_1:
          "You are among the first to try out our new scenario system. At this stage, we seek your feedback to improve the experience and effectiveness of the system.",
        BETA_PHASE_SUBTITLE_2:
          "Your insight is vital and we will use your suggestions and issue reports to improve our service before the official launch.",
        BETA_PHASE_SUBTITLE_3:
          "We thank you for being part of this important moment and counting on your collaboration. Please let us know about any issues or suggestions via our support team.",
        BETA_PHASE_TITLE: "Welcome to the Beta phase!",
        BETWEEN: "between",
        BIGGER: "bigger",
        BILLET: "Billet",
        BILLING_DETAILS: "Billing details",
        BILLING_TYPE: "Billing type",
        CANCELED: "Canceled",
        CANCEL_REGISTRATION: "Cancel registration",
        CANCEL_REGISTRATION_MESSAGE:
          "Do you really want to confirm this operation? Your account will be deleted and you must register again if you want to use the system.",
        CHANGE_CONDITION: "Change condition",
        CHANGE_HISTORY: "Change history",
        CHANGE_HISTORY_MESSAGE:
          "To restore a version of your changes, click a date and apply the change.",
        CHANGE_PASSWORD: "Change password",
        CHANGE_PLAN: "Change Plan",
        CHANGE_PLAN_SUBTITLE: "Will be changed to plan",
        CHANGING_CTRL_PARAM: "Changing controller parameters",
        CHARGE_MORE_DEVICES: "Charge more devices",
        CHECK_EMAIL: "Check your email",
        CHOOSE: "Choose",
        CITY: "City",
        CLEAN: "Clean",
        CLIENT: "Client",
        CLOSE_CONTROLLER_LIST: "Close controller list",
        CODE: "Code",
        COMMANDS: "Commands",
        COMMANDS_COLLECTIVE: "Collective commands",
        COMMUNICATION_FAILURE: "Communication Failure Alert",
        COMMUNICATION_FAILURE_INFO:
          "After configuring the Communication Failure Alert, it is necessary to enable the",
        COMPANY: "Company",
        COMPLEMENT: "Complement",
        CONDITION_SATISFIED: "Condition satisfied",
        CONDITION: "Condition",
        CONDITIONALS_BY_SCENARIO: "Conditionals by scenario",
        CONDITIONS: "Conditions",
        CONFIRM: "Confirm",
        CONFIRM_CANCELATION: "Confirm cancellation",
        CONFIRM_CANCELATION_MESSAGE:
          "Are you sure you want to cancel the device subscription",
        CONFIRM_CANCELATION_MESSAGE_2:
          "After cancellation, the plan will remain active until the end of the contracted period.",
        CONFIRM_CANCELATION_MESSAGE_TWO:
          "Don't worry! You can reactivate it at any time!",
        CONFIRM_PASSWORD: "Confirm passwrod",
        CONFIRM_SYNC: "Confirm Synchronization",
        CONFIRM_SYNC_MESSAGE: "Are you sure you want to synchronize the sector",
        CONTINUOUS_SHOOTING: "Continuous Trigger",
        CONTROL: "Control",
        CONTROL_MESSAGE:
          "You are always in charge. Manage or cancel your subscription at any time on your device plan.",
        CONTROLLER: "Controller",
        CONTROLLERS: "Controllers",
        COPY: "Copy",
        COUPON_MESSAGE: "Add discount coupon",
        COUPON: "Coupon",
        COUPONS: "Coupons",
        COUPON_CODE: "Coupon code",
        COUNTRY: "Country",
        COURTESY: "Courtesy",
        CREATE_ACCOUNT: "Create Account",
        CREATE_ARCSYS_ACCOUNT: "Create your ArcSys Cloud account",
        CREATE_PASSWORD: "Create password",
        CREATE_TAG: "Create TAG",
        CREATION: "Creation",
        CREATION_DATE: "Creation date",
        CREDIT_CARD: "Credit card",
        CURRENT: "Current",
        CURRENT_PASSWORD: "Current password",
        CURRENT_PLAN: "Current plan",
        CURRENT_PLAN_SUBTITLE: "Your current plan is",
        DAILY_SHOT: "Daily shot",
        DATA_GRAPH_24H: "Data log of the last 24 hours (5 minute sampling)",
        DATE: "Date",
        DATE_OF_BIRTH: "Date of birth",
        DAYS: "Days",
        DEBIT: "Debit",
        DESCENDING_ORDER: "Descending order",
        DEFINING_CONDITIONS: "Condition Definition",
        DEFINITION_ACTIONS: "Action Definition",
        DELETED: "Deleted",
        DELETE_CONTROLLER: "Delete Controller",
        DELETE_CONTROLLER_MESSAGE:
          "Are you sure you want to delete the controller",
        DELETE_COUPON_MESSAGE: "Are you sure you want to remove the coupon",
        DELETE_DEVICE_TITLE: "Delete Device",
        DELETE_DEVICE_MESSAGE: "Are you sure you want to delete the device",
        DELETE_PENDING_PAYMENT: "Delete payment",
        DELETE_PENDING_PAYMENT_MESSAGE:
          "Are you sure you want to delete this pending payment",
        DELETE_PENDING_PAYMENT_MESSAGE_WITH_MAC:
          "Are you sure you want to delete payment from MAC device",
        DELETE_SCENARIO: "Delete scenario",
        DELETE_SECTOR: "Delete sector",
        DELETE_SECTOR_MESSAGE: "Are you sure you want to remove the sector",
        DELETE_SCENARIO_MESSAGE: "Are you sure you want to delete the scenario",
        DELETE_TAG_MESSAGE: "Are you sure you want to delete the TAG",
        DELETE_USER_MESSAGE: "Are you sure you want to delete the user",
        DESCRIPTION: "Description",
        DEVICE: "Device",
        DEVICE_CONNECTION: "Device Connection",
        DEVICE_ERROR: "Error device",
        DEVICE_NAME: "Device name",
        DEVICE_NOT_AVAILABLE: "Device not available",
        DEVICE_SHARING: "Device sharing",
        DEVICE_REGISTRATION: "Device registration",
        DEVICES: "Device",
        DEVICES_ERROR: "Error devices",
        DEVICES_OFFLINE: "Offline devices",
        DEVICES_ONLINE: "Online devices",
        DEVICE_USERS: "Device Users",
        DIFFERENT: "different",
        DISABLE_ENABLE: "Disable / Enable",
        DISABLE_AFTER_ERROR: "Disable after error",
        DISABLE_AFTER_ERROR_MESSAGE:
          "Specify if the scenario should be disabled following an error during its execution.",
        DISABLED: "Disabled",
        DISCOUNT_AMOUNT: "Discount amount",
        DUE_DATE: "Due date",
        EDIT: "Edit",
        EDIT_DEVICE: "Edit Device",
        EDIT_SCENARIO: "Edit scenario",
        EMAIL: "Email",
        EMAIL_ALERTS: "Sending alerts",
        EMPTY_SCENARIOS: "No scenarios registered",
        EMPTY_SECTOR: "No sector registered",
        EMPTY_TABLE: "No device registered",
        EMPTY_USER_TABLE: "No users found",
        END_DATE: "End date",
        ENABLE_SCENARIO: "Enable scenario",
        ENTER_MAC: "Enter MAC address",
        ENTER_PASSWORD: "To confirm, enter your password below",
        EQUAL: "equal",
        ERROR: "Error",
        EXAMPLE: "Example",
        EXCLUSIVE_FOR_WEBFI:
          "Exclusive for WebFi with firmware version 2.3 or higher",
        EXECUTE_ALL_CONDITION: "All conditions are met",
        EXECUTE_ALL_CONDITION_MESSAGE:
          "For action to be taken, all listed conditions must be met simultaneously.",
        EXECUTE_ANY_CONDITION: "Any condition is met",
        EXECUTE_ANY_CONDITION_MESSAGE:
          "Action will be taken if at least one of the listed conditions is met, regardless of the others.",
        EXPAND: "Expand",
        EXPIRATION: "Expiration",
        EXPIRATION_DATE: "Expiration date",
        EXPIRED: "Expired",
        EXTRA_INFO: "Extra information",
        FAILURE: "Failure",
        FIRING_FREQUENCY: "Trigger Frequency",
        FIRING_FREQUENCY_QUESTION_ONE:
          "Determine if the scenario is set to trigger only once",
        FIRING_FREQUENCY_QUESTION_TWO: "or multiple times",
        FIRING_FREQUENCY_QUESTION_THREE:
          " The scenario will be disabled after the single trigger is executed.",
        FOR_MAC: "for MAC",
        FORGOT_PASSWORD: "Forgot password",
        FORMER_ADDRESS: "Former address controller",
        FREE: "Free",
        FREQUENCY_INVERTERS: "Frequency inverters",
        FREQUENCY_INVERTERS_MESSAGE:
          "Make sure that all inverters in the sector are in OFF state. In operation, the inverters do not allow changing parameters, therefore, synchronization will fail.",
        FREQUENCY_INVERTERS_MESSAGE_2:
          "If the sector does not contain inverters, disregard this message and",
        FRIDAY: "Friday",
        FUNCTIONALITIES: "Its functionalities",
        GENERAL: "General",
        GENERAL_INFO: "General info",
        GENERATE_SUBS_REPORT: "Generate subscription report",
        GENERATE_REPORT: "Generate report",
        GRAPH: "Graph",
        HEATING: "Heating",
        HIDE: "Hide",
        HISTORIC: "History",
        HOUR: "Hour",
        HOURS: "Hours",
        HOW_CONDITIONS_WORK: "How conditions are evaluated",
        HYSTERESIS: "Hysteresis",
        IDENTIFICATION: "Identification",
        IDENTIFIER: "Identifier",
        IF: "If",
        IMPORTANT: "Important",
        IMPORTANT_MESSAGE:
          "In case of failure in this sensor, the sector will be able to operate normally. The monitoring sensor is only intended to be a reference to the conditions in the sector, and does not have control functions.",
        INACTIVE: "Inactive",
        INCLUDE_IN_REPORT: "Include in report",
        INCLUDE_FREE_SUBS: "Include free subs",
        INCLUSION_DATE: "Date of inclusion",
        INCOMPATIBLE_MODEL: "Scenario incompatible with current device",
        INFORM_THE_TIME: "Inform the time",
        INITIAL: "Initial",
        INITIAL_DATA: "Initial data",
        INITIAL_VALUE: "Initial value",
        INSTRUCTIONS: "Instructions",
        INTERVAL: "Interval",
        INTERVAL_BETWEEN_SHOTS: "Interval between Triggers",
        INTERVAL_BETWEEN_SHOTS_QUESTION:
          "Set the minimum time interval between consecutive scene triggers in continuous mode. This prevents the scenario from being activated repeatedly in a short period.",
        INVOICES: "Invoices",
        IS: "is",
        LABEL: "Label",
        LAST_RECORDED_EVENT: "Last recorded event",
        LAST_RUN_STATUS: "Last run status",
        LEGAL_NAME: "Corporate name",
        LEGAL_PERSON: "Legal person",
        LICENSE_TERMS: "Use license terms",
        LIMITED_QUANTITY: "Limited quantity",
        LIMITS: "Limits",
        LINK_DEVICE: "Link Device",
        LOCK: "Lock",
        LOCK_CHANGES: "Lock changes",
        LOCK_CHANGES_MESSAGE: "Are you sure you want to block sector changes",
        LOGIN: "Login",
        LOGOUT: "Logout",
        MAC_ADDRESS: "MAC Address",
        MAC_ADDRESS_INFO_TITLE:
          "The MAC address of your WebFi or WebFi Pro product can be found",
        MAC_ADDRESS_INFO_ONE: "Printed on the bottom of the product case.",
        MAC_ADDRESS_INFO_TWO:
          "On the product settings page, in the Basic Settings menu, ARCSYS CLOUD item.",
        MAC_NAME: "Device MAC",
        MACS_ADDRESS: "Devices MAC",
        MAIN_SENSOR: "Monitoring Sensor",
        MAIN_SENSOR_WHAT: "What is the Monitoring Sensor",
        MAIN_SENSOR_WHAT_SUBTITLE:
          "The Monitoring Sensor is responsible for providing real-time data readings. It is the source of the information displayed on the industry dashboard.",
        MAIN_SENSOR_WHY: "Why select it",
        MAIN_SENSOR_WHY_TITLE:
          "Choosing a suitable Monitoring Sensor is essential",
        MANAGE: "Manage",
        MAXIMUM: "Maximum",
        MAXIMUM_AND_MIN_VALUES: "Values of",
        MAXIMUM_AND_MIN_VALUES_MESSAGE:
          "Define the limits within which the setpoint can be adjusted in the sector panel. These limits prevent adjustments outside a safe or efficient range for industry operations.",
        MAXIMUM_CONDITIONS_ACTIONS: "Maximum actions per scenario",
        MAXIMUM_CONDITIONS_SCENARIO: "Maximum conditions per scenario",
        MAXIMUM_SCENARIOS_CTRL: "Maximum scenarios per controller",
        MAX_TIME: "Maximum time",
        MESSAGE: "Message",
        MIN_TIME: "Minimum time",
        MINIMIZE_MAXIMIZE: "Minimize / Maximize",
        MINIMUM: "Minimum",
        MINIMUM_INTERVAL_SHOTS: "Minimum interval between Triggers",
        MINUTES: "Minutes",
        MONDAY: "Monday",
        MODEL: "Model",
        MODELS: "Models",
        MODEL_NOT_FOUND: "Model not found",
        MONITORING: "Monitoring",
        MONTHS: "Months",
        MONTH: "Month",
        NAME: "Name",
        NEIGHBORHOOD: "Neighborhood",
        NEXT: "Next",
        NEW_PASSWORD: "New password",
        NO: "No",
        NO_BENEFITS: "You have no benefit",
        N0_CONDITIONS: "No conditions found",
        NO_CONTROLLER_FOUND: "No controller found",
        NO_CONTROLLER_SYNC: "Synchronization not performed",
        NO_DEVICES: "No device",
        NO_DEVICES_AVAILABLE: "No devices available",
        NO_DEVICES_ONLINE_TITLE: "No devices connected",
        NO_HISTORIC: "No registration",
        NO_MAC_ADDRESS: "No device available",
        NO_PAYMENTS: "No payments made",
        NO_PAYMENTS_OUTSTANDING: "No outstanding payments",
        NO_SCENARIOS: "No scenario available",
        NO_STATE_SELECTED: "No state selected",
        NO_SIGNATURE: "No signature",
        NO_TAG_AVAILABLE: "No TAG available",
        NO_TAG_SELECTED: "No TAG selected",
        NO_USER: "No user",
        NOT_VISIBLE: "Not Visible",
        NUMBER: "Number",
        NUMBER_COUPONS: "Number of coupons",
        NUMBER_OF_COUPONS: "Initial number of coupons",
        OF: "",
        OF_THE_DEVICE_IN: "of the device in",
        OFFLINE_DEVICE: "Offline Device",
        OFFLINE_DEVICES: "Offline Device",
        ONCE_A_DAY: "once a day",
        OPERATOR: "Operator",
        OR: "or",
        OUTSTANDING_PAYMENTS: "Outstanding payments",
        OWNER: "Owner",
        PAGE_NOT_FOUND_MESSAGE:
          "Either something went wrong or the page doesn't exist.",
        PAGE_NOT_FOUND_TITLE: "Oops, sorry we can't find that page!",
        PAID: "Paid",
        PASSWORD: "Password",
        PARAM: "Param",
        PARAMETER_CHANGE: "Parameter change",
        PARAMS: "Parameters",
        PAY_DAY: "Payment date and time",
        PAYMENT: "Payment",
        PAYMENT_HISTORY: "Payment history",
        PAYMENT_LINK: "Payment link",
        PAYMENT_METHODS: "Payment methods",
        PERMISSION: "Permission",
        PERMISSIONS: "Permissions",
        PENDING: "Pending",
        PHONE: "Phone",
        PHYSICAL_PERSON: "Physical person",
        PLAN: "Plan",
        PLAN_DETAILS: "Plan Details",
        PLAN_DURATION: "Plan duration (months)",
        PLAN_POSITION: "Plan position",
        PLANS: "Plans",
        PLANS_AND_SUBSCRIPTIONS: "Plans and Subscriptions",
        PLANS_AVAILABLE: "Plans available",
        PLANS_CONTRACT_TERMS_SUBTITLE_1: "Benefits and Permissions",
        PLANS_CONTRACT_TERMS_SUBTITLE_2: "Data Policy and Plan Expiration",
        PLANS_CONTRACT_TERMS_SUBTITLE_3: "General Provisions",
        PLANS_CONTRACT_TERMS_TITLE: "Plan contract terms",
        PLANS_TERMS_CHECKBOX:
          "Yes, I have read and accept the Plan's terms of contract.",
        PLANS_PERMISSION_1_SUBTITLE:
          "the subscriber will be entitled to the following benefits and permissions",
        PLANS_PERMISSION_2_SUBTITLE_1: "Grace Period",
        PLANS_PERMISSION_2_SUBTITLE_2: "Notifications",
        PLANS_PERMISSION_2_SUBTITLE_3: "Transition to the Free Plan",
        PLANS_PERMISSION_2_SUBTITLE_4: "Data Retention Policy",
        PLANS_PERMISSION_2_SUBTITLE_5: "Data Backup Offer",
        PLANS_PERMISSION_2_SUBTITLE_6: "Data Deletion",
        PLANS_PERMISSION_2_TEXT_1:
          "The subscriber will enter a 10-day grace period after the plan expires. During this time, the subscriber will maintain access to the features of the expired plan. Notification reminders will be issued at the beginning, middle and end of the grace period.",
        PLANS_PERMISSION_2_TEXT_2:
          "The subscriber will be notified by email 7 days before the expiration date, on the expiration date and periodically during the grace period. These notifications will include instructions for renewing the plan and the implications of non-renewal.",
        PLANS_PERMISSION_2_TEXT_3:
          "If renewal is not completed by the end of the grace period, the subscriber will automatically move to the free plan. This transition will imply a reduction in features, as outlined in the plan comparison matrix.",
        PLANS_PERMISSION_2_TEXT_4:
          "After transitioning to the free plan, historical device data and measurements related to premium features will be retained for an additional 20 days before deletion. Subscribers will have the opportunity to export or back up their data within this period.",
        PLANS_PERMISSION_2_TEXT_5:
          "Guidance on exporting or backing up data will be provided during the grace period. Subscribers are encouraged to back up critical data to avoid loss.",
        PLANS_PERMISSION_2_TEXT_6:
          "After the retention period, data associated with premium features will be permanently deleted. A final notification will be issued 7 days before data deletion.",
        PLANS_PERMISSION_3_TEXT:
          "All actions taken will comply with applicable data protection and privacy legislation. This policy is subject to change, and subscribers will be duly informed of any significant changes.",
        PLAN_EXPIRED: "Plan expired or about to expire",
        PLAN_EXPIRE_MSG: "There are plans about to expire",
        PREFERENCES: "Preferences",
        PREVIOUS: "Previous",
        PRINT: "Print",
        PROFILE: "Profile",
        RECORDING_INTERVAL: "Recording interval",
        RECORDS: "Records",
        RECOVER_ACCESS: "Recover access",
        RECOVER_ACCESS_MESSAGE:
          "Enter your new password. Right after the reset, you will have access to ArcSys Cloud",
        RECURRENCE: "Recurrence (months)",
        RECURRENCES: "Recurrences",
        RECURRENT: "Recurrent",
        RECURRING_SUBSCRIPTION: "Recurring subscription",
        RECURRING_SUBSCRIPTION_MODAL_SUBTITLE:
          "With our recurring payment option, you are guaranteed continuous service. See how it benefits you",
        REFERENCE_CONTROLLER: "Reference Controller",
        REFERENCE_OFFLINE: "Offline reference",
        REFRIGERATION: "Refrigeration",
        REGISTERED: "Registered",
        REGISTERED_AT: "Registered at",
        REGISTERED_MESSAGE:
          "Enter your registered email to regain access to ArcSys Cloud",
        REGISTRATION_DATE: "Registration date",
        RELATIONSHIP_CONDITIONS: "Relationship between conditions",
        REMANING: "Remaining",
        REMANING_QUANTITY_COUPONS: "Remaining quantity of coupons",
        REMOVE: "Remove",
        REMOVE_APPLIED_CTRLS: "Clean Associate Controllers",
        REMOVE_APPLIED_CTRLS_MESSAGE:
          "Are you sure you want to clean Associate Controllers",
        REMOVE_ASSOCIATED_CTRL: "Remove Associated Controller",
        REMOVE_ASSOCIATED_CTRL_MESSAGE:
          "Are you sure you want to remove the Associated Controller",
        REMOVE_DEVICE: "Remove Device",
        REMOVE_HISTORIC_MESSAGE:
          "Are you sure you want to remove the item from the day's history",
        REMOVE_MAC_ADDRESS: "Are you sure you want to remove the MAC address",
        REMOVE_MAC_ADDRESS_MESSAGE: "Remove MAC address",
        REMOVE_PLAN_MESSAGE: "Are you sure you want to remove the plan",
        REMOVE_TAG: "Are you sure you want to remove the TAG",
        RENEWAL_DATE: "Renewal date",
        RENEW_SUBSCRIPTION: "Renew Subscription",
        REPORT: "Report",
        REPORT_DATA: "Report data",
        REPORT_MESSAGE: "Select viewing period",
        REPORTS: "Reports",
        RESTORE_VERSION: "Restore version",
        RESTORE_VERSION_MESSAGE:
          "Are you sure you want to restore the old version of both models",
        RESTORE_VERSION_TRANSLATIONS_MESSAGE:
          "Are you sure you want to restore the old version of the translations",
        RETYPE_NEW_PASSWORD: "Retype the new password",
        SAMPLING_RATE: "Sampling rate",
        SATURDAY: "Saturday",
        SAVE_AND_SYNC: "Save and Sync",
        SAVE_AND_SYNC_MESSAGE_0: "Synchronization",
        SAVE_AND_SYNC_MESSAGE:
          "A synchronization routine will adjust the values ​​configured in the sector in each controller/inverter. The adjusted values ​​will be:",
        SAVE_AND_SYNC_MESSAGE_1:
          "At the end of the process, monitor the industry dashboard for any signs of error.",
        SAVE_AND_SYNC_MESSAGE_1_IMPORTANT:
          "Synchronization will not change the state of the devices, only the parameters related to the setpoint.",
        SAVE_AND_SYNC_MESSAGE_2: "Error Checking",
        SAVE_AND_SYNC_MESSAGE_3:
          "In case of errors indicated on the panel, access the sector controller panel to identify and correct discrepancies in setpoint values.",
        SAVE_MODEL_MESSAGE:
          "Are you sure that you want to save the new alterations in our models",
        SAVE_TIME: "Save Time",
        SAVE_TIME_MESSAGE:
          "Set it up once and never worry about the payment process again. We value your time as much as you do.",
        SCENARIOS_BY_CTRL: "Scenarios by controller",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_1:
          "Where you can find an overview of your devices.",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_2:
          "Navigate to one of the devices you want to configure.",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_3:
          "On the device page, look for the 'Add Scenario' option and start customizing.",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_1: "Access the Dashboard",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_2: "Select a Controller/Inverter",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_3: "Create your Scenario",
        SCENARIOS_EMPTY_TITLE:
          "To easily get started and create your first scenario, follow these simple steps:",
        SCENARIO_FAILURE: "Scenario failure",
        SCENARIO_NAME: "Scenario name",
        SCENARIO_PARAMS: "Scenario parameters",
        SCENARIOS: "Scenarios",
        SCENARIOS_IDENTIFICATION: "Scenario Identification",
        SCENARIOS_STATUS: "Scenario Status",
        SCENARIOS_STATUS_QUESTION_ONE:
          "Determine if the scenario will be set to",
        SCENARIOS_STATUS_QUESTION_TWO: "upon creation or start as",
        SCENARIOS_SELECT_DAYS:
          "Define the days of the week that the scenario can be run.",
        SCENARIOS_SUMMARY: "Scenario summary",
        SEARCH_CONFIG: "Search configuration",
        SEARCH_COUPONS: "Search Coupons",
        SEARCH_DEVICE: "Search Device",
        SEARCH_MAC: "Search MAC",
        SEARCH_PAYMENT: "Search Payments",
        SEARCH_PLANS: "Search Plans",
        SEARCH_SCENARIO: "Search Scenario",
        SEARCH_USER: "Search User",
        SECONDS: "Seconds",
        SECTOR: "Sector",
        SECTOR_CONTROLLERS: "Sector Controllers",
        SECTOR_MODEL: "Sector model",
        SECTOR_REFERENCE: "Monitoring model",
        SECTOR_SETPOINT: "Sector Setpoint",
        SECTOR_SYNC_FAILED: "Sector synchronization failed",
        SECTORS: "Sectors",
        SEE_ALL_BENEFITS: "See all benefits",
        SELECT: "Select",
        SELECT_ALL: "Select all",
        SELECT_DAYS: "Days of Week",
        SELECT_FIRST_CTRL: "Choose the Default Controller",
        SELECT_PERIOD: "Select period",
        SELECT_CONTROLLER: "Select the Controller",
        SELECT_CONTROLLERS: "Select the Controllers",
        SELECT_DEVICE: "Select the Device",
        SELECT_DEVICES: "Select the Devices",
        SELECT_DOC: "Document and contact",
        SELECT_MAC: "Select MAC address",
        SELECT_MAIN_SENSOR: "Select the sector Monitoring Sensor",
        SELECT_MAIN_SENSOR_HOW: "How to select the Monitoring Sensor",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_1:
          "Opt for a sensor that has a history of providing accurate and stable data.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_2:
          "The sensor should be in a location that best reflects the general environmental conditions of the industry.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_3:
          "Ensure that the sensor you choose is capable of maintaining a consistent connection and is indicated as 'ONLINE'.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_4:
          "Remember, the correct selection of the Monitoring Sensor is an important step towards effective monitoring and management of your sector.",
        SELECT_MODEL: "Select the model (optional)",
        SELECT_REFERENCE: "Select reference",
        SELECT_SETPOINT_MESSAGE:
          "Choose the type of setpoint you want to adjust for this sector. Make sure the controller is in the operating mode corresponding to the selected setpoint type to ensure correct application of values.",
        SELECT_SETPOINT_MESSAGE_2:
          "For example: When choosing a specific setpoint, such as 'Set Point Eco', confirm that the controller is in eco mode.",
        SELECT_SETPOINT_MESSAGE_3:
          "Incorrectly selected setpoints for the controller's operating mode will not be respected in the sector.",
        SELECT_SECOUND_CTRL: "Include Additional Controllers",
        SELECT_SECTOR_CTRLS: "Select Sector Controllers",
        SELECT_TAG: "Select TAG",
        SELECT_TYPE: "Select the type",
        SEND_EMAIL_TO: "We send an email to",
        SEND_EMAIL_INSTRUCTIONS:
          "with instructions on how to activate your account",
        SENDING_EMAIL_ALERTS: "Sending email alerts",
        SETPOINT_CHANGE: "Setpoint change",
        SETPOINT_INITIAL_VALUE_MESSAGE:
          "Enter the initial value for the setpoint. This will be the reference value when the sector is started and must be within the defined maximum and minimum limits.",
        SETPOINT_MAX: "Maximum setpoint",
        SETPOINT_MIN: "Minimum setpoint",
        SETPOINT_PARAM: "Setpoint Parameters",
        SETTINGS: "Settings",
        SETTINGS_ALERT_DEFAULT: "Default time for alert resend",
        SETTINGS_DEFAULT_DATA: "Default data recording interval",
        SETTINGS_EMAIL_MESSAGE: "Email to request plans",
        SETTINGS_MAX_DATA_INTERVAL: "Maximum data recording interval",
        SETTINGS_MAX_USERS: "Maximum users per device",
        SETTINGS_TIME_CTRL_DASHBOARD: "Time to hide controller from dashboard",
        SETTINGS_TIME_CTRL_INACTIVE: "Time for controller to be inactive",
        SETTINGS_TIME_DEVICE_OFFLINE: "Time for device to go offline",
        SETTINGS_TIME_MQTT: "Time between offline MQTT Broker alerts",
        SHARE_DEVICE: "Share Device",
        SHARED: "Shared",
        SHARED_DEVICE: "Shared device",
        SHARED_USERS: "Shared users",
        SHOW: "Show",
        SHOW_OFFLINE_DEVICES: "Show offline controllers on the dashboard",
        SIGN_HERE: "Sign here",
        SINGLE_OR_CONTINUOUS: "Single or continuous Trigger",
        SINGLE_PAYMENTS: "Single payments",
        SINGLE_SHOT: "Single Trigger",
        SMALLER: "smaller",
        STANDARD_CTRL: "Standard Controller",
        STANDARD_CTRL_SUBTITLE:
          "The Standard Controller establishes the model for other controllers in the sector, ensuring uniformity and compatibility for operations.",
        START: "Start",
        START_DATE: "Start date",
        STATE: "state",
        STATE_INSC: "State registration",
        STATE_USER_REGISTRATION: "State",
        STICKY_NOTES: "Sticky notes",
        STICKY_NOTES_MESSAGE:
          "We'll send you a notification before each payment so there are no surprises.",
        STREET: "Street",
        SUBMIT_ACTION: "Submit command",
        SUBMIT_ACTION_COLLECTIVE: "Submit collective command",
        SUBMIT_ACTION_COLLECTIVE_MESSAGE:
          "Are you sure you want to send the collective command",
        SUBMIT_ACTION_MESSAGE: "Are you sure you want to send the command",
        SUBSCRIBE_TO_A_PLAN: "Subscribe to a plan",
        SUBSCRIPTIONS_REPORT: "Subscriptions report",
        SUBSCRIPTIONS_REPORT_MODAL_TITLE: "Subscriptions report",
        SUCCESS: "Success",
        SUNDAY: "Sunday",
        SURNAME: "Surname",
        SUSPENDED: "Suspended",
        SUSPENDED_SINGULAR: "Suspended",
        SYNC: "synchronize",
        SYNC_ADJUST: "Synchronization will adjust the following parameters",
        SYNC_COMPLETE: "Sync complete",
        SYNC_SECTOR: "Sync Sector",
        SYNC_IN_PROGRESS: "Sync in progress",
        SYNCHRONIZED_CTRLS: "Synchronized Controllers",
        SYSTEM: "System",
        SYSTEM_REGISTER: "We register you in our system",
        TAG_FILTER: "Filter TAGs",
        TAG_MODAL_TITLE: "What are TAGs and what are they for?",
        TAG_MODAL_SUBTITLE_DASHBOARD:
          "After these steps, you will be able to filter the controllers assigned to TAGs.",
        TAG_MODAL_TITLE_DASHBOARD: "There is no TAG created.",
        TAG_MODAL_STEP_SUBTITLE:
          "Access the page of one of the devices you want to assign a TAG to.",
        TAG_MODAL_STEP_SUBTITLE_2:
          "Go to the parameters table and create or select the TAG already created in the system.",
        TAG_MODAL_STEP_SUBTITLE_3:
          "After these steps, you will be able to filter the controllers assigned to TAGs by selecting them at the top of the Dashboard page.",
        TAG_MODAL_STEP_TITLE: "To assign a TAG to a controller, you must",
        TAG_MODAL_SUBTITLE:
          "TAGs are labels that serve to classify and organize controllers. With this, you will be able to find controllers among others that are linked to TAG information.",
        TAKE_ACTION: "Take action",
        TAKE_ACTION_COMMAND: "Take command",
        TELEGRAM_BOT_LINK: "bot link",
        TELEGRAM_BOT_TITLE: "Instructions for using the Telegram bot",
        TELEGRAM_INSTRUCTIONS:
          "Follow the instructions presented in the Bot chat.",
        TELEGRAM_MESSAGE: "Telegram message",
        TERMS_CHECKBOX_MESSAGE: "Yes, I have read and accept the Terms of Use.",
        THAN: "than",
        THAW: "Thaw",
        THE: "the",
        THURSDAY: "Thursday",
        TIME: "time",
        TIME_END: "End time",
        TIME_START: "Start time",
        TIME_AFTER: "after",
        TIME_BEFORE: "sooner",
        TIME_DAS: "before",
        TO: "to",
        TO_EXPIRE: "To expire",
        TOKEN_TELEGRAM: "Token Telegram",
        TOPICS: "Topics",
        TRANSLATIONS: "Translations",
        TRUSTWORTHY_REPRESENTATION: "Trustworthy Representation",
        TRUSTWORTHY_REPRESENTATION_MESSAGE:
          "By choosing a well-positioned and reliable sensor, you ensure that the data displayed adequately represents current industry conditions.",
        TUESDAY: "Tuesday",
        TYPE: "Type",
        TYPE_OF_ACTION: "Type of Action",
        UNDER_THE_PLAN: "Under the plan",
        UNDETERMINED: "Undetermined",
        UNINTERRUPTED_SERVICE: "Uninterrupted Service",
        UNINTERRUPTED_SERVICE_MESSAGE:
          "remain active without interruptions. Forget about manual renewals, we take care of everything for you.",
        UNLIMITED: "Unlimited",
        UNLOCK: "Unlock",
        UNLOCK_CHANGES: "Unlock changes",
        UNSHARE_DEVICE_TITLE: "Unshare device",
        UNSHARE_DEVICE_MESSAGE: "Are you sure you want to unshare the device",
        UNSUBSCRIBE: "Unsubscribe",
        UPDATE: "Update",
        UPDATED_DATA: "Updated Data",
        UPDATED_DATA_MESSAGE:
          "Temperature readings and other measurements on the sector panel are continually updated, reflecting the most recent information provided by this sensor.",
        UPLOAD_JSON: "Upload JSON",
        USER: "User",
        USER_MANUAL: "User manual",
        USER_PROFILE: "user profile",
        USERS: "Users",
        USERNAME: "Username",
        VALIDATE_MAC_ADDRESSES: "Validate MAC addresses",
        VALUE: "Value",
        VIEW: "View",
        VISIBILITY: "Visibility",
        VISIBLE: "Visible",
        WEEKS: "Weeks",
        WELCOME: "Welcome",
        WECOME_MESSAGE_ONE: "Welcome!",
        WECOME_MESSAGE_TWO: "Thank you for creating your ArcSys Cloud account",
        WECOME_MESSAGE_THREE:
          "In order for your device to communicate with ArcSys Cloud it is necessary finish the setup process.",
        WECOME_MESSAGE_FOUR:
          "The 'Device Connection' email has been sent, which contains an access code that must be configured on your device.",
        WECOME_MESSAGE_FIVE:
          "After setup, your device data will automatically appear in our system.",
        WEDNESDAY: "Wednesday",
        YES: "Yes",
        ZIP_CODE: "Zip code",
      },
      TOAST: {
        ERROR: {
          ACCESS_SHARED_DEVICE:
            "Access not permitted. The share has not yet been accepted.",
          ACCESS_USER_PROFILE:
            "Access not permitted. The user has not yet accepted the share.",
          ADD_COUPON: "Error creating coupon",
          ADD_MAC_ADDRESS: "Error adding MAC address",
          ADD_MAC_ADDRESS_EXISTENT:
            "This MAC address is already registered in the system",
          ADD_PLAN: "Error adding plan",
          ALERT_EMAIL: "Error when changing alert email",
          CANCEL_PLAN: "Error canceling plan",
          CONTROLLER_CHANGED: "Failed to perform operation",
          CREATE_SCENARIO: "Error creating scenario",
          CREATE_SECTOR: "Error creating sector",
          CREATE_TAG: "This TAG already exists, please choose another name",
          DELETE_CONTROLLER: "Error deleting controller",
          DELETE_COUPON: "Error deleting coupon",
          DELETE_DEVICE: "Error deleting device",
          DELETE_SCENARIO: "Error deleting scenario",
          DELETE_SECTOR: "Error deleting sector",
          DELETE_TAG: "Error deleting TAG",
          DELETE_USER: "Error deleting user",
          DEVICE_ACCEPTED: "Error accepting share",
          DEVICE_ADDED: "Error adding device",
          DEVICE_EDITED: "Error editing device",
          EDITED_COUPON: "Error when editing coupon",
          EDITED_TAG: "Error editing TAG",
          EDITED_USER: "Error editing user",
          EMAIL_ALREADY_REGISTERED:
            "This email or username is already registered",
          EMAIL_NOT_REGISTERED: "Email not registered",
          GENERATE_REPORT_ADMIN: "Error generating report",
          INVALID_PASSWORD: "Invalid password",
          INVALID_TOKEN: "Invalid token",
          INVERTER_ON: "Change not permitted. The inverter is on",
          LOGIN: "Error logging in",
          MAC_IN_USE: "That MAC address is already in use",
          MAC_NOT_REGISTERED:
            "This MAC address is not registered in the system",
          MAC_UNAVAILABLE: "MAC address is already in use",
          MODEL_STRUCTURE: "Error model structure",
          NO_SETPOINT: "The selected controller is not supported",
          PASSWORD_CHANGED: "Error when changing password",
          PENDING_PAYMENT: "Error deleting pending payment",
          PENDING_PAYMENT_ALREADY: "This device already has a pending payment",
          PLAN_NOT_ASSOCIATE: "Plan not associate",
          RANGE_MONTH: "Choose a period of up to 2 months",
          RECURRING_PAYMENT: "Error canceling recurring payment",
          REGISTRATION_CANCELED: "Error canceling registration",
          REGISTERED_MAC: "This MAC address is not registered in the system",
          REMOVE_MAC_ADDRESS: "Error removing MAC address",
          REMOVE_PAYMENT: "Error deleting payment",
          REMOVE_PLAN: "Error removing plan",
          REMOVE_TAG: "Error removing TAG",
          SCENARIO_CHANGED: "Error when changing scenario",
          SCENARIO_STATUS_SWITCH: "Error when changing scenario status",
          SELECT_CTRLS_MODEL: "Select controllers only of the same model",
          SELECTED_TAG: "Error when selecting TAG",
          SHARE_DEVICE: "Error sharing device",
          SHARE_DEVICE_EXISTENT:
            "The device is already being shared with this user",
          SHARE_DEVICE_FORBIDDEN:
            "Current plan does not allow sharing a device",
          SHARE_DEVICE_PERMISSION:
            "The current plan for this device does not have access to this feature",
          SHARE_DEVICE_PERMISSION_MAX: "Maximum number of shares exceeded",
          SHARE_DEVICE_PERMISSION_SECTORS:
            "The current device plan of one of the selected controllers does not have access to this functionality",
          SHARE_DEVICE_PLAN:
            "Shared devices do not have access to this functionality",
          UNSHARE_DEVICE: "Error when unshare device",
          VALID_COUPON: "Invalid coupon",
          ZIP_CODE: "Invalid zip code",
        },
        INFO: {
          NO_DATA_RECORD: "No data record.",
          NO_DATA_STORED: "No data stored in the last 24 hours.",
        },
        SUCCESS: {
          ACCESS_CODE_COPIED: "Access Code copied",
          ACCOUNT_CREATED: "Account created successfully",
          ADD_COUPON: "Coupon created successfully",
          ADD_MAC_ADDRESS: "MAC address added successfully",
          ADD_PLAN: "Success adding plan",
          ALERT_EMAIL: "Alert email changed successfully",
          ALTERNATE_ACTION: "Operation performed successfully",
          CANCEL_PLAN: "Successfully canceled plan",
          CREATE_SCENARIO: "Success creating scenario",
          CREATE_SECTOR: "Successfully created sector",
          CREATE_TAG: "TAG created successfully",
          DELETE_CONTROLLER: "Controller deleted successfully",
          DELETE_COUPON: "Coupon successfully deleted",
          DELETE_DEVICE: "Device deleted successfully",
          DELETE_SCENARIO: "Successfully deleted scenario",
          DELETE_SECTOR: "Successfully deleted sector",
          DELETE_TAG: "Success in deleting the TAG",
          DELETE_USER: "User deleted successfully",
          DEVICE_ACCEPTED: "Device accepted successfully",
          DEVICE_ADDED: "Device added successfully",
          DEVICE_EDITED: "Device edited successfully",
          EDITED_COUPON: "Coupon edited successfully",
          EDITED_TAG: "TAG edited successfully",
          EDITED_USER: "User successfully edited",
          GENERATE_REPORT_ADMIN: "Report generated successfully",
          LOGIN: "Welcome",
          PASSWORD_CHANGED: "Password changed successfully",
          PENDING_PAYMENT: "Pending payment deleted successfully",
          RECURRING_PAYMENT: "Recurring payment canceled successfully",
          REGISTRATION_CANCELED: "Registration canceled successfully",
          REMOVE_MAC_ADDRESS: "MAC address successfully removed",
          REMOVE_PAYMENT: "Payment deleted successfully",
          REMOVE_PLAN: "Plan removed successfully",
          REMOVE_TAG: "TAG removed successfully",
          RESET_PASSWORD_EMAIL:
            "We send an email to the address provided with instructions for resetting your password",
          SCENARIO_CHANGED: "Scenario changed successfully",
          SCENARIO_STATUS_SWITCH: "Scenario status changed successfully",
          SELECTED_CONTROLLERS: "Successfully selected controllers",
          SELECTED_TAG: "TAG selected successfully",
          SHARE_DEVICE:
            "Device sharing success, wait for invitation to be accepted",
          TOKEN_TELEGRAM_COPIED: "Telegram token copied",
          UNSHARE_DEVICE: "Device unshared successfully",
          VALID_COUPON: "Valid coupon",
          ZIP_CODE: "Zip code located",
        },
        WARNING: {
          LOADING_ERROR_COM: "Waiting for device response",
          ERROR_COM: "Error communicating with the device",
        },
      },
    },
  },
  es: {
    translation: {
      BUTTON: {
        ADD: "Registrar",
        APPLY: "Asociar",
        ASSOCIATE: "Associate",
        CANCEL: "Cancelar",
        CLOSE: "Cerrar",
        CONCLUDE: "Concluir",
        CONFIRM: "Confirmar",
        CONFIRM_CANCELLATION: "Confirmar cancelación",
        CREATE_NEW_CONDITION: "Crear nueva condición",
        DELETE: "Eliminar",
        DISABLE: "Desactivar",
        EDIT: "Editar",
        HOME_PAGE: "Página de inicio",
        REACTIVATE_PLAN: "Reactivar plan",
        REMOVE_NEW_CONDITION: "Eliminar nueva condición",
        RESET_PASSWORD: "Restablecer la contraseña",
        RETURN: "Volver",
        SAVE: "Guardar",
        SEARCH: "Buscar",
        SEND: "Mandar",
        SYNCHRONIZE: "Sincronización",
        UNSHARE: "Dejar de compartir",
      },
      ERROR: {
        INVALID_ADDRESS: "Dirección inválida",
        INVALID_EMAIL: "Email inválido",
        MIN: "Error por setpoint mínima",
        MAX: "Error por setpoint máxima",
        OPERATION_IN_PROGRESS: "Operación en progreso",
        PASSWORD: "La contraseña debe contener al menos 6 caracteres",
        INVALID_CHARACTERS: "Caracteres inválidos",
        REQUIRED_CONFIRM_NEW_PASSWORD: "Las contraseñas no son compatibles",
        REQUIRED_FIELD: "Campo obligatorio",
        SETPOINT: "Error de setpoint",
        VALUE_NOT_ALLOWED: "Valor no permitido",
      },
      TEXT: {
        ABLE: "Capaz",
        ACCEPT_SHARING: "Aceptar compartir",
        ACCEPT_TERMS: "Aceptar los terminos",
        ACCESS_CODE: "Código de Acceso",
        ACCESS_TELEGRAM: "Acceso",
        ACCESS_TELEGRAM_MESSAGE: "Accede a Telegram a través de",
        ACCESS_TO_DATA: "Acceso al historial de datos",
        ACCESS_TO_SCENARIOS: "Acceso a escenarios",
        ACCESS_TO_SHARE_DEVICE: "Acceso para compartir dispositivos",
        ACCESSION_DATE: "Fecha de adhesión",
        ACCOMPLISHED: "Logrado",
        ACTION: "Acción",
        ACTIONS: "Acciones",
        ACTIONS_BY_SCENARIO: "Acciones por escenario",
        ACTIVE: "Activo",
        ACTIVE_SUBSCRIPTION_MESSAGE:
          "No tiene ningún Arcsys con una suscripción activa",
        ACCESS: "Acceso",
        ADD: "Agregar",
        ADD_DEVICE: "Añadir Dispositivo",
        ADD_MAC_ADDRESS: "Agregar dirección MAC",
        ADD_PLAN: "Agregar plan",
        ADD_SCENARIO: "Añadir escenario",
        ADDITIONAL_CTRLS: "Controladores Adicionales",
        ADDITIONAL_CTRLS_SUBTITLE:
          "Elige los demás dispositivos del mismo modelo estándar para conformar tu sector. Estos controladores se ajustarán colectivamente a través del panel de la industria.",
        ADDITIONAL_SETTINGS: "Configuraciones Adicionales",
        ADDRESS: "Dirección",
        ADDRESS_INFO: "Datos del Domicilio",
        ADMIN_AREA: "Área de Administración",
        AFTER: "Después",
        ALARM: "Alarma",
        ALARMERROR: "Alarma/Error",
        ALERTS_EMAIL_TITLE: "Recibir alertas por correo electrónico",
        ALL: "Todo",
        ALL_DEVICES: "Todos los dispositivos",
        ALL_USERS: "Todos los usuarios",
        ALTER: "Cambio de",
        ALTER_PLAN: "Cambio de plan",
        ALTER_PLAN_MESSAGE: "¿Seguro que quieres cambiar de plan",
        AMOUNT: "Cantidad a pagar",
        PAID_AMOUNT: "Cantidad pagada",
        AMOUNT_COUPON: "Cantidad",
        AND: "e",
        ANYONE: "incluye",
        APPLIED_CONTROLLERS: "Controladores Asociados",
        APPLY_SETPOINT_MESSAGE:
          "Cuando se confirme, se cambiará el punto de ajuste de todos los controladores del sector.",
        ASCENDING_ORDER: "Orden creciente",
        ASSIGN_ID: "Asignar identificación a ID de usuario en localStorage",
        ASSOCIATES: "Asociados",
        ATTENTION: "Atención",
        AVAILABLE: "Disponible",
        AVAILABLE_FOR_PLATAFORMS: "Disponible para plataformas",
        AVAILABLE_PLURAL: "Disponible",
        AVERAGE: "Promedio",
        BASIC_INFO: "Informaciones básicas",
        BEGINNING_PLAN: "Comienzo del Plan",
        BENEFITS: "Beneficios",
        BETA_PHASE_SUBTITLE_1:
          "Estás entre los primeros en probar nuestro nuevo sistema de escenarios. En esta etapa, buscamos sus comentarios para mejorar la experiencia y la efectividad del sistema.",
        BETA_PHASE_SUBTITLE_2:
          "Su conocimiento es vital y utilizaremos sus sugerencias e informes de problemas para mejorar nuestro servicio antes del lanzamiento oficial.",
        BETA_PHASE_SUBTITLE_3:
          "Le agradecemos por ser parte de este importante momento y contar con su colaboración. Háganos saber cualquier problema o sugerencia a través de nuestro equipo de soporte.",
        BETA_PHASE_TITLE: "¡Bienvenidos a la fase Beta!",
        BETWEEN: "entre",
        BIGGER: "más grande",
        BILLET: "Palanquilla",
        BILLING_DETAILS: "Detalles de facturación",
        BILLING_TYPE: "Tipo de facturación",
        CANCELED: "Cancelado",
        CANCEL_REGISTRATION: "Cancelar registro",
        CANCEL_REGISTRATION_MESSAGE:
          "¿Realmente quieres confirmar esta operación? Su cuenta será eliminada y debe registrarse nuevamente si desea utilizar el sistema.",
        CHANGE_CONDITION: "Cambiar condición",
        CHANGE_HISTORY: "Cambia la historia",
        CHANGE_HISTORY_MESSAGE:
          "Para restaurar una versión de sus cambios, haga clic en una fecha y aplique el cambio.",
        CHANGE_PASSWORD: "Cambiar contraseña",
        CHANGE_PLAN: "Cambio de Plan",
        CHANGE_PLAN_SUBTITLE: "Se cambiará al plan",
        CHANGING_CTRL_PARAM: "Cambiar los parámetros del controlador",
        CHARGE_MORE_DEVICES: "Cargue más dispositivos",
        CHECK_EMAIL: "Consultar su correo electrónico",
        CHOOSE: "Elegir",
        CITY: "Ciudad",
        CLEAN: "Limpiar",
        CLIENT: "Cliente",
        CLOSE_CONTROLLER_LIST: "Cerrar lista de controladores",
        CODE: "Código",
        COMMANDS: "Comandos",
        COMMANDS_COLLECTIVE: "Comandos colectivos",
        COMMUNICATION_FAILURE: "Alerta de Falla de Comunicación",
        COMMUNICATION_FAILURE_INFO:
          "Después de configurar la Alerta de falla de comunicación, es necesario habilitar el",
        COMPANY: "Empresa",
        COMPLEMENT: "Complementar",
        CONDITION_SATISFIED: "Condición satisfecha",
        CONDITION: "Condicion",
        CONDITIONALS_BY_SCENARIO: "Condicionales por escenario",
        CONDITIONS: "Condiciones",
        CONFIRM: "Confirme",
        CONFIRM_CANCELATION: "Confirmar cancelación",
        CONFIRM_CANCELATION_MESSAGE:
          "¿Estás segura de que deseas cancelar la suscripción al dispositivo",
        CONFIRM_CANCELATION_MESSAGE_2:
          "Tras la cancelación, el plan permanecerá activo hasta el final del periodo contratado.",
        CONFIRM_CANCELATION_MESSAGE_TWO:
          "¡No se preocupe! ¡Puedes reactivarlo en cualquier momento!",
        CONFIRM_PASSWORD: "Confirmar seña",
        CONFIRM_SYNC: "Confirmar Sincronización",
        CONFIRM_SYNC_MESSAGE:
          "¿Estás seguro de que quieres sincronizar el sector",
        CONTINUOUS_SHOOTING: "Disparo continuo",
        CONTROL: "Control",
        CONTROL_MESSAGE:
          "Tú siempre estás a cargo. Administre o cancele su suscripción en cualquier momento en el plan de su dispositivo.",
        CONTROLLER: "Controlador",
        CONTROLLERS: "Controladores",
        COPY: "Copiar",
        COUPON_MESSAGE: "Añadir cupón de descuento",
        COUPON: "Coupon",
        COUPONS: "Cupones",
        COUPON_CODE: "Código promocional",
        COUNTRY: "País",
        COURTESY: "Cortesía",
        CREATE_ACCOUNT: "Crea cuenta",
        CREATE_ARCSYS_ACCOUNT: "Crea tu cuenta de ArcSys Cloud",
        CREATE_PASSWORD: "Crear contraseña",
        CREATE_TAG: "Crear TAG",
        CREATION: "Creación",
        CREATION_DATE: "Fecha de creación",
        CREDIT_CARD: "Tarjeta de crédito",
        CURRENT: "Actual",
        CURRENT_PASSWORD: "Contraseña actual",
        CURRENT_PLAN: "Plan actual",
        CURRENT_PLAN_SUBTITLE: "Su plan actual es",
        DAILY_SHOT: "Tiro diario",
        DATA_GRAPH_24H:
          "Registro de datos de las últimas 24 horas (muestreo de 5 minutos)",
        DATE: "Fecha",
        DATE_OF_BIRTH: "Fecha de nacimiento",
        DAYS: "Días",
        DEBIT: "Débito",
        DESCENDING_ORDER: "Orden descendiente",
        DEFINING_CONDITIONS: "Definición de condiciones",
        DEFINITION_ACTIONS: "Definición de acciones",
        DELETED: "Eliminado",
        DELETE_CONTROLLER: "Eliminar Controlador",
        DELETE_CONTROLLER_MESSAGE:
          "¿Está seguro de que desea eliminar el controlador",
        DELETE_COUPON_MESSAGE: "¿Estás seguro de que quieres eliminar el cupón",
        DELETE_DEVICE_TITLE: "Eliminar dispositivo",
        DELETE_DEVICE_MESSAGE:
          "¿Está seguro de que desea eliminar el dispositivo",
        DELETE_PENDING_PAYMENT: "Eliminar pago",
        DELETE_PENDING_PAYMENT_MESSAGE:
          "¿Estás seguro de que deseas eliminar este pago pendiente",
        DELETE_PENDING_PAYMENT_MESSAGE_WITH_MAC:
          "¿Está seguro de que desea eliminar el pago del dispositivo MAC",
        DELETE_SCENARIO: "Eliminar escenario",
        DELETE_SECTOR: "Eliminar sector",
        DELETE_SECTOR_MESSAGE:
          "¿Estás seguro de que quieres eliminar el sector",
        DELETE_SCENARIO_MESSAGE:
          "¿Estás seguro de que deseas eliminar el escenario",
        DELETE_TAG_MESSAGE: "¿Estás seguro de que deseas eliminar la ETIQUETA",
        DELETE_USER_MESSAGE: "¿Estás seguro de que deseas eliminar al usuario",
        DESCRIPTION: "Descripción",
        DEVICE: "Dispositivo",
        DEVICE_CONNECTION: "Conexión del dispositivo",
        DEVICE_ERROR: "Dispositivo de error",
        DEVICE_NAME: "Nombre del Dispositivo",
        DEVICE_NOT_AVAILABLE: "Dispositivo no disponible",
        DEVICE_SHARING: "Compartir dispositivo",
        DEVICE_REGISTRATION: "Registro de Dispositivo",
        DEVICES: "Dispositivos",
        DEVICES_ERROR: "Dispositivos de error",
        DEVICES_OFFLINE: "Dispositivos sin conexión",
        DEVICES_ONLINE: "Dispositivos en línea",
        DEVICE_USERS: "Usuarios de dispositivos",
        DIFFERENT: "diferente",
        DISABLE_ENABLE: "Desactivar / Activar",
        DISABLE_AFTER_ERROR: "Desactivar después de error",
        DISABLE_AFTER_ERROR_MESSAGE:
          "Defina si el escenario será deshabilitado después de un error durante la ejecución",
        DISABLED: "Desactivado",
        DISCOUNT_AMOUNT: "Importe de descuento",
        DUE_DATE: "Vencimiento",
        EDIT: "Editar",
        EDIT_DEVICE: "Editar Dispositivo",
        EDIT_SCENARIO: "Agregar escenario",
        EMAIL: "Correo electrónico",
        EMAIL_ALERTS: "Envio de alertas",
        EMPTY_SCENARIOS: "No hay escenarios registrados",
        EMPTY_SECTOR: "Ningún sector registrado",
        EMPTY_TABLE: "Ningún dispositivo registrado",
        EMPTY_USER_TABLE: "No se encontraron usuarios",
        END_DATE: "Fecha final",
        ENABLE_SCENARIO: "Habilitar escenario",
        ENTER_MAC: "Ingrese la dirección MAC",
        ENTER_PASSWORD: "Para confirmar, ingrese su contraseña a continuación",
        EQUAL: "igual",
        ERROR: "Error",
        EXAMPLE: "Ejemplo",
        EXCLUSIVE_FOR_WEBFI:
          "Exclusivo para WebFi con versión de firmware 2.3 o superior",
        EXECUTE_ALL_CONDITION: "Todas las condiciones se cumplen",
        EXECUTE_ALL_CONDITION_MESSAGE:
          "Para que se puedan tomar medidas, todas las condiciones enumeradas deben cumplirse simultáneamente.",
        EXECUTE_ANY_CONDITION: "Cualquier condición se cumple",
        EXECUTE_ANY_CONDITION_MESSAGE:
          "Se tomarán medidas si se cumple al menos una de las condiciones enumeradas, independientemente de las demás.",
        EXPAND: "Expandir",
        EXPIRATION: "Expiración",
        EXPIRATION_DATE: "Fecha de expiración",
        EXPIRED: "Caducado",
        EXTRA_INFO: "Información extra",
        FAILURE: "Falla",
        FIRING_FREQUENCY: "Frecuencia de disparo",
        FIRING_FREQUENCY_QUESTION_ONE:
          "Definir si el escenario solo se activará una vez",
        FIRING_FREQUENCY_QUESTION_TWO: "o repetidamente",
        FIRING_FREQUENCY_QUESTION_THREE:
          " El escenario se desactivará después de ejecutar el disparo único.",
        FOR_MAC: "por MAC",
        FORGOT_PASSWORD: "Olvido la contraseña",
        FORMER_ADDRESS: "Ex controlador de direcciones",
        FREE: "Gratis",
        FREQUENCY_INVERTERS: "Convertidores de frecuencia",
        FREQUENCY_INVERTERS_MESSAGE:
          "Asegúrese de que todos los inversores del sector estén en estado APAGADO. En funcionamiento, los inversores no permiten cambiar parámetros, por lo que la sincronización fallará.",
        FREQUENCY_INVERTERS_MESSAGE_2:
          "Si el sector no contiene inversores, ignore este mensaje y",
        FRIDAY: "Viernes",
        FUNCTIONALITIES: "Sus funcionalidades",
        GENERAL: "General",
        GENERAL_INFO: "Informaciones generales",
        GENERATE_SUBS_REPORT: "Generar informe de suscripción",
        GENERATE_REPORT: "Generar informe",
        GRAPH: "Gráfico",
        HEATING: "Calefacción",
        HIDE: "Esconder",
        HISTORIC: "Historial",
        HOUR: "Hora",
        HOURS: "Horas",
        HOW_CONDITIONS_WORK: "Cómo se evalúan las condiciones",
        HYSTERESIS: "Histéresis",
        IDENTIFICATION: "Identificación",
        IDENTIFIER: "Identificador",
        IF: "Si",
        IMPORTANT: "Importante",
        IMPORTANT_MESSAGE:
          "En caso de falla en este sensor, el sector podrá operar normalmente. El sensor de seguimiento sólo pretende ser una referencia de las condiciones del sector y no tiene funciones de control.",
        INACTIVE: "Inactivo",
        INCLUDE_IN_REPORT: "Incluir en el informe",
        INCLUDE_FREE_SUBS: "Incluye subs gratis",
        INCLUSION_DATE: "Fecha de inclusión",
        INCOMPATIBLE_MODEL: "Escenario incompatible con el dispositivo actual",
        INFORM_THE_TIME: "Informar la hora",
        INITIAL: "Inicial",
        INITIAL_DATA: "Datos iniciales",
        INITIAL_VALUE: "Valor inicial",
        INSTRUCTIONS: "Instrucciones",
        INTERVAL: "Intervalo",
        INTERVAL_BETWEEN_SHOTS: "Intervalo entre disparos",
        INTERVAL_BETWEEN_SHOTS_QUESTION:
          "Establezca el intervalo de tiempo mínimo entre activaciones de escenas consecutivas en el modo de disparo continuo. Esto evita que el escenario se active repetidamente en un período corto.",
        INVOICES: "Facturas",
        IS: "es",
        LABEL: "Etiqueta",
        LAST_RECORDED_EVENT: "Último evento registrado",
        LAST_RUN_STATUS: "Estado de la última ejecución",
        LEGAL_NAME: "Razón social",
        LEGAL_PERSON: "Persona jurídica",
        LICENSE_TERMS: "Usar términos de licencia",
        LIMITED_QUANTITY: "Cantidad limitada",
        LIMITS: "Límites",
        LINK_DEVICE: "Dispositivo de enlace",
        LOCK: "Bloquear",
        LOCK_CHANGES: "Cambios de bloqueo",
        LOCK_CHANGES_MESSAGE:
          "¿Estás seguro de que quieres bloquear los cambios del sector",
        LOGIN: "Entrar",
        LOGOUT: "Cerrar Sesión",
        MAC_ADDRESS: "Dirección MAC",
        MAC_ADDRESS_INFO_TITLE:
          "Puede encontrar la dirección MAC de su producto WebFi o WebFi Pro",
        MAC_ADDRESS_INFO_ONE:
          "Impreso en la parte inferior de la caja del producto.",
        MAC_ADDRESS_INFO_TWO:
          "En la página de configuración del producto, en el menú Configuración básica, elemento ARCSYS CLOUD.",
        MAC_NAME: "Dispositivo MAC",
        MACS_ADDRESS: "Dispositivos MAC",
        MAIN_SENSOR: "Sensor de Monitoreo",
        MAIN_SENSOR_WHAT: "¿Cuál es el sensor de Monitoreo",
        MAIN_SENSOR_WHAT_SUBTITLE:
          "El Sensor de Monitoreo se encarga de proporcionar lecturas de datos en tiempo real. Es la fuente de la información que se muestra en el panel de la industria.",
        MAIN_SENSOR_WHY: "¿Por qué seleccionarlo",
        MAIN_SENSOR_WHY_TITLE:
          "Elegir un sensor de Monitoreo adecuado es esencial",
        MANAGE: "Administrar",
        MAX_TIME: "Tiempo máximo",
        MESSAGE: "Mensaje",
        MIN_TIME: "Tiempo minimo",
        MAXIMUM: "Máximo",
        MAXIMUM_AND_MIN_VALUES: "Valores de",
        MAXIMUM_AND_MIN_VALUES_MESSAGE:
          "Defina los límites dentro de los cuales se puede ajustar el punto de ajuste en el panel sectorial. Estos límites evitan ajustes fuera de un rango seguro o eficiente para las operaciones de la industria.",
        MAXIMUM_CONDITIONS_ACTIONS: "Acciones máximas por escenario",
        MAXIMUM_CONDITIONS_SCENARIO: "Condiciones máximas por escenario",
        MAXIMUM_SCENARIOS_CTRL: "Escenarios máximos por controlador",
        MINIMIZE_MAXIMIZE: "Minimizar / Maximizar",
        MINIMUM: "Mínimo",
        MINIMUM_INTERVAL_SHOTS: "Intervalo mínimo entre disparos",
        MINUTES: "Minutos",
        MONDAY: "Lunes",
        MODEL: "Modelo",
        MODELS: "Modelos",
        MODEL_NOT_FOUND: "Modelo no encontrado",
        MONITORING: "Supervisión",
        MONTHS: "Meses",
        MONTH: "Mes",
        NAME: "Nombre",
        NEIGHBORHOOD: "Vecindario",
        NEXT: "Próximo",
        NEW_PASSWORD: "Nueva contraseña",
        NO: "No",
        NO_BENEFITS: "No tienes beneficio",
        N0_CONDITIONS: "No se encontraron condiciones",
        NO_CONTROLLER_FOUND: "No se encontró ningún controlador",
        NO_CONTROLLER_SYNC: "Sincronización no realizada",
        NO_DEVICES: "Ningún dispositivo",
        NO_DEVICES_AVAILABLE: "Ningún dispositivo disponible",
        NO_DEVICES_ONLINE_TITLE: "Sin dispositivos conectado",
        NO_HISTORIC: "No hay registro",
        NO_MAC_ADDRESS: "No hay dispositivo disponible",
        NO_PAYMENTS: "No se han realizado pagos",
        NO_PAYMENTS_OUTSTANDING: "Sin pagos pendientes",
        NO_SCENARIOS: "No hay escenario disponible",
        NO_STATE_SELECTED: "Ningún estado seleccionado",
        NO_SIGNATURE: "Sin firma",
        NO_TAG_AVAILABLE: "No hay TAG disponible",
        NO_TAG_SELECTED: "Ninguna TAG seleccionada",
        NO_USER: "Ningún usuario",
        NOT_VISIBLE: "No visible",
        NUMBER: "Número",
        NUMBER_COUPONS: "Número de cupones",
        NUMBER_OF_COUPONS: "Número inicial de cupones",
        OF: "Del",
        OF_THE_DEVICE_IN: "del dispositivo en",
        OFFLINE_DEVICE: "Dispositivo fuera de línea",
        OFFLINE_DEVICES: "Dispositivos fuera de línea",
        ONCE_A_DAY: "una vez al dia",
        OPERATOR: "Operador",
        OR: "o",
        OUTSTANDING_PAYMENTS: "Pagos pendientes",
        OWNER: "Dueño",
        PAGE_NOT_FOUND_MESSAGE: "O algo salió mal o la página no existe.",
        PAGE_NOT_FOUND_TITLE:
          "¡Oops, lo siento, no podemos encontrar esa página!",
        PAID: "Pago",
        PASSWORD: "Contraseña",
        PARAM: "Parámetro",
        PARAMETER_CHANGE: "Cambio de parámetro",
        PARAMS: "Parámetros",
        PAY_DAY: "Fecha y hora de pago",
        PAYMENT: "Pago",
        PAYMENT_HISTORY: "Historial de pagos",
        PAYMENT_LINK: "Enlace de pago",
        PAYMENT_METHODS: "Formas de pago",
        PERMISSION: "Permiso",
        PERMISSIONS: "Permisos",
        PENDING: "Pendiente",
        PHONE: "Telefone",
        PHYSICAL_PERSON: "Persona física",
        PLAN: "Plano",
        PLAN_DETAILS: "Detalles del plan",
        PLAN_DURATION: "Duración del Plan (meses)",
        PLAN_POSITION: "Posición del Plano",
        PLANS: "Planos",
        PLANS_AND_SUBSCRIPTIONS: "Planes y Suscripciones",
        PLANS_AVAILABLE: "Planes disponibles",
        PLANS_CONTRACT_TERMS_SUBTITLE_1: "Beneficios y permisos",
        PLANS_CONTRACT_TERMS_SUBTITLE_2:
          "Política de datos y vencimiento del plan",
        PLANS_CONTRACT_TERMS_SUBTITLE_3: "Disposiciones generales",
        PLANS_CONTRACT_TERMS_TITLE: "Términos del contrato del plan",
        PLANS_TERMS_CHECKBOX:
          "Sí, he leído y acepto los términos del contrato del Plan.",
        PLANS_PERMISSION_1_SUBTITLE:
          "el suscriptor tendrá derecho a los siguientes beneficios y permisos",
        PLANS_PERMISSION_2_SUBTITLE_1: "Periodo de Carencia",
        PLANS_PERMISSION_2_SUBTITLE_2: "Notificaciones",
        PLANS_PERMISSION_2_SUBTITLE_3: "Transición al plan gratuito",
        PLANS_PERMISSION_2_SUBTITLE_4: "Política de retención de datos",
        PLANS_PERMISSION_2_SUBTITLE_5: "Oferta de copia de seguridad de datos",
        PLANS_PERMISSION_2_SUBTITLE_6: "Eliminación de Datos",
        PLANS_PERMISSION_2_TEXT_1:
          "El suscriptor ingresará a un período de gracia de 10 días después de que expire el plan. Durante este tiempo, el suscriptor mantendrá el acceso a las funcionalidades del plan vencido. Se emitirán recordatorios de notificación al principio, a la mitad y al final del período de gracia.",
        PLANS_PERMISSION_2_TEXT_2:
          "El suscriptor será notificado por correo electrónico 7 días antes de la fecha de vencimiento, en la fecha de vencimiento y periódicamente durante el período de gracia. Estas notificaciones incluirán instrucciones para la renovación del plan y las implicaciones de la no renovación.",
        PLANS_PERMISSION_2_TEXT_3:
          "Si la renovación no se completa al final del período de gracia, el suscriptor pasará automáticamente al plan gratuito. Esta transición implicará una reducción de características, como se describe en la matriz de comparación de planos.",
        PLANS_PERMISSION_2_TEXT_4:
          "Después de realizar la transición al plan gratuito, los datos históricos del dispositivo y las mediciones relacionadas con las funciones premium se conservarán durante 20 días adicionales antes de su eliminación. Los suscriptores tendrán la oportunidad de exportar o hacer una copia de seguridad de sus datos dentro de este período.",
        PLANS_PERMISSION_2_TEXT_5:
          "Durante el período de gracia se proporcionará orientación sobre cómo exportar o realizar copias de seguridad de los datos. Se anima a los suscriptores a realizar copias de seguridad de los datos críticos para evitar pérdidas.",
        PLANS_PERMISSION_2_TEXT_6:
          "Después del período de retención, los datos asociados con las funciones premium se eliminarán permanentemente. Se emitirá una notificación final 7 días antes de la eliminación de datos.",
        PLANS_PERMISSION_3_TEXT:
          "Todas las acciones tomadas cumplirán con la legislación aplicable en materia de protección de datos y privacidad. Esta política está sujeta a cambios y cualquier cambio significativo será debidamente informado a los suscriptores.",
        PLAN_EXPIRED: "Plan caducado o a punto de caducar",
        PLAN_EXPIRE_MSG: "Hay planes a punto de caducar",
        PREFERENCES: "Preferencias",
        PREVIOUS: "Anterior",
        PRINT: "Impresión",
        PROFILE: "Perfil",
        RECORDING_INTERVAL: "Intervalo de registro",
        RECORDS: "Registros",
        RECOVER_ACCESS: "Recuperar acceso",
        RECOVER_ACCESS_MESSAGE:
          "Introduzca su nueva contraseña. Inmediatamente después del reinicio, tendrá acceso a ArcSys Cloud",
        RECURRENCE: "Recurrencia (meses)",
        RECURRENCES: "Recurrencias",
        RECURRENT: "Recurrente",
        RECURRING_SUBSCRIPTION: "Subscripción recurrente",
        RECURRING_SUBSCRIPTION_MODAL_SUBTITLE:
          "Con nuestra opción de pago recurrente, se le garantiza un servicio continuo. Mira cómo te beneficia",
        REFERENCE_CONTROLLER: "Controlador de referencia",
        REFERENCE_OFFLINE: "Referencia fuera de línea",
        REFRIGERATION: "Refrigeración",
        REGISTERED: "Registrado",
        REGISTERED_AT: "Registrado en",
        REGISTERED_MESSAGE:
          "Ingrese su correo electrónico registrado para recuperar el acceso a ArcSys Cloud",
        REGISTRATION_DATE: "Fecha de Registro",
        RELATIONSHIP_CONDITIONS: "Relación entre condiciones",
        REMANING: "Restante",
        REMANING_QUANTITY_COUPONS: "Cantidad restante de cupones",
        REMOVE: "Eliminar",
        REMOVE_APPLIED_CTRLS: "Limpiar Controladores Asociados",
        REMOVE_APPLIED_CTRLS_MESSAGE:
          "¿Está seguro de que desea limpiar los Controladores Asociados",
        REMOVE_ASSOCIATED_CTRL: "¿Eliminar Controlador Asociado",
        REMOVE_ASSOCIATED_CTRL_MESSAGE:
          "¿Está seguro de que desea eliminar el Controlador Asociado",
        REMOVE_DEVICE: "Retire el dispositivo",
        REMOVE_HISTORIC_MESSAGE:
          "¿Está seguro de que desea eliminar el elemento del historial del día",
        REMOVE_MAC_ADDRESS: "Eliminar dirección MAC",
        REMOVE_MAC_ADDRESS_MESSAGE:
          "¿Está seguro de que desea eliminar la dirección MAC",
        REMOVE_PLAN_MESSAGE: "¿Estás seguro de que quieres eliminar el plan",
        REMOVE_TAG: "¿Estás seguro de que deseas eliminar la TAG",
        RENEWAL_DATE: "Fecha de renovación",
        RENEW_SUBSCRIPTION: "Renovar la suscripción",
        REPORT: "Informe",
        REPORT_DATA: "Informe de datos",
        REPORT_MESSAGE: "Seleccione el período de visualización",
        REPORTS: "Informes",
        RESTORE_VERSION: "Restaurar versión",
        RESTORE_VERSION_MESSAGE:
          "¿Está seguro de que desea restaurar una versión antigua de dos modelos",
        RESTORE_VERSION_TRANSLATIONS_MESSAGE:
          "¿Está seguro de que desea restaurar la versión anterior de las traducciones",
        RETYPE_NEW_PASSWORD: "Vuelva a escribir la nueva contraseña",
        SAMPLING_RATE: "Tasa de muestreo",
        SATURDAY: "Sábado",
        SAVE_AND_SYNC: "Guardar y Sincronizar",
        SAVE_AND_SYNC_MESSAGE_0: "Sincronización",
        SAVE_AND_SYNC_MESSAGE:
          "Una rutina de sincronización ajustará los valores configurados en el sector en cada controlador/inversor. Los valores ajustados serán:",
        SAVE_AND_SYNC_MESSAGE_1:
          "Al final del proceso, supervise el panel de la industria para detectar cualquier signo de error.",
        SAVE_AND_SYNC_MESSAGE_1_IMPORTANT:
          "La sincronización no cambiará el estado de los dispositivos, sólo los parámetros relacionados con el setpoint.",
        SAVE_AND_SYNC_MESSAGE_2: "Comprobación de errores",
        SAVE_AND_SYNC_MESSAGE_3:
          "En caso de errores indicados en el panel, acceda al panel del controlador del sector para identificar y corregir discrepancias en los valores de los puntos de ajuste.",
        SAVE_MODEL_MESSAGE:
          "¿Tem certeza de que deseas salvar as novas alterações nos modelos",
        SAVE_TIME: "Ahorrar tiempo",
        SAVE_TIME_MESSAGE:
          "Configúrelo una vez y nunca más se preocupe por el proceso de pago. Valoramos su tiempo tanto como usted.",
        SCENARIOS_BY_CTRL: "Escenarios por controlador",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_1:
          "Dónde puede encontrar una descripción general de sus dispositivos.",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_2:
          "Navegue hasta uno de los dispositivos que desea configurar.",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_3:
          "En la página del dispositivo, busque la opción 'Agregar escenario' y comience a personalizar.",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_1: "Accede al panel",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_2: "Seleccione un controlador/inversor",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_3: "Crea tu escenario",
        SCENARIOS_EMPTY_TITLE:
          "Para comenzar fácilmente y crear su primer escenario, siga estos sencillos pasos:",
        SCENARIO_FAILURE: "Fallo del escenario",
        SCENARIO_NAME: "Nombre del escenario",
        SCENARIO_PARAMS: "Parámetros del escenario",
        SCENARIOS: "Escenarios",
        SCENARIOS_IDENTIFICATION: "Identificación del escenario",
        SCENARIOS_STATUS: "Estado del escenario",
        SCENARIOS_STATUS_QUESTION_ONE: "Defina si el escenario será",
        SCENARIOS_STATUS_QUESTION_TWO:
          "tan pronto como se cree o si comenzará como",
        SCENARIOS_SELECT_DAYS:
          "Defina los días de la semana en los que se puede ejecutar el escenario.",
        SCENARIOS_SUMMARY: "Resumen del escenario",
        SEARCH_CONFIG: "Configuración de búsqueda",
        SEARCH_COUPONS: "Buscar cupones",
        SEARCH_DEVICE: "Dispositivo de búsqueda",
        SEARCH_MAC: "MAC de búsqueda",
        SEARCH_PAYMENTS: "Buscar Pagos",
        SEARCH_PLANS: "Planes de búsqueda",
        SEARCH_SCENARIO: "Escenario de búsqueda",
        SEARCH_USER: "Usuario de búsqueda",
        SECONDS: "Segundos",
        SECTOR: "Sector",
        SECTOR_CONTROLLERS: "Controladores de sector",
        SECTOR_MODEL: "Modelo de industria",
        SECTOR_REFERENCE: "Modelo de seguimiento",
        SECTOR_SETPOINT: "Setpoint del Sector",
        SECTOR_SYNC_FAILED: "Falló la sincronización del sector",
        SECTORS: "Sectores",
        SEE_ALL_BENEFITS: "Ver todos los beneficios",
        SELECT: "Seleccionar",
        SELECT_ALL: "Seleccionar todo",
        SELECT_DAYS: "Días de la Semana",
        SELECT_FIRST_CTRL: "Elija el controlador predeterminado",
        SELECT_PERIOD: "Seleccionar período",
        SELECT_CONTROLLER: "Seleccione el Controlador",
        SELECT_CONTROLLERS: "Seleccionar Controladores",
        SELECT_DEVICE: "Seleccione el dispositivo",
        SELECT_DEVICES: "Seleccione el dispositivos",
        SELECT_DOC: "Documento y contacto",
        SELECT_MAC: "Seleccione la dirección MAC",
        SELECT_MAIN_SENSOR: "Seleccione el sector Sensor de Monitoreo",
        SELECT_MAIN_SENSOR_HOW: "¿Cómo seleccionar el sensor de monitoreo",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_1:
          "Opte por un sensor que tenga un historial de proporcionar datos precisos y estables.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_2:
          "El sensor debe estar en una ubicación que refleje mejor las condiciones ambientales generales de la industria.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_3:
          "Asegúrese de que el sensor que elija sea capaz de mantener una conexión constante y esté indicado como 'ONLINE'.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_4:
          "Recuerde, la correcta selección del Sensor de monitoreo es un paso importante hacia el seguimiento y gestión eficaz de su sector.",
        SELECT_MODEL: "Seleccione el modelo (opcional)",
        SELECT_REFERENCE: "Seleccionar referencia",
        SELECT_SETPOINT_MESSAGE:
          "Elija el tipo de setpoint que desea ajustar para este sector. Asegúrese de que el controlador esté en el modo de funcionamiento correspondiente al tipo de punto de ajuste seleccionado para garantizar la correcta aplicación de los valores.",
        SELECT_SETPOINT_MESSAGE_2:
          "Por ejemplo: al elegir un punto de ajuste específico, como 'Punto de ajuste Eco', confirme que el controlador esté en modo ecológico.",
        SELECT_SETPOINT_MESSAGE_3:
          "Los setpoints incorrectamente seleccionados para el modo de funcionamiento del controlador no serán respetados en el sector.",
        SELECT_SECOUND_CTRL: "Incluir controladores adicionales",
        SELECT_SECTOR_CTRLS: "Seleccionar controladores de sector",
        SELECT_TAG: "Seleccione TAG",
        SELECT_TYPE: "Seleccione el tipo",
        SEND_EMAIL_TO: "Enviamos un correo electrónico a",
        SEND_EMAIL_INSTRUCTIONS:
          "con instrucciones sobre cómo activar su cuenta",
        SENDING_EMAIL_ALERTS: "Envío de alertas por correo electrónico",
        SETPOINT_CHANGE: "Cambio de setpoint",
        SETPOINT_INITIAL_VALUE_MESSAGE:
          "Introduzca el valor inicial para el punto de ajuste. Este será el valor de referencia cuando se inicie el sector y deberá estar dentro de los límites máximo y mínimo definidos.",
        SETPOINT_MAX: "Setpoint máximo",
        SETPOINT_MIN: "Setpoint mínimo",
        SETPOINT_PARAM: "Parámetros de Setpoint",
        SETTINGS: "Ajustes",
        SETTINGS_ALERT_DEFAULT: "Hora predeterminada para el reenvío de alerta",
        SETTINGS_DEFAULT_DATA: "Intervalo de grabación de datos predeterminado",
        SETTINGS_EMAIL_MESSAGE: "Email para solicitar planes",
        SETTINGS_MAX_DATA_INTERVAL: "Intervalo máximo de grabación de datos",
        SETTINGS_MAX_USERS: "Usuarios máximos por dispositivo",
        SETTINGS_TIME_CTRL_DASHBOARD:
          "Es hora de ocultar el controlador del tablero",
        SETTINGS_TIME_CTRL_INACTIVE:
          "Tiempo para que el controlador esté inactivo",
        SETTINGS_TIME_DEVICE_OFFLINE:
          "Es hora de que el dispositivo se desconecte",
        SETTINGS_TIME_MQTT:
          "Tiempo entre alertas del corredor MQTT sin conexión",
        SHARE_DEVICE: "Compartir dispositivo",
        SHARED: "Compartido",
        SHARED_DEVICE: "Dispositivo compartido",
        SHARED_USERS: "Usuarios compartidos",
        SHOW: "Mostrar",
        SHOW_OFFLINE_DEVICES: "Mostrar controladores sin conexión en el panel",
        SIGN_HERE: "Firma aqui",
        SINGLE_OR_CONTINUOUS: "Disparo único o continuo",
        SINGLE_PAYMENTS: "Pagos únicos",
        SINGLE_SHOT: "Un solo tiro",
        SMALLER: "menor",
        STANDARD_CTRL: "Controlador Estándar",
        STANDARD_CTRL_SUBTITLE:
          "El Controlador Estándar establece el modelo para otros controladores del sector, asegurando uniformidad y compatibilidad en las operaciones.",
        START: "Inicio",
        START_DATE: "Fecha de inicio",
        STATE: "estado",
        STATE_INSC: "Inscripción Estadual",
        STATE_USER_REGISTRATION: "Estado",
        STICKY_NOTES: "Notas adhesivas",
        STICKY_NOTES_MESSAGE:
          "Te enviaremos una notificación antes de cada pago para que no haya sorpresas.",
        STREET: "Camino",
        SUBMIT_ACTION: "Enviar comando",
        SUBMIT_ACTION_COLLECTIVE: "Enviar comando colectivo",
        SUBMIT_ACTION_COLLECTIVE_MESSAGE:
          "¿Está seguro de que desea enviar el comando colectivo",
        SUBMIT_ACTION_MESSAGE: "¿Está seguro de que desea enviar el comando",
        SUBSCRIBE_TO_A_PLAN: "Suscríbete a un plan",
        SUBSCRIPTIONS_REPORT: "Informe de suscripciones",
        SUCCESS: "Éxito",
        SUNDAY: "Domingo",
        SURNAME: "Apellido",
        SUSPENDED: "Suspendido",
        SUSPENDED_SINGULAR: "Suspendido",
        SYNC: "sincronice",
        SYNC_ADJUST: "La sincronización ajustará los siguientes parámetros",
        SYNC_COMPLETE: "Sincronización completa",
        SYNC_SECTOR: "Sector de Sincronización",
        SYNC_IN_PROGRESS: "Sincronización en progreso",
        SYNCHRONIZED_CTRLS: "Controladores Sincronizados",
        SYSTEM: "Sistemo",
        SYSTEM_REGISTER: "Ni registras vin en nia sistemo",
        TAG_FILTER: "Filtrar TAGs",
        TAG_MODAL_TITLE: "¿Qué son los TAG y para qué sirven?",
        TAG_MODAL_SUBTITLE_DASHBOARD:
          "Después de estos pasos, podrá filtrar los controladores asignados a los TAG.",
        TAG_MODAL_TITLE_DASHBOARD: "No se ha creado ningún TAG.",
        TAG_MODAL_STEP_SUBTITLE:
          "Accede a la página de uno de los dispositivos al que quieras asignar un TAG.",
        TAG_MODAL_STEP_SUBTITLE_2:
          "Vaya a la tabla de parámetros y cree o seleccione el TAG ya creado en el sistema.",
        TAG_MODAL_STEP_SUBTITLE_3:
          "Después de estos pasos, podrá filtrar los controladores asignados a TAG seleccionándolos en la parte superior de la página del Panel.",
        TAG_MODAL_STEP_TITLE: "Para asignar un TAG a un controlador, debe",
        TAG_MODAL_SUBTITLE:
          "Los TAG son etiquetas que sirven para clasificar y organizar los controladores. Con esto podrás encontrar controladores entre otros que estén vinculados a la información del TAG.",
        TAKE_ACTION: "Tomar acción",
        TAKE_ACTION_COMMAND: "Take el comando",
        TELEGRAM_BOT_LINK: "enlace de robot",
        TELEGRAM_BOT_TITLE: "Instrucciones para usar el bot de Telegram",
        TELEGRAM_INSTRUCTIONS:
          "Siga las instrucciones presentadas en el chat del Bot.",
        TELEGRAM_MESSAGE: "Mensaje de Telegram",
        TERMS_CHECKBOX_MESSAGE: "Sí, he leído y acepto las Condiciones de uso.",
        THAN: "que",
        THAW: "Deshielo",
        THE: "el",
        THURSDAY: "Jueves",
        TIME: "tiempo",
        TIME_END: "Hora final",
        TIME_START: "Hora de inicio",
        TIME_AFTER: "después",
        TIME_BEFORE: "antes",
        TIME_DAS: "de",
        TO: "al",
        TO_EXPIRE: "Estar caducado",
        TOKEN_TELEGRAM: "Token de Telegram",
        TOPICS: "Temas",
        TRANSLATIONS: "Traducciones",
        TRUSTWORTHY_REPRESENTATION: "Representación confiable",
        TRUSTWORTHY_REPRESENTATION_MESSAGE:
          "Al elegir un sensor confiable y bien ubicado, se asegura de que los datos mostrados representen adecuadamente las condiciones actuales de la industria.",
        TUESDAY: "Martes",
        TYPE: "Tipo",
        TYPE_OF_ACTION: "Tipo de Acción",
        UNDER_THE_PLAN: "Bajo el plan",
        UNDETERMINED: "Indeterminado",
        UNINTERRUPTED_SERVICE: "Servicio Ininterrumpido",
        UNINTERRUPTED_SERVICE_MESSAGE:
          "permanecen activas sin interrupciones. Olvídate de las renovaciones manuales, nosotros nos encargamos de todo por ti.",
        UNLIMITED: "Ilimitado",
        UNLOCK: "Desbloquear",
        UNLOCK_CHANGES: "Desbloquear cambios",
        UNSHARE_DEVICE_TITLE: "Dejar de compartir dispositivo",
        UNSHARE_DEVICE_MESSAGE:
          "¿Estás seguro de que quieres dejar de compartir el dispositivo",
        UNSUBSCRIBE: "Cancelar firma",
        UPDATE: "Actualizar",
        UPDATED_DATA: "Datos Actualizados",
        UPDATED_DATA_MESSAGE:
          "Las lecturas de temperatura y otras mediciones en el panel sectorial se actualizan continuamente, reflejando la información más reciente proporcionada por este sensor.",
        UPLOAD_JSON: "Subir JSON",
        USER: "Usuario",
        USER_MANUAL: "Manual de usuario",
        USER_PROFILE: "perfil del usuario",
        USERS: "Usuarios",
        USERNAME: "Nombre de usuario",
        VALIDATE_MAC_ADDRESSES: "Validar direcciones MAC",
        VALUE: "Valor",
        VIEW: "Ver",
        VISIBILITY: "Visibilidad",
        VISIBLE: "Visible",
        WEEKS: "Semanas",
        WELCOME: "Bienvenida",
        WECOME_MESSAGE_ONE: "¡Sea bienvenido!",
        WECOME_MESSAGE_TWO: "Gracias por crear su cuenta de ArcSys Cloud",
        WECOME_MESSAGE_THREE:
          "Para que su dispositivo se comunique con ArcSys Cloud es necesario finalizar el proceso de configuración.",
        WECOME_MESSAGE_FOUR:
          "Se ha enviado el correo electrónico de 'Conexión del dispositivo', el cual contiene un código de acceso que debe configurarse en su dispositivo.",
        WECOME_MESSAGE_FIVE:
          "Después de la configuración, los datos de su dispositivo aparecerán automáticamente en nuestro sistema.",
        WEDNESDAY: "Miércoles",
        YES: "Si",
        ZIP_CODE: "Código postal",
      },
      TOAST: {
        ERROR: {
          ACCESS_SHARED_DEVICE:
            "Acceso no permitido. La acción aún no ha sido aceptada.",
          ACCESS_USER_PROFILE:
            "Acceso no permitido. El usuario aún no ha aceptado la acción.",
          ADD_COUPON: "Error al crear el cupón",
          ADD_MAC_ADDRESS: "Error al agregar la dirección MAC",
          ADD_MAC_ADDRESS_EXISTENT:
            "Esta dirección MAC ya está registrada en el sistema.",
          ADD_PLAN: "Error al agregar plan",
          ALERT_EMAIL: "Error al cambiar el correo electrónico de alerta",
          CANCEL_PLAN: "Error al cancelar el plan",
          CONTROLLER_CHANGED: "No se pudo realizar la operación",
          CREATE_SCENARIO: "Error al crear el escenario",
          CREATE_SECTOR: "Error al crear sector",
          CREATE_TAG: "Esta TAG ya existe, por favor elige otro nombre",
          DELETE_CONTROLLER: "Error al eliminar el controlador",
          DELETE_COUPON: "Error al eliminar el cupón",
          DELETE_DEVICE: "Error al eliminar el dispositivo",
          DELETE_SCENARIO: "Error al eliminar el escenario",
          DELETE_SECTOR: "Error al eliminar sector",
          DELETE_TAG: "Error al eliminar TAG",
          DELETE_USER: "Error al eliminar usuario",
          DEVICE_ACCEPTED: "Error al aceptar compartir",
          DEVICE_ADDED: "Error al agregar dispositivo",
          DEVICE_EDITED: "Dispositivo de edición de error",
          EDITED_COUPON: "Error al editar el cupón",
          EDITED_TAG: "Error al editar ETIQUETA",
          EDITED_USER: "Error al editar usuario",
          EMAIL_ALREADY_REGISTERED:
            "Este correo electrónico o nombre de usuario ya está registrado",
          EMAIL_NOT_REGISTERED: "Correo electrónico no registrado",
          GENERATE_REPORT_ADMIN: "Error al generar informe",
          INVALID_PASSWORD: "Contraseña invalida",
          INVALID_TOKEN: "Token no válido",
          INVERTER_ON: "Cambio no permitido. El inversor está encendido.",
          LOGIN: "Error al iniciar sesión",
          MAC_IN_USE: "Esa dirección MAC ya está en uso",
          MAC_NOT_REGISTERED:
            "Esta dirección MAC no está registrada en el sistema",
          MAC_UNAVAILABLE: "La dirección MAC ya está en uso",
          MODEL_STRUCTURE: "Estructura del modelo de error",
          NO_SETPOINT: "El controlador seleccionado no es compatible",
          PASSWORD_CHANGED: "Error al cambiar contraseña",
          PENDING_PAYMENT: "Error al eliminar el pago pendiente",
          PENDING_PAYMENT_ALREADY:
            "Este dispositivo ya tiene un pago pendiente",
          PLAN_NOT_ASSOCIATE: "Plan no asociado",
          RANGE_MONTH: "Elija un período de hasta 2 meses",
          RECURRING_PAYMENT: "Error al cancelar el pago recurrente",
          REGISTRATION_CANCELED: "Error al cancelar el registro",
          REGISTERED_MAC: "Esta dirección MAC no está registrada en el sistema",
          REMOVE_MAC_ADDRESS: "Error al eliminar la dirección MAC",
          REMOVE_PAYMENT: "Error al eliminar el pago",
          REMOVE_PLAN: "Error al eliminar el plan",
          REMOVE_TAG: "Error al eliminar la etiqueta",
          SCENARIO_CHANGED: "Error al cambiar de escenario",
          SCENARIO_STATUS_SWITCH: "Error al cambiar el estado del escenario",
          SELECT_CTRLS_MODEL: "Seleccione controladores solo del mismo modelo",
          SELECTED_TAG: "Error al seleccionar TAG",
          SHARE_DEVICE: "Error al compartir dispositivo",
          SHARE_DEVICE_EXISTENT:
            "El dispositivo ya se está compartiendo con este usuario",
          SHARE_DEVICE_FORBIDDEN:
            "El plan actual no permite compartir un dispositivo",
          SHARE_DEVICE_PERMISSION:
            "El plan actual para este dispositivo no tiene acceso a esta función",
          SHARE_DEVICE_PERMISSION_MAX: "Se superó el número máximo de acciones",
          SHARE_DEVICE_PERMISSION_SECTORS:
            "El plan de dispositivo actual de uno de los controladores seleccionados no tiene acceso a esta funcionalidad",
          SHARE_DEVICE_PLAN:
            "Los dispositivos compartidos no tienen acceso a esta funcionalidad",
          UNSHARE_DEVICE: "Error al dejar de compartir el dispositivo",
          VALID_COUPON: "Cupón inválido",
          ZIP_CODE: "Código postal no válido",
        },
        INFO: {
          NO_DATA_RECORD: "Sin registro de datos.",
          NO_DATA_STORED: "No hay datos almacenados en las últimas 24 horas.",
        },
        SUCCESS: {
          ACCESS_CODE_COPIED: "Código de acceso copiado",
          ACCOUNT_CREATED: "Cuenta creada con éxito",
          ADD_COUPON: "Cupón creado exitosamente",
          ADD_MAC_ADDRESS: "Dirección MAC agregada exitosamente",
          ADD_PLAN: "Éxito al agregar un plan",
          ALERT_EMAIL: "El correo electrónico de alerta cambió con éxito",
          ALTERNATE_ACTION: "Operación realizada con éxito",
          CANCEL_PLAN: "Plan cancelado con exito",
          CREATE_SCENARIO: "Escenario de creación de éxito",
          CREATE_SECTOR: "Sector creado con éxito",
          CREATE_TAG: "TAG creada con éxito",
          DELETE_CONTROLLER: "Controlador eliminado con éxito",
          DELETE_COUPON: "Cupón eliminado exitosamente",
          DELETE_DEVICE: "Dispositivo eliminado con éxito",
          DELETE_SCENARIO: "Escenario eliminado con éxito",
          DELETE_SECTOR: "Sector eliminado con éxito",
          DELETE_TAG: "Éxito al eliminar el TAG",
          DELETE_USER: "Éxito al eliminar el usuario",
          DEVICE_ACCEPTED: "Dispositivo aceptado con éxito",
          DEVICE_ADDED: "Dispositivo agregado con éxito",
          DEVICE_EDITED: "Dispositivo editado con éxito",
          EDITED_COUPON: "Cupón editado exitosamente",
          EDITED_TAG: "TAG editada exitosamente",
          EDITED_USER: "Usuario editado con éxito",
          GENERATE_REPORT_ADMIN: "Informe generado exitosamente",
          LOGIN: "Bienvenido",
          PASSWORD_CHANGED: "Contraseña alterada con éxito",
          PENDING_PAYMENT: "Pago pendiente eliminado exitosamente",
          RECURRING_PAYMENT: "Pago recurrente cancelado exitosamente",
          REGISTRATION_CANCELED: "Registro cancelado con éxito",
          REMOVE_MAC_ADDRESS: "Dirección MAC eliminada con éxito",
          REMOVE_PAYMENT: "Pago eliminado exitosamente",
          REMOVE_PLAN: "Plan eliminado exitosamente",
          REMOVE_TAG: "TAG eliminada exitosamente",
          RESET_PASSWORD_EMAIL:
            "Le enviamos un correo electrónico a la dirección provista con instrucciones para restablecer su contraseña",
          SCENARIO_CHANGED: "El escenario cambió con éxito",
          SCENARIO_STATUS_SWITCH: "El estado del escenario cambió exitosamente",
          SELECTED_CONTROLLERS: "Controladores seleccionados exitosamente",
          SELECTED_TAG: "TAG seleccionada exitosamente",
          SHARE_DEVICE:
            "Dispositivo compartido exitoso, espere a que se acepte la invitación",
          TOKEN_TELEGRAM_COPIED: "Token de Telegram copiado",
          UNSHARE_DEVICE: "Dispositivo no compartido con éxito",
          VALID_COUPON: "Cupón válido",
          ZIP_CODE: "Código postal ubicado",
        },
        WARNING: {
          LOADING_ERROR_COM: "Esperando respuesta del dispositivo",
          ERROR_COM: "Error al comunicarse con el dispositivo",
        },
      },
    },
  },
  pt: {
    translation: {
      BUTTON: {
        ADD: "Adicionar",
        APPLY: "Aplicar",
        ASSOCIATE: "Associar",
        CANCEL: "Cancelar",
        CLOSE: "Fechar",
        CONCLUDE: "Concluir",
        CONFIRM: "Confirmar",
        CONFIRM_CANCELLATION: "Confirmar cancelamento",
        CREATE_NEW_CONDITION: "Criar nova condição",
        DELETE: "Excluir",
        DISABLE: "Desabilitar",
        EDIT: "Editar",
        HOME_PAGE: "Página inicial",
        REACTIVATE_PLAN: "Reativar plano",
        REMOVE_NEW_CONDITION: "Remover nova condição",
        RESET_PASSWORD: "Resetar senha",
        RETURN: "Voltar",
        SAVE: "Salvar",
        SEARCH: "Pesquisar",
        SEND: "Enviar",
        SYNCHRONIZE: "Sincronização",
        UNSHARE: "Descompartilhar",
      },
      ERROR: {
        INVALID_ADDRESS: "Endereço inválido",
        INVALID_EMAIL: "Email inválido",
        MIN: "Erro devido ao setpoint mínimo",
        MAX: "Erro devido ao setpoint máximo",
        OPERATION_IN_PROGRESS: "Operação em andamento",
        PASSWORD: "A senha deve contar no mínimo 6 caracteres",
        REQUIRED_CONFIRM_NEW_PASSWORD: "As senhas não são compatíveis",
        INVALID_CHARACTERS: "Há caracteres inválidos",
        REQUIRED_FIELD: "Campo obrigatório",
        SETPOINT: "Erro no setpoint",
        VALUE_NOT_ALLOWED: "Valor não permitido",
      },
      TEXT: {
        ABLE: "Habilitado",
        ACCEPT_SHARING: "Aceitar compartilhamento",
        ACCEPT_TERMS: "Aceitar termos",
        ACCESS_CODE: "Código de Acesso",
        ACCESS_TELEGRAM: "Acesso",
        ACCESS_TELEGRAM_MESSAGE: "Acessar o Telegram pelo",
        ACCESS_TO_DATA: "Acesso ao histórico de dados",
        ACCESS_TO_SCENARIOS: "Acesso aos cenários",
        ACCESS_TO_SHARE_DEVICE: "Acesso a compartilhar dispositivos",
        ACCESSION_DATE: "Data de adesão",
        ACCOMPLISHED: "Realizado",
        ACTION: "Ação",
        ACTIONS: "Ações",
        ACTIONS_BY_SCENARIO: "Acões por cenário",
        ACTIVE: "Ativo",
        ACTIVE_SUBSCRIPTION_MESSAGE:
          "Você não possui nenhum Arcsys com assinatura ativa",
        ACCESS: "Acessar",
        ADD: "Adicionar",
        ADD_DEVICE: "Adicionar Dispositivo",
        ADD_MAC_ADDRESS: "Adicionar endereço MAC",
        ADD_PLAN: "Adicionar plano",
        ADD_SCENARIO: "Adicionar Cenário",
        ADDITIONAL_CTRLS: "Controladores Adicionais",
        ADDITIONAL_CTRLS_SUBTITLE:
          "Escolha os demais dispositivos do mesmo modelo padrão para compor o seu setor. Estes controladores serão ajustados coletivamente através do painel do setor.",
        ADDITIONAL_SETTINGS: "Configurações Adicionais",
        ADDRESS: "Endereço",
        ADDRESS_INFO: "Informações de endereço",
        ADMIN_AREA: "Área Admin",
        AFTER: "Após",
        ALARM: "Alarme",
        ALARMERROR: "Alarme/Erro",
        ALERTS_EMAIL_TITLE: "Receber email de alertas",
        ALL: "Todos",
        ALL_DEVICES: "Todos os dispositivos",
        ALL_USERS: "Todos os usuários",
        ALTER: "Alterar",
        ALTER_PLAN: "Alterar plano",
        ALTER_PLAN_MESSAGE: "Tem certeza que deseja alterar de plano",
        AMOUNT: "Valor à pagar",
        PAID_AMOUNT: "Valor pago",
        AMOUNT_COUPON: "Quantidade",
        AND: "e",
        ANYONE: "incluir",
        APPLIED_CONTROLLERS: "Controladores Associados",
        APPLY_SETPOINT_MESSAGE:
          "Ao confirmar, o setpoint de todos os controladores do setor será alterado.",
        ASCENDING_ORDER: "Ordem crescente",
        ASSIGN_ID: "Atribuir id ao userId no localStorage",
        ASSOCIATES: "Associados",
        ATTENTION: "Atenção",
        AVAILABLE: "Disponivel",
        AVAILABLE_FOR_PLATAFORMS: "Disponível para as plataformas",
        AVAILABLE_PLURAL: "Disponíveis",
        AVERAGE: "Média",
        BASIC_INFO: "Informações Básicas",
        BEGINNING_PLAN: "Início do Plano",
        BENEFITS: "Benefícios",
        BETA_PHASE_SUBTITLE_1:
          "Você está entre os primeiros a experimentar nosso novo sistema de cenários. Nesta fase, buscamos seu feedback para aprimorar a experiência e a eficácia do sistema.",
        BETA_PHASE_SUBTITLE_2:
          "Seu insight é vital e vamos utilizar suas sugestões e relatórios de problemas para melhorar nosso serviço antes do lançamento oficial.",
        BETA_PHASE_SUBTITLE_3:
          "Agradecemos por fazer parte desse momento importante e contar com a sua colaboração. Por favor, nos informe sobre qualquer problema ou sugestão através de nossa equipe de suporte.",
        BETA_PHASE_TITLE: "Bem-vindo à fase Beta!",
        BETWEEN: "entre",
        BIGGER: "maior",
        BILLET: "Boleto",
        BILLING_DETAILS: "Detalhes da cobrança",
        BILLING_TYPE: "Tipo de cobrança",
        CANCELED: "Cancelado",
        CANCEL_REGISTRATION: "Cancelar cadastro",
        CANCEL_REGISTRATION_MESSAGE:
          "Deseja realmente confirmar esta operação? A sua conta será excluída e você deverá se cadastrar novamente caso queira utilizar o sistema.",
        CHANGE_CONDITION: "Alterar condição",
        CHANGE_HISTORY: "Histórico de alterações",
        CHANGE_HISTORY_MESSAGE:
          "Para restaurar uma versão das modificações, clique em uma data e aplique a alteração.",
        CHANGE_PASSWORD: "Alterar senha",
        CHANGE_PLAN: "Trocar Plano",
        CHANGE_PLAN_SUBTITLE: "Será alterado para o plano",
        CHANGING_CTRL_PARAM: "Alteração de parâmetros de controladores",
        CHARGE_MORE_DEVICES: "Carregar mais dispositivos",
        CHECK_EMAIL: "Verifique seu email",
        CHOOSE: "Escolher",
        CITY: "Cidade",
        CLEAN: "Limpar",
        CLIENT: "Cliente",
        CLOSE_CONTROLLER_LIST: "Fechar lista de controladores",
        CODE: "Código",
        COMMANDS: "Comandos",
        COMMANDS_COLLECTIVE: "Comandos coletivos",
        COMMUNICATION_FAILURE: "Alerta de Falha de Comunicação",
        COMMUNICATION_FAILURE_INFO:
          "Após a configuração do Alerta de Falha de Comunicação, é necessário habilitar o",
        COMPANY: "Empresa",
        COMPLEMENT: "Complemento",
        CONDITION_SATISFIED: "Condição satisfeita",
        CONDITION: "Condição",
        CONDITIONALS_BY_SCENARIO: "Condicionais por cenário",
        CONDITIONS: "Condições",
        CONFIRM: "Confirme",
        CONFIRM_CANCELATION: "Confirmar cancelamento",
        CONFIRM_CANCELATION_MESSAGE:
          "Você tem certeza de que deseja cancelar a assinatura do dispositivo",
        CONFIRM_CANCELATION_MESSAGE_2:
          "Após o cancelamento, o plano permanecerá ativo até o final do período contratado.",
        CONFIRM_CANCELATION_MESSAGE_TWO:
          "Não se preocupe! Você poderá reativá-lo a qualquer momento!",
        CONFIRM_PASSWORD: "Confirmar senha",
        CONFIRM_SYNC: "Confirmar Sincronização",
        CONFIRM_SYNC_MESSAGE: "Tem certeza que deseja sincronizar o setor",
        CONTINUOUS_SHOOTING: "Disparo contínuo",
        CONTROL: "Controle",
        CONTROL_MESSAGE:
          "Você está sempre no comando. Gerencie ou cancele sua assinatura a qualquer momento no plano do seu dispositivo.",
        CONTROLLER: "Controlador",
        CONTROLLERS: "Controladores",
        COPY: "Copiar",
        COUPON_MESSAGE: "Adicionar cupom de desconto",
        COUPON: "Cupom",
        COUPONS: "Cupons",
        COUPON_CODE: "Código do cupom",
        COUNTRY: "País",
        COURTESY: "Cortesia",
        CREATE_ACCOUNT: "Criar conta",
        CREATE_ARCSYS_ACCOUNT: "Crie sua conta no ArcSys Cloud",
        CREATE_PASSWORD: "Criar senha",
        CREATE_TAG: "Criar TAG",
        CREATION: "Criação",
        CREATION_DATE: "Data de criação",
        CREDIT_CARD: "Cartão de crédito",
        CURRENT: "Atual",
        CURRENT_PASSWORD: "Senha atual",
        CURRENT_PLAN: "Plano atual",
        CURRENT_PLAN_SUBTITLE: "O seu plano atual é",
        DAILY_SHOT: "Disparo diário",
        DATA_GRAPH_24H:
          "Registro de dados das últimas 24 horas (amostragem de 5 minutos)",
        DATE: "Data",
        DATE_OF_BIRTH: "Data de nascimento",
        DAYS: "Dias",
        DEBIT: "Débito",
        DESCENDING_ORDER: "Ordem decrescente",
        DEFINING_CONDITIONS: "Definição de Condições",
        DEFINITION_ACTIONS: "Definição de Ações",
        DELETED: "Deletado",
        DELETE_CONTROLLER: "Excluir Controlador",
        DELETE_CONTROLLER_MESSAGE:
          "Tem certeza que deseja excluir o controlador",
        DELETE_COUPON_MESSAGE: "Tem certeza que deseja remover o cupom",
        DELETE_DEVICE_TITLE: "Excluir Dispositivo",
        DELETE_DEVICE_MESSAGE: "Tem certeza que deseja excluir o dispositivo",
        DELETE_PENDING_PAYMENT: "Excluir pagamento",
        DELETE_PENDING_PAYMENT_MESSAGE:
          "Tem certeza que deseja excluir este pagamento pendente",
        DELETE_PENDING_PAYMENT_MESSAGE_WITH_MAC:
          "Tem certeza que deseja excluir o pagamento do dispositivo MAC",
        DELETE_SCENARIO: "Excluir cenário",
        DELETE_SECTOR: "Remover setor",
        DELETE_SECTOR_MESSAGE: "Tem certeza que deseja remover o setor",
        DELETE_SCENARIO_MESSAGE: "Tem certeza que deseja excluir o cenário",
        DELETE_TAG_MESSAGE: "Tem certeza que deseja excluir a TAG",
        DELETE_USER_MESSAGE: "Tem certeza que deseja excluir o usuário",
        DESCRIPTION: "Descrição",
        DEVICE: "Dispositivo",
        DEVICE_CONNECTION: "Conexão do Dispositivo",
        DEVICE_ERROR: "Dispositivo com erro",
        DEVICE_NAME: "Nome do Dispositivo",
        DEVICE_NOT_AVAILABLE: "Dispositivo não disponível",
        DEVICE_SHARING: "Compartilhamento de dispositivos",
        DEVICE_REGISTRATION: "Cadastro do Dispositivo",
        DEVICES: "Dispositivos",
        DEVICES_ERROR: "Dispositivos com erro",
        DEVICES_OFFLINE: "Dispositivos offline",
        DEVICES_ONLINE: "Dispositivos online",
        DEVICE_USERS: "Usuários do Dispositivo",
        DIFFERENT: "diferente",
        DISABLE_ENABLE: "Desativar / Ativar",
        DISABLE_AFTER_ERROR: "Desabilitar após erro",
        DISABLE_AFTER_ERROR_MESSAGE:
          "Defina se o cenário será desabilitado após um erro na execução.",
        DISABLED: "Desativado",
        DISCOUNT_AMOUNT: "Valor do desconto",
        DUE_DATE: "Vencimento",
        EDIT: "Editar",
        EDIT_DEVICE: "Editar Dispositivo",
        EDIT_SCENARIO: "Editar cenário",
        EMAIL: "Email",
        EMAIL_ALERTS: "Envio de alertas",
        EMPTY_SCENARIOS: "Nenhum cenário cadastrado",
        EMPTY_SECTOR: "Nenhum setor cadastrado",
        EMPTY_TABLE: "Nenhum dispositivo cadastrado",
        EMPTY_USER_TABLE: "Nenhum usuário encontrado",
        END_DATE: "Data de fim",
        ENABLE_SCENARIO: "Habilitar cenário",
        ENTER_MAC: "Insira o endereço MAC",
        ENTER_PASSWORD: "Para confirmar digite sua senha abaixo",
        EQUAL: "igual",
        ERROR: "Erro",
        EXAMPLE: "Exemplo",
        EXCLUSIVE_FOR_WEBFI:
          "Exclusivo para WebFi com versão de firmware igual ou superior a 2.3",
        EXECUTE_ALL_CONDITION: "Todas as condições sejam satisfeitas",
        EXECUTE_ALL_CONDITION_MESSAGE:
          "Para que uma ação seja tomada, todas as condições listadas devem ser atendidas simultaneamente.",
        EXECUTE_ANY_CONDITION: "Qualquer condição seja satisfeita",
        EXECUTE_ANY_CONDITION_MESSAGE:
          "A ação será tomada se pelo menos uma das condições listadas for atendida, independentemente das outras.",
        EXPAND: "Expandir",
        EXPIRATION: "Expiração",
        EXPIRATION_DATE: "Data de expiração",
        EXPIRED: "Vencido",
        EXTRA_INFO: "Informações extras",
        FAILURE: "Falha",
        FIRING_FREQUENCY: "Frequência de Disparo",
        FIRING_FREQUENCY_QUESTION_ONE:
          "Defina se o cenário será acionado apenas uma vez",
        FIRING_FREQUENCY_QUESTION_TWO: "ou repetidamente",
        FIRING_FREQUENCY_QUESTION_THREE:
          " O cenário será desabilitado após a execução do disparo único.",
        FOR_MAC: "por MAC",
        FORGOT_PASSWORD: "Esqueceu a senha",
        FORMER_ADDRESS: "Controlador anterior do endereço",
        FREE: "Grátis",
        FREQUENCY_INVERTERS: "Inversores de frequência",
        FREQUENCY_INVERTERS_MESSAGE:
          "Certifique-se que todos os inversores do setor estão em estado OFF. Em operação, os inversores não permitem a alteração de parâmetros, sendo assim, a sincronização irá falhar.",
        FREQUENCY_INVERTERS_MESSAGE_2:
          "Caso o setor não contenha inversores, desconsidere essa mensagem e",
        FRIDAY: "Sexta",
        FUNCTIONALITIES: "Suas funcionalidades",
        GENERAL: "Geral",
        GENERAL_INFO: "Informações gerais",
        GENERATE_SUBS_REPORT: "Gerar relatório de assinatura",
        GENERATE_REPORT: "Gerar relatório",
        GRAPH: "Gráfico",
        HEATING: "Aquecimento",
        HIDE: "Ocultar",
        HISTORIC: "Histórico",
        HOUR: "Hora",
        HOURS: "Horas",
        HOW_CONDITIONS_WORK: "Como as condições são avaliadas",
        HYSTERESIS: "Histerese",
        IDENTIFICATION: "Identificação",
        IDENTIFIER: "Identificador",
        IF: "Se",
        IMPORTANT: "Importante",
        IMPORTANT_MESSAGE:
          "Em caso de falhas neste sensor, o setor poderá operar normalmente. O sensor de monitoramento tem a função de ser apenas uma referência às condições do setor, e não apresenta funções de controle.",
        INACTIVE: "Inativo",
        INCLUDE_IN_REPORT: "Incluir no relatório",
        INCLUDE_FREE_SUBS: "Incluir subs grátis",
        INCLUSION_DATE: "Data de inclusão",
        INCOMPATIBLE_MODEL: "Cenário incompatível com o dispositivo atual",
        INFORM_THE_TIME: "Informe o horário",
        INITIAL: "Inicial",
        INITIAL_DATA: "Dados iniciais",
        INITIAL_VALUE: "Valor inicial",
        INSTRUCTIONS: "Instruções",
        INTERVAL: "Intervalo",
        INTERVAL_BETWEEN_SHOTS: "Intervalo entre disparos",
        INTERVAL_BETWEEN_SHOTS_QUESTION:
          "Defina o intervalo mínimo de tempo entre acionamentos consecutivos do cenário no modo de disparo contínuo. Isso evita que o cenário seja acionado repetidamente em um curto período.",
        INVOICES: "Faturas",
        IS: "for",
        LABEL: "Rotulo",
        LAST_RECORDED_EVENT: "Último evento registrado",
        LAST_RUN_STATUS: "Status da última execução",
        LEGAL_NAME: "Razão social",
        LEGAL_PERSON: "Pessoa jurídica",
        LICENSE_TERMS: "Termos de licença de uso",
        LIMITED_QUANTITY: "Quantidade Limitada",
        LIMITS: "Limites",
        LINK_DEVICE: "Vincular Dispositivo",
        LOCK: "Bloquear",
        LOCK_CHANGES: "Bloquear alterações",
        LOCK_CHANGES_MESSAGE:
          "Tem certeza que deseja bloquear alterações do setor",
        LOGIN: "Entrar",
        LOGOUT: "Sair",
        MAC_ADDRESS: "Endereço MAC",
        MAC_ADDRESS_INFO_TITLE:
          "O endereço MAC do seu produto WebFi ou WebFi Pro pode ser encontrando",
        MAC_ADDRESS_INFO_ONE:
          "Impresso na parte inferior do gabinete do produto.",
        MAC_ADDRESS_INFO_TWO:
          "Na página de configurações do produto, no menu Configurações Básicas, item ARCSYS CLOUD.",
        MAC_NAME: "MAC do Dispositivo",
        MACS_ADDRESS: "Endereços MAC",
        MAIN_SENSOR: "Sensor de Monitoramento",
        MAIN_SENSOR_WHAT: "O que é o Sensor de Monitoramento",
        MAIN_SENSOR_WHAT_SUBTITLE:
          "O Sensor de Monitoramento é responsável por fornecer leituras de dados em tempo real. Ele é a fonte das informações exibidas no painel do setor.",
        MAIN_SENSOR_WHY: "Por que selecioná-lo",
        MAIN_SENSOR_WHY_TITLE:
          "Escolher um Sensor de Monitoramento adequado é essencial",
        MANAGE: "Administrar",
        MAXIMUM: "Máximo",
        MAXIMUM_AND_MIN_VALUES: "Valores do",
        MAXIMUM_AND_MIN_VALUES_MESSAGE:
          "Defina os limites dentro dos quais o setpoint pode ser ajustado no painel do setor. Estes limites previnem ajustes fora de um intervalo seguro ou eficiente para as operações do setor.",
        MAXIMUM_CONDITIONS_ACTIONS: "Máximo de ações por cenário",
        MAXIMUM_CONDITIONS_SCENARIO: "Máximo de condições por cenário",
        MAXIMUM_SCENARIOS_CTRL: "Máximo de cenários por controlador",
        MAX_TIME: "Horário de máximo",
        MESSAGE: "Mensagem",
        MIN_TIME: "Horário de mínimo",
        MINIMIZE_MAXIMIZE: "Minimizar / Maximizar",
        MINIMUM: "Mínimo",
        MINIMUM_INTERVAL_SHOTS: "Intervalo mínimo entre disparos",
        MINUTES: "Minutos",
        MONDAY: "Segunda",
        MODEL: "Modelo",
        MODELS: "Modelos",
        MODEL_NOT_FOUND: "Modelo não encontrado",
        MONITORING: "Monitoramento",
        MONTHS: "Meses",
        MONTH: "Mês",
        NAME: "Nome",
        NEIGHBORHOOD: "Bairro",
        NEXT: "Próximo",
        NEW_PASSWORD: "Nova senha",
        NO: "Não",
        NO_BENEFITS: "Você não possui nenhum benefício",
        N0_CONDITIONS: "Nenhuma condição encontrada",
        NO_CONTROLLER_FOUND: "Nenhum controlador encontrado",
        NO_CONTROLLER_SYNC: "Sincronização não realizada",
        NO_DEVICES: "Nenhum dispositivo",
        NO_DEVICES_AVAILABLE: "Nenhum dispositivo disponível",
        NO_DEVICES_ONLINE_TITLE: "Nenhum dispositivo conectado",
        NO_HISTORIC: "Nenhum registro",
        NO_MAC_ADDRESS: "Nenhum dispositivo disponível",
        NO_PAYMENTS: "Nenhum pagamento efetuado",
        NO_PAYMENTS_OUTSTANDING: "Nenhum pagamento pendente",
        NO_SCENARIOS: "Nenhum cenário disponível",
        NO_STATE_SELECTED: "Nenhum estado selecionado",
        NO_SIGNATURE: "Nenhuma assinatura",
        NO_TAG_AVAILABLE: "Nenhuma TAG disponível",
        NO_TAG_SELECTED: "Nenhuma TAG selecionada",
        NO_USER: "Nenhum usuário",
        NOT_VISIBLE: "Não Visível",
        NUMBER: "Número",
        NUMBER_COUPONS: "Quantidade de cupons",
        NUMBER_OF_COUPONS: "Quantidade inicial de cupons",
        OF: "De",
        OF_THE_DEVICE_IN: "do dispositivo no",
        OFFLINE_DEVICE: "Dispositivo offline",
        OFFLINE_DEVICES: "Dispositivos offline",
        ONCE_A_DAY: "uma vez por dia",
        OPERATOR: "Operador",
        OR: "ou",
        OUTSTANDING_PAYMENTS: "Pagamentos pendentes",
        OWNER: "Proprietário",
        PAGE_NOT_FOUND_MESSAGE: "Ocorreu um erro ou esta página não existe.",
        PAGE_NOT_FOUND_TITLE: "Oops, desculpe, página não encontrada!",
        PAID: "Pago",
        PASSWORD: "Senha",
        PARAM: "Parâmetro",
        PARAMETER_CHANGE: "Alteração de parâmetro",
        PARAMS: "Parâmetros",
        PAY_DAY: "Data e hora do pagamento",
        PAYMENT: "Pagamento",
        PAYMENT_HISTORY: "Histórico de pagamentos",
        PAYMENT_LINK: "Link do pagamento",
        PAYMENT_METHODS: "Formas de pagamento",
        PERMISSION: "Permissão",
        PERMISSIONS: "Permissões",
        PENDING: "Pendente",
        PHONE: "Telefone",
        PHYSICAL_PERSON: "Pessoa física",
        PLAN: "Plano",
        PLAN_DETAILS: "Detalhes do Plano",
        PLAN_DURATION: "Duração do Plano (meses)",
        PLAN_POSITION: "Posição do Plano",
        PLANS: "Planos",
        PLANS_AND_SUBSCRIPTIONS: "Planos e Assinaturas",
        PLANS_AVAILABLE: "Planos disponíveis",
        PLANS_CONTRACT_TERMS_SUBTITLE_1: "Benefícios e Permissões",
        PLANS_CONTRACT_TERMS_SUBTITLE_2:
          "Política de Dados e Expiração do Plano",
        PLANS_CONTRACT_TERMS_SUBTITLE_3: "Disposições Gerais",
        PLANS_CONTRACT_TERMS_TITLE: "Termos de contrato do Plano",
        PLANS_TERMS_CHECKBOX:
          "Sim, eu li e aceito os Termos de contrato do Plano.",
        PLANS_PERMISSION_1_SUBTITLE:
          "o assinante terá direito aos seguintes benefícios e permissões",
        PLANS_PERMISSION_2_SUBTITLE_1: "Período de Carência",
        PLANS_PERMISSION_2_SUBTITLE_2: "Notificações",
        PLANS_PERMISSION_2_SUBTITLE_3: "Transição para o Plano Gratuito",
        PLANS_PERMISSION_2_SUBTITLE_4: "Política de Retenção de Dados",
        PLANS_PERMISSION_2_SUBTITLE_5: "Oferta de Backup de Dados",
        PLANS_PERMISSION_2_SUBTITLE_6: "Exclusão de Dados",
        PLANS_PERMISSION_2_TEXT_1:
          "O assinante entrará em um período de carência de 10 dias após a expiração do plano. Durante este tempo, o assinante manterá acesso às funcionalidades do plano expirado. Lembretes de notificação serão emitidos no início, meio e fim do período de carência.",
        PLANS_PERMISSION_2_TEXT_2:
          "O assinante será notificados por e-mail 7 dias antes da data de expiração, na data de expiração e periodicamente durante o período de carência. Estas notificações incluirão instruções para renovação do plano e as implicações da não renovação.",
        PLANS_PERMISSION_2_TEXT_3:
          "Caso a renovação não seja efetuada até o final do período de carência, o assinante passará automaticamente para o plano gratuito. Esta transição implicará uma redução nas funcionalidades, conforme delineado na matriz de comparação dos planos.",
        PLANS_PERMISSION_2_TEXT_4:
          "Após a transição para o plano gratuito, os dados e medições históricas dos dispositivos relacionadas às funcionalidades premium serão retidos por mais 20 dias antes da exclusão. O assinante terá a oportunidade de exportar ou fazer backup de seus dados dentro deste prazo.",
        PLANS_PERMISSION_2_TEXT_5:
          "Orientações sobre a exportação ou backup de dados serão fornecidas durante o período de carência. Incentiva-se o assinante a fazerem backup de dados críticos para evitar perda.",
        PLANS_PERMISSION_2_TEXT_6:
          "Após o período de retenção, os dados associados às funcionalidades premium serão permanentemente excluídos. Uma notificação final será emitida 7 dias antes da exclusão dos dados.",
        PLANS_PERMISSION_3_TEXT:
          "Todas as ações tomadas estarão em conformidade com a legislação aplicável de proteção de dados e privacidade. Esta política está sujeita a alterações, e os assinante será devidamente informado sobre quaisquer mudanças significativas.",
        PLAN_EXPIRED: "Plano vencido ou prestes a expirar",
        PLAN_EXPIRE_MSG: "Há planos prestes a vencer",
        PREFERENCES: "Preferencias",
        PREVIOUS: "Anterior",
        PRINT: "Imprimir",
        PROFILE: "Perfil",
        RECORDING_INTERVAL: "Intervalo de gravação",
        RECORDS: "Registros",
        RECOVER_ACCESS: "Recuperar acesso",
        RECOVER_ACCESS_MESSAGE:
          "Insira sua nova senha. Logo após a redefinição, você terá acesso ao ArcSys Cloud",
        RECURRENCE: "Recorrência (meses)",
        RECURRENCES: "Recorrências",
        RECURRENT: "Recorrente",
        RECURRING_SUBSCRIPTION: "Assinatura recorrente",
        RECURRING_SUBSCRIPTION_MODAL_SUBTITLE:
          "Com a nossa opção de pagamento recorrente, você está garantindo um serviço contínuo. Veja como isso beneficia você",
        REFERENCE_CONTROLLER: "Controlador Referência",
        REFERENCE_OFFLINE: "Referência offline",
        REFRIGERATION: "Refrigeração",
        REGISTERED: "Cadastrado",
        REGISTERED_AT: "Cadastrado em",
        REGISTERED_MESSAGE:
          "Insira seu e-mail cadastrado para recuperar o acesso ao ArcSys Cloud",
        REGISTRATION_DATE: "Data de registro",
        RELATIONSHIP_CONDITIONS: "Relação entre as condições",
        REMANING: "Restante",
        REMANING_QUANTITY_COUPONS: "Quantidade restante de cupons",
        REMOVE: "Remover",
        REMOVE_APPLIED_CTRLS: "Limpar Controladores Associados",
        REMOVE_APPLIED_CTRLS_MESSAGE:
          "Tem certeza que deseja limpar os Controladores Associados",
        REMOVE_ASSOCIATED_CTRL: "Remover Controlador Associado",
        REMOVE_ASSOCIATED_CTRL_MESSAGE:
          "Tem certeza que deseja remover o Controlador Associado",
        REMOVE_DEVICE: "Remover Dispositivo",
        REMOVE_HISTORIC_MESSAGE:
          "Tem certeza que deseja remover o item do histórico do dia",
        REMOVE_MAC_ADDRESS: "Remover endereço MAC",
        REMOVE_MAC_ADDRESS_MESSAGE:
          "Você tem certeza que deseja remover o endereço MAC",
        REMOVE_PLAN_MESSAGE: "Tem certeza que deseja remover o plano",
        REMOVE_TAG: "Tem certeza que deseja remover a TAG",
        RENEWAL_DATE: "Data de renovação",
        RENEW_SUBSCRIPTION: "Renovar Assinatura",
        REPORT: "Relatório",
        REPORT_DATA: "Dados do Relatório",
        REPORT_MESSAGE: "Selecione o período de visualização",
        REPORTS: "Relatórios",
        RESTORE_VERSION: "Restaurar versão",
        RESTORE_VERSION_MESSAGE:
          "Tem certeza que deseja restaurar a antiga versão dos modelos",
        RESTORE_VERSION_TRANSLATIONS_MESSAGE:
          "Tem certeza que deseja restaurar a antiga versão das traduções",
        RETYPE_NEW_PASSWORD: "Digite novamente a nova senha",
        SAMPLING_RATE: "Taxa de amostragem",
        SATURDAY: "Sábado",
        SAVE_AND_SYNC: "Salvar e Sincronizar",
        SAVE_AND_SYNC_MESSAGE_0: "Sincronização",
        SAVE_AND_SYNC_MESSAGE:
          "Uma rotina de sincronização ajustará os valores configurados no setor em cada controlador/inversor. Os valores ajustados serão:",
        SAVE_AND_SYNC_MESSAGE_1:
          "Ao final do processo, monitore o painel do setor para quaisquer sinais de erro.",
        SAVE_AND_SYNC_MESSAGE_1_IMPORTANT:
          "A sincronização não irá alterar o estado dos dispositivos, apenas os parâmetros relacionados ao setpoint.",
        SAVE_AND_SYNC_MESSAGE_2: "Verificação de Erro",
        SAVE_AND_SYNC_MESSAGE_3:
          "Em caso de erros indicados no painel, acesse o painel de controladores do setor para identificar e corrigir discrepâncias nos valores de setpoint.",
        SAVE_MODEL_MESSAGE:
          "Tem certeza que deseja salvar as novas alterações nos modelos",
        SAVE_TIME: "Economize Tempo",
        SAVE_TIME_MESSAGE:
          "Configure uma vez e nunca mais se preocupe com o processo de pagamento. Valorizamos o seu tempo tanto quanto você.",
        SCENARIOS_BY_CTRL: "Cenários por controlador",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_1:
          "Onde você encontra uma visão geral dos seus dispositivos.",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_2:
          "Navegue até um dos dispositivos que deseja configurar.",
        SCENARIOS_EMPTY_SUBTITLE_MESSAGE_3:
          "Na página do dispositivo, procure pela opção 'Adicionar Cenário' e comece a personalizar.",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_1: "Acesse o Dashboard",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_2: "Selecione um Controlador/Inversor",
        SCENARIOS_EMPTY_SUBTITLE_TITLE_3: "Crie seu Cenário",
        SCENARIOS_EMPTY_TITLE:
          "Para iniciar e criar seu primeiro cenário com facilidade, siga estes passos simples:",
        SCENARIO_FAILURE: "Falha no cenário",
        SCENARIO_NAME: "Nome do cenário",
        SCENARIO_PARAMS: "Parâmetros do cenário",
        SCENARIOS: "Cenários",
        SCENARIOS_IDENTIFICATION: "Identificação do Cenário",
        SCENARIOS_STATUS: "Status do Cenário",
        SCENARIOS_STATUS_QUESTION_ONE: "Defina se o cenário estará",
        SCENARIOS_STATUS_QUESTION_TWO:
          "assim que for criado ou se começará como",
        SCENARIOS_SELECT_DAYS:
          "Defina os dias da semana que o cenário pode ser executado.",
        SCENARIOS_SUMMARY: "Resumo do cenário",
        SEARCH_CONFIG: "Pesquisar configuração",
        SEARCH_COUPONS: "Pesquisar Cupons",
        SEARCH_DEVICE: "Pesquisar Dispositivo",
        SEARCH_MAC: "Pesquisar Endereço MAC",
        SEARCH_PAYMENTS: "Pesquisar pagamentos",
        SEARCH_PLANS: "Pesquisar Planos",
        SEARCH_SCENARIO: "Pesquisar Cenário",
        SEARCH_USER: "Pesquisar Usuário",
        SECONDS: "Segundos",
        SECTOR: "Setor",
        SECTOR_CONTROLLERS: "Controladores do Setor",
        SECTOR_MODEL: "Modelo do setor",
        SECTOR_REFERENCE: "Modelo de monitoramento",
        SECTOR_SETPOINT: "Setpoint do Setor",
        SECTOR_SYNC_FAILED: "Falha na sincronização do setor",
        SECTORS: "Setores",
        SEE_ALL_BENEFITS: "Ver todos os benefícios",
        SELECT: "Selecionar",
        SELECT_ALL: "Selecionar todos",
        SELECT_DAYS: "Dias da Semana",
        SELECT_FIRST_CTRL: "Escolha o Controlador Padrão",
        SELECT_PERIOD: "Selecionar período",
        SELECT_CONTROLLER: "Selecione o Controlador",
        SELECT_CONTROLLERS: "Selecione os Controladores",
        SELECT_DEVICE: "Selecione o Dispositivo",
        SELECT_DEVICES: "Selecione os Dispositivos",
        SELECT_DOC: "Documento e contato",
        SELECT_MAC: "Selecione o endereço MAC",
        SELECT_MAIN_SENSOR: "Selecione o Sensor de Monitoramento do setor",
        SELECT_MAIN_SENSOR_HOW: "Como selecionar o Sensor de Monitoramento",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_1:
          "Opte por um sensor que tenha um histórico de fornecimento de dados precisos e estáveis.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_2:
          "O sensor deve estar em um local que melhor reflita as condições ambientais gerais do setor.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_3:
          "Garanta que o sensor escolhido seja capaz de manter uma conexão consistente e esteja indicado como 'ONLINE'.",
        SELECT_MAIN_SENSOR_HOW_MESSAGE_4:
          "Lembre-se, a correta seleção do Sensor de Monitoramento é um passo importante para o monitoramento efetivo e a gestão do seu setor.",
        SELECT_MODEL: "Selecione o modelo (opcional)",
        SELECT_REFERENCE: "Selecionar Referência",
        SELECT_SETPOINT_MESSAGE:
          "Escolha o tipo de setpoint que deseja ajustar para este setor. Certifique-se de que o controlador está no modo operacional correspondente ao tipo de setpoint selecionado para garantir a aplicação correta dos valores.",
        SELECT_SETPOINT_MESSAGE_2:
          "Por exemplo: Ao escolher um setpoint específico, como o 'Set Point Eco', confirme que o controlador está em modo econômico.",
        SELECT_SETPOINT_MESSAGE_3:
          "Setpoints selecionados incorretamente para o modo de operação do controlador não serão respeitados no setor.",
        SELECT_SECOUND_CTRL: "Inclua Controladores Adicionais",
        SELECT_SECTOR_CTRLS: "Selecionar Controladores do Setor",
        SELECT_TAG: "Selecionar TAG",
        SELECT_TYPE: "Selecione o tipo",
        SEND_EMAIL_TO: "Enviamos um email para",
        SEND_EMAIL_INSTRUCTIONS: "com as instruções de como ativar a sua conta",
        SENDING_EMAIL_ALERTS: "Envio de alertas por email",
        SETPOINT_CHANGE: "Alteração de setpoint",
        SETPOINT_INITIAL_VALUE_MESSAGE:
          "Informe o valor inicial para o setpoint. Este será o valor de referência quando o setor for iniciado e deve estar dentro dos limites máximos e mínimos definidos.",
        SETPOINT_MAX: "Setpoint máximo",
        SETPOINT_MIN: "Setpoint mínimo",
        SETPOINT_PARAM: "Parâmetros do Setpoint",
        SETTINGS: "Configurações",
        SETTINGS_ALERT_DEFAULT: "Tempo padrão para reenvio de alerta",
        SETTINGS_DEFAULT_DATA: "Intervalo padrão de gravação de dados",
        SETTINGS_EMAIL_MESSAGE: "Email para solicitação de planos",
        SETTINGS_MAX_DATA_INTERVAL: "Intervalo máximo de gravação de dados",
        SETTINGS_MAX_USERS: "Máximo de usuários por dispositivo",
        SETTINGS_TIME_CTRL_DASHBOARD:
          "Tempo para esconder controlador do dashboard",
        SETTINGS_TIME_CTRL_INACTIVE: "Tempo para controlador ficar inativo",
        SETTINGS_TIME_DEVICE_OFFLINE: "Tempo para dispositivo ficar offline",
        SETTINGS_TIME_MQTT: "Tempo entre alertas de MQTT Broker offline",
        SHARE_DEVICE: "Compartilhar Dispositivo",
        SHARED: "Compartilhado",
        SHARED_DEVICE: "Dispositivo compartilhado",
        SHARED_USERS: "Usuários compartilhados",
        SHOW: "Mostrar",
        SHOW_OFFLINE_DEVICES: "Mostrar controladores offline no dashboard",
        SIGN_HERE: "Assine aqui",
        SINGLE_OR_CONTINUOUS: "Disparo único ou contínuo",
        SINGLE_PAYMENTS: "Pagamentos único",
        SINGLE_SHOT: "Disparo único",
        SMALLER: "menor",
        STANDARD_CTRL: "Controlador Padrão",
        STANDARD_CTRL_SUBTITLE:
          "O Controlador Padrão estabelece o modelo para os demais controladores do setor, assegurando uniformidade e compatibilidade para operações.",
        START: "Inicio",
        START_DATE: "Data do início",
        STATE: "estado",
        STATE_INSC: "Inscrição estadual",
        STATE_USER_REGISTRATION: "Estado",
        STICKY_NOTES: "Lembretes",
        STICKY_NOTES_MESSAGE:
          "Enviaremos uma notificação antes de cada pagamento, para que não haja surpresas.",
        STREET: "Rua",
        SUBMIT_ACTION: "Enviar comando",
        SUBMIT_ACTION_COLLECTIVE: "Enviar comando coletivo",
        SUBMIT_ACTION_COLLECTIVE_MESSAGE:
          "Tem certeza que deseja enviar o comando coletivo",
        SUBMIT_ACTION_MESSAGE: "Tem certeza que deseja enviar o comando",
        SUBSCRIBE_TO_A_PLAN: "Assinar um plano",
        SUBSCRIPTIONS_REPORT: "Relatório de assinaturas",
        SUCCESS: "Sucesso",
        SUNDAY: "Domingo",
        SURNAME: "Sobrenome",
        SUSPENDED: "Suspensos",
        SUSPENDED_SINGULAR: "Suspenso",
        SYNC: "sincronize",
        SYNC_ADJUST: "A sincronização fará o ajuste nos seguintes parâmetros",
        SYNC_COMPLETE: "Sincronização concluída",
        SYNC_SECTOR: "Sincronizar Setor",
        SYNC_IN_PROGRESS: "Sincronização em andamento",
        SYNCHRONIZED_CTRLS: "Controladores Sincronizados",
        SYSTEM: "Sistema",
        SYSTEM_REGISTER: "Cadastramos você em nosso sistema",
        TAG_FILTER: "Filtrar TAGs",
        TAG_MODAL_TITLE: "O que são TAGs e para que servem?",
        TAG_MODAL_SUBTITLE_DASHBOARD:
          "Após estas etapas, você poderá filtrar os controladores atribuidos a TAGs.",
        TAG_MODAL_TITLE_DASHBOARD: "Não há nenhuma TAG criada.",
        TAG_MODAL_STEP_SUBTITLE:
          "Acessar a página de um dos dispositivos que deseja atribuir uma TAG.",
        TAG_MODAL_STEP_SUBTITLE_2:
          "Ir até a tabela de parâmetros e criar ou selecionar a TAG já criada no sistema.",
        TAG_MODAL_STEP_SUBTITLE_3:
          "Após estas etapas, você poderá filtrar os controladores atribuidos a TAGs, selecionando na parte superior da página Dashboard.",
        TAG_MODAL_STEP_TITLE:
          "Para você atribuir uma TAG a um controlador, você deve",
        TAG_MODAL_SUBTITLE:
          "As TAGs são etiquetas que servem para classificar e organizar os controladores. Com isso, você poderá encontrar controladores dentre outros que estejam ligados as informações da TAG.",
        TAKE_ACTION: "Executar ação",
        TAKE_ACTION_COMMAND: "Executar comando",
        TELEGRAM_BOT_LINK: "link do bot",
        TELEGRAM_BOT_TITLE: "Instruções para a utilização do bot Telegram",
        TELEGRAM_INSTRUCTIONS:
          "Seguir as instruções apresentadas no chat do Bot.",
        TELEGRAM_MESSAGE: "Mensagem do Telegram",
        TERMS_CHECKBOX_MESSAGE: "Sim, eu li e aceito os Termos de Uso.",
        THAN: "que",
        THAW: "Degelo",
        THE: "o",
        THURSDAY: "Quinta",
        TIME: "horário",
        TIME_END: "Hora final",
        TIME_START: "Horário inicial",
        TIME_AFTER: "após",
        TIME_BEFORE: "antes",
        TIME_DAS: "das",
        TO: "a",
        TO_EXPIRE: "À vencer",
        TOKEN_TELEGRAM: "Token Telegram",
        TOPICS: "Tópicos",
        TRANSLATIONS: "Traduções",
        TRUSTWORTHY_REPRESENTATION: "Representação Fidedigna",
        TRUSTWORTHY_REPRESENTATION_MESSAGE:
          "Ao escolher um sensor bem posicionado e confiável, você assegura que os dados exibidos representam adequadamente as condições atuais do setor.",
        TUESDAY: "Terça",
        TYPE: "Tipo",
        TYPE_OF_ACTION: "Tipo de Ação",
        UNDER_THE_PLAN: "Sob o plano",
        UNDETERMINED: "Indeterminada",
        UNINTERRUPTED_SERVICE: "Serviço Ininterrupto",
        UNINTERRUPTED_SERVICE_MESSAGE:
          "permanecem ativas sem interrupções. Esqueça as renovações manuais, cuidamos de tudo para você.",
        UNLIMITED: "Ilimitado",
        UNLOCK: "Desbloquear",
        UNLOCK_CHANGES: "Desbloquear alterações",
        UNSHARE_DEVICE_TITLE: "Descompartilhar o dispositivo",
        UNSHARE_DEVICE_MESSAGE:
          "Tem certeza que deseja descompartilhar o dispositivo",
        UNSUBSCRIBE: "Cancelar assinatura",
        UPDATE: "Atualizar",
        UPDATED_DATA: "Dados Atualizados",
        UPDATED_DATA_MESSAGE:
          "As leituras de temperatura e outras demais medidas no painel do setor são atualizadas continuamente, refletindo as informações mais recentes fornecidas por este sensor.",
        UPLOAD_JSON: "Importar JSON",
        USER: "Usuário",
        USER_MANUAL: "Manual do usuário",
        USER_PROFILE: "perfil do usuário",
        USERS: "Usuários",
        USERNAME: "Nome do usuario",
        VALIDATE_MAC_ADDRESSES: "Validar endereços MAC",
        VALUE: "Valor",
        VIEW: "Visualizar",
        VISIBILITY: "Visibilidade",
        VISIBLE: "Visível",
        WEEKS: "Semanas",
        WELCOME: "Boas vindas",
        WECOME_MESSAGE_ONE: "Seja bem vindo!",
        WECOME_MESSAGE_TWO: "Obrigado por criar sua conta no ArcSys Cloud",
        WECOME_MESSAGE_THREE:
          "Para que seu dispositivo se comunique com o ArcSys Cloud é necessário terminar o processo de configuração.",
        WECOME_MESSAGE_FOUR:
          "Foi enviado o email 'Conexão com o Dispositivo' que contém um código de acesso que deve ser configurado no seu aparelho.",
        WECOME_MESSAGE_FIVE:
          "Após a configuração, os dados do seu dispositivo irão aparecer automaticamente em nosso sistema.",
        WEDNESDAY: "Quarta",
        YES: "Sim",
        ZIP_CODE: "CEP",
      },
      TOAST: {
        ERROR: {
          ACCESS_SHARED_DEVICE:
            "Acesso não permitido. O compartilhamento ainda não foi aceito.",
          ACCESS_USER_PROFILE:
            "Acesso não permitido. O usuário ainda não aceitou o compartilhamento.",
          ADD_COUPON: "Erro ao criar cupom",
          ADD_MAC_ADDRESS: "Erro ao adicionar endereço MAC",
          ADD_MAC_ADDRESS_EXISTENT:
            "Este endereço MAC já está registrado no sistema",
          ADD_PLAN: "Erro ao adicionar plano",
          ALERT_EMAIL: "Erro ao alterar email de alerta",
          CANCEL_PLAN: "Erro ao cancelar plano",
          CONTROLLER_CHANGED: "Falha ao executar operação",
          CREATE_SCENARIO: "Erro ao criar cenário",
          CREATE_SECTOR: "Erro ao criar setor",
          CREATE_TAG: "Esta TAG já existe, por favor escolha outro nome",
          DELETE_CONTROLLER: "Erro ao excluir controlador",
          DELETE_COUPON: "Erro ao excluir cupom",
          DELETE_DEVICE: "Erro ao excluir dispositivo",
          DELETE_SCENARIO: "Erro ao excluir cenário",
          DELETE_SECTOR: "Erro ao remover setor",
          DELETE_TAG: "Erro ao excluir TAG",
          DELETE_USER: "Erro ao excluir usuário",
          DEVICE_ACCEPTED: "Erro ao aceitar compartilhamento",
          DEVICE_ADDED: "Erro ao adicionar dispositivo",
          DEVICE_EDITED: "Erro ao editar dispositivo",
          EDITED_COUPON: "Erro ao editar cupom",
          EDITED_TAG: "Erro ao editar TAG",
          EDITED_USER: "Erro ao editar usuário",
          EMAIL_ALREADY_REGISTERED:
            "Este email ou nome de usuário já está cadastrado",
          EMAIL_NOT_REGISTERED: "Email não cadastrado",
          GENERATE_REPORT_ADMIN: "Erro ao gerar relatorio",
          INVALID_PASSWORD: "Senha inválida",
          INVALID_TOKEN: "Token inválido",
          INVERTER_ON: "Alteração não permitida. O inversor está ligado",
          LOGIN: "Erro ao fazer login",
          MAC_IN_USE: "Esse endereço MAC já está em uso",
          MAC_NOT_REGISTERED:
            "Este endereço MAC não está cadastrado no sistema",
          MAC_UNAVAILABLE: "Endereço MAC já está em uso",
          MODEL_STRUCTURE: "Estrutura do modelo com erro",
          NO_SETPOINT: "O controlador selecionado não é compatível",
          PASSWORD_CHANGED: "Erro ao alterar senha",
          PENDING_PAYMENT: "Erro ao excluir pagamento pendente",
          PENDING_PAYMENT_ALREADY:
            "Este dispositivo ja possui um pagamento pendente",
          PLAN_NOT_ASSOCIATE: "Plano não associado",
          RANGE_MONTH: "Escolha um período de até 2 meses",
          RECURRING_PAYMENT: "Erro ao cancelar pagamento recorrente",
          REGISTRATION_CANCELED: "Erro ao cancelar cadastro",
          REGISTERED_MAC: "Este endereço MAC não está cadastrado no sistema",
          REMOVE_MAC_ADDRESS: "Erro ao remover endereço MAC",
          REMOVE_PAYMENT: "Erro ao excluir pagamento",
          REMOVE_PLAN: "Erro ao remover plano",
          REMOVE_TAG: "Erro ao remover TAG",
          SCENARIO_CHANGED: "Erro ao alterar cenário",
          SCENARIO_STATUS_SWITCH: "Erro ao alterar status do cenário",
          SELECT_CTRLS_MODEL: "Selecione controladores somente do mesmo modelo",
          SELECTED_TAG: "Erro ao selecionado TAG",
          SHARE_DEVICE: "Erro ao compartilhar dispositivo",
          SHARE_DEVICE_EXISTENT:
            "O dispositivo já está sendo compartilhado com este usuário",
          SHARE_DEVICE_FORBIDDEN:
            "O plano atual não permite compartilhar um dispositivo",
          SHARE_DEVICE_PERMISSION:
            "O plano corrente deste dispositivo não tem acesso a esta funcionalidade",
          SHARE_DEVICE_PERMISSION_MAX:
            "Número máximo de compartilhamentos excedido",
          SHARE_DEVICE_PERMISSION_SECTORS:
            "O plano corrente do dispositivo de um dos controladores selecionado não tem acesso a esta funcionalidade",
          SHARE_DEVICE_PLAN:
            "Dispositivos compartilhados não tem acesso a esta funcionalidade",
          UNSHARE_DEVICE: "Erro ao descompartilhar dispositivo",
          VALID_COUPON: "Cupom inválido",
          ZIP_CODE: "CEP inválido",
        },
        INFO: {
          NO_DATA_RECORD: "Não há registro de dados.",
          NO_DATA_STORED: "Nenhum dado armazenado nas últimas 24h.",
        },
        SUCCESS: {
          ACCESS_CODE_COPIED: "Código de acesso copiado",
          ACCOUNT_CREATED: "Conta criada com sucesso",
          ADD_COUPON: "Cupom criado com sucesso",
          ADD_MAC_ADDRESS: "Endereço MAC adicionado com sucesso",
          ADD_PLAN: "Sucesso ao adicionar plano",
          ALERT_EMAIL: "Email de alerta alterado com sucesso",
          ALTERNATE_ACTION: "Operação realizada com sucesso",
          CANCEL_PLAN: "Plano cancelado com sucesso",
          CREATE_SCENARIO: "Sucesso ao criar cenário",
          CREATE_SECTOR: "Setor criado com sucesso",
          CREATE_TAG: "TAG criada com sucesso",
          DELETE_CONTROLLER: "Controlador excluído com sucesso",
          DELETE_COUPON: "Cupom excluido com sucesso",
          DELETE_DEVICE: "Dispositivo excluido com sucesso",
          DELETE_SCENARIO: "Cenário excluido com sucesso",
          DELETE_SECTOR: "Setor removido com sucesso",
          DELETE_TAG: "Sucesso a excluir a TAG",
          DELETE_USER: "Usuário excluido com sucesso",
          DEVICE_ACCEPTED: "Dispositivo aceito com sucesso",
          DEVICE_ADDED: "Dispositivo adicionado com sucesso",
          DEVICE_EDITED: "Dispositivo editado com sucesso",
          EDITED_COUPON: "Cupom editado com sucesso",
          EDITED_TAG: "TAG editado com sucesso",
          EDITED_USER: "Usuário editado com sucesso",
          GENERATE_REPORT_ADMIN: "Relatorio gerado com sucesso",
          RESET_PASSWORD_EMAIL:
            "Enviamos um email para o endereço informado com as instruções para resetar a senha",
          SCENARIO_CHANGED: "Cenário alterado com sucesso",
          SCENARIO_STATUS_SWITCH: "Status do cenário alterado com sucesso",
          SELECTED_CONTROLLERS: "Controladores selecionados com sucesso",
          SELECTED_TAG: "TAG selecionado com sucesso",
          LOGIN: "Bem vindo",
          PASSWORD_CHANGED: "Senha alterada com sucesso",
          PENDING_PAYMENT: "Pagamento pendente excluido com sucesso",
          RECURRING_PAYMENT: "Pagamento recorrente cancelado com sucesso",
          REGISTRATION_CANCELED: "Cadastro cancelado com sucesso",
          REMOVE_MAC_ADDRESS: "Endereço MAC removido com sucesso",
          REMOVE_PAYMENT: "Pagamento excluido com sucesso",
          REMOVE_PLAN: "Plano removido com sucesso",
          REMOVE_TAG: "TAG removida com sucesso",
          SHARE_DEVICE:
            "Sucesso ao compartilhar dispositivo, aguarde o convite ser aceito",
          TOKEN_TELEGRAM_COPIED: "Token Telegram copiado",
          UNSHARE_DEVICE: "Dispositivo descompartilhado com sucesso",
          VALID_COUPON: "Cupom válido",
          ZIP_CODE: "CEP localizado",
        },
        WARNING: {
          LOADING_ERROR_COM: "Aguardando a resposta do dispositivo",
          ERROR_COM: "Erro na comunicação com o dispositivo",
        },
      },
    },
  },
};
