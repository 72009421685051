import { Box, Typography } from "@mui/material";

import { CardData } from "../../contexts/ControllerContext";
import Icons from "./Icons";
import Measures from "./Measures";
import { getCtrlConfig } from "../Controller/parser";
import { getRawCtrlConfig } from "../../config";

type BodyProps = {
  data: CardData;
  setpointsector?: string;
  setpointindex?: number;
};

const Body = ({ data, setpointsector, setpointindex }: BodyProps) => {
  const valuesData = data;
  const measures = valuesData.measures;
  const icons = valuesData.icons;

  const measuresComponent = <Measures measures={measures} />;

  // Resgatar os dados do Setpoint
  const ctrlConfig = getCtrlConfig(measures.modelo)?.setpoints;
  const rawCtrlConfig = getRawCtrlConfig(measures.modelo);

  // Título do setpoint filtrado
  const setpointTitle = ctrlConfig[setpointindex ?? 0]?.description;
  const setpointUnit = ctrlConfig[setpointindex ?? 0]?.unitOfMeasurement;

  // Validação se o inversor está OFF
  const setpointIsOff =
    setpointsector === "0.0" &&
    rawCtrlConfig?.inversor &&
    rawCtrlConfig?.inversor === true;

  return (
    <>
      <Box p={2}>{measuresComponent}</Box>
      {ctrlConfig.length > 0 && setpointsector && setpointindex && (
        <Box textAlign="center" mb={-1}>
          <Typography fontSize={15}>{setpointTitle}</Typography>
          {setpointIsOff ? (
            <Typography fontWeight={500} fontSize={20}>
              OFF
            </Typography>
          ) : (
            <Box display="flex" justifyContent="center" ml={1}>
              <Typography fontWeight={500} fontSize={20}>
                {setpointsector}
              </Typography>
              <Typography fontWeight={500} fontSize={11}>
                {setpointUnit}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Icons icons={icons} />
    </>
  );
};

export default Body;
