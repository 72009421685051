import { useNavigate } from "react-router-dom";

import { Memory as MemoryIcon } from "@mui/icons-material";
import { Box, Typography, Divider, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import Modal from "../../components/Modal";
import Controller from "./Controller";

interface ModalExpandProps {
  open: boolean;
  setOpen: (e: boolean) => void;
  actionValue: number | null;
  myDevicesList: any[];
}

const ModalExpand = ({
  open,
  setOpen,
  actionValue,
  myDevicesList,
}: ModalExpandProps) => {
  const navigate = useNavigate();

  // Fechar o Modal do Accordion expandido
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} handleClose={handleClose}>
      {actionValue !== null &&
        myDevicesList?.map((v, i) => (
          <Box key={i}>
            <Box display="flex">
              <Box mb={2}>
                {v.isWebfi && (
                  <Tooltip title="WebFi">
                    <IconButton
                      onClick={() => navigate(`/controller/${v.mac}/CTRL36`)}
                      sx={{ cursor: "pointer" }}
                    >
                      <MemoryIcon fontSize="large" color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
                {!v.isWebfi && (
                  <>
                    <IconButton onClick={() => null} sx={{ cursor: "default" }}>
                      <MemoryIcon fontSize="large" />
                    </IconButton>
                  </>
                )}
              </Box>
              <Box justifyContent="space-between" mt={1.4} ml={0.5}>
                <Typography fontSize={19} fontWeight={600}>
                  {v.name}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Grid container spacing={1} pt={5}>
              <Controller value={v} />
            </Grid>
          </Box>
        ))[actionValue]}
    </Modal>
  );
};

export default ModalExpand;
