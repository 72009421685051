import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

import {getUser, isTokenValid, removeUserData, storeUserData} from "../auth";
import {api} from "../services/api";
import {login, logout} from "../services/data/auth";
import {Login} from "../services/types/auth";
import {TOKEN_KEY} from "../auth/constants";
import mqtt from "mqtt";

interface AuthProviderProps {
  children: ReactNode;
}

export type User = {
  login: string;
  name: string;
  userId?: string;
};

interface AuthData {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  token: string | undefined;
  userArcsys: any | undefined;
  client: mqtt.MqttClient | undefined;
  setToken: Dispatch<SetStateAction<string | undefined>>;
  authenticated: boolean;
  signIn: (auth: Login) => Promise<boolean>;
  signOut: () => Promise<any>;
  connectClient: (userdId: string, passwordHash: string) => void;
  setClient: (e: mqtt.MqttClient) => void;
  setUserArcsys: Dispatch<SetStateAction<any | undefined>>;
  userId: string;
  setUserId: Dispatch<SetStateAction<string>>;
}

export const AuthContext = createContext({} as AuthData);

const userLS = getUser();
const isAuthenticated = isTokenValid();

export const AuthProvider = ({children}: AuthProviderProps) => {
  const [user, setUser] = useState<User>({
    login: userLS.login,
    name: userLS.name,
  });
  const [authenticated, setAuthenticated] = useState(isAuthenticated);
  const [token, setToken] = useState<string>();
  const [client, setClient] = useState<mqtt.MqttClient>();
  const [userArcsys, setUserArcsys] = React.useState<any>();
  const [userId, setUserId] = useState<string>("");

  const connectClient = (userdId: string, passwordHash: string) =>
    mqtt.connect(process.env.REACT_APP_WS ?? "", {
      username: userdId,
      password: passwordHash,
      clean: process.env.REACT_APP_MQTT_CLEAN === "true" ? true : false,
      keepalive: process.env.REACT_APP_MQTT_KEEP_ALIVE
        ? +process.env.REACT_APP_MQTT_KEEP_ALIVE
        : 0,
      connectTimeout: process.env.REACT_APP_MQTT_CONNECT_TIMEOUT
        ? +process.env.REACT_APP_MQTT_CONNECT_TIMEOUT
        : 5000,
      reconnectPeriod: process.env.REACT_APP_MQTT_RECONNECT_PERIOD
        ? +process.env.REACT_APP_MQTT_RECONNECT_PERIOD
        : 1000,
    });

  const signIn = (auth: Login) =>
    login(auth)
      .then(({data}) => {
        if (!client?.connected) {
          console.log("CLIENT MQTT CONNECTED - LOGIN");
          setClient(connectClient(`${data.user._id}`, data.user.password));
        }
        const {login} = auth;
        const userData = {login, name: data.user.name, ...data};
        api.defaults.headers.common.authorization = "Bearer " + data.token;
        localStorage.setItem(TOKEN_KEY, "Bearer " + data.token);
        setAuthenticated(true);
        setUser({login, name: data.user.name});
        setUserId(data.user._id.toString());
        storeUserData(userData);

        localStorage.removeItem("ageonRef");

        return true;
      })
      .catch(() => false);

  const signOut = () =>
    logout().finally(() => {
      delete api.defaults.headers.common.authorization;
      client?.end();
      setAuthenticated(false);
      setUser({login: "", name: ""});
      setUserId("");
      removeUserData();
      setUserArcsys(undefined);
    });

  const AuthProviderValue = useMemo(() => {
    return {
      user,
      token,
      authenticated,
      client,
      userArcsys,
      setUserArcsys,
      setClient,
      setToken,
      signIn,
      signOut,
      connectClient,
      setUser,
      userId,
      setUserId,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, token, user, client, userArcsys]);

  return (
    <AuthContext.Provider value={AuthProviderValue}>
      {children}
    </AuthContext.Provider>
  );
};
