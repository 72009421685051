import { api } from "../api";
import axios, { CancelTokenSource } from "axios";
import { getStoreRoute } from "../routes/store";

let cancelTokenSourceGetStore: CancelTokenSource;
// Requisição get para montar o gráfico
export const getStore = async (mac: string, ctrl: string, arcsysid: string) => {
  // Cancele a solicitação anterior antes de fazer uma nova solicitação
  if (cancelTokenSourceGetStore) {
    cancelTokenSourceGetStore.cancel();
  }

  // Cria um novo token de cancelamento
  cancelTokenSourceGetStore = axios.CancelToken.source();
  try {
    const response = await api.get(getStoreRoute(mac, ctrl, arcsysid), {
      cancelToken: cancelTokenSourceGetStore.token,
    });
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
    } else {
      // Lida com erros usuais
    }
  }
};
