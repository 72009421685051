import React, { ChangeEvent } from "react";

import { styled, SwitchProps, Switch as SwitchComponent } from "@mui/material";
import { red } from "@mui/material/colors";

// Style customizado do componente Switch
const IOSSwitch = styled((props: SwitchProps) => (
  <SwitchComponent
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: red[700],
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface SwitchComponentProps {
  name?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<any>) => void;
  disabled?: boolean;
  [x: string]: any;
}

const Switch = ({
  name,
  checked,
  onChange,
  disabled,
  ...rest
}: SwitchComponentProps) => (
  <IOSSwitch
    name={name}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    {...rest}
  />
);

export default Switch;
