import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface MqttProviderProps {
  children: ReactNode;
}

interface MqttData {
  mqtt: any;
  setMqtt: Dispatch<SetStateAction<any>>;
}

export const MqttContext = createContext({} as MqttData);

export const MqttProvider = ({ children }: MqttProviderProps) => {
  const [mqtt, setMqtt] = useState<any>();

  const MqttProviderValue = useMemo(
    () => ({
      mqtt,
      setMqtt,
    }),
    [mqtt]
  );

  return (
    <MqttContext.Provider value={MqttProviderValue}>
      {children}
    </MqttContext.Provider>
  );
};
