import React from "react";

import { Box, Typography } from "@mui/material";
import { version } from "../../version";

const Version = () => {
  return (
    <Box
      color="text.secondary"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: 2,
        marginTop: 2,
        marginBottom: 1,
      }}
    >
      <Box />
      <Box
        display="flex"
        marginRight="1px"
        sx={{ placeContent: "center" }}
        paddingLeft="47px"
      >
        <Box display="flex" marginRight={0.5}>
          <Typography>©</Typography>
          <Typography>2024 - </Typography>
        </Box>
        <a href="https://ageon.com.br/serie/arcsys/" target="_blank" rel="noreferrer">
          <img
            src="/mnlogo.png"
            alt="logo"
            style={{ height: "16px", marginTop: "3px" }}
          />
        </a>
      </Box>
      <Typography>{version}</Typography>
    </Box>
  );
};

export default Version;
