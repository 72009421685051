import { Alert, Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { red } from "@mui/material/colors";
import Modal from "../../../Devices/Modal";
import useIsMobile from "../../../../hooks/useIsMobile";
import { RowData } from "../../../../components/Controller/parser/types";

interface ModalInitialSetpointProps {
  handleOpen: boolean;
  handleClose: () => void;
  filteredSetpoint: RowData;
}

const ModalInitialSetpoint = ({
  handleOpen,
  handleClose,
  filteredSetpoint,
}: ModalInitialSetpointProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  // Resgatar o titulo do setpoint selecionado
  const setpointTitle = filteredSetpoint?.description
    ? `${t("TEXT.INITIAL_VALUE")}: ${filteredSetpoint?.description}`
    : `${t("TEXT.INITIAL_VALUE")}: ${"Setpoint"}`;

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : 400}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <HelpOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {setpointTitle}
      </Typography>
      <Alert severity="info" sx={{ mt: 2, mb: 2, alignItems: "center" }}>
        <Typography>{t("TEXT.SETPOINT_INITIAL_VALUE_MESSAGE")}</Typography>
      </Alert>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: red[50],
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalInitialSetpoint;
