import React from "react";

import { Box } from "@mui/material";

import Devices from "./Devices";
import { AuthContext } from "../../contexts/AuthContext";
import { updateCtrl } from "../../config";
import { getModelos } from "../../services/data/modelo";
import { ArcsysContext } from "../../contexts/ArcsysProviderContext";
import { CurrentMqttSubsContext } from "../../contexts/CurrentMqttSubsContext";
import { WelcomeContext } from "../../contexts/WelcomeContext";
import { TOKEN_KEY } from "../../auth/constants";
import ModalWelcome from "./ModalWelcome";
import { api } from "../../services/api";
import { getDashboardMacs } from "../../services/data/arcsys";
/* import { initializeApp } from "firebase/app";
import { Messaging, getMessaging, onMessage } from "firebase/messaging";
import { getToken } from "firebase/messaging"; */

const Dashboard = () => {
  const { client } = React.useContext(AuthContext);
  const { macsToSubscribe, setMacsToSubscribe, isMacsToSubscribeRequested, setIsMacsToSubscribeRequested } =
    React.useContext(ArcsysContext);
  const { authenticated, token } = React.useContext(AuthContext);
  const { welcome, isOpenned, setIsOpenned } = React.useContext(WelcomeContext);
  const { currentMqtt, setCurrentMqtt } = React.useContext(
    CurrentMqttSubsContext
  );

  /* // Requisição do token do Firebase
  const [messaging, setMessaging] = React.useState<Messaging | null>(null);
  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyBZ1m8DoURrqCSLqmqk85E6nfYmczEMI34",
      authDomain: "testefirebase-2903a.firebaseapp.com",
      projectId: "testefirebase-2903a",
      storageBucket: "testefirebase-2903a.appspot.com",
      messagingSenderId: "867679744866",
      appId: "1:867679744866:web:67175d4dcf796836efb23b",
      measurementId: "G-592595BY30",
    };

    const requestPermission = async () => {
      console.log("Requesting notification permission...");
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");

        // Inicializa o Firebase
        const app = initializeApp(firebaseConfig);
        // Obtém a instância do Firebase Messaging
        setMessaging(getMessaging(app));
      } else {
        console.log("Notification permission denied.");
      }
    };

    requestPermission();
  }, []);

  React.useEffect(() => {
    const handleToken = async () => {
      if (messaging) {
        // Obtém o token
        const currentToken = await getToken(messaging, {
          vapidKey:
            "BCJqOKIY0smGkD0mNannmb_QoZaHpyQSQaEaos9MMgaOVW4wCxuYer36MAhrDYGTIR-qcHXOEemXwh8YFRg5atI",
        });
        if (currentToken) {
          console.log("Firebase messaging token:", currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      }
    };

    // Resposta do Firebase
    const handleOnMessage = (payload: any) => {
      console.log("Message received: ", payload);
    };

    if (messaging) {
      handleToken();
      // Escuta o evento onMessage
      onMessage(messaging, handleOnMessage);
    }
  }, [messaging]); */

  // State do modal de boas vindas
  const [open, setOpen] = React.useState(true);

  // Fechar modal de boas vindas
  const handleClose = () => {
    setOpen(false);
    setIsOpenned(false);
  };

  React.useEffect(() => {
    const fetchStatus = () => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Se a key 'ageonRef' do localStorage for indefinida:
        // Resgata os modelos JSON conforme a linguagem selecionada
        if (localStorage.ageonRef === undefined) {
          getModelos().then((res) => {
            if (localStorage.lang === "pt") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model["pt-br"])
              );
            } else if (localStorage.lang === "en") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.en)
              );
            } else {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.es)
              );
            }
            updateCtrl(localStorage.ageonRef);
          });
        } else {
          updateCtrl(localStorage.ageonRef);
        }
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  React.useEffect(() => {
    const fetchStatus = () => {
      if (!isMacsToSubscribeRequested) {
        // Rota para resgatar todos os dispositivos MACs no Dashboard
        getDashboardMacs().then((res) => {
          const { data } = res;
          setMacsToSubscribe(data);
          setIsMacsToSubscribeRequested(true);
        });
      }
    };
    fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMacsToSubscribeRequested, setMacsToSubscribe]);

  // Dar subscribe nos arcsys do usuário
  React.useEffect(() => {
    const fetchStatus = () => {
      const { connected } = client ?? {};
      let subscribed_devices: string[] = [];

      if (connected === true) {
        macsToSubscribe?.forEach(({ mac }) => {
          if (mac) {
            subscribed_devices.push(mac);

            if (currentMqtt?.includes(`${mac}/EVT/#`)) return;
            client?.subscribe(`${mac}/EVT/#`);
            setCurrentMqtt((prev) =>
              prev ? `${prev},${mac}/EVT/#` : `${mac}/EVT/#`
            );
          }
        });

        currentMqtt?.split(",").forEach((subscription) => {
          const mac = subscription.split("/")[0];
          if (mac.length && !subscribed_devices.includes(mac)) {
            client?.unsubscribe(subscription);

            // Remove unsubscribed device from currentMqtt
            setCurrentMqtt((prev) => {
              if (!prev) return prev;
              const subscriptions = prev.split(",");
              const filtered = subscriptions.filter(
                (sub) => sub !== subscription
              );
              return filtered.join(",");
            });
          }
        });
      }
    };

    fetchStatus();
    const intervalId = setInterval(fetchStatus, 1000);
    return () => clearInterval(intervalId);
  }, [client, currentMqtt, setCurrentMqtt, macsToSubscribe]);

  // Modal de boas vindas
  // React.useMemo(() => {
  //   if (authenticated) {
  //     if (token !== undefined) {
  //       localStorage.setItem(TOKEN_KEY, token);
  //     }
  //     api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
  //     const fetchStatus = () => {
  //       // Resgatar apenas os Dispositivos com pending=false
  //       if (welcome === false && isOpenned === true) {
  //         getDevices(true).then((res) => {
  //           const { data } = res;

  //           // Checa se o usuário logado tem somente 1 arcsys
  //           if (data?.items.length === 1) {
  //             // Regata os Controladores do Dispositivo
  //             getControllersOfDevice(data?.items[0]._id).then((controllers) => {
  //               // Checa se o usuário não tem nenhum controlador no arcsys
  //               if (controllers.data.total === 0) {
  //                 // Renderizar modal de boas vindas
  //                 setWelcome(true);
  //               } else {
  //                 setWelcome(false);
  //                 setIsOpenned(false);
  //               }
  //             });
  //           } else {
  //             setWelcome(false);
  //             setIsOpenned(false);
  //           }
  //         });
  //       }
  //     };
  //     fetchStatus();
  //     const statusInterval = setInterval(fetchStatus, 10000000);
  //     return () => clearInterval(statusInterval);
  //   }
  // }, [authenticated, isOpenned, setIsOpenned, setWelcome, token, welcome]);

  return (
    <Box mb={2}>
      <Devices />
      {welcome && welcome === true && isOpenned === true && (
        <ModalWelcome handleOpen={open} handleClose={handleClose} />
      )}
    </Box>
  );
};

export default Dashboard;
