import React from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Modal as MuiModal,
  SxProps,
  Theme,
} from "@mui/material";

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  width: number;
}

const style: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 4,
};

const Modal = ({ children, open, width, handleClose }: ModalProps) => {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      className="scroll"
    >
      <Box sx={style} style={{ width }}>
        <IconButton
          onClick={(e: any) => {
            e.stopPropagation();
            handleClose();
          }}
          sx={{ position: "absolute", top: 1, right: 1 }}
        >
          <CloseIcon />
        </IconButton>
        <Box p={1} pt={2}>
          {children}
        </Box>
      </Box>
    </MuiModal>
  );
};

export default Modal;
