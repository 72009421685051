import React from "react";

import GaugeComponent from "react-gauge-component";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import { valueWithUnit } from "./parse";

interface GaugeProps {
  value: number;
  unit: string;
  maxValue: number;
  minValue: number;
}

const Gauge = ({ value, unit, maxValue, minValue }: GaugeProps) => {
  const { theme } = React.useContext(ThemeModeContext);

  // Mínimo, máximo e valor atual do Setpoint do setor
  const min_value = minValue < 0 ? 0 : minValue;
  const max_value = minValue < 0 ? Math.abs(minValue) + maxValue : maxValue;
  const value_current = minValue < 0 ? Math.abs(minValue) + value : value

  return (
    <GaugeComponent
      style={{
        width: 330,
        height: 200,
        marginLeft: "-21px",
        marginRight: "-105px",
      }}
      arc={{
        nbSubArcs: 150,
        subArcs: [
          {
            length: min_value,
            color: "#00FFFB",
            showTick: true,
          },
          {
            length: max_value / 4,
            color: "#00FF04",
            showTick: true,
          },
          {
            length: max_value / 2,
            color: "#ECFF00",
            showTick: true,
          },
          {
            length: max_value,
            color: theme === "light" ? "#DB2400" : "#FF0000",
            showTick: true,
          },
        ],
      }}
      labels={{
        valueLabel: {
          formatTextValue: () => valueWithUnit(value, unit),
          style: {
            fontSize:
              valueWithUnit(value, unit).length > 4
                ? "35px"
                : valueWithUnit(value, unit).length > 5
                  ? "30px"
                  : valueWithUnit(value, unit).length > 6
                    ? "25px"
                    : "45px",

            fontWeight: 500,
            textShadow: "none",
          },
        },
        tickLabels: {
          hideMinMax: true,
        },
      }}
      value={value_current}
      minValue={min_value}
      maxValue={max_value}
    />
  );
};

export default Gauge;
