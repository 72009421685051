import { api } from "../api";
import {
  ADD_TAG_TO_CTRL_ROUTE,
  DELETE_TAG_TO_CTRL_ROUTE,
  TAGS_ROUTE,
  editTagRoute,
  getCtrlTagByIdRoute,
  getDashboardTagsRoute,
  getTagNamesByIdRoute,
  removeTagRoute,
} from "../routes/TAGs";

// Criar uma nova TAG
export const createTag = async (data: any) => api.post<any>(TAGS_ROUTE, data);

// Rota para resgatar as TAGs criadas por 'id' do usuário
export const getTagNamesById = (id: number) =>
  api.get<any>(getTagNamesByIdRoute(id));

// Rota para selecionar TAG no controlador
export const addTagToCtrl = (data: any) =>
  api.post<any>(ADD_TAG_TO_CTRL_ROUTE, data);

// Rota para remover TAG no controlador
export const deleteTagToCtrl = (data: any) =>
  api.post<any>(DELETE_TAG_TO_CTRL_ROUTE, data);

// Rota para remover TAG no perfil do usuário
export const removeTag = (id: string) => api.delete<any>(removeTagRoute(id));

// Rota para resgatar a TAG selecionada na página do controlador
export const getCtrlTagById = async (ctrl: string, id: string) =>
  api.get<any>(getCtrlTagByIdRoute(ctrl, id));

// Rota para resgatar a lista de TAGs na página do Dashboard
export const getDashboardTags = async (id: number) =>
  api.get<any>(getDashboardTagsRoute(id));

// Rota para editar TAG
export const editTag = async (id: string, data: any) =>
  api.put<void>(editTagRoute(id), data);
