import { api } from "../api";
import {
  CHANGE_HISTORY_ROUTE,
  LAST_TRANSLATION_ROUTE,
  TRANSLATION_ROUTE,
  getTranslationsByIdRoute,
} from "../routes/translations";

// Resgatar as traduções atuais
export const getActualTranalation = () => api.get<any>(LAST_TRANSLATION_ROUTE);

// Rota para editar traduções no modo admin
export const editTranslationsJson = async (data: any) =>
  api.post<any>(TRANSLATION_ROUTE, data);

// Resgatar a lista do histórico de alterações das traduções no modo admin
export const getChangeHistory = () => api.get<any>(CHANGE_HISTORY_ROUTE);

// Resgatar traduções por 'id' no modo admin
export const getTranslationsById = (id: string) =>
  api.get<any>(getTranslationsByIdRoute(id));

// Rota para remover item do histórico
export const deleteHistoricById = (id: string) =>
  api.delete<any>(getTranslationsByIdRoute(id));
