import React from "react";

import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import useIsMobile from "../../hooks/useIsMobile";

interface EditorProps {
  actualTranslation: any;
  setActualTranslation: (e: any) => void;
  setSaveButton: (e: boolean) => void;
  lang: string;
}

// Estrutura de edição do modelo JSON não editável
export const EditorNotEdited = ({
  actualTranslation,
  setActualTranslation,
  setSaveButton,
  lang,
}: EditorProps) => {
  const ref: any = React.useRef();
  const mobile = useIsMobile();
  const [editor, setEditor] = React.useState<JSONEditor>();

  React.useEffect(() => {
    if (actualTranslation) {
      if (!editor) {
        const newEditor: any = new JSONEditor(ref.current, {
          mode: "code",
          onChangeText: (e) => {
            setActualTranslation({ ...actualTranslation, ...JSON.parse(e) });
            setSaveButton(true);
          },
          onEditable: () => {
            return false;
          },
          mainMenuBar: false,
        });

        newEditor.set({
          [lang]: actualTranslation[lang],
        });
        setEditor(newEditor);
      } else {
        editor.set({
          [lang]: actualTranslation[lang],
        });
      }
    }
  }, [actualTranslation, editor, lang, setActualTranslation, setSaveButton]);

  return (
    <div
      id="jsoneditor"
      ref={ref}
      style={{
        height: mobile ? "500px" : "950px",
      }}
    />
  );
};
