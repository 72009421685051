import React from "react";

import { Box, Tab, Tabs } from "@mui/material";
import {
  CardMembership as CardMembershipIcon,
  Money as MoneyIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  PaidOutlined as PaidOutlinedIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Plans from "./Plans/index";
import Coupon from "./Coupons";
import Recurrences from "./Recurrences";
import SinglePayments from "./SinglePayments";
import PendingPayments from "./PendingPayments";

const AdminArea = () => {
  const { t } = useTranslation();
  // Ação de alterar TABs no modo admin
  const [optionsTab, setOptionsTab] = React.useState(0);
  const handleChangeOptionsTab = (
    _event: React.SyntheticEvent,
    newValue: number
  ) => {
    setOptionsTab(newValue);
  };

  return (
    <Box>
      <Tabs
        orientation="horizontal"
        value={optionsTab}
        onChange={handleChangeOptionsTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ mt: 2.5 }}
      >
        <Tab
          icon={<CardMembershipIcon />}
          label={t("TEXT.PLANS")}
          sx={{ pt: 0 }}
          disableRipple
        />
        <Tab
          icon={<MoneyIcon />}
          label={t("TEXT.COUPONS")}
          sx={{ pt: 0 }}
          disableRipple
        />
        <Tab
          icon={<PaidOutlinedIcon />}
          label={t("TEXT.SINGLE_PAYMENTS")}
          sx={{ pt: 0 }}
          disableRipple
        />
        <Tab
          icon={<CurrencyExchangeIcon />}
          label={t("TEXT.RECURRENCES")}
          sx={{ pt: 0 }}
          disableRipple
        />
        <Tab
          icon={<ReceiptIcon />}
          label={t("TEXT.INVOICES")}
          sx={{ pt: 0 }}
          disableRipple
        />
      </Tabs>
      {optionsTab === 0 ? (
        <Plans />
      ) : optionsTab === 1 ? (
        <Coupon />
      ) : optionsTab === 2 ? (
        <SinglePayments />
      ) : optionsTab === 3 ? (
        <Recurrences />
      ) : (
        <PendingPayments />
      )}
    </Box>
  );
};

export default AdminArea;
