import React from "react";

import { Box, Chip, Skeleton, Tooltip, Typography } from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";

import Card from "./Menu/index";
import { useTranslation } from "react-i18next";
import { red } from "@mui/material/colors";
import ModalActions from "./Modal/Actions";
import { getCtrlConfig } from "../Controller/parser";
import { OFFLINE_ERROR } from "../Controller/constants";

type HeaderProps = {
  id: string | undefined;
  ctrl: string;
  title: string | undefined;
  addr: number | undefined;
  modelFound: boolean;
  comError: boolean;
  modelo: string | undefined;
  errorSector?: boolean;
  setpointsector?: string | undefined;
  handleOpenModalDelete?: () => void;
  checked?: boolean;
  alarm: number;
};

const Header = ({
  id,
  ctrl,
  title,
  addr,
  modelFound,
  comError,
  modelo,
  errorSector,
  setpointsector,
  handleOpenModalDelete,
  checked,
  alarm,
}: HeaderProps) => {
  const { t } = useTranslation();
  const [openModalActions, setOpenModalActions] =
    React.useState<boolean>(false);

  // Resgatar as Ações do Controlador
  const ctrlConfig = getCtrlConfig(modelo ?? "");
  const { acoes: acoesConfig } = ctrlConfig;

  // Ação de abrir modal das ações
  const handleOpenModalActions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // Desabilita o redirecionamento ao clicar no link do Card
    event.preventDefault();
    setOpenModalActions(true);
  };

  // Ações de fechar o modal de Ações
  const handleCloseModalActions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // Desabilita o redirecionamento ao clicar no link do Card
    event.preventDefault();
    setOpenModalActions(false);
  };

  const cardComponent = id && addr && modelo && (
    <Card
      id={id}
      ctrl={ctrl}
      addr={addr}
      modelFound={modelFound}
      comError={comError}
      modelo={modelo}
      setpointsector={setpointsector}
      handleOpenModalDelete={handleOpenModalDelete}
      checked={checked}
    />
  );

  // Checa se há erro no card do setor
  const isError = errorSector !== undefined && errorSector === false;

  return (
    <Box
      className="header"
      display="flex"
      sx={{
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        justifyContent: "space-between",
      }}
    >
      <Box display="flex" mr={isError ? -10 : -3}>
        {acoesConfig ? (
          <>
            {title ? (
              <Tooltip title={t("TEXT.COMMANDS")}>
                <Box
                  onClick={(e: any) => {
                    e.preventDefault();
                    alarm !== OFFLINE_ERROR && handleOpenModalActions(e);
                  }}
                  sx={{
                    borderRadius: 1,
                    border:
                      alarm === OFFLINE_ERROR
                        ? "1.5px solid, #b9b9b9"
                        : "1.5px solid, #fff",
                    cursor: alarm === OFFLINE_ERROR ? "default" : "pointer",
                  }}
                  width={25}
                  height={25}
                  mr={isError ? 1 : 0}
                >
                  <SendIcon
                    sx={{
                      color: alarm === OFFLINE_ERROR ? "#b9b9b9" : "#fff",
                      fontSize: 17,
                      alignSelf: "center",
                      mt: 0.3,
                      pl: 0.1,
                    }}
                  />
                </Box>
              </Tooltip>
            ) : (
              <Skeleton
                variant="rounded"
                width={28}
                height={23}
                sx={{ mr: 6 }}
              />
            )}
          </>
        ) : (
          <Box mr={2}></Box>
        )}
        {isError ? (
          <Tooltip title={t("TEXT.DEVICE_ERROR")}>
            <Chip
              label={t("TEXT.ERROR")}
              color="error"
              size="small"
              sx={{
                fontWeight: 700,
                backgroundColor: red[100],
                color: red[800],
                fontSize: 13,
                pl: 0.2,
                pr: 0.2,
              }}
            />
          </Tooltip>
        ) : (
          <Box></Box>
        )}
      </Box>
      <Typography
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        width={isError ? 200 : 190}
        pl={isError && title && title.length > 11 ? 8 : 0}
      >
        {title ? title : <Skeleton variant="rounded" width={150} height={23} />}
      </Typography>
      <Box>{cardComponent}</Box>
      {openModalActions && modelo && (
        <ModalActions
          handleOpen={openModalActions}
          handleClose={(e: any) => handleCloseModalActions(e)}
          model={modelo}
          ctrl={ctrl}
          alarm={alarm}
        />
      )}
    </Box>
  );
};

export default Header;
