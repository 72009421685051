import { Grid, Skeleton, styled, Tooltip, Typography } from "@mui/material";

import { modelExists } from "../../config";
import { Measures as MeasuresType } from "../../services/types/ctrls";
import { NO_DATA_VALUE } from "../Controller/constants";
import { MeasuresData } from "../Controller/parser/types";
import { parseMeasures } from "../Controller/parser/utils";
import { checkIfIsUndefinedString } from "./parser";

const StyledDisplayValue = styled("div")(() => ({
  position: "relative",

  "&>.unit": {
    position: "absolute",
    top: 0,
    left: "100%",
  },
}));

interface MainMeasureProps {
  measure: MeasuresData | undefined;
  measures: MeasuresData[] | undefined;
}

export const MainMeasure = ({ measure, measures }: MainMeasureProps) => {
  const description = measure ? measure.description : undefined;
  const unitOfMeasurement = measure ? measure.unitOfMeasurement : undefined;
  const value = measure ? measure.value : undefined;

  const checkValueIsInvalid = value === NO_DATA_VALUE || value === 3276.7;
  const checkIfValueIsInteger = !Number.isInteger(value) ? value : `${value}.0`;

  const checkIsValidValue = checkValueIsInvalid ? "--" : checkIfValueIsInteger;
  const checkIsValidUnit = checkValueIsInvalid ? "" : unitOfMeasurement;

  const checkMeasuresLength = measures ? measures.length === 4 : undefined;

  const checkIsValidMeasuresValue = measures
    ? measures[0]?.value === NO_DATA_VALUE || measures[0]?.value === 3276.7
      ? "--"
      : !Number.isInteger(measures[0]?.value)
      ? measures[0]?.value
      : `${measures[0]?.value}.0`
    : undefined;

  const checkIsValidMeasuresUnit = measures
    ? measures[0]?.value === NO_DATA_VALUE || measures[0]?.value === 3276.7
      ? ""
      : measures[0]?.unitOfMeasurement
    : undefined;

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ mb: 2, justifyContent: "space-around" }}
    >
      {checkIfIsUndefinedString(description) ? (
        <Grid item>
          <StyledDisplayValue>
            <Typography variant="h3">{checkIsValidValue}</Typography>
            <Typography className="unit">{checkIsValidUnit}</Typography>
          </StyledDisplayValue>
        </Grid>
      ) : (
        <Tooltip title={description} arrow enterTouchDelay={0}>
          <Grid item>
            <StyledDisplayValue>
              <Typography variant="h3">{checkIsValidValue}</Typography>
              <Typography className="unit">{checkIsValidUnit}</Typography>
            </StyledDisplayValue>
          </Grid>
        </Tooltip>
      )}
      {checkMeasuresLength &&
        (measures ? (
          <Tooltip title={measures[0]?.description} arrow enterTouchDelay={0}>
            <Grid item mt={3}>
              <StyledDisplayValue>
                <Typography variant="h6">
                  {checkIsValidMeasuresValue}
                </Typography>
                <Typography variant="body2" fontSize={10} className="unit">
                  {checkIsValidMeasuresUnit}
                </Typography>
              </StyledDisplayValue>
            </Grid>
          </Tooltip>
        ) : (
          <Skeleton variant="rounded" width={100} height={30} />
        ))}
    </Grid>
  );
};

interface SecondaryMeasuresProps {
  measures: MeasuresData[] | undefined;
  variant: "h6" | "h3";
}

export const SecondaryMeasures = ({
  measures,
  variant,
}: SecondaryMeasuresProps) => {
  const checkMeasuresLength = measures
    ? measures.length === 4
      ? measures.slice(1, 4)
      : measures
    : undefined;

  return (
    <Grid container justifyContent="space-evenly" spacing={1}>
      {checkMeasuresLength ? (
        checkMeasuresLength.map((measure) => {
          const { description, unitOfMeasurement, value } = measure;
          const checkValueIsInvalid = measures
            ? value === NO_DATA_VALUE || measures[0]?.value === 3276.7
            : undefined;
          const checkIsValidValue = checkValueIsInvalid
            ? "--"
            : !Number.isInteger(value)
            ? value
            : `${value}.0`;
          const checkIsValidUnit = checkValueIsInvalid ? "" : unitOfMeasurement;

          return (
            <Grid item key={`${description}${unitOfMeasurement}${value}`}>
              <Tooltip title={description ?? ""} arrow enterTouchDelay={0}>
                <Grid container justifyContent="center" alignItems="flex-start">
                  <Grid item>
                    <StyledDisplayValue>
                      <Typography variant={variant}>
                        {checkIsValidValue}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize={10}
                        className="unit"
                      >
                        {checkIsValidUnit}
                      </Typography>
                    </StyledDisplayValue>
                  </Grid>
                </Grid>
              </Tooltip>
            </Grid>
          );
        })
      ) : (
        <Skeleton variant="rounded" width={210} height={60} />
      )}
    </Grid>
  );
};

interface MeasuresProps {
  measures: MeasuresType | undefined;
}

const Measures = ({ measures }: MeasuresProps) => {
  const modelo = measures ? measures.modelo : undefined;
  if (modelo && !modelExists(modelo)) return null;

  const parsedMeasures = measures ? parseMeasures(measures) : undefined;
  const mainMeasure = parsedMeasures ? parsedMeasures.shift()! : undefined;
  const secondaryMeasures = parsedMeasures;

  return (
    <Grid container direction="column">
      <Grid item>
        <MainMeasure measure={mainMeasure} measures={secondaryMeasures} />
      </Grid>
      <Grid item>
        <SecondaryMeasures variant="h6" measures={secondaryMeasures} />
      </Grid>
    </Grid>
  );
};

export default Measures;
