import React from "react";

import {
  Search as SearchIcon,
  Clear as ClearIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
  KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
  CloseFullscreen as CloseFullscreenIcon,
  OpenInFull as OpenInFullIcon,
} from "@mui/icons-material";
import { Box, Paper, IconButton, Tooltip, InputBase, Fab } from "@mui/material";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import CustomWidthTooltip from "../../components/CustomTooltip";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";

interface SearchBarProps {
  setSelectedDevice: (e: string) => void;
  searchedDevice: string;
  setSearchedDevice: (e: string) => void;
  handleOpenAllAccordions: () => void;
  expandCollapse: boolean;
  setOrderOfRenderGrater: (e: any) => void;
  orderOfRenderGrater: boolean;
}

const SearchBar = ({
  setSearchedDevice,
  searchedDevice,
  setSelectedDevice,
  handleOpenAllAccordions,
  expandCollapse,
  setOrderOfRenderGrater,
  orderOfRenderGrater,
}: SearchBarProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  const { theme } = React.useContext(ThemeModeContext);

  // Barra de pesquisa do Dispositivo
  const handleSearch = (e: any) => {
    setSearchedDevice(e);
  };

  // Limpar barra de pesquisa do Dispositivo
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedDevice("");
    setSelectedDevice("");
  };

  // Reordenar arcSys no Dashboard
  const handleOrderOfRenderGrater = () => {
    setOrderOfRenderGrater((prevState: any) => !prevState);
  };

  return (
    <Box
      display="flex"
      mt={mobile ? "0px" : "-10px"}
      pl={mobile ? 1 : 0}
      pr={mobile ? 1 : 0}
    >
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          mr: mobile ? 2 : 4,
          borderRadius: 5,
          width: mobile ? "90%" : "100%",
          backgroundColor: theme === "dark" ? "#181818" : "#F7F7F7",
        }}
      >
        <Tooltip title={t("BUTTON.SEARCH")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={() => setSelectedDevice(searchedDevice)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          id="myInput"
          defaultValue={searchedDevice}
          onChange={(e) => handleSearch(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setSelectedDevice(e.target.value);
            }
          }}
          sx={{ ml: mobile ? 0 : 2, flex: 1 }}
          placeholder={t("TEXT.SEARCH_DEVICE")}
        />
        <Tooltip title={t("TEXT.CLEAN")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleClearSearch}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      <Tooltip
        title={
          orderOfRenderGrater === true
            ? t("TEXT.DESCENDING_ORDER")
            : t("TEXT.ASCENDING_ORDER")
        }
      >
        <Fab
          size="small"
          onClick={handleOrderOfRenderGrater}
          sx={{ mt: 0.4, mr: 2, p: "0px 21px" }}
        >
          {orderOfRenderGrater === true ? (
            <KeyboardDoubleArrowDownIcon />
          ) : (
            <KeyboardDoubleArrowUpIcon />
          )}
        </Fab>
      </Tooltip>
      <CustomWidthTooltip title={t("TEXT.MINIMIZE_MAXIMIZE")}>
        <Fab
          size="small"
          onClick={handleOpenAllAccordions}
          sx={{ mt: 0.4, p: "0px 21px" }}
        >
          {expandCollapse === true ? (
            <CloseFullscreenIcon />
          ) : (
            <OpenInFullIcon />
          )}
        </Fab>
      </CustomWidthTooltip>
    </Box>
  );
};

export default SearchBar;
