import { Box, Card, CardContent, Typography } from "@mui/material";
import { Equalizer as EqualizerIcon } from "@mui/icons-material";
import Form from "./Form";
import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import ReportStructure from "./Pages";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import {
  getControllersOfDevice,
  getNumbersOfDevices,
} from "../../services/data/arcsys";
import getDeviceStorageTime from "./parse";
import { useParams } from "react-router-dom";

export type FormDateProps = {
  dateStart: Date;
  dateEnd: Date;
};

const Report = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { userArcsys, token, authenticated } = React.useContext(AuthContext);
  const [submit, setSubmit] = React.useState<boolean>(false);
  const [clean, setClean] = React.useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  const [macId, setMacId] = React.useState<string>();
  const [mac, setMac] = React.useState<string>();
  const [controllers, setControllers] = React.useState<any>();
  const [formValue, setFormValue] = React.useState<any>();
  const [formDate, setFormDate] = React.useState<FormDateProps>();
  const [model, setModel] = React.useState<any>();
  const [name, setName] = React.useState<string>();
  const [startDay, setStartDay] = React.useState<Date>();
  const [pages, setPages] = React.useState<number>(1);
  const [devices, setDevices] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [payloadDevices, setPayloadDevices] = React.useState<any>([]);
  const [notShow, setNotShow] = React.useState(false);
  const params = useParams();
  const macParam = params?.mac ?? "";

  const id: number = userArcsys?._id;
  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        // Rota para resgatar a quantidade de dispositivos que irão ser renderizados
        getNumbersOfDevices(
          true,
          pages,
          10,
          "",
          userIsAdmin ? 1 : 0,
          "",
          macParam
        ).then((res) => {
          if (res?.data) {
            const payloadData = res.data.items?.sort((s: any) =>
              s.online === true ? -1 : 1
            );
            if (res.data.this_item_list_qtn === res.data.total) {
              setNotShow(true);
            }
            setPayloadDevices(res.data.items);
            setDevices((prevOptions: any) => [...prevOptions, ...payloadData]);
            setLoading(false);
          }
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, token, userIsAdmin, pages]);

  // Adicionar novos dados no select dos Dispositivos
  const handleLoadMore = () => {
    setPages((prevPage) => prevPage + 1);
  };

  React.useEffect(() => {
    // Após selecionar um Dispositivo, resgata a lista de Controladores do Dispositivo
    if (macId) {
      if (devices.length === 0) {
        getNumbersOfDevices(true, pages, 10, "", userIsAdmin ? 1 : 0, "").then(
          (res) => {
            if (res?.data) {
              const payloadData = res.data.items?.sort((s: any) =>
                s.online === true ? -1 : 1
              );
              if (res.data.this_item_list_qtn === res.data.total) {
                setNotShow(true);
              }
              setPayloadDevices(res.data.items);
              setDevices((prevOptions: any) => [
                ...prevOptions,
                ...payloadData,
              ]);
              setLoading(false);
              const currentSub = devices
                .filter((f: any) => f.id === macId)[0]
                ?.subscriptions.filter((f: any) => f.status === "ACTIVE")[0];
              setStartDay(
                getDeviceStorageTime(currentSub?.plan, currentSub?.created_at)
              );
            }
          }
        );
      } else {
        // Data mínima do início do relatório de acordo com o plano do Dispositivo
        const currentSub = devices
          .filter((f: any) => f?.id === macId)[0]
          ?.subscriptions.filter((f: any) => f.status === "ACTIVE")[0];
        setStartDay(
          getDeviceStorageTime(currentSub?.plan, currentSub?.created_at)
        );
      }

      getControllersOfDevice(macId).then((res) => {
        const filterControllers = res.data.items.map(
          (controller: { modelsUsed: string | any[]; name: any }) => {
            const seenModels = new Map(); // Tracks seen modelos with their index

            // Iterate in reverse to prioritize last occurrences and track the indexes
            for (let i = controller.modelsUsed.length - 1; i >= 0; i--) {
              const model = controller.modelsUsed[i];
              if (
                model.nome === controller.name ||
                !seenModels.has(model.modelo)
              ) {
                // Keep if nome matches the controller's name or if not yet seen
                seenModels.set(model.modelo, i);
              }
            }

            // Create the filtered modelsUsed array using the recorded indexes
            const modelsUsed = Array.from(seenModels.values()).map(
              (index) => controller.modelsUsed[index]
            );

            return { ...controller, modelsUsed };
          }
        );
        setControllers(filterControllers);
      });
    }
  }, [devices.length, id, macId, userIsAdmin, pages, devices]);

  // Renderiza um Spinner ao apertar no botão 'Aplicar'
  const updateReports = () => {
    setUpdateLoading(true);
    setTimeout(() => {
      setUpdateLoading(false);
    }, 30000);
  };

  return (
    <Box display="grid">
      <Box overflow="auto" whiteSpace="nowrap">
        <Box display="flex" justifyContent="center">
          <Card elevation={3} sx={{ width: 1500, p: mobile ? 0 : 1, mb: 2 }}>
            <CardContent sx={{ p: mobile ? 0.5 : 1 }}>
              <Box display="flex" justifyContent="space-between" ml={2} mr={2}>
                <Typography
                  fontSize={mobile ? 22 : 25}
                  gutterBottom
                  sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                >
                  {t("TEXT.REPORTS")}
                </Typography>
              </Box>
              <Card elevation={0}>
                <CardContent sx={{ pl: mobile ? 0.5 : 2, pr: mobile ? 0 : 2 }}>
                  <Form
                    startDay={startDay ?? new Date()}
                    controllers={controllers}
                    setMacId={setMacId}
                    devices={devices}
                    setSubmit={setSubmit}
                    setClean={setClean}
                    updateReports={updateReports}
                    setFormValue={setFormValue}
                    setModel={setModel}
                    setName={setName}
                    setFormDate={setFormDate}
                    setMac={setMac}
                    setUpdateLoading={setUpdateLoading}
                    handleLoadMore={handleLoadMore}
                    loading={loading}
                    payloadDevices={payloadDevices}
                    notShow={notShow}
                  />
                </CardContent>
              </Card>
              <Card elevation={0}>
                <CardContent sx={{ pl: mobile ? 0.5 : 2, pr: mobile ? 0 : 2 }}>
                  {submit ? (
                    <Box>
                      {!clean && (
                        <ReportStructure
                          macId={macId ?? ""}
                          mac={mac ?? ""}
                          formDate={formDate}
                          updateLoading={updateLoading}
                          formValue={formValue}
                          model={model}
                          name={name ?? ""}
                        />
                      )}
                    </Box>
                  ) : (
                    <Card
                      sx={{
                        p: 2,
                        pt: mobile ? 1 : 10,
                        height: mobile ? 200 : 360,
                        textAlign: "center",
                      }}
                    >
                      <CardContent>
                        <EqualizerIcon
                          sx={{ width: 100, height: 100 }}
                          color="primary"
                        />
                        <Typography variant="h5" fontSize={mobile ? 16 : 22}>
                          {t("TEXT.REPORT_MESSAGE")}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Report;
