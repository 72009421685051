import React, { Context } from "react";

import { Badge, Box, Menu, styled } from "@mui/material";

import MenuItems from "./MenuItems";
import { AuthContext } from "../../../contexts/AuthContext";
import AvatarIcon from "../../AvatarIcon";
import { red } from "@mui/material/colors";

const StyledIcon = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

interface AccountMenuProps {
  colorModeContext: Context<{ toggleColorMode: () => void }>;
  setChangeColor: (e: string) => void;
}

const AccountMenu = ({
  colorModeContext,
  setChangeColor,
}: AccountMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = React.useContext(AuthContext);
  const open = Boolean(anchorEl);

  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          cursor: "pointer",
        }}
        onClick={handleClick}
        display="flex"
        alignItems="center"
      >
        <StyledIcon
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <AvatarIcon name={user?.login} bgcolor={red[600]} />
        </StyledIcon>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItems
          handleClose={handleClose}
          setChangeColor={setChangeColor}
          colorModeContext={colorModeContext}
        />
      </Menu>
    </>
  );
};

export default AccountMenu;
